<template>
  <modal class="wliveMemberPaymentModel" :isShow="isShow">
    <div class="header">微生活支付<span class="close" @click="hide()">×</span></div>
   
        <tabs class="modal-body" >
            <div class="cover" v-show="PayResultCode==1"></div>
            <div ref="CardMoney">
            <div class="filter-nav">
                <div class="nav-box">
                    <tab-nav class="nav-li">按会员卡号支付</tab-nav>
                </div>
                <div class="PointCheck">
                    <el-checkbox v-model="PointChecked">不参与线上积分活动</el-checkbox>
                </div>
            </div>
             <div></div>
            <div class="clearfix" >
                <div class="left-box">
                    <tab-pane>
                        <div class="tr-li">
                            请扫码或输入付款码：
                            <div class="from-input" style="width:325px">
                                <input type="text" ref="input" class="key-focus" data-model='idCode' @focus="(e)=>e.target.select()" v-model="idCode" @keyup.enter="onMemberInfo()" />
                                <i class="iconfont icon-sousuo" @click="onMemberInfo()"></i>
                            </div>
                            <button class="btn" @click="readCard()">读卡</button>
                        </div>
                        <div class="table-box">
                            <div class="tr-li">
                                <div class="lable-txt" style="width:220px;">会员卡号：<span style="color: #000;font-size: 14px;">{{meberInfo.Card_No}}</span></div>
                                <div class="lable-txt" style="width:200px;">会员等级：<span style="color: #000;font-size: 14px;">{{meberInfo.Bestech_Card_KindName}}</span></div>
                                <div class="lable-txt" style="width:145px;">姓名：<span style="color: #000;font-size: 14px;">{{meberInfo.MemberName}}</span></div>
                            </div>
                            <div class="tr-li">
                                <div class="lable-txt" style="width:220px;">
                                    <span style="letter-spacing:0.5em;">手机</span>号:
                                    <span style="color: #000;font-size: 14px;">{{meberInfo.Mobile}}</span>
                                </div>
                                <div class="lable-txt" style="width:200px;">
                                    性<span style="padding-left:25px;">别</span>：
                                    <span style="color: #000;font-size: 14px;" v-if="meberInfo.Gender>-1">{{meberInfo.Gender==0?'男':'女'}}</span>
                                </div>
                                <div class="lable-txt" style="width:145px;">生日：<span style="color: #000;font-size: 14px;">{{meberInfo.Birthday}}</span></div>
                            </div>
                            <div class="tr-li">
                                <div class="lable-txt" style="width:220px;">
                                    余<span style="padding-left:25px;">额</span>:
                                    <span style="color: red;font-size: 17px;font-weight:600;" v-if="meberInfo.Card_Money>0">{{Number(meberInfo.Card_Money)}}</span>
                                    <span style="color: red;" v-if="meberInfo.Gift_Money>0" >（赠{{Number(meberInfo.Gift_Money)}}）</span>
                                </div>
                                <div class="lable-txt" style="width:200px;">
                                    <span style="letter-spacing:0.6em;">可用</span>券：
                                    <span style="color: red;font-size: 17px;font-weight:600;">{{meberInfo.Live_CouponsNum}}</span>
                                    <span style="color: red;padding-left:5px;" v-if="meberInfo.Live_CouponsNum">张</span>
                                </div>
                                <div class="lable-txt" style="width:145px;">积分：
                                    <span style="color: red;font-size: 17px;font-weight:600;" v-if="meberInfo.Card_PilePoint>0">{{Number(meberInfo.Card_PilePoint)}}</span>
                                </div>
                            </div>
                        </div>
                        <tabs class="coupons-box">
                            <div class="filter-nav">
                                <div class="nav-box">
                                    <tab-nav class="nav-li">代金券</tab-nav>
                                    <tab-nav class="nav-li">商品券</tab-nav>
                                </div>
                                <div class="float-right">
                                    <div class="bnt-fangxiang" ref="item-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                    <div class="bnt-fangxiang" ref="item-down"><i class="iconfont icon-fangxiang-down"></i></div>
                                </div>
                            </div>
                            <div class="item-box" v-scroll-anime="{up:'item-up',down:'item-down',liClass:'.item-li',paddingH:8,marginH:12,isRow:false}">
                                <tab-pane class="tab-pane">
                                    <div class="item-li " :class="{selected:item.Check==true}" v-for="item in cashCoupon" :key="item" @click="oncashClick(item)">
                                        <div class="top-tags">
                                            <div class="left">{{item.Coupon_Name}}</div>
                                            <div class="right">{{Number(item.Coupon_FaceValue)}}</div>
                                        </div>
                                        <div class="titles"><span class="tages-span">有效期:</span>{{new Date(item.Begin_Date).Format("yy/MM/dd")}}~{{new Date(item.End_Date).Format("yy/MM/dd")}}</div>
                                        <div class="left-bottom-tags">
                                            <span class="tages-span">券规则:</span>{{item.Description}}
                                        </div>
                                         <i class="iconfont icon-gou"></i>
                                    </div>
                                </tab-pane>
                                <tab-pane class="tab-pane">
                                    <div class="item-li " :class="{selected:item.Check==true}" v-for="item in commodityVoucher" :key="item" @click="oncashClick(item)">
                                        <div class="top-tags">
                                            <div class="left">{{item.Coupon_Name}}</div>
                                            <div class="right">{{discountShow(item)}}</div>
                                        </div>
                                        <div class="titles"><span class="tages-span">有效期:</span>{{new Date(item.Begin_Date).Format("yy/MM/dd")}}~{{new Date(item.End_Date).Format("yy/MM/dd")}}</div>
                                        <div class="left-bottom-tags">
                                            <span class="tages-span">券规则:</span>{{item.Description}}
                                        </div>
                                         <i class="iconfont icon-gou"></i>
                                    </div>
                                </tab-pane>
                            </div>
                        </tabs>
                        <div class="limitClass" v-if="meberInfo.Is_Limit_BalancePay==1">注:最大可用储值&nbsp;
                            <span class="limitSpan">{{Number(meberInfo.BalancePay_Limit)}}</span>
                        </div>
                        <div class="limitClass" v-if="meberInfo.Is_Limit_PointPay==1">注:积分使用限制数量&nbsp;
                            <span class="limitSpan">{{Number(meberInfo.PointPay_Limit)}}</span>
                        </div>
                        <div class="limitClass">注:剩余可开票金额&nbsp;
                            <span class="limitSpan" v-if="meberInfo.InvoiceMoney_Remain">{{Number(meberInfo.InvoiceMoney_Remain)}}</span>
                            <span class="limitSpan" v-else>0</span>
                        </div>
                    </tab-pane> 
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="money-info">
                <div class="lable-txt">待支付金额：
                    <b class="noney red" v-if="unPaidMoney>0">{{(Conversion((unPaidMoney.toFixed(2)-Number(PointPayCouponMoney)).toFixed(2)))}}</b>
                    <b class="noney red" v-if="unPaidMoney<=0">0</b>
                </div>
                <div class="lable-txt" v-show="is_CardPayShow">储值支付：
                    <input type="text" class="key-focus" style="color:red;" ref="couponsPayinput" data-model='couponsPay' v-model="couponsPay"  @click="onCouponPayClick()" />
                </div>
                <div class="lable-txt" v-show="is_CardPayShow">积分支付：
                    <input type="text" class="key-focus"  style="color:red;" ref="PointPayinput"  data-model='PointPay' :placeholder="PointPay_Scale"  v-model="PointPay" @click="onIntegralClick()"/>
                </div>
                <div class="lable-txt" v-show="is_CardPayShow">券抵扣：<b class="red">{{couponsMoney.toFixed(2)}}</b></div>
            </div>
            </div>
        </tabs>
        <div class="fastfooter-box">
            <div style="position:absolute;width:91%;height:10%;" v-show="PayResultCode==1" ></div>
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-border" @click="select()">重新查询支付结果</button>
            <!--<button class="btn btn-border" @click="quchongzhi()" >去充值</button> @click="scanCodePay=true"-->
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
        <!--付款金额不够弹出-->
        <div class="RechargePopup" :class="{over:Rechargeshow==true}" v-if="Rechargeshow">
            <div class="Rechargeheader">
                <span class="RecharTitle">支付方式</span>
                <span class="RecharTitle">支付金额</span>
                <span class="RecharTitle">剩余金额:{{(unPaidMoney.toFixed(2)-Number(PointPayCouponMoney)).toFixed(2)}}</span>
                
            </div>
        <div class="clearfix" ref="zf">
                <div class="tab-content">
                        <div class="left-box">
                            <div class="table-box">
                                <table>
                                    <thead>
                                        <tr>
                                            <th style="width:110px;">选择</th>
                                            <th >支付方案</th>
                                            <th style="width:140px;">金额（元）</th>
                                        </tr>
                                    </thead>
                                </table>
                                <table>
                                    <tbody>
                                        <tr style="height:0">
                                            <td style="width:110px;height:0px"></td>
                                            <td style="height:0px"></td>
                                            <td style="width:140px;height:0px"></td>
                                        </tr>
                                        <tr style="border-bottom: 1px solid #ddd;" v-for="(item,index) in rechargeList" :key="index" :class="{selected:rechargeclass==item.rechargeId}" @click="radioClick(item)" >
                                            
                                            <td>
                                                <div class="left-shadow" v-show="PayResultCode==1" ></div>
                                                <label class="checkbox" ><input type="radio" name="_fa_radio" :checked="rechargeclass==item.rechargeId" @click.stop="radioClick(item)" /><i class="iconfont icon-iconoption"></i></label>
                                            </td>
                                            <td class="alignLeft">{{item.rechargeName}}</td>
                                            <td v-if="index==0">
                                                <input type="text" ref="money"  class="key-focus" style="width: 100px;height:40px;text-align:center;" v-model="item.rechargeMoney" />
                                            </td>
                                            <td v-if="index==1">
                                                <input type="text" ref="money"  class="key-focus" style="width: 100px;height:40px;text-align:center;" v-model="item.rechargeMoney" />
                                            </td>
                                            <td v-if="index==2">
                                                <input type="text" ref="money"  class="key-focus" style="width: 100px;height:40px;text-align:center;" v-model="item.rechargeMoney" />
                                            </td>
                                        </tr>
                                        <div style="width:100%;height:1px;background-color: #c2bfbf;"></div>
                                    </tbody>
                                </table>
                            </div>
                            <div class="tip-box">
                                {{errorTitle}}
                            </div>
                        </div>
                        <div class="right-box">
                            <div class="right-shadow" v-show="PayResultCode==1" ></div>
                            <div class="keyboard">
                                <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                        data-value="9">9</span></div>
                                <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                        data-value="6">6</span></div>
                                <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                        data-value="3">3</span></div>
                                <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                        data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                            </div>
                            <div class="timer" v-show="timerCount>0">
                                <i class="iconfont icon-shijian"></i><span class="timer-shijian">{{timerCount}}秒</span>
                            </div>
                        </div>
                </div>
                <div class="footer-box">
                    <button class="btn btn-cancels"  @click="Rechargehide()">
                        <span v-show="this.PayResultCode==1"><i class="iconfont icon-suo"></i></span>取消</button>
                    <button class="btn btn-confirms"  @click="Rechargedefine()">确认</button>
                </div>
            </div>
        </div>
        <!--付款金额不够选择他类型弹出-->
        <div class="RechargePopup" :class="{payover:payKindShow==true}" v-if="payKindShow">
            <div class="Popup-Title">
                <span class="Popup-Title-left" v-on:click="payKindShow=false">其他支付方式</span>
            </div>
            <div class="payment-list-box" style="background-color:#E9E9EE;">
                <div class="clearfix">
                    <div class="li" :class="{selected:ispayid==pay.PAY_ID}" v-for="pay in payType" :key="pay" @click="onChoicePayType(pay)" v-show="pay.INTERFACE_TYPE==2 || pay.INTERFACE_TYPE==9021001" >
                        {{pay.PAY_NAME}}
                    </div>
                </div>
            </div>
            <div class="money-info">
                <div class="lable-txt">待支付金额：
                    <b class="noney red" v-if="unPaidMoney>0">{{(Conversion((unPaidMoney.toFixed(2)-Number(PointPayCouponMoney)).toFixed(2)))}}</b>
                    <b class="noney red" v-if="unPaidMoney<=0">0</b>
                </div>
                <div class="lable-txt" style="margin-right:40px;">金额：
                    <input type="number" class="red" data-model='per'  v-model="otherPay" />
                </div>
            </div>
            <div class="footer-box">
                    <button class="btn btn-cancels"  @click="payKindShow=false">取消</button>
                    <button class="btn btn-confirms"  @click="PayClick()">确认</button>
            </div>
        </div>
    <!-- 扫码付 -->
    <scanCode-Pay-model  :isShow="scanCodePay" :memberPay="1" v-on:closeModel="scanCodePay=false,timerCount=0" :rechargeMoney="rechargeMoney" :orderInfo="orderInfo" :payTypeSSPay="payTypeSSPay" @memberCodePayModel="memberCodePayModel" @scanCodeclose="scanCodeclose" ></scanCode-Pay-model>
    <!--会员卡信息-->
    <modal-load :isShow="cardMemberShow">
        <card-member-Model :isShow="cardMemberShow" v-on:closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo" ></card-member-Model>
    </modal-load>
    <modal-load :isShow="cardCodeShow">
        <card-code-model :isShow="cardCodeShow" v-on:closeModel="cardCodeShow=false" @cardCodeConfirm="cardCodeConfirm"></card-code-model>
    </modal-load>
    <!--密码验证-->
    <modal-load :isShow="passwordShow">
        <password-confirm-model :isShow="passwordShow" v-on:closeModel="passwordShow=false" @passwordConfirm="passwordConfirm"></password-confirm-model>
    </modal-load>
  </modal>
</template>


<script>
import cardCodeModel from '../../diningDetail/model/cardCodeModel.vue'
import passwordConfirmModel from '../../diningDetail/model/passwordConfirmModel.vue'
import cardMemberModel from '../../model/cardMemberModel/cardMemberModel.vue'
import {initKeyBoard,NumberConversion} from '/src/common' 
import { httpAES } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"
import scanCodePayModel from '../scanCodePayModel/scanCodePayModel.vue'
import ModalLoad from '../../../../template/modal/modalLoad.vue'

/**会员支付 */
export default {
    name:"wliveMemberPaymentModel",
    components:{
        scanCodePayModel,
        cardMemberModel,
        cardCodeModel,
        ModalLoad,
        passwordConfirmModel
        // paymentOptionsModel
    },
    props:{
        isShow:Boolean,
        orderInfo:Object,
        choicePayType:Object,
        payType:Object,
        snackPaidMoney:Number,
        orderMenuList:Object,
        MantissaMoney:Number,
        headingInfo:Object,
        Channellike:String,
        RoomAutoID:String,
        DeskName:String,
        EatAutoIDs:String,
        Timestamps:String,
        paymentHistory:Object,
        totalMoney:Number,
        discountMoneuy:Number
    },
    data(){
        return {
            cardMemberShow:false,
            scanCodePay:false,
            payTypeSSPay:'',
            payKindShow:false,
            Rechargeshow:false,
            RechargeType:0,
            is_CardPayShow:true,
            cardCodeShow:false,
            passwordShow:false,
            is_AllowMixUsing:false, //是否有不可混用券
            is_AllowMixUsingCoupon_Name:'', //不可混用券
            idCode:'',//身份码
            payCode:'',//付款码
            meberInfo:'', //会员信息
            cashCoupon:'', //代金券
            commodityVoucher:'',//商品券
            couponsCount:0,  //选择的券总数量
            couponsMoney:0, //券的抵扣金额
            couponsPay:'', //储值支付金额
            PointPay:'', //积分支付积分
            PointPayCouponMoney:'',
            PointPayMoney:0, //积分转换成的金额
            PointPay_Scale:'', //积分比例
            unPaidMoney:0, //待付金额
            choiceCoupons:[], //选择的券
            errCounc:1,
            memberorderInfo:'',  //临时订单数据
            /**充值基础显示 */
            rechargeList:[
                {rechargeName:'现金',rechargeId:'1',rechargeMoney:0,Payment_ID:'67a33e74-e428-43f9-b152-ad6e1c0bfcaf',Payment_Code:'1',Payment_Type:1},
                {rechargeName:'聚合支付',rechargeId:'2',rechargeMoney:0,Payment_ID:'SSPay',Payment_Code:'SSPay',Payment_Type:801010301},
                {rechargeName:'其他',rechargeId:'3',rechargeMoney:0,Payment_ID:'',Payment_Code:'',Payment_Type:0}],
            rechargeMoney:0,
            rechargeMoney1:0,
            rechargeMoney2:0,
            rechargeMoney3:0,
            /**选择样式 */
            rechargeclass:0,
            /**选择券 */
            ispayid:0,
            ispayName:'',
            otherPay:'',
            Payment_Code:'',
            Payment_Type:0,
            scanPayCode:'',
            eatAutoID:'',
            /**是否触发过订单提交接口*/
            PayResultCode:0,
            /**是否触发过会员查询 */
            isGetMember:false,
            /**保存订单支付返回记录 */
            payPreviewData:'',
            /**card_AutoID */
            card_AutoID:'',
            /**内卡号 */
            cardSNR:'',
            /**错误提示 */
            errorTitle:'',
            cardMemberList:[], //会员卡信息
            verifyCode:'', //验证码
            PointChecked:false, //是否参与线上积分支付
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            /**临时存放卡号 */
            tempidCode:'',
            /**默认倒计时 */
            timerCount:0
        }
    },
    mounted(){
       this.$nextTick(()=> {
           initKeyBoard.call(this,this.$refs.CardMoney);
           initKeyBoard.call(this,this.$refs.zf);
           this.$refs.input?.focus();
           this.openimp()
        })
    },
    watch: {
        snackPaidMoney:{
            immediate:true,
            handler(vla){
                this.EmptyDatas();
                this.memberorderInfo = JSON.parse(JSON.stringify(this.orderMenuList))
                if(this.memberorderInfo){
                    // this.memberorderInfo.map((order,i)=>{
                    //     order = Object.assign(order,{isCoupon:false,CouponNumber:0})
                    //     if(order.Is_Gift==true || (order.Eat_Number==0 && order.Refund_Number>0)){
                    //         this.memberorderInfo.splice(i, 1)
                    //         return 
                    //     }
                    // })
                    for(let i=0; i<this.memberorderInfo.length; i++){
                        this.memberorderInfo[i] = Object.assign(this.memberorderInfo[i],{isCoupon:false,CouponNumber:0})
                        if(this.memberorderInfo[i].Is_Gift==true || (this.memberorderInfo[i].Eat_Number==0 && this.memberorderInfo[i].Refund_Number>0)){
                            this.memberorderInfo.splice(i,1)
                            i--
                        }
                    }
                }
                this.memberorderInfo.sort(this.compare('Eat_CheckPrice',''));
                this.unPaidMoney= Number(Number(this.snackPaidMoney).toFixed(2))
                this.couponsPay=this.unPaidMoney
                this.payType.map(pay=>{
                    if(pay.INTERFACE_TYPE==1){
                        this.rechargeList[0].Payment_ID=pay.PAY_ID
                        this.rechargeList[0].Payment_Code = pay.PAY_CODE
                        this.rechargeList[0].Payment_Type = pay.INTERFACE_TYPE
                    }
                    if(pay.INTERFACE_TYPE==801010301){
                        this.rechargeList[1].Payment_ID=pay.PAY_ID
                        this.rechargeList[1].Payment_Code = pay.PAY_CODE
                        this.rechargeList[1].Payment_Type = pay.INTERFACE_TYPE
                    }
                })
                this.PointPayCouponMoneyInfo(0)
            }
        },
        timerCount:{
            handler(value) {
                setTimeout(() => {
                    if(this.timerCount>1){
                        this.timerCount--;
                    }else{
                        this.timerCount=0
                        this.PayResultCode=0
                    }
                }, 1000);
            },
            immediate: true
        },
        PayResultCode(){
            if(this.PayResultCode==0){
                clearTimeout(this._timePay);//清除定时器
            }
        },
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            if(this.isShow==true){
                this.openimp()
            }else{
                this.create();
            }
        },
        couponsPay(){
            if(this.couponsPay<0){
               this.couponsPay=0
            }
            //限制最大可用储值金额
            if(this.meberInfo?.Is_Limit_BalancePay==1){
                if(Number(this.couponsPay)>Number(this.meberInfo?.BalancePay_Limit)){
                    this.couponsPay = Number(this.meberInfo?.BalancePay_Limit)
                }
            }
            this.PointPayCouponMoneyInfo(1)
        },
        PointPay(){
            if(this.PointPay<0){
               this.PointPay=0
            }else{
                if(Number(this.PointPay)>this.meberInfo.Card_PilePoint){
                    this.$message.warning('您的可用积分为:'+this.meberInfo.Card_PilePoint+'');
                    this.PointPay=this.meberInfo.Card_PilePoint
                    return
                }
                if(this.meberInfo?.Is_Limit_PointPay==1){
                    if(Number(this.PointPay)<=this.meberInfo?.PointPay_Limit){
                        this.PointPayMoney = Number(this.PointPay) / this.meberInfo.PointPay_Scale
                    }else{
                        this.PointPay=this.meberInfo?.PointPay_Limit
                    }
                }else{
                    if(this.meberInfo.PointPay_Scale){
                        this.PointPayMoney = Number(this.PointPay) / this.meberInfo.PointPay_Scale
                    }
                }
            }
            this.PointPayCouponMoneyInfo(2)
        },
        rechargeMoney1(){
            if(this.PayResultCode!=1){
                 this.rechargeList.map(re=>{
                    if(re.rechargeId==1){
                        re.rechargeMoney = this.rechargeMoney1
                    }
                })
            }
           
        },
        rechargeMoney2(){
            if(this.PayResultCode!=1){
                this.rechargeList.map(re=>{
                    if(re.rechargeId==2){
                        re.rechargeMoney = this.rechargeMoney2
                    }
                })
            }
        },
        rechargeMoney3(){
            if(this.PayResultCode!=1){
                this.rechargeList.map(re=>{
                    if(re.rechargeId==3){
                        re.rechargeMoney = this.rechargeMoney3
                    }
                })
            }
        },
    },
    methods:{
        openimp(){
            this.EmptyDatas();
            this.idCode='',this.errorTitle='',this.tempidCode=''
            this.initializaTion()
            this.unPaidMoney= Number(Number(this.snackPaidMoney).toFixed(2))
            this.rechargeList=[
                {rechargeName:'现金',rechargeId:'1',rechargeMoney:0,Payment_ID:'',Payment_Code:'1',Payment_Type:1},
                {rechargeName:'聚合支付',rechargeId:'2',rechargeMoney:0,Payment_ID:'SSPay',Payment_Code:'SSPay',Payment_Type:801010301},
                {rechargeName:'其他',rechargeId:'3',rechargeMoney:0,Payment_ID:'',Payment_Code:'',Payment_Type:0}]

            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderMenuList))
            if(this.memberorderInfo){
                // this.memberorderInfo.map((order,i)=>{
                //     order = Object.assign(order,{isCoupon:false,CouponNumber:0})
                //     if(order.Is_Gift==true || (order.Eat_Number==0 && order.Refund_Number>0)){
                //         this.memberorderInfo.splice(i, 1)
                //         return 
                //     }
                // })
                for(let i=0; i<this.memberorderInfo.length; i++){
                    this.memberorderInfo[i] = Object.assign(this.memberorderInfo[i],{isCoupon:false,CouponNumber:0})
                    if(this.memberorderInfo[i].Is_Gift==true || (this.memberorderInfo[i].Eat_Number==0 && this.memberorderInfo[i].Refund_Number>0)){
                        this.memberorderInfo.splice(i,1)
                        i--
                    }
                }
            }
            this.memberorderInfo.sort(this.compare('Eat_CheckPrice',''));
            this.unPaidMoney= Number(Number(this.snackPaidMoney).toFixed(2))
            this.payType.map(pay=>{
                if(pay.INTERFACE_TYPE==1){
                        this.rechargeList[0].Payment_ID=pay.PAY_ID
                        this.rechargeList[0].Payment_Code = pay.PAY_CODE
                        this.rechargeList[0].Payment_Type = pay.INTERFACE_TYPE
                }
                if(pay.INTERFACE_TYPE==801010301){
                    this.rechargeList[1].Payment_ID=pay.PAY_ID
                    this.rechargeList[1].Payment_Code = pay.PAY_CODE
                    this.rechargeList[1].Payment_Type = pay.INTERFACE_TYPE
                }
            })
            this.PointPayCouponMoneyInfo(0)
        },
        /**聚合支付取消 */
        scanCodeclose(){
            this.scanCodePay=false
        },
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        blurRechargeMoney(){
            if(this.unPaidMoney.toFixed(2)<0){
                this.unPaidMoney =0
            }
        },
        /**获取支付方式*/
        onChoicePayType(pay){    
            if(this.ispayid != pay.PAY_ID){
                this.ispayid = pay.PAY_ID
                this.ispayName = pay.PAY_NAME
                this.Payment_Code = pay.PAY_CODE
                this.Payment_Type = pay.INTERFACE_TYPE
            }
        },
        Rechargedefine(){
            this.RechargeType=1
            let money=0
            let NewMoney = this.unPaidMoney.toFixed(2)
            this.rechargeList.map(re=>{
                money = Number(money) + Number(re.rechargeMoney)
            })
            if(money!=((NewMoney-Number(this.couponsPay)-this.PointPayMoney).toFixed(2))){
                this.$message.error('应付金额和剩下需要付款金额不相等');
            }else{
                this.confirm()
            }
        },
        /**实体卡读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.idCode=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.onMemberInfo()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**其他支付 */
        PayClick(){
            if(this.ispayid==0){
                this.$message.error('请先选择对应的支付方式');
                return
            }
            if(this.otherPay==''){
                this.$message.error('请输入对应金额');
                return
            }
            if(Number(Number(this.otherPay).toFixed(2))>Number(Number(this.unPaidMoney).toFixed(2))){
                this.$message.error('金额不能大于待支付金额');
                return
            }
            this.rechargeList.map(re=>{
                if(re.rechargeId=='3'){
                    re.rechargeName = this.ispayName
                    re.rechargeMoney = Number(this.otherPay).toFixed(2)
                    re.Payment_ID = this.ispayid
                    re.Payment_Code = this.Payment_Code
                    re.Payment_Type = this.Payment_Type
                }
            })
            this.payKindShow=false
        },
        /**查询支付结果 */
        select(){
           if(this.EatAutoIDs!=''){
                this.eatAutoID = this.EatAutoIDs
                this.ordertimestamps = this.Timestamps
            }
            if(this.eatAutoID==''){
                this.$message.error('没有需要查询的支付结果');
                return
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            const loading = this.$loading({
                    text: "查询支付结果中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
            });
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.eatAutoID, //结账单ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_EquipmentID:this.userInfo?.Site_Serial, //设备id
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Timestamps:this.ordertimestamps.toString(), //账单时间戳
            }
            if(this.ordertimestamps!=''){
                param.Timestamps = this.ordertimestamps.toString();
            }
            httpAES.post("Bestech.CloudPos.RequeryThePaymentResult", param).then((d)=>{
                loading.close()
                if(d.ResponseHeader.ResultCode!=0){
                    this.errorTitle = d.ResponseHeader.ResultDesc
                    this.ispaySuccess=1
                    if(d.ResponseHeader.ResultCode==4003){
                        this.$message.error(d.ResponseHeader.ResultDesc);
                        this.ispaySuccess=0
                        this.payCode=''
                        this.ordertimestamps = d.ResponseBody?.Timestamps.toString();
                    }
                    return;
                }else{
                    let ResultDesc = '未查询到订单的支付结果'
                    let ResuType=0
                    if(d.ResponseHeader.ResultDesc==''){
                        d.ResponseHeader.ResultDesc = ResultDesc
                        ResuType = 1
                    }
                    this.$alert(d.ResponseHeader.ResultDesc, '提示', {
                        confirmButtonText: '确定',
                        callback: () => {
                            this.ordertimestamps = d.ResponseBody.Timestamps;
                            this.ispaySuccess=0;
                            this.$emit("ThePaymentResultClic",d,this.payCode)
                        }
                    });
                    return;
                }
            }).catch((e)=>{
                loading.close()
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**获取会员信息 */
        onMemberInfo(){
            if(!this.idCode){
                this.$message.error('查询码不能为空');
                return
            }
            this.idCode =this.idCode.replace(/[;,；,=,＝,?,？]/g,"");
            //this.$refs.input.blur();
            this.$refs.input?.select();
            if(this.isGetMember==true){
                if(this.tempidCode!=this.idCode){
                    this.idCode = this.tempidCode
                }
                return
            }else{
                this.isGetMember=true
            }
            if(!this.tempidCode){
                this.tempidCode=this.idCode
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            this.initializaTion()
            let ConsumeList=[]
            let _memberorderInfo= JSON.parse(JSON.stringify(this.orderMenuList))
            _memberorderInfo.map(order=>{
                let item={
                    Eat_ConsumeID:order.Eat_ConsumeID,
                    Eat_XFBMID:order.EAT_XFBMID,
                    Eat_XFBMName:order.EAT_XFName,
                    Eat_Number:order.Eat_Number,
                    Eat_Price:order.Eat_SalePrice, //折前价格
                    Eat_ZkPrice:order.Eat_CheckPrice,//折扣后的价格
                }
                ConsumeList.push(item)
            })
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,  //用户id
                DynamicNo:this.idCode,
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
                Member_Type:4,
                IsGetCouponUsingRules:true,
                Need_CheckBillMoney:true,/**是否在读卡时核算账单消费优惠金额 */
                OrderConsumes:ConsumeList, //核算账单优惠时，请求时传入账单菜品明细 
            }
            const loading = this.$loading({
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",param).then((data)=>{
                loading.close();
                this.isGetMember=false
                this.tempidCode=''
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.cardSNR=''
                    this.card_AutoID=''
                    if(data.ResponseBody.length>1){
                        this.cardMemberList = data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.isread=true
                        this.meberInfo=data.ResponseBody[0];
                        this.cardInfoShow()
                        this.$emit('memberCardBack',this.meberInfo)
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.isGetMember=false
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**券选择 */
        oncashClick(item){
            if(item.Check==true){
                if(item.Coupon_Kind==2){
                    this.memberorderInfo.map(order=>{
                        this.choiceCoupons.map(cho=>{
                            if(order.Eat_ConsumeID==cho.Eat_ConsumeID && item.Coupon_AutoID==cho.Coupon_AutoID){
                                //DiscountType:3.按份量抵扣 */
                                if(item.DiscountType==3){
                                    order.CouponNumber = Number(this.accSub(cho.ExchangeNum,order.CouponNumber))
                                }else{
                                    if(order.CouponNumber>=1){
                                        order.CouponNumber = order.CouponNumber -1
                                    }
                                }
                                if(order.CouponNumber<order.Eat_Number){
                                    order.isCoupon=false
                                }
                            }
                        })
                    })
                    this.commodityJudge(item,2)
                }else{
                    this.unPaidMoney =this.unPaidMoney + Number(item.Coupon_FaceValue)
                    this.couponsMoney = Number(this.couponsMoney)-Number(item.Coupon_FaceValue)
                    this.couponsPay = Number(Number(this.couponsPay)-Number(item.Coupon_FaceValue)).toFixed(2)
                    if(Number(this.couponsPay)< this.unPaidMoney){
                       this.couponsPay = Number(Number(this.unPaidMoney)-Number(this.PointPayMoney)).toFixed(2)
                    }
                    if(Number(this.meberInfo?.Card_Money)<this.couponsPay){
                        this.couponsPay = Number(this.meberInfo?.Card_Money)
                    }
                    item.Coupon_FaceValue = item.CouponOriMoney
                }
                this.couponsCount--;
                item.Check =false;
                this.choiceCoupons = this.choiceCoupons.filter(t => t.Coupon_AutoID != item.Coupon_AutoID)
                /**券的使用规则 */
                if(item.CouponUsingDetailedRules){
                    /**是否可以混用 */
                    let tktype=0
                    this.choiceCoupons.map(ch=>{
                        if(ch.Tk_AutoID==item.Tk_AutoID){
                            tktype=1
                        }
                    })
                    if(this.is_AllowMixUsing==true && tktype!=1){
                        this.is_AllowMixUsing = false;
                    }
                }
            }else{
                if(this.unPaidMoney<=0){
                    this.$message.error('付款金额已够,无需再选用多余的券');
                    return
                }
                 /**券的使用规则 */
                if(item.CouponUsingDetailedRules){
                    this.errCounc =1
                    let _count=0;
                    //每次消费最多可用张数
                    let _UseMaxNum = item.CouponUsingDetailedRules.UseMaxNum;
                    if(_UseMaxNum!=0){
                        this.choiceCoupons.map(cho=>{
                            if(cho.Tk_AutoID==item.Tk_AutoID){
                                _count++;
                            }
                        })
                        if(_count>=_UseMaxNum){
                            this.$message.error('超出每次消费使用次数');
                            this.errCounc++
                            return
                        }
                    }
                    //判断是否存在不可混用券
                    if(this.is_AllowMixUsing==true){
                        if(item.Coupon_Name!=this.is_AllowMixUsingCoupon_Name){
                            this.$message.error(this.is_AllowMixUsingCoupon_Name+'不可与其他券混用');
                            this.errCounc++
                            return;
                        }
                    }
                    //判断是否存在部分不可混用的券
                    if(this.couponsCount>0){
                        let _coupname=''
                        let _coupType=0
                        this.choiceCoupons.map(cho=>{
                            if(cho.CouponUsingDetailedRules){
                                if(cho.CouponUsingDetailedRules.Is_AllowMixUsing==3){
                                    cho.CouponUsingDetailedRules.ForbidMixUsingCoupons.map(coupon=>{
                                        if(coupon.TK_AutoID == item.Tk_AutoID){
                                            _coupType=1
                                            _coupname=''+item.Coupon_Name+'不可与'+cho.Coupon_Name+'混用'
                                            this.errCounc++
                                            return;
                                        }
                                    })
                                }
                            }            
                        })
                        if(_coupType==1){
                             this.$message.error(_coupname);
                             return;
                        }
                    }
                    //是否可以混用 1.可以 2.不可以 3.部分不可以
                    if(item.CouponUsingDetailedRules.Is_AllowMixUsing==2){
                        if(this.couponsCount>0){
                            let _coupname=''
                            let _coupType=0
                            this.choiceCoupons.map(cho=>{
                                if(cho.Tk_AutoID!=item.Tk_AutoID){
                                    _coupType=1
                                    _coupname=item.Coupon_Name+'不可与其他券混用'
                                    this.errCounc++
                                    return;
                                }   
                            })
                            if(_coupType==1){
                                this.$message.error(_coupname);
                                return;
                            }   
                        }
                    }
                    if(item.CouponUsingDetailedRules.Is_AllowMixUsing==3){
                        if(this.couponsCount>0){
                            let _coupname=''
                            let _coupType=0
                            item.CouponUsingDetailedRules.ForbidMixUsingCoupons.map(pons=>{
                                this.choiceCoupons.map(cho=>{
                                    if(pons.TK_AutoID==cho.Tk_AutoID){
                                        _coupType=1
                                        _coupname=''+item.Coupon_Name+'不可与'+cho.Coupon_Name+'混用'
                                        this.errCounc++
                                        return;
                                    }
                                })
                            })
                            if(_coupType==1){
                                this.$message.error(_coupname);
                                return;
                            }
                        }
                    }
                    //判断日期
                    if(item.CouponUsingDetailedRules.UseWeeks){
                        let _week = RegExp(this.setNowTimes());
                        let _UseWeeks = item.CouponUsingDetailedRules.UseWeeks;
                        if(!_week.test(_UseWeeks)){
                            this.$message.error('该券今日不可使用');
                            this.errCounc++
                            return;
                        }
                    }
                    //时间段控制 0所有时段,1指定时段
                    if(item.CouponUsingDetailedRules.TimeFrame_ControlType==1){
                        let date = new Date(); //当前时间
                        let hh = date.getHours()+':'+date.getMinutes(); //时:分
                        let hm =hh.split(':')
                        let timeError =''
                        if(item.CouponUsingDetailedRules.UsingTimeFrames.length>0){
                            item.CouponUsingDetailedRules.UsingTimeFrames.map(time=>{
                                let Begin_Time = time.Begin_Time.split(':')
                                let End_Time = time.End_Time.split(':')
                                if(date.setHours(Begin_Time[0],Begin_Time[1]) > date.setHours(hm[0],hm[1]) || date.setHours(hm[0],hm[1]) > date.setHours(End_Time[0],End_Time[1])){
                                    timeError ='该券当前时间不可使用'
                                    this.errCounc++
                                }
                            })
                        }else{
                            timeError ='该券当前时间不可使用'
                            this.errCounc++
                        }
                        if(timeError!=''){
                            this.$message.error(timeError);
                            return
                        }
                    }
                    //节假日控制 0 不限制节假日   1 剔除指定的节假日
                    if(item.CouponUsingDetailedRules.Holiday_ControlType==1){
                        let _timeName=''
                        let _timeType=0
                        let now =  new Date(); //当前时间
                        let time = now.getFullYear()+'-'+(now.getMonth()+1)+'-'+now.getDate()
                        item.CouponUsingDetailedRules.ForbidUsingCouponHolidays.map(days=>{
                            days.Holiday_Dates.map(date=>{
                                if(new Date(time).Format("yyyy-MM-dd") == date){
                                    _timeType=1
                                    _timeName='该券不可在'+days.Holiday_Name+'使用'
                                    this.errCounc++
                                    return
                                }
                            })
                        })
                        if(_timeType==1){
                            this.$message.error(_timeName);
                            return
                        }
                    }
                    
                    //商品券
                    if(item.Coupon_Kind==2){
                        let _coupTypes = 0
                        let _coupname=''
                        let _coupType=0
                        let Eat_Number=0
                        let Eat_ConsumeID=''
                        //如果是折扣券
                        if(item.DiscountType==2){
                            let isFindFood=0
                            this.memberorderInfo.forEach(order=>{
                                if(isFindFood==0){
                                    if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1){
                                        if(order.isCoupon==false && order?.Is_Discounted==true){
                                            if(order.Eat_CheckPrice != order.Eat_SalePrice){
                                                _coupname= order.EAT_XFName+'价格已变动,无法使用折扣券'
                                                _coupType=1
                                            }else{
                                                _coupname=''
                                                _coupType=1
                                                isFindFood=1
                                            }
                                        }else{
                                            _coupname='订单里没有可折的菜品,无法使用折扣券'
                                            _coupType=1
                                        }
                                    }else{
                                        _coupname='订单里没有可折的菜品,无法使用折扣券'
                                        _coupType=1
                                    }
                                }
                            })
                        }
                        if(!_coupname){
                            //&& order.Eat_CheckPrice == order.Eat_SalePrice
                            this.memberorderInfo.some(order=>{
                                let _types = 0
                                if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1 && order.Food_Type!=4){
                                    if(order.isCoupon==false){
                                        _coupTypes=1
                                        Eat_Number = Number(Eat_Number) + Number(order.Eat_Number)
                                        Eat_ConsumeID = order.Eat_ConsumeID
                                        item = Object.assign(item,{Eat_ConsumeID:Eat_ConsumeID})
                                        _types=1
                                    }else{
                                        _coupname='对应的菜品已享受优惠,无法选择多张'
                                        _coupType=1
                                    }
                                    // _coupTypes=1
                                    // Eat_Number = Number(Eat_Number) + Number(order.Eat_Number)
                                }else{
                                    _coupname='订单需要有:'+item.Coupon_Exchange_Dishes+'编号菜品才可使用该券'
                                    _coupType=1
                                }
                                if(_types==1){
                                    return true
                                }
                            })
                        }
                        if(_coupType==1 && _coupTypes==0){
                            this.errCounc++
                            this.$message.error(_coupname);
                            return
                        }
                    }

                    let _ConsumeMoney = item.CouponUsingDetailedRules.ConsumeMoney;
                    let _ConsumeMoney_ControlType = item.CouponUsingDetailedRules.ConsumeMoney_ControlType;
                    //判断金额控制ConsumeMoney_ControlType 0不限金额,1为账单满多少金额可用,2为每满多少金额后可用1张
                    this.CouponProducts(item,_ConsumeMoney,_ConsumeMoney_ControlType)
                    if(this.errCounc==1){
                        this.commodityJudge(item,1)
                        this.CouponsSelect(item,'','')
                    }
                }else{
                    let ConsumeID =''
                    let _FirstNum=0
                    //商品券&& item.DiscountType==3
                    if(item.Coupon_Kind==2){
                        let _coupTypes = 0
                        let _coupname=''
                        let _coupType=0
                        if(item.DiscountType==2){
                            let isFindFood=0
                            this.memberorderInfo.forEach(order=>{
                                if(isFindFood==0){
                                    if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1){
                                        if(order.isCoupon==false && order?.Is_Discounted==true){
                                            if(order.Eat_CheckPrice != order.Eat_SalePrice){
                                                _coupname= order.EAT_XFName+'价格已变动,无法使用折扣券'
                                                _coupType=1
                                            }else{
                                                _coupname=''
                                                _coupType=1
                                                isFindFood=1
                                            }
                                        }else{
                                            _coupname='订单里没有可折的菜品,无法使用折扣券'
                                            _coupType=1
                                        }
                                    }else{
                                        _coupname='订单里没有可折的菜品,无法使用折扣券'
                                        _coupType=1
                                    }
                                }
                            })
                        }
                        if(!_coupname){
                            this.memberorderInfo.map(order=>{
                                if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1 && order.isCoupon==false && order.Food_Type!=4){
                                    if(item.DiscountType==3){
                                        let tempEatNumber = this.accSub(Number(order.CouponNumber),order.Eat_Number)
                                        if(Number(tempEatNumber)<Number(item.DiscountValue)){
                                            _coupname='该券不满足菜品兑换所需要的数量条件'
                                            _coupType=1
                                        }else{
                                            _coupTypes=1
                                            if(_FirstNum==0){
                                                _FirstNum = Number(tempEatNumber)
                                                if(!ConsumeID){
                                                    ConsumeID = order.Eat_ConsumeID
                                                }
                                            }
                                            //如果菜品的数量大于券的抵扣上限,券的抵用数量则为券能抵扣的最大上限
                                            if(Number(tempEatNumber)>Number(item.DiscountValueMax)){
                                                _FirstNum = Number(item.DiscountValueMax)
                                            }
                                            let _num = this.MaxNumberCoupon(_FirstNum,order)
                                            if(_num!=0){
                                                if(!ConsumeID){
                                                    ConsumeID = order.Eat_ConsumeID
                                                }
                                            }
                                        }
                                    }else{
                                        _coupTypes=1
                                        if(!ConsumeID){
                                            ConsumeID = order.Eat_ConsumeID
                                        }
                                        _FirstNum = 1
                                    }
                                }else{
                                    if(!_coupname){
                                         _coupname='订单需要有:'+item.Coupon_Exchange_Dishes+'编号菜品才可使用该券'
                                    }
                                    _coupType=1
                                }
                            })
                        }
                        item = Object.assign(item,{Eat_ConsumeID:ConsumeID,ExchangeNum:_FirstNum})
                        if(_coupType==1 && _coupTypes==0){
                            this.errCounc++
                            this.$message.error(_coupname);
                            return
                        }
                    }
                    this.commodityJudge(item,1)
                    this.CouponsSelect(item,'','')
                }
            }
        },
         /** Products_ControlType: 0 适用所有菜品  1 指定的菜品满足金额才可使用券，2.指定菜品方案规则，满足菜品方案规则条件下的菜品总价才能使用对应券 */
         /**ControlType:0 不限制起用金额并不开启消费菜品控制 , 1：消费总金额满足才可使用券 2.消费总金额每满足指定价格可使用1张 */
        CouponProducts(item,_ConsumeMoney,ControlType){
            /**0:所有菜品 */
            if(item.CouponUsingDetailedRules.Products_ControlType==0){
                if(ControlType==1){
                    //+this.memberorderInfo.Mantissa_Money
                    if((Number(this.totalMoney) + Number(this.discountMoneuy))<_ConsumeMoney){
                        this.errCounc++
                        this.$message.error('账单需要满足'+Number(_ConsumeMoney)+'才可以使用这张券');
                        return
                    }
                }
                if(ControlType==2){
                    //+ this.memberorderInfo.Mantissa_Money
                    if(_ConsumeMoney >(Number(this.totalMoney) + Number(this.discountMoneuy))){
                        this.errCounc++
                        this.$message.error('账单每满足'+Number(_ConsumeMoney)+'可以使用1张此券');
                        return
                    }else{
                        let money =0;
                        this.memberorderInfo.map(order=>{
                            money = Number(money) + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                        })
                        let _choiceNumber=1
                        let ErrorTitle=''
                        let ErrorType=0
                        if(this.choiceCoupons.length>0){
                            this.choiceCoupons.map(()=>{
                                _choiceNumber = _choiceNumber+1
                                if(money < (_choiceNumber * item.CouponUsingDetailedRules.ConsumeMoney)){
                                    this.errCounc++
                                    ErrorType=1
                                    ErrorTitle = '账单每满足'+Number(_ConsumeMoney)+'可以使用此券1张'
                                }
                            })
                        }else{
                            if(money< item.CouponUsingDetailedRules.ConsumeMoney ){
                                this.errCounc++
                                ErrorType=1
                                ErrorTitle = '账单每满足'+Number(_ConsumeMoney)+'可以使用此券1张'
                            }
                        }
                        if(ErrorType==1){
                            this.$message.error(ErrorTitle);
                            return
                        }   
                    }
                }
            }
            /**指定菜品 */
            if(item.CouponUsingDetailedRules.Products_ControlType==1){
                /**查询下单菜品中是否符合该券使用条件 */
                let _Products = item.CouponUsingDetailedRules.AppointProducts;
                let _productmoney =0;
                this.memberorderInfo.map(order=>{
                    if(_Products.indexOf(order.EAT_XFCode)!=-1){
                        _productmoney = Number(_productmoney) + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                    }
                })
                if(ControlType==1){
                    if(_productmoney<item.CouponUsingDetailedRules.ConsumeMoney){
                        this.errCounc++
                        this.$message.error('该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用');
                        return
                    }
                }
                if(ControlType==2){
                    let _choiceNumber =1
                    let ErrorTitle=''
                    let ErrorType=0
                    if(this.choiceCoupons.length>0){
                        this.choiceCoupons.map(cho=>{
                            if(cho.Tk_AutoID == item.Tk_AutoID){
                                _choiceNumber = _choiceNumber+1
                                if(_productmoney < (_choiceNumber * item.CouponUsingDetailedRules.ConsumeMoney)){
                                    this.errCounc++
                                    ErrorType=1
                                    ErrorTitle = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                                }
                            }else{
                                if(_productmoney< item.CouponUsingDetailedRules.ConsumeMoney ){
                                    this.errCounc++
                                    ErrorType=1
                                    ErrorTitle = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                                }
                            }
                        })
                    }else{
                        if(_productmoney< item.CouponUsingDetailedRules.ConsumeMoney ){
                            this.errCounc++
                            ErrorType=1
                            ErrorTitle = '账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                        }
                    }
                    if(ErrorType==1){
                        this.$message.error(ErrorTitle);
                        return
                    }
                }
                
            }
            /**指定菜品适用规则 */
            if(item.CouponUsingDetailedRules.Products_ControlType==2){
                if(ControlType==1){
                    let ErrorTitle=''
                    let ErrorType=0
                    let ErrorCount=0
                    /**指定的菜品类型,菜品小类编号 */
                    let _AppointProductCategorys = item.CouponUsingDetailedRules.AppointProductRules.AppointProductCategorys
                    if(_AppointProductCategorys!=''){
                        let money=0
                        this.memberorderInfo.forEach((order)=>{
                            if(_AppointProductCategorys.indexOf(order.SubCategory_Code)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                                ErrorType=1
                                this.errCounc++
                                ErrorTitle='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                                ErrorCount++
                            }
                        }
                    }
                    /**菜品小类列表中不可用的菜品编号 */
                    let _InCategoryForbidProducts = item.CouponUsingDetailedRules.AppointProductRules.InCategoryForbidProducts
                    if(_InCategoryForbidProducts!=''){
                        let money=0
                        this.memberorderInfo.forEach((order)=>{
                            if(_InCategoryForbidProducts.indexOf(order.EAT_XFCode)==-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                                ErrorType=1
                                this.errCounc++
                                ErrorTitle='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                                ErrorCount++
                            }
                        }
                    }
                    /**菜品小类列表中可用的菜品编号 */
                    let _OutCategoryAppointProducts = item.CouponUsingDetailedRules.AppointProductRules.OutCategoryAppointProducts
                    if(_OutCategoryAppointProducts!=''){
                        let money=0
                        this.memberorderInfo.map(order=>{
                            if(_OutCategoryAppointProducts.indexOf(order.EAT_XFCode)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                            this.errCounc++
                            ErrorCount++
                            ErrorTitle='该券需要满足指定菜品总价达到'+item.CouponUsingDetailedRules.ConsumeMoney+'才可使用'
                        }
                    }
                    if(ErrorCount!=3){
                        this.errCounc=1
                    }
                    if(ErrorType==1 && ErrorCount==3){
                        this.$message.error(ErrorTitle);
                        return
                    }

                }
                if(ControlType==2){
                    let ErrorTitle=''
                    let ErrorType=0
                    let ErrorCount=0
                    /**指定的菜品类型,菜品小类编号 */
                    let _AppointProductCategorys = item.CouponUsingDetailedRules.AppointProductRules.AppointProductCategorys
                    if(_AppointProductCategorys!=''){
                        let money=0
                        this.memberorderInfo.forEach((order)=>{
                            if(_AppointProductCategorys.indexOf(order.SubCategory_Code)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                            ErrorTitle = cm.ErrorTitle
                            ErrorType = cm.ErrorType
                            if(ErrorType!=0){
                                ErrorCount++
                            }
                        }
                    }
                    /**菜品小类列表中不可用的菜品编号 */
                    let _InCategoryForbidProducts = item.CouponUsingDetailedRules.AppointProductRules.InCategoryForbidProducts
                    if(_InCategoryForbidProducts!=''){
                        let money=0
                        this.memberorderInfo.forEach((order)=>{
                            if(_InCategoryForbidProducts.indexOf(order.EAT_XFCode)==-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                            ErrorTitle = cm.ErrorTitle
                            ErrorType = cm.ErrorType
                            if(ErrorType!=0){
                                ErrorCount++
                            }
                        }
                    }
                    /**菜品小类列表中可用的菜品编号 */
                    let _OutCategoryAppointProducts = item.CouponUsingDetailedRules.AppointProductRules.OutCategoryAppointProducts
                    if(_OutCategoryAppointProducts!=''){
                        let money=0
                        this.memberorderInfo.map(order=>{
                            if(_OutCategoryAppointProducts.indexOf(order.EAT_XFCode)!=-1){
                                money = money + (Number(order.Eat_CheckPrice) * Number(order.Eat_Number))
                            }
                        })
                        if(money==0){
                            ErrorTitle = item.Coupon_Name +'不适用此单里的菜品'
                            ErrorType=1
                            this.errCounc++
                            ErrorCount++
                        }else{
                            let cm = this.CouponMoneyType(money,item,_ConsumeMoney)
                            ErrorTitle = cm.ErrorTitle
                            ErrorType = cm.ErrorType
                            if(ErrorType!=0){
                                ErrorCount++
                            }
                        }
                    }
                    if(ErrorCount!=3){
                        this.errCounc=1
                    }
                    if(ErrorType==1 && ErrorCount==3){
                        this.$message.error(ErrorTitle);
                        return
                    }
                }
            }
        },
        CouponMoneyType(money,item,_ConsumeMoney){
            let _choiceNumber=1
            let ErrorType=0
            let ErrorTitle=''
            if(this.choiceCoupons.length>0){
                this.choiceCoupons.map(cho=>{
                    if(cho.Tk_AutoID == item.Tk_AutoID){
                        _choiceNumber = _choiceNumber+1
                        if(money < (_choiceNumber * item.CouponUsingDetailedRules.ConsumeMoney)){
                            this.errCounc++
                            ErrorType=1
                            ErrorTitle ='账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                        }
                    }else{
                        if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                            this.errCounc++
                            ErrorType=1
                            ErrorTitle ='账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                        }
                    }
                })
            }else{
                if(money<item.CouponUsingDetailedRules.ConsumeMoney){
                    this.errCounc++
                    ErrorType=1
                    ErrorTitle ='账单每满足指定菜品'+Number(_ConsumeMoney)+'可以使用此券1张'
                }
            }
            let retError={ErrorType:ErrorType,ErrorTitle:ErrorTitle}
            return retError
        },   
        /**券选中事件 */
        CouponsSelect(item,ConsumeID,FirstNum){
            if(item.Coupon_Kind==1){
                if(Number(item.Coupon_FaceValue)>Number(this.unPaidMoney)){
                    item.Coupon_FaceValue = Number(Number(this.unPaidMoney).toFixed(2))
                }
                this.couponsMoney = Number(this.couponsMoney) + Number(item.Coupon_FaceValue)
                if((Number(this.couponsPay)+Number(item.Coupon_FaceValue))>=Number(this.unPaidMoney)){
                    let _tempMoney = (Number(this.couponsPay)+Number(item.Coupon_FaceValue))-Number(this.unPaidMoney)
                    this.unPaidMoney = Number(Number(this.unPaidMoney - Number(item.Coupon_FaceValue)).toFixed(2))
                    this.couponsPay = Number(Number(this.couponsPay) - _tempMoney).toFixed(2)
                }else if((Number(this.couponsPay)+Number(item.Coupon_FaceValue))<Number(this.unPaidMoney)){
                    this.unPaidMoney = Number(Number(this.unPaidMoney - Number(item.Coupon_FaceValue)).toFixed(2))
                }
                // else{
                //     this.unPaidMoney = this.unPaidMoney - Number(item.Coupon_FaceValue)
                //     this.couponsPay = Number(Number(this.couponsPay) - Number(item.Coupon_FaceValue)).toFixed(2)
                // }
            }
            if(item.CouponUsingDetailedRules){
                if(item.CouponUsingDetailedRules.Is_AllowMixUsing==2){
                    if(this.couponsCount==0){
                        this.is_AllowMixUsing = true;
                        this.is_AllowMixUsingCoupon_Name = item.Coupon_Name;
                    }
                }
            }
            this.couponsCount++;
            item.Check = true;
            let _item = item
            // if(ConsumeID!=''){
            //     _item = Object.assign(_item,{Eat_ConsumeID:ConsumeID,ExchangeNum:FirstNum})
            // }
            this.choiceCoupons.push(item);
            this.memberorderInfo.map(order=>{
                order.CouponNumber=0
                this.choiceCoupons.map(cho=>{
                    if(order.Eat_ConsumeID==cho.Eat_ConsumeID){
                        if(order.CouponNumber<order.Eat_Number){
                            //DiscountType:3.按份量抵扣 */
                            if(item.DiscountType==3){
                                order.CouponNumber += cho.ExchangeNum
                            }else{
                                order.CouponNumber = order.CouponNumber+1
                            }
                            if(order.CouponNumber>=order.Eat_Number){
                                order.isCoupon=true
                            }
                        }else{
                            order.isCoupon=true
                        }
                    }
                })
            })
        },
        /**商品券判断 type:1 选中, 2取消选中 */
        commodityJudge(item,type){
            if(item.Coupon_Kind==2){
                /**商品券价值类型，0：按菜品金额抵扣 ，1：按固定金额抵扣 注：如果固定金额超过菜品金额则按菜品金额抵扣， 2：按折扣率抵扣 注：5折填写50 3.按份量抵扣 */
                if(item.DiscountType==0){
                    let _num=0
                    this.memberorderInfo.map(order=>{
                        if(_num==0 && order?.isCoupon==false){
                            if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1 && item.Eat_ConsumeID==order.Eat_ConsumeID){
                                _num=1
                                if(type==1){
                                    if(order.CouponNumber>order.Eat_Number){
                                        let _num = 1-(order.CouponNumber - order.Eat_Number)
                                        item.Coupon_FaceValue =Number(Number(order.Eat_CheckPrice * _num).toFixed(2))
                                    }
                                    else if(this.unPaidMoney<order.Eat_CheckPrice){
                                        item.Coupon_FaceValue = this.unPaidMoney
                                    }else{
                                        item.Coupon_FaceValue = Number(order.Eat_CheckPrice)
                                    }
                                    this.unPaidMoney = this.unPaidMoney - Number(item.Coupon_FaceValue) 
                                    this.couponsMoney = Number(this.couponsMoney) + Number(item.Coupon_FaceValue)
                                    this.couponsPay = Number(Number(this.couponsPay) - Number(item.Coupon_FaceValue)).toFixed(2)
                                }else{
                                    this.unPaidMoney = this.unPaidMoney + Number(item.Coupon_FaceValue) 
                                    this.couponsMoney = Number(this.couponsMoney) - Number(item.Coupon_FaceValue)
                                    if(Number(this.meberInfo.Card_Money)>Number(item.Coupon_FaceValue)){
                                        this.couponsPay = Number(Number(this.couponsPay) +  Number(item.Coupon_FaceValue)).toFixed(2)
                                    }
                                    //this.couponsPay = Number(Number(this.couponsPay) + Number(item.Coupon_FaceValue)).toFixed(2)
                                    item.Coupon_FaceValue = 0
                                }
                                return
                            }
                        }
                    })
                }
                if(item.DiscountType==1){
                    let _num=0
                    this.memberorderInfo.map(order=>{
                        if(_num==0 && order?.isCoupon==false){
                            if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1 && item.Eat_ConsumeID==order.Eat_ConsumeID){
                                _num=1
                                /**如果固定金额超过菜品金额则按菜品金额抵扣 */
                                if(Number(item.DiscountValue)>order.Eat_CheckPrice){
                                    item.Coupon_FaceValue = Number(order.Eat_CheckPrice)
                                }else{
                                    item.Coupon_FaceValue = Number(item.DiscountValue)
                                }
                                if(type==1){
                                    // if(Number(item.Coupon_FaceValue)>Number(this.unPaidMoney)){
                                    //     item.Coupon_FaceValue = Number(this.unPaidMoney)
                                    // }
                                    this.unPaidMoney = this.unPaidMoney - Number(item.Coupon_FaceValue) 
                                    this.couponsMoney = Number(this.couponsMoney) + Number(item.Coupon_FaceValue)
                                    this.couponsPay = Number(Number(this.couponsPay) - Number(item.Coupon_FaceValue)).toFixed(2)
                                }else{
                                    this.unPaidMoney = this.unPaidMoney + Number(item.Coupon_FaceValue) 
                                    this.couponsMoney = Number(this.couponsMoney) - Number(item.Coupon_FaceValue)
                                    if(Number(this.meberInfo.Card_Money)>Number(item.Coupon_FaceValue)){
                                        this.couponsPay = Number(Number(this.couponsPay) +  Number(item.Coupon_FaceValue)).toFixed(2)
                                    }
                                    //this.couponsPay = Number(Number(this.couponsPay) + Number(item.Coupon_FaceValue)).toFixed(2)
                                }
                            }
                        }
                    })
                }
                if(item.DiscountType==2){
                    let _num=0
                     this.memberorderInfo.map(order=>{
                         if(_num==0 && order?.Is_Discounted==true && order?.isCoupon==false){
                             if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1 && item.Eat_ConsumeID==order.Eat_ConsumeID && order.Eat_CheckPrice==order.Eat_SalePrice){
                                 _num=1
                                let money =  Number(order.Eat_CheckPrice) * (1-(item.DiscountValue / 100))
                                if(type==1){
                                    this.unPaidMoney = this.unPaidMoney - Number(money) 
                                    this.couponsMoney = Number(this.couponsMoney) + Number(money)
                                    this.couponsPay = Number(Number(this.couponsPay) - Number(money)).toFixed(2)
                                    item.Coupon_FaceValue = Number(money)
                                }else{
                                    this.unPaidMoney = this.unPaidMoney + Number(item.Coupon_FaceValue) 
                                    this.couponsMoney = Number(this.couponsMoney) - Number(item.Coupon_FaceValue)
                                    if(Number(this.meberInfo.Card_Money)>Number(item.Coupon_FaceValue)){
                                        this.couponsPay = Number(Number(this.couponsPay) +  Number(item.Coupon_FaceValue)).toFixed(2)
                                    }
                                    //this.couponsPay = Number(Number(this.couponsPay) + Number(item.Coupon_FaceValue)).toFixed(2)
                                    item.Coupon_FaceValue = 0
                                }
                            }
                         }
                    })
                    return
                }
                if(item.DiscountType==3){
                    this.memberorderInfo.forEach(order=>{
                        if(item.Coupon_Exchange_Dishes.indexOf(order.EAT_XFCode)!=-1 && item.Eat_ConsumeID==order.Eat_ConsumeID){
                            let tempCheckMoney = order.Eat_CheckMoney
                            //菜品已使用券的数量CouponNumber
                            let tempEatNumber = this.accSub(Number(order.CouponNumber),order.Eat_Number)
                            //如果菜品数量超过商品券DiscountValueMax抵扣上限,则按上限数量抵扣对应金额
                            if(item.DiscountValueMax<Number(tempEatNumber)){
                                tempCheckMoney = Number(Number(Number(order.Eat_CheckPrice) * Number(item.DiscountValueMax)).toFixed(2))
                            }else{
                                tempCheckMoney = Number(Number(Number(order.Eat_CheckPrice) * Number(tempEatNumber)).toFixed(2))
                            }
                            if(type==1){
                                if(item.Coupon_FaceValue<tempCheckMoney){
                                    if(Number(tempCheckMoney)>Number(this.unPaidMoney)){
                                        tempCheckMoney = Number(this.unPaidMoney)
                                    }
                                    this.unPaidMoney = this.unPaidMoney - tempCheckMoney
                                    this.couponsMoney = Number(this.couponsMoney) + tempCheckMoney
                                    this.couponsPay = Number(Number(this.couponsPay) - Number(tempCheckMoney)).toFixed(2)
                                    item.Coupon_FaceValue = Number(tempCheckMoney)
                                }
                                // if(item.Coupon_FaceValue<Number(order.Eat_SaleMoney)){
                                //     this.unPaidMoney = this.unPaidMoney - Number(order.Eat_SaleMoney) 
                                //     this.couponsMoney = Number(this.couponsMoney) + Number(order.Eat_SaleMoney)
                                //     this.couponsPay = Number(Number(this.couponsPay) - Number(order.Eat_SaleMoney)).toFixed(2)
                                //     item.Coupon_FaceValue = Number(order.Eat_SaleMoney)
                                // }
                            }else{
                                this.unPaidMoney = this.unPaidMoney + Number(item.Coupon_FaceValue) 
                                this.couponsMoney = Number(this.couponsMoney) - Number(item.Coupon_FaceValue)
                                if(Number(this.meberInfo.Card_Money)>Number(item.Coupon_FaceValue)){
                                    this.couponsPay = Number(Number(this.couponsPay) +  Number(item.Coupon_FaceValue)).toFixed(2)
                                }
                                // this.unPaidMoney = this.unPaidMoney + Number(order.Eat_SaleMoney) 
                                // this.couponsMoney = Number(this.couponsMoney) - Number(order.Eat_SaleMoney)
                                // if(Number(this.meberInfo.Card_Money)>Number(item.Coupon_FaceValue)){
                                //     this.couponsPay = Number(Number(this.couponsPay) +  Number(item.Coupon_FaceValue)).toFixed(2)
                                // }
                                item.Coupon_FaceValue = 0
                            }
                        }
                    })
                }
            }
        },
        /**商品券展示类型 */
        discountShow(item){
            if(item.DiscountType==0){
                return '完全抵用'
            }
            if(item.DiscountType==1){
                return '抵扣'+item.DiscountValue+'元'
            }
            if(item.DiscountType==2){
                return '抵扣'+ (100 - item.DiscountValue)+'%'
            }
        },
        /**商品券选择优化自动选择最大数量 */
        MaxNumberCoupon(FirstNum,order){
           //菜品剩余可使用券的数量CouponNumber
            let tempEatNumber = this.accSub(Number(order.CouponNumber),order.Eat_Number)
            if(Number(tempEatNumber)>FirstNum){
               return order.Eat_ConsumeID
            }else{
                return 0
            }
        },
        /**积分点击 */
        onIntegralClick(){
            setTimeout(()=>{
                this.$refs.PointPayinput?.select();
            },100)
            if(this.meberInfo){
                if(!this.meberInfo.PointPay_Scale){
                    this.$message.warning('微生活后台没有设置相对应的积分兑换比例,请先去设置比例');
                    return
                }
                /**是否允许会员支付 0不允许,1允许 */
                if(this.meberInfo?.Is_Allowed_PointPay==0){
                    this.$alert('该卡暂不允许积分支付!', '提示', {
                            confirmButtonText: '确定',
                    });
                }
            }
        },
        hide(){
            if(this.PayResultCode!=1){
                this.$emit("memberCloseModel")
            }
        },
        Rechargehide(){
            if(this.PayResultCode!=1){
                this.rechargeclass=0
                this.RechargeType = 0
                this.Rechargeshow = false
            }
        },
        /**确定付款 */
        confirm(){
            this.rechargeMoney = this.rechargeList[1].rechargeMoney
            if(!this.meberInfo){
                this.$message.warning('请先读取会员卡');
                return
            }
            if(Number(this.couponsPay)>0){
                if(Number(this.couponsPay)>this.unPaidMoney.toFixed(2)){
                    this.$message.error('储值金额超过待支付金额');
                    return
                }
            }
            if(Number(this.PointPay)>0){
                if(this.PointPay.toString().indexOf('.')!=-1){
                    this.$message.error('积分只能为整数');
                    return
                }
                if(this.meberInfo.Card_PilePoint<Number(this.PointPay)){
                    this.$message.error('您的积分不足');
                    return
                }
                if(this.PointPayMoney>this.unPaidMoney.toFixed(2)){
                    this.$message.error('积分可兑换金额超过待支付金额');
                    return
                }
            }
            //是否限制同一笔支付，储值和积分不能同时使用，0：不限制， 1：开启限制
            if(Number(this.couponsPay)>0 && Number(this.PointPay)>0 && this.meberInfo.Is_ForbidBalanceAndPointUseSimultaneously==1){
                this.$message.error('储值和积分不能同时使用');
                return
            }
            if(Number(this.couponsPay)>0 && this.PointPayMoney>0){
                if((Number(this.couponsPay)+this.PointPayMoney)>this.unPaidMoney.toFixed(2)){
                    this.$message.error('储值金额和积分总额超出待支付金额');
                    return
                }
            }
            if(Number(this.couponsPay)>Number(this.meberInfo.Card_Money)){
                this.$message.error('卡内余额不足,本次支付所需'+this.couponsPay+'，卡内余额：'+this.meberInfo.Card_Money);
                return
            }
            if(this.rechargeclass==2 && this.scanPayCode==''){
                this.scanCodePay = true
            }else{
                if(this.PayResultCode==0){
                    this.userInfo=this.$auth.getUserInfo(); //获取用户id
                    let _order = this.memberorderInfo
                    let _meberInfo=''
                    /** 预支付总金额 */
                    let _counMoney=0
                    /**支付方式 */
                    let _payment=[]
                    let _meber = this.meberInfo
                    /**会员信息 */
                    _meberInfo ={
                        Member_Sys:4,
                        DynamicType:_meber.DynamicType, //查询码类型 5i会员类型：1手机会员、2卡会员
                        Card_AutoID:_meber.Bestech_Card_AutoID, //卡主键
                        Card_No:_meber.Card_No, //卡号
                        Member_Name:_meber.MemberName, //姓名
                        MemberCard_Kind:_meber.Bestech_Card_KindName, //会员等级
                        Mobile:_meber.Mobile,//会员手机号
                        NotJoinPointProgram:this.PointChecked,//不参与线上积分
                        Invoice_Money:0, //本次消费开票金额
                        Invoice_No:'',//发票号码
                    }
                    //券支付方式
                    if(this.couponsMoney>0){
                        //订单未支付价格
                        let _paymoney = Number(this.snackPaidMoney)
                        //获取券的信息
                        this.choiceCoupons.forEach(cho=>{
                            if(_paymoney>0){
                                if(Number(cho.Coupon_FaceValue)<_paymoney){
                                    _paymoney = _paymoney - Number(cho.Coupon_FaceValue)
                                }
                                else{
                                    cho.Coupon_FaceValue = _paymoney
                                }
                            }
                            
                            if(cho.Coupon_Kind==2){
                                let _choice={
                                        Coupon_AutoId:cho.Coupon_AutoID,
                                        Coupon_Code:cho.Coupon_Code,
                                        Coupon_Name:cho.Coupon_Name,
                                        Coupon_Kind:cho.Coupon_Kind,
                                        Coupon_Num:1,
                                        Coupon_CostPrice:cho.Coupon_CostMoney,
                                        Exchange_ConsumeID:cho.ConsumeID, //抵用明细ID
                                        Exchange_Num:cho.ExchangeNum, //抵用数量
                                    }
                                _payment.push({
                                    Payment_ID:'CardCouponsPay',  //支付方式ID
                                    Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                                    Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                                    Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                                    Payment_Money: Number(cho.Coupon_FaceValue), //支付金额
                                    Payment_FactMoney:0, //计营业额
                                    Qr_Code:'', //扫码付款的码值
                                    Card_No:_meber.Card_No, //卡号
                                    CouponInfo:_choice, //券的详情
                                })
                            }else if(cho.Coupon_Kind==1){
                                let _choice={
                                        Coupon_AutoId:cho.Coupon_AutoID,
                                        Coupon_Code:cho.Coupon_Code,
                                        Coupon_Name:cho.Coupon_Name,
                                        Coupon_Kind:cho.Coupon_Kind,
                                        Coupon_Num:1,
                                        Coupon_CostPrice:cho.Coupon_CostMoney
                                    }
                                _payment.push({
                                    Payment_ID:'CardCouponsPay',  //支付方式ID
                                    Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                                    Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                                    Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                                    Payment_Money: Number(cho.Coupon_FaceValue), //支付金额
                                    Payment_FactMoney:0, //计营业额
                                    Qr_Code:'', //扫码付款的码值
                                    Card_No:_meber.Card_No, //卡号
                                    CouponInfo:_choice, //券的详情
                                })
                            }
                            _counMoney = _counMoney + Number(cho.Coupon_FaceValue)
                        })
                    }
                    //积分支付
                    if(this.PointPay>0){
                        _payment.push({
                            Payment_ID:'CardPointPay',  //支付方式ID
                            Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                            Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                            Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                            Payment_Money:this.PointPayMoney, //支付金额
                            Payment_FactMoney:0, //计营业额
                            Qr_Code:'', //扫码付款的码值
                            Card_No:_meber.Card_No, //卡号
                            CouponInfo:null, //券的详情
                        })
                        _counMoney = _counMoney + Number(this.PointPayMoney)
                    }
                    //储值
                    if(this.couponsPay>0){
                        _payment.push({
                            Payment_ID:'CardMoneyPay',  //支付方式ID
                            Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                            Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                            Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                            Payment_Money: Number(this.couponsPay), //支付金额
                            Payment_FactMoney:0, //计营业额
                            Qr_Code:'', //扫码付款的码值
                            Card_No:_meber.Card_No, //卡号
                            CouponInfo:null, //券的详情
                        })
                        _counMoney = _counMoney + Number(this.couponsPay)
                    }
                    //其他支付方式
                    if(this.RechargeType==1){
                        this.rechargeList.map(re=>{
                            if(re.rechargeMoney>0){
                                _payment.push({
                                    Payment_ID:re.Payment_ID,  //支付方式ID
                                    Payment_Code:re.Payment_Code, //支付方式编号
                                    Payment_Name:re.rechargeName, //支付方式名称
                                    Payment_Type:re.Payment_Type, //支付方式类型
                                    Payment_Money: Number(re.rechargeMoney), //支付金额
                                    Payment_FactMoney:0, //计营业额
                                    Qr_Code:this.scanPayCode, //扫码付款的码值
                                    Card_No:_meber.Card_No, //卡号
                                    CouponInfo:null, //券的详情
                                })
                                _counMoney = _counMoney + Number(re.rechargeMoney)
                            }
                        })
                    }
                    /**结合其他付款方式 */
                    if(this.paymentHistory){
                        this.paymentHistory.forEach(pay=>{
                            if(pay.is_pay==false){
                                _payment.push({
                                    Payment_ID:pay.Payment_ID,  //支付方式ID
                                    Payment_Code:pay.Payment_Code, //支付方式编号
                                    Payment_Name:pay.Payment_Name, //支付方式名称
                                    Payment_Type:pay.Payment_Type, //支付方式类型
                                    Payment_Money: pay.Payment_Money, //支付金额
                                    Payment_FactMoney:pay.Payment_FactMoney, //计营业额
                                    Qr_Code:pay.Qr_Code, //扫码付款的码值
                                    Card_No:pay.Card_No, //卡号
                                    CouponInfo:pay.CouponInfo, //券的详情
                                })
                            }
                        })
                    }
                    let _memberorderInfo= JSON.parse(JSON.stringify(this.orderMenuList))
                    let _orderConsumes=this.$global.getFastOrderConsumes(_memberorderInfo);
                    
                    let param={
                        Body_ID:newGuid(),
                        User_ID:this.userInfo?.User_ID, //登陆用户ID
                        Eat_AutoID:'',//结账单ID
                        Timestamps:'', //账单时间戳
                        Operator_Name:this.userInfo?.Login_Name,//操作员
                        Eat_PersonNum:this.headingInfo?.DinersNum,//人数
                        Channel_ID:this.Channellike,//订单渠道ID
                        Room_ID:this.RoomAutoID,//餐厅ID
                        Eat_DeskID:this.headingInfo?.DeskAutoID, //桌台ID
                        Eat_DeskName:this.DeskName, //桌台名称
                        Eat_TakeNo:this.headingInfo?.brandNumber,//牌号
                        Eat_AllRemark:'', //订单全单备注
                        Order_Remark:this.headingInfo.orderRemark,//订单备注
                        Order_BatchNo:newGuid(),//下单批次号，每次提交下单，调用方生成一个唯一批次号，接口判断防止重复下单
                        NotSendToKitPrint:false, //下单不送打厨房
                        MantissaAdjustment_Money: Number(Number(this.MantissaMoney).toFixed(2)),//尾数调整金额
                        Member:_meberInfo,//会员信息
                        Payments:_payment, //支付方式列表
                        OrderConsumes:_orderConsumes,//订单消费明细
                    }
                    if(this.EatAutoIDs){
                        param.Eat_AutoID = this.EatAutoIDs
                        param.Timestamps = this.Timestamps
                    }
                    /**计算总金额,金额不够弹出充值通道*/
                    // console.log('_counMoney:'+_counMoney)
                    // console.log('snackPaidMoney:'+this.snackPaidMoney)
                    if(Number(_counMoney.toFixed(2))<Number((this.snackPaidMoney).toFixed(2))){
                        if(this.RechargeType==1){
                            this.$message.error('金额不足');
                        }else{
                            this.Rechargeshow = true
                            this.errorTitle=''
                        }
                        return
                    }
                    /**支付预览 */
                    const loading = this.$loading({
                        text: "支付预览中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.1)",
                    });
                    console.log('支付预览提交:'+JSON.stringify(param))
                    this.$httpAES.post("Bestech.CloudPos.SnackPayPreview",param).then((data)=>{
                        loading.close();
                        this.payPreviewData = data
                        console.log('支付预览返回:'+JSON.stringify(data))
                        if(data.ResponseBody?.Timestamps){
                            this.ordertimestamps = data.ResponseBody?.Timestamps.toString();
                            this.eatAutoID = data.ResponseBody?.Eat_AutoID
                        }
                        if(data.ResponseHeader.ResultCode!=0){
                            this.$message.error(data.ResponseHeader.ResultDesc);
                        }else{
                            this.tempUserId = data.ResponseBody?.User_ID
                            this.tempOperatorName = data.ResponseBody?.User_Name
                            if(data.ResponseBody?.OpenCashBox==true){
                                this.$webBrowser.openCashBoxBnt().then(d=>{
                                    if(d?.state!=0){
                                        this.$message.error('钱箱打开失败:'+(d?.message||""));
                                    }
                                });
                            }
                            /**Is_NeedSMSCode：是否需要短信验证码0不需要1需要 */
                            if(data.ResponseBody.Is_NeedSMSCode==1){
                                this.cardCodeShow = true
                                return
                            }
                            /**Is_NeedPassWord：是否需要密码验证0不需要1需要 */
                            if(data.ResponseBody.Is_NeedPassWord==1){
                                this.passwordShow=true
                                return
                            }
                            /**支付提交判断 0不需要 1需要 */
                            if(data.ResponseBody.Is_NeedSubmit==1){
                                this.PaySubmit(data)
                            }else{
                                if(data.ResponseBody?.PrintsInfo){
                                    setTimeout(()=>{
                                        this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                                    },100);
                                }
                                ElMessage.success({ message: "支付完成"});
                                this.$emit('paySuccessConfirm');
                            }
                        }
                    }).catch((e)=>{
                        loading.close();
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    })
                }else if(this.PayResultCode==1){
                    this.PaySubmit(this.payPreviewData)
                }else{
                    this.scanCodePay = true
                }
            }
        },
        /**支付提交方法 */
        PaySubmit(data){
            if(data.ResponseBody.Is_NeedSubmit==1){
               let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID, //登陆用户ID
                    Eat_AutoID: data.ResponseBody?.Eat_AutoID,//结账单ID
                    MantissaAdjustMoney:data.ResponseBody?.MantissaAdjustMoney,//尾数调整金额
                    Member_Type:data.ResponseBody?.Member_Type,  //会员类型
                    Biz_ID:data.ResponseBody?.Biz_ID, //业务交易流
                    Verify_Code:this.verifyCode, //验证码
                    Operator_Name:this.userInfo?.Login_Name, //操作员
                    Timestamps:data.ResponseBody.Timestamps.toString(),
                    Payments:data.ResponseBody.Payments
                }
                /**支付提交 */
                const loading = this.$loading({
                    text: "支付提交中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
                });
                console.log('支付提交:'+JSON.stringify(param))
                this.$httpAES.post("Bestech.CloudPos.SnackPaySubmit",param).then((data)=>{
                    loading.close();
                    console.log('支付提交返回:'+JSON.stringify(data))
                    if(data.ResponseHeader.ResultCode!=0){
                        if(data.ResponseBody?.Timestamps){
                            this.payPreviewData.ResponseBody.Timestamps = data.ResponseBody.Timestamps.toString()
                            this.$emit("updatePay",'',data.ResponseBody?.Timestamps)
                        }
                        //Timestamps = data.ResponseBody.Timestamps.toString()
                        this.errorTitle = data.ResponseHeader.ResultDesc
                        if(data.ResponseHeader.ResultCode==4001){
                            this.PayResultCode=1
                            if(this.timerCount==0){
                                this.timerCount=60
                            }
                            this.timeoutPayment();
                        }
                        if(data.ResponseHeader.ResultCode==4003){
                            this.PayResultCode=0
                            this.scanPayCode=''
                            this.scanCodePay = true
                        }
                        if(data.ResponseHeader.ResultCode==4008){
                            this.rechargeList.map(re=>{
                                if(Number(re.rechargeMoney)>0){
                                    re.rechargeMoney=0
                                }
                            })
                            this.PayResultCode=0
                            //this.Rechargehide()
                            this.$emit("updatePay",data.ResponseBody,data.ResponseBody?.Timestamps)
                        }
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        if(data.ResponseBody?.PrintsInfo){
                            setTimeout(()=>{
                                this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                            },100);
                        }
                        if(data.ResponseBody?.OpenCashBox==true){
                            this.$webBrowser.openCashBoxBnt().then(d=>{
                                if(d?.state!=0){
                                    this.$message.error('钱箱打开失败:'+(d?.message||""));
                                }
                            });
                        }
                        ElMessage.success({ message: "支付完成"});
                        this.$emit('paySuccessConfirm');
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }
        },
        /**获取星期 */
        setNowTimes(){
            let myDate = new Date()
            let wk = myDate.getDay()
            let weeks = ['7', '1', '2', '3', '4', '5', '6']
            let week = weeks[wk]
            return week;
        },
        /**数据初始化 */
        initializaTion(){
            this.meberInfo=''
            this.payCode=''
            this.couponsCount=0
            this.couponsMoney=0
            this.couponsPay=''
            this.PointPay=''
            this.PointPayMoney=0
            this.PointPay_Scale=''
            this.choiceCoupons=[]
            this.cashCoupon=''
            this.commodityVoucher=''
            this.Rechargeshow=false
            this.payKindShow=false
            this.rechargeclass=0
            this.RechargeType=0
            this.is_AllowMixUsing=false
            this.is_AllowMixUsingCoupon_Name=''
            this.scanPayCode=''
            this.PayResultCode=0
            this.timerCount=0
            this.payPreviewData=''
            this.PointChecked = false
            this.tempUserId='',this.tempOperatorName='',this.eatAutoID=''
            this.unPaidMoney = this.snackPaidMoney
            this.memberorderInfo.map(order=>{
                order.isCoupon=false,
                order.CouponNumber=0
            })
        },
        /**样式选择 */
        radioClick(item){
            if(this.PayResultCode==1){
                this.$message.error('有待支付的订单,暂时无法修改支付方式');
                return
            }
            if(this.PayResultCode!=1){
                this.rechargeclass = item.rechargeId
                item.rechargeMoney=0
                this.rechargeList.map(re=>{
                    re.rechargeMoney=0
                })
                this.rechargeList.map(re=>{
                        if(re.rechargeId==item.rechargeId && item.rechargeMoney==0 && item.rechargeMoney!=(this.unPaidMoney.toFixed(2)-Number(this.couponsPay)-this.PointPayMoney)){
                            item.rechargeMoney = (this.unPaidMoney.toFixed(2)-Number(this.couponsPay)-this.PointPayMoney).toFixed(2)
                        }else if(re.rechargeMoney == ((this.unPaidMoney.toFixed(2)-Number(this.couponsPay)-this.PointPayMoney).toFixed(2))){
                            re.rechargeMoney=0
                        }
                })
                if(item.rechargeId==3){
                    let CounMoney = 0
                    this.rechargeList.map(re=>{
                        if(re.rechargeId!=3){
                            CounMoney = CounMoney + Number(re.rechargeMoney)
                        }
                    })
                    this.otherPay = (this.unPaidMoney.toFixed(2)-Number(this.couponsPay)-this.PointPayMoney-CounMoney).toFixed(2)
                    this.payKindShow=true
                }
            }
        },
        /**聚合支付返回 */
        memberCodePayModel(payCode){
            this.scanCodePay = false
            this.scanPayCode=payCode
            this.PayResultCode=0
            this.create()
            this.timerCount=0
            this.confirm()
        },
        /**会员卡选择返回 */
        selectCardInfo(data){
            this.meberInfo=data;
            this.idCode = data.Card_No;
            this.cardMemberShow = false;
            this.cardInfoShow()
        },
        /**会员卡信息展示 */
        cardInfoShow(){
            let _cashArr = [];  //定义一个数组
            let _commArr=[];
            if(this.meberInfo?.Coupons){
                this.meberInfo.Coupons.forEach(item=>{
                    if(item.Coupon_Kind==1){
                        _cashArr.push(Object.assign(item,{Check:false,CouponOriMoney:item.Coupon_FaceValue}))
                        this.cashCoupon=_cashArr;
                    }else{
                        _commArr.push(Object.assign(item,{Check:false}))
                        this.commodityVoucher=_commArr;
                    }
                    if(this.meberInfo.PointPay_Scale==undefined){
                        this.PointPay_Scale =  '1:1'
                    }else{
                        this.PointPay_Scale =  this.meberInfo.PointPay_Scale+':1'
                    }
                })
            }
            let _unPaidMoney =0
            if((this.unPaidMoney.toFixed(2)-Number(this.couponsPay)).toFixed(2)==0){
                _unPaidMoney = this.unPaidMoney.toFixed(2)
            }else{
                _unPaidMoney = (this.unPaidMoney.toFixed(2)-Number(this.couponsPay)).toFixed(2)
            }
            if(_unPaidMoney< Number(this.meberInfo.Card_Money)){
                this.couponsPay = _unPaidMoney
            }else{
                this.couponsPay = Number(this.meberInfo.Card_Money)
            }
        },
        /**验证码返回 */
        cardCodeConfirm(code){
            this.verifyCode=code
            this.cardCodeShow = false
            this.PaySubmit(this.payPreviewData)
        },
        passwordConfirm(pwd){
            this.verifyCode=pwd
            this.passwordShow = false
            this.PaySubmit(this.payPreviewData)
        },
        PointPayCouponMoneyInfo(type){
            if(this.meberInfo){
                if(type==0){
                    this.PointPayCouponMoney= Number(this.couponsPay) + Number(this.PointPayMoney)
                }
                if(type==1){
                    this.PointPayCouponMoney= Number(this.couponsPay) + Number(this.PointPayMoney)
                }
                if(type==2){
                    if(Number(this.couponsPay)+Number(this.PointPayMoney)<this.unPaidMoney){
                        this.PointPayCouponMoney= Number(this.couponsPay) + Number(this.PointPayMoney)
                        if(this.isActiveCodePay==false){
                            this.couponsPay = Number(this.couponsPay)+Number(this.accSub(this.PointPayCouponMoney,this.unPaidMoney))
                        }
                    }else if(Number(this.couponsPay)+Number(this.PointPayMoney)==this.unPaidMoney){
                        this.PointPayCouponMoney= Number(this.couponsPay) + Number(this.PointPayMoney)
                    }else{
                        /**this.unPaidMoney - Number(this.PointPayMoney) */
                        this.couponsPay =this.accSub(Number(this.PointPayMoney),this.unPaidMoney) 
                        this.PointPayCouponMoney = this.unPaidMoney
                    }
                }
            }else{
                this.PointPayCouponMoney=0
            }
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num)
        },
        onCouponPayClick(){
            setTimeout(()=>{
                this.$refs.couponsPayinput?.select();
            },100)
        },
        /**减法函数 */
        accSub(arg1,arg2){ 
             var r1,r2,m,n;
            try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
            try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
            m=Math.pow(10,Math.max(r1,r2));
            //动态控制精度长度
            n=(r1>=r2)?r1:r2;
            return ((arg2*m-arg1*m)/m).toFixed(n);
        },
        /**数组排序 */
        compare(attr,Time) {
            return function(a,b){
                var val1 = a[attr];
                var val2 = b[attr];
                var Times1 = a[Time];
                var Times2 = b[Time];
                return val2 - val1 || Times1 - Times2;
            }
        },
        /**定时查看结果 */
        timeoutPayment(){
            if(this._timePay) clearTimeout(this._timePay);
            this._timePay=setTimeout(()=>{
                if(this.PayResultCode!=0){
                    this.confirm();
                }
            },5000);
        },
        create(){
            if(this._timePay) clearTimeout(this._timePay);
        }
    }
}
</script>

<style lang="sass">
@import "./wliveMemberPaymentModel.scss";
</style>