<template>
  <modal class="cardCodeModel" :isShow="isShow">
      <div class="header">短信验证</div>
        <div class="modal-body">
            <div class="clearfix" ref="recharRight">
                <div class="left-box">
                    <div class="title">短信验证</div>
                    <div class="tr-li">
                        <div class="lable-txt">请输入验证码</div>
                        <div class="from-input"><input type="text" class="key-focus"  ref="priceInput" v-model="CodeNum" @keyup.enter="confirm()"/></div>
                        <template v-if="mobile">
                            <count-down class="send-code loop-code" v-if="isCodeTime" :duration="60" unit="秒" @success="isCodeTime=false">{{codeTime}}秒</count-down>    
                            <div class="send-code" @click="readCode()" v-else>验证码</div> 
                        </template>
                    </div>
                    <div class="tr-li" v-if="wliveCardMember">
                        <div class="lable-txtcard">当前验证的会员卡号:</div>
                        <div class="lable-txtNo">{{wliveCardMember.Card_No}}</div>
                    </div>
                    <div class="tip">提示:短信验证码5分钟内有效!</div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="tip-box">
                <!--提示：此处显示提示文本，没有提示则不显示-->
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>

import {initKeyBoard} from '/src/common'

export default {
    name:'cardCodeModel',
    emits:["closeModel","cardCodeConfirm"],
    props:{
        isShow:Boolean,
        wliveCardMember:Object,
        //手机号 用来发送验证码
        mobile:{
            type:String,
            default:""
        }
    },
    data(){
        return{
            /**短信验证码 */
            CodeNum:'',
            /*是否 倒计时开始*/
            isCodeTime:false
        }
    },
    watch: {
        isShow() { 
            if(this.isShow){
                this.EmptyDatas();
            }
        }
    },
    mounted(){
        this.$nextTick(()=> {
            initKeyBoard.call(this,this.$el,this.$refs.recharRight);
            this.EmptyDatas();
        })
    },
    methods:{
        EmptyDatas(){
            this.CodeNum='';
            this.$refs.priceInput?.focus();
            if(this.mobile){//必须有手机号
                this.isCodeTime=true;
            }
            
        },
        /**确认 */
        confirm(){
            this.$emit("cardCodeConfirm",this.CodeNum);
        },
        /**取消 */
        hide(){
            this.isCodeTime=false;
            this.$emit("closeModel");
        },
        /**发送验证码 */
        readCode(){
            
            if(!this.mobile){
                this.$message.error('用户未绑定手机号，无法发送验证码！');
                return
            }
            const loading = this.$loading({
                text: "发送验证码中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.SendMobileSMSCode",{
                App_Type:5,//1：卡绑定   2：实体卡开卡验证码  3：修改手机号验证   4：卡解绑验证 5:卡消费验证
                Mobile:this.mobile,
                Verify_Type:0,
                Verify_Code:''
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.isCodeTime=true;
                    this.$message.success("验证码发送成功,请注意查收");
                }else{
                    this.$message.error("验证码发送失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('验证码发送失败：'+e);
                console.log('验证码发送失败：'+e);
            })
        },
    }
}
</script>

<style lang="sass">
@import './cardCodeModel.scss';
</style>