import aesUtil from './aesUtil.js'
import webBrowser from './webBrowser.js'
import customDir from './customDir.js'
import touch from './touch.js'
import animation from './animation.js'
import layoutRC from './layoutRC.js';
import tableElHeight from './tableElHeight.js'
import domResize from './domResize.js'
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import { render } from 'vue'

(()=>{
    if(!String.prototype.trim){
        String.prototype.trim=function() {
            return this.replace(/(^\s*)|(\s*$)/g, "");
        }
    }
   
    if(!String.prototype.replaceAll){
        String.prototype.replaceAll = function(s1, s2) {
            return this.replace(new RegExp(s1, "gm"), s2);
        }
    }
})();

/**
 * 控制模拟键盘输入
 * @param {*} target 
 */
const initKeyBoard=function(target,input){
    if(!target) return;
    let keyFocus = input;
    let index=0;
    let keyboard= target.getElementsByClassName('keyboard');
    let keys=target.getElementsByClassName('key-focus');
    if(keys){
        Array.from(keys).forEach((element,i)=>{
            element.addEventListener('focus',(e)=>{
                keyFocus=e.currentTarget;
                index=i;
            });
            element.addEventListener('blur',()=>{
                keyFocus=undefined;
            });
        })
    }
    //是否通过点击时 判断当前input焦点是否可输入
    let isActiveInput=false;
    if(!input && (!keys?.length>0)){
        isActiveInput=true;
    }
    function updateInput(val){
        keyFocus.value=val;
        keyFocus.dispatchEvent(new Event("input",{data:'val',inputType:'insertText'}));
    }
    //禁止安卓弹层软键盘
    function diskey(input){
        let isReadonly=input.getAttribute("readonly");
        if(!isReadonly){
            input.setAttribute("readonly","readonly");
            if(input._setTime) clearTimeout(input._setTime);
            input._setTime=setTimeout(()=>{
                input?.removeAttribute("readonly");
            },100)
        }
    }
    //当前input 的焦点
    function setInputActive(){
        let input=document.activeElement;
        let keys=target.getElementsByClassName('key-focus');
        if(keys){
            let isBo=false;
            Array.from(keys).forEach((element,i)=>{
                if(input==element){
                    isBo=true;
                }
            })
            if(isBo){
                keyFocus=input;
            }
        }
    }

    if(keyboard && keyboard.length>0){
        let bnts=keyboard[0].getElementsByTagName('span');
        Array.from(bnts||[]).forEach(element => {
            element.onmousedown=(e)=>{
                if(isActiveInput){
                    setInputActive();
                }
                if (keyFocus) {
                    diskey(keyFocus);
                    let val=e.currentTarget.getAttribute('data-value');
                    //let bing=keyFocus.getAttribute('data-model');
                    let value=keyFocus.value;
                    if(keyFocus.selectionStart<keyFocus.selectionEnd){
                        value=value.slice(0,keyFocus.selectionStart)+value.slice(keyFocus.selectionEnd);
                    }

                    if (val == '.' || !isNaN(val)) {
                        updateInput(value);
                        if(val=='.'){
                            if(value==""){
                                updateInput("0"+val);
                                //this[bing]="0"+val;
                            }else if(value.indexOf('.')<0){
                                updateInput(value + val);
                                //this[bing] = value + val;
                            }
                        }else{
                            updateInput(value + val);
                            //this[bing] = value + val;
                        }
                    } else if (val == 'back') {
                        if(keyFocus.selectionStart<keyFocus.selectionEnd){
                            updateInput(value);
                        }
                        else if(value!=''){
                            updateInput(value.substr(0, value.length - 1));
                           // this[bing] = value.substr(0, value.length - 1);
                        }
                    } else if (val == "clean") {
                        updateInput("");
                        //this[bing] = "";
                    } else if (val == "next") {
                        
                        if (keys && keys.length > index + 1) {
                            diskey(keys[index+1]);
                            keys[index+1].focus();
                        } else {
                            keyFocus.blur();
                        }
                    } else {
                        return;
                    }
                    e.preventDefault();
                }
            }
        });
    }

    return {
        setInput(input){//修改input
            keyFocus=input;
            isActiveInput=false;
        },
        getInput(){
            return keyFocus;
        }
    }
}

/**现在input 输入格式*/
const inputPattern= function(app){
    app.directive("input-pattern", {
        mounted(el, binding) {
            const target = el instanceof HTMLInputElement ? el : el.querySelector("input");
            let regex;
            if(binding.value){
                if(typeof(binding.value)=="string"){
                    if(binding.value=="number"){//数字类型
                        regex=/^(-?(([1-9]\d*)|\d?)([.]\d*)?$)/g
                    }else if(binding.value=="money"){//money 类型 正数 小数位不能超过2位  
                        regex=/^((([1-9]\d*)|\d?)([.]\d{0,2})?$)/g
                    }
                }else if(binding.value instanceof RegExp){
                    regex=binding.value;
                }
            }
            function test(str){
                if(regex){
                   let bo= regex.test(str);
                   regex.lastIndex=0;
                   return bo;
                }
                return false;
            }
            if (regex) {
                // Regex check
                if (!regex.test(el.value)) {
                    el.value='';
                    el.dispatchEvent(new Event("input"));
                }
                el._oldValue=el.value;
            }

            target.addEventListener("focus", e => {
                if (regex) {
                    // Regex check
                    if (!test(e.target.value)) {
                        e.target.value='';
                        e.target.dispatchEvent(new Event("input"));
                    }
                    e.target._oldValue=e.target.value;
                }
            });
            target.addEventListener("keydown", e => {
                if (regex) {
                    // Regex check
                    if (!test(e.target.value)) {
                        e.target.value=e.target._oldValue
                        e.target.dispatchEvent(new Event("input"));
                    }
                    e.target._oldValue=e.target.value;
                }
            });

            target.addEventListener("input", e => {
                if (e.inputType=="insertCompositionText" || e.inputType=="insertText" || e.inputType=="deleteContentBackward") {
                    // Regex check
                    if (!test(e.target.value)) {
                        setTimeout(()=>{
                            e.target.value=e.target._oldValue;
                            e.target.dispatchEvent(new Event("input"));//调用input事件使vue v-model绑定更新,下面相同
                        },0)
                    }else{
                        e.target._oldValue=e.target.value;
                    }
                }
            });
            target.addEventListener("paste", e => {
                if (!test(e.target.value)) {
                    e.target.value=e.target._oldValue
                    e.target.dispatchEvent(new Event("input"));
                }
                e.target._oldValue=e.target.value;
            });
            target.addEventListener("keyup", e => {
                if (!test(e.target.value)) {
                    e.target.value=e.target._oldValue
                    e.target.dispatchEvent(new Event("input"));
                }
                e.target._oldValue=e.target.value;
            });
        } // end bind
    }); // end directive
}


const on=function(el,event,className,fun){
    el.addEventListener(event,(e)=>{
        let classList=[];
        el.querySelectorAll(className)?.forEach((item)=>{
            classList.push(item);
        })
        if(e.path.find(obj=>classList.find(it=>it==obj))){
            fun(e);
        }
    })
}


const config={
    /**设备id */
    UUID:'',
    /** 公钥 */
    Access_Key:'',
    /** 私钥 加密用 */ 
    Secret_key:'',
    /** 接口地址 */ 
    OpenAPIUrl:'',
    /** 开发者 */
    DeveloperID:'Bestech',
    /** 消息类型 */
    Msg_Type: 'CloudPos',
    /** 模块 */
    Function_Module:'Register'
}

/**
 * 32位随机编码
 * @returns 32位随机编码
 */
function newGuid() {
    var guid = "";
    for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if ((i == 8) || (i == 12) || (i == 16) || (i == 20))
            guid += "-";
    }
    return guid;
}
/**
 * 
 * @param {*} date 
 * @param {*} fmt 
 * @returns 
 */
function formateDate(date,fmt){
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      }
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ''
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
        }
      }
      return fmt
}
// 左边补0函数
function padLeftZero (str) {
    return ('00' + str).substr(str.length)
}  

/**
 * 整数转换
 * @param {*} num 数字
 */
function NumberConversion(num){
    if(parseFloat(num)===parseInt(num)){
        return parseInt(num)
    }else{
        return Number(num).toFixed(2)
    }
}

/**
 * 获取开台时间和当前时间的时间差
 * @param {*} beginTime 开始时间
 * @returns 5:10
 */
function differenceTime(beginTime) {
    if(beginTime==null|| beginTime==""){
        return "";
    }else{
        var dateBegin = new Date(beginTime); //开台时间
        var dateEnd = new Date(); //当前时间
        var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
        //var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
        var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
        //计算相差秒数
        //var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        //var seconds = Math.round(leave3 / 1000);
        //console.log("相差"+dayDiff +"天" +hours +"小时" +minutes +"分钟" +seconds +"秒");
        return hours+":"+minutes;
    }
}

  /**
   * 获取下单时间返回分秒
   *  @param {*} beginTime 开始时间
   *  @param {*} type 类型
   *  */
function differenceTimeseconds(beginTime,type) {
    if(beginTime==null|| beginTime==""){
        return "";
    }else{
        var dateBegin = new Date(beginTime); //开台时间
        var dateEnd = new Date(); //当前时间
        var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
        //var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
        var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        //var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);
        //console.log("相差"+dayDiff +"天" +hours +"小时" +minutes +"分钟" +seconds +"秒");
        if(type==1){
            if(minutes=='-1'){
                return '0';
            }else{
                return (dateDiff /1000 /60).toFixed(0);
            }
        }else{
            if(minutes=='-1'){
                return '0:00';
            }else{
                return minutes+':'+seconds;
            }
        }
    }
}
/**
 * 超时 分钟
 * @param time1 开始 时间
 * @param time2 结束 时间
 */
function getDiffMinutes(time1,time2){
    var dateDiff =  time2.getTime()-time1.getTime(); //时间差的毫秒数
    return parseInt(dateDiff/(60*1000));
}

/**
 * 深度克隆
 * @param {*} o 数据
 * @returns 
 */
function deepClone(o) {
    // 判断如果不是引用类型，直接返回数据即可
    if (typeof o === 'string' || typeof o === 'number' || typeof o === 'boolean' || typeof o === 'undefined' || o === null) {
        return o
    } else if (Array.isArray(o)) { // 如果是数组，则定义一个新数组，完成复制后返回
        // 注意，这里判断数组不能用typeof，因为typeof Array 返回的是object
        var _arr = []
        o.forEach(item => { _arr.push(deepClone(item)) })
        return _arr
    } else if (typeof o === 'object') {
        var _o = {}
        for (let key in o) {
            _o[key] = deepClone(o[key])
        }
        return _o
    }
}

(()=>{
    /**数组 object 排序用  @name 字段名称  @sorttype 排序类型  ASC 升序  DESC 降序 */
    Array.prototype.order=function(name,sorttype){
        if(this)
        return this.sort((d1,d2)=>{
            var convertA = d1[name];
            var convertB = d2[name];
            if (typeof convertA == 'string' && typeof convertB == 'string') {
                if (sorttype.toUpperCase() == 'ASC') {
                    return convertA.localeCompare(convertB);
                } else {
                    return convertB.localeCompare(convertA);
                }
            } else {
                if (sorttype.toUpperCase() == 'ASC') {
                    return convertA - convertB;
                } else {
                    return convertB - convertA;
                }
            }
        })
    }
    //合并数据
    Array.prototype.sum=function(json,fixed){
        if(this){
            let data=Object.assign({},json);
            this.forEach((it)=>{
                for(let key in data){
                    data[key]=(data[key]||0)+it[key];
                }
            })
            if(fixed>=0){
                for(let key in data){
                    data[key]= parseFloat(parseFloat(data[key]).toFixed(fixed)); 
                }
            }
            return data;
        }
    }

    /**数组 groupBy 分组  @name 字段名称 @sorttype 排序类型  ASC 升序  DESC 降序 默认ASC*/
    Array.prototype.groupBy=function(name,callBackfun,sorttype){
        if(this){
            let groupKey={};
            this.forEach((it)=>{
                let key=it[name];
                if(!groupKey[key]){
                    groupKey[key]=[];
                }
                groupKey[key].push(it);
            })
            let kesArr= Object.keys(groupKey)
            if(sorttype){
                kesArr= kesArr.sort();
                if((sorttype||"").toUpperCase() == 'DESC'){
                    kesArr=kesArr.reverse();
                }
            }
            let group=kesArr.map(it=> groupKey[it]);

            group.forEach(callBackfun)
            return group;
        }
    }

    Number.prototype.compute=function(){
        let unit="+";
        let leng=arguments.length;
        if(leng==0){
            return this;
        }else if(leng>1){
            if(typeof(arguments[leng-1])=="string"){
                unit=arguments[leng-1];
                leng=leng-1
            }
        }
        let pre=6;//小数位精度
        let sum=Number(this.toFixed(pre));
        for(let i=0;i<leng;i++){
            let num=Number((Number(arguments[i])||0).toFixed(pre));
            if(unit=="+"){
                sum= Number((sum+num).toFixed(pre));
            }else if(unit=="-"){
                sum= Number((sum-num).toFixed(pre));
            }
        }
        return sum;
    }
    //保留小数位 精确进度问题
    Number.prototype.toFixed=function(n){
        if(n > 20 || n < 0) {
            throw new RangeError('toFixed() digits argument must be between 0 and 20');
        }
        const number = this;
        if(isNaN(number) || number >= Math.pow(10, 21)) {
            return number.toString();
        }
        if(typeof(n) == 'undefined'|| n == 0) {
            return(Math.round(number)).toString();
        }
        let result = number.toString();
        const arr = result.split('.');
        // 整数的情况
        if(arr.length < 2) {
            result += '.';
            for(let i = 0; i < n; i += 1) {
                result += '0';
            }
            return result;
        }
        const integer = arr[0];
        const decimal = arr[1];
        if(decimal.length == n) {
            return result;
        }
        if(decimal.length < n) {
            for(let i = 0; i < n - decimal.length; i += 1) {
                result += '0';
            }
            return result;
        }
        result = integer + '.'+ decimal.substring(0, n);
        const last = decimal.substring(n, n+1);
        // 四舍五入，转换为整数再处理，避免浮点数精度的损失
        if(parseInt(last, 10) >= 5) {
            const x = Math.pow(10, n);
            if(number>0){
                result = (Math.round((parseFloat(result) * x)) + 1) / x;
            }else{
                result = (Math.round((parseFloat(result) * x)) - 1) / x;
            }

            result = result.toFixed(n);
        }
        return result;
    }
})();



/**时间 格式化  Format('yyyy-MM-dd hh:mm:ss') */
(Date.prototype.Format = function(fmt){ //author: meizz
    if(this){
        var o = {
            "M+" : this.getMonth()+1,                 //月份
            "d+" : this.getDate(),                    //日
            "h+" : this.getHours(),                   //小时
            "m+" : this.getMinutes(),                 //分
            "s+" : this.getSeconds(),                 //秒
            "q+" : Math.floor((this.getMonth()+3)/3), //季度
            "S"  : this.getMilliseconds()             //毫秒
        };
        if(/(y+)/.test(fmt))
            fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
        for(var k in o)
            if(new RegExp("("+ k +")").test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        return fmt;
    }
})();

//计算 字符串 站位长度
function stringCodeLeng(str){
    let total=0;
    var fuhao=['，','。','；','！','：','【','】','…','？','“','”','—','·' ,'、','《' ,'》' ,'（','）' ,'￥','＠' ];//一些中文符号
	var fuhao_code=[];
	for(var j=0;j<fuhao.length;j++){
		fuhao_code.push(fuhao[j].charCodeAt(0));
	}
    for(let i = 0, len = str.length; i < len; i++){
       let charCode=str.charCodeAt(i);
        if((charCode >= 0x4e00 && charCode<=0x9fa5) || (charCode>=0xFE30 && charCode<=0xFFA0) || fuhao_code.indexOf(charCode)>=0){//汉字 及 中文符号 占2个位置长度
            total += 2;
        }else{//其他 占1个位置长度
            total += 1;
        }
    }
    return total;
}
/**
 * 按 字符串 站位长度 分割成数组
 * @param str 字符串
 * @param leng 占位长度
 */
function substrCodeArry(str,leng){
    let total=0;
    let arr=[],start=0;
    var fuhao=['，','。','；','！','：','【','】','…','？','“','”','—','·' ,'、','《' ,'》' ,'（','）' ,'￥','＠' ];//一些中文符号
	var fuhao_code=[];
	for(var j=0;j<fuhao.length;j++){
		fuhao_code.push(fuhao[j].charCodeAt(0));
	}
    for(let i = 0, len = str.length; i < len; i++){
        let charCode=str.charCodeAt(i);
        let codeLeng=1;
        if((charCode >= 0x4e00 && charCode<=0x9fa5) || (charCode>=0xFE30 && charCode<=0xFFA0) || fuhao_code.indexOf(charCode)>=0){//汉字 及 中文符号 占2个位置长度
            codeLeng= 2;
        }

        if(leng<=total+codeLeng || len==i+1){
            if(total<leng && leng<total+codeLeng){//截取部分 少于占位 长度
                if(leng<total+codeLeng ){
                    i--;
                }
               
            }
           
            arr.push(str.substring(start,i+1));
            total=0;
            start=i+1;
        }else{
            total+=codeLeng;
        }
    }
    return arr;
}

function getStrAlignArray(name,printLen,align){
    let arrTxt=substrCodeArry(name,printLen);
    let empty=new Array(printLen+1).join(" ");
    return arrTxt.map(str=>{
        let leng=stringCodeLeng(toString(str));
        let leng1=printLen-leng;
        if(align==1){//居中
            let startLeng=parseInt(leng1/2);
            return empty.substring(0,startLeng) +toString(str)+empty.substring(0,leng1-startLeng);
        }else if(align==2){//右对齐
            let leng1=printLen-leng;
            return empty.substring(0,leng1)+toString(str);
        }else{
            return toString(str)+empty.substring(0,leng1);
        }
    })
}

/**
 * 转换为字符串
 */
function toString(str){
    if(str==undefined || str==null){
        return "";
    }
    return str.toString();
}

/**打印预览的html */
function printView(printBodies){
    let html=[];
    (printBodies||[]).map((item)=>{
        let cssStyle=[];
        let fontSize=10;//单位pt = 13.333px
        if(item.Print_Bold){
            cssStyle.push("font-weight:bold");
        }
        if(item.Print_FontName){
            cssStyle.push("font-family:"+item.Print_FontName);
        }

        if(item.Print_FontSize>0){
            fontSize=item.Print_FontSize;
        }
        //对齐，@: 默认左对齐，1: 居中 ，2: 右对齐
        if(item.Print_Align==1){
            cssStyle.push("text-align:center");
        }else if(item.Print_Align==2){
            cssStyle.push("text-align:right");
        }
        cssStyle.push("font-size:"+item.Print_FontSize+"pt");
        fontSize=item.Print_FontSize;
        let span=item.Print_Text.replace(/([^ ]+([ ][^ ]+)?)|([ ]+)/g,(txt)=>{
            
            if(txt.replace(/[ ]/g,"")==""){
                let leng=txt.length*(fontSize/2);
                return '<span style="display:inline-block;width:'+leng+'pt;"></span>';
            }else{
                let leng=stringCodeLeng(txt)*(fontSize/2);

                return '<span style="display:inline-block;width:'+leng+'pt">'+txt+'</span>';
            }
        });
        html.push('<div class="content-box" style="'+cssStyle.join(';')+'">'+span+'</div>');
    })
    return html.join('');
}


/**打印 json 一条数据*/
function printTrJson(analysisItems,data,type){
    let json=[];
    analysisItems=(analysisItems||[]).filter(item=>item.DataItem_PrintLen>0)//占位数必须大于0的数据才可以打印
    if(type==0){
        (analysisItems||[]).forEach((item)=>{
            let text="";
            item.DataItem_PrintLen=item.DataItem_PrintLen||48;//占位数
            let empty=new Array(item.DataItem_PrintLen+1).join(" ");
            item=item||{};
            let defaultJson={
                "Print_Bold": item.DataItem_FontBold||false,
                "Print_FontName": item.DataItem_FontName||"微软雅黑",
                "Print_FontSize": item.DataItem_FontSize||10,
                "Print_Text": "",
                "Print_Type": 0
            }
            let leng=stringCodeLeng(item.DataItem_Name+toString(data[item.DataItem_Key]));//名称加 内容 显示长度
            //对齐方式
            if(item.DataItem_FontAlign==1){//居中
                text=empty.substring(0,(item.DataItem_PrintLen-leng)/2);
                text=item.DataItem_Name+text+toString(data[item.DataItem_Key]);
            }else if(item.DataItem_FontAlign==2){//右对齐
                text=empty.substring(0,item.DataItem_PrintLen-leng);
                text=item.DataItem_Name+text+toString(data[item.DataItem_Key]);
            }else{//左对齐
                text=item.DataItem_Name+toString(data[item.DataItem_Key])+empty.substring(0,item.DataItem_PrintLen-leng);
            }
            
            if(leng>item.DataItem_PrintLen){
                let txtArray = getStrAlignArray(text,item.DataItem_PrintLen,item.DataItem_FontAlign);
                txtArray.map((t)=>{
                    json.push(Object.assign({},defaultJson,{Print_Text:t}));
                })
            }else{
                json.push(Object.assign({},defaultJson,{Print_Text:text}));
            }
        })

    }else{//表格
        if(analysisItems && analysisItems.length>0){
            let defaultJson={
                "Print_Bold": analysisItems[0].DataItem_FontBold||false,
                "Print_FontName": analysisItems[0].DataItem_FontName||"微软雅黑",
                "Print_FontSize": analysisItems[0].DataItem_FontSize||10,
                "Print_Text": "",
                "Print_Type": 0
            }
            let arrTrTxt=[];
            let trLeng=1;
            let trPrintLen=0;
            //标题头部
            analysisItems.forEach((item,index)=>{
                item.DataItem_PrintLen=item.DataItem_PrintLen||48;//占位数
                trPrintLen+=item.DataItem_PrintLen;
                let name=item.DataItem_Name.replace(/^[ ]|[ ]$/g,"");
                arrTrTxt[index]=getStrAlignArray(name,item.DataItem_PrintLen,item.DataItem_FontAlign);
                if(arrTrTxt[index].length>trLeng){
                    trLeng=arrTrTxt[index].length;
                }
            });

            for(let i=0;i<trLeng;i++){
                let txtArr=[];
                analysisItems.forEach((item,index)=>{
                    if(arrTrTxt[index][i]){
                        txtArr.push(arrTrTxt[index][i]);
                    }else{
                        txtArr.push(new Array(item.DataItem_PrintLen+1).join(" "));
                    }
                })
                json.push(Object.assign({},defaultJson,{Print_Text:txtArr.join('') }));
            }


            (data||[]).forEach((trd)=>{

                let bodyArrTrTxt=[[]];
                let bodyTrLeng=1;
                let totalJson={};
                let totalTr=[];
                if(trd.isTotal){
                    totalJson.Print_Bold=true;
                }
                
                analysisItems.forEach((item,index)=>{
                    let printLen=item.DataItem_PrintLen;
                    if(trd.isTotal && typeof(trd[item.DataItem_Key])=="string" && trd[item.DataItem_Key]?.indexOf('\n')>=0){//有换行
                        let arrTrTxt=[];
                        trd[item.DataItem_Key].split('\n').forEach((name,index)=>{
                            if(index==0){
                                let arr=getStrAlignArray(name,printLen,item.DataItem_FontAlign);
                                arr?.forEach(it=>{
                                    arrTrTxt.push(it);
                                })

                            }else{
                                totalTr=totalTr.concat(getStrAlignArray(name,trPrintLen,item.DataItem_FontAlign))
                            }
                        })
                        bodyArrTrTxt[index]=arrTrTxt;
                        if(bodyArrTrTxt[index].length>bodyTrLeng){
                            bodyTrLeng=bodyArrTrTxt[index].length;
                        }
                    }else{
                        let name=toString(trd[item.DataItem_Key]).replace(/^[ ]|[ ]$/g,"");
                        bodyArrTrTxt[index]=getStrAlignArray(name,printLen,item.DataItem_FontAlign);
                        let trLeng=bodyArrTrTxt[index].length
                        if(trLeng>bodyTrLeng){
                            bodyTrLeng=trLeng;
                        }
                    }
                });

                
                for(let i=0;i<bodyTrLeng;i++){
                    let txtArr=[];
                    analysisItems.forEach((item,index)=>{
                        let printLen=item.DataItem_PrintLen;
                        if(bodyArrTrTxt[index][i]){
                            txtArr.push(bodyArrTrTxt[index][i]);
                        }else{
                            txtArr.push(new Array(printLen+1).join(" "));
                        }
                    })
                    json.push(Object.assign({},defaultJson,totalJson,{Print_Text:txtArr.join('') }));
                }

                if(trd.isTotal){
                    totalTr.forEach(text=>{
                        json.push(Object.assign({},defaultJson,totalJson,{Print_Text:text }));
                    })
                    json.push(Object.assign({},defaultJson,{Print_Text:new Array(48+1).join("-") }));
                }
            })

        }
    }
    return json
}

/** 打印 json 一条数据 
 * @txt 要打印的内容
 * @data 打印格式 默认 defaultJson={
 *      "DataItem_FontAlign":0,//左对齐
        "Print_Bold": false,
        "Print_FontName": "宋体",
        "Print_FontSize": 10,
        "Print_Text": "",
        "Print_Type": 0
    }
*/
function printJson(txt,data){
    data=data||{};
    data.DataItem_PrintLen=data.DataItem_PrintLen||48;//占位数
    let defaultJson={
        "Print_Bold": data.DataItem_FontBold||false,
        "Print_FontName": data.DataItem_FontName||"微软雅黑",
        "Print_FontSize": data.DataItem_FontSize||10,
        "Print_Text": "",
        "Print_Type": 0
    }
    if(data.baseline){
        return Object.assign({},defaultJson,{Print_Text:new Array(data.DataItem_PrintLen+1).join(data.baseline)});
    }
    let json=[];
    let text="";
    let empty=new Array(data.DataItem_PrintLen+1).join(" ");
    
    txt=toString(txt);
    let leng=stringCodeLeng(txt);//名称加 内容 显示长度
    let leng1=data.DataItem_PrintLen-leng;
    //对齐方式
    if(data.DataItem_FontAlign==1){//居中
        text=empty.substring(0,leng1/2)+txt;
    }else if(data.DataItem_FontAlign==2){//右对齐
        text=empty.substring(0,leng1)+txt;
    }else{//左对齐
        text=txt+empty.substring(0,leng1);
    }
    if(leng>data.DataItem_PrintLen){
        let txtArray = getStrAlignArray(text,data.DataItem_PrintLen,data.DataItem_FontAlign);
        txtArray.map((t)=>{
            json.push(Object.assign({},defaultJson,{Print_Text:t}));
        })
    }else{
        json.push(Object.assign({},defaultJson,{Print_Text:text}));
    }
    return json;
}

/** 
 * XLSX 自定义 内容 导出
 * @param arr[][]  [{value:"显示内容",col:"站用列数 默认1",row:"站用行数 默认1",t}]
*/
function addSheetCell(arr){
    let ws={};
    ws['!merges']=[];
    let rowIndex=1;//行
    let colIndex=0;//列
    let maxCol=0;//最大列
    let tempArr=[[]];
    arr?.forEach((list,rIndex)=>{
        colIndex=0;
        let row=rowIndex;
        list?.forEach((d)=>{
            if(rIndex>0 && tempArr[rIndex] && tempArr[rIndex][colIndex]){
                for(let i=colIndex;i<maxCol;i++){
                    if(!tempArr[rIndex][i]){
                        colIndex=i;
                        break;
                    }
                }
            }

            let col= XLSX.utils.encode_col(colIndex);//列
            //t  b布尔值，n数字，e错误，s字符串，d日期，z存根
            ws[col+row]={t:d.t||"s",v:d.value,z: d.z};

            let span=(d.col||1)-1;
            
            let colEnd=XLSX.utils.encode_col(colIndex+span);
            
            let rowEnd=row+(d.row||1)-1;
            ws['!merges'].push(XLSX.utils.decode_range(col+row+":"+colEnd+rowEnd))
            
            for(let i=row-1;i<rowEnd;i++){
                for(let j=colIndex;j<=colIndex+span;j++){
                    if(!tempArr[i]){tempArr[i]=[]}
                    tempArr[i][j]=true;
                }
            }
            colIndex+=span;
            if(maxCol<colIndex){
                maxCol=colIndex;
            }
            colIndex++;
        })
        rowIndex++;
    })
    ws['!ref']="A1:"+ XLSX.utils.encode_col(maxCol)+(rowIndex-1);
    return ws;
}
/** 
 * 讲elTable 格式 导出成  arr[][] 格式 [{value:"显示内容",col:"站用列数 默认1",row:"站用行数 默认1",t}]
 * @param elTable el 表格对象  this.$refs.elTable
 * @param list 要导出的数据
 * @param colFormat  ({row:"数据",column:"table 列格式",columnIndex:"当前列位置"})=>{} 每列数据转换时 可自定义 设置 输出类型或 数据内容
 * 
 * ``` 
 * colFormat 可不传  没有时 会根据eltable 中  el-table-column  属性判断 数据类型  
 * 例如：<el-table-column data-format="number" /> number 数字  text 字符串
 * 
 * colFormat:(row,column)=>{
 *      let json={};
 *      //t 类型  b布尔值，n数字，e错误，s字符串，d日期，z存根
 *      if(column.property=="Eat_Number"){
 *          json.t='n';
 *      }
 *      return json;
 * }
 * ``` 
*/
function addElTableToAll(elTable,list,colFormat){
    if(elTable){
        let exData=[];
        if(elTable?._?.type?.name=="elTableVir"){
            elTable=elTable.$refs.elTable;
        }
        let tableRefs=elTable.$refs;
        let tableHeader=tableRefs?.tableHeader;
        let hiddenColumns=tableRefs.hiddenColumns;

        if(tableHeader?.columnRows?.length>0){
            //表头
            let hData=[];
            tableHeader?.columnRows.forEach((tr,i)=>{
                
                hData[i]=[]
                tr.forEach((th,index)=>{
                    if(th.type== "selection" || th.type=="edit"){
                        return;
                    }
                    hData[i][index]={value:th.label,col:th.colSpan,row:th.rowSpan}
                })
            })

           
            
            //查找栏目设置的 属性(Format:'数据类型')
            let setColAttr=(obj,tr,colDom)=>{
                if(tr.children?.length>0){
                    tr.children?.forEach((ctr,ci)=>{
                        setColAttr(obj,ctr,colDom.children[ci]);
                    })
                }else{
                    let format=colDom.getAttribute("data-format");
                    let formatz=colDom.getAttribute("data-formatz");
                    let isRender=colDom.getAttribute("is-render");
                    if(isRender=="" || isRender=="false"){
                        isRender=false;
                    }else{
                        isRender=true;
                    }
                    obj.push({format:format,formatz:formatz,isRender:isRender});
                }
            }
            let columns=hiddenColumns.children;
            let colAttr=[];
            tableHeader?.columnRows[0]?.forEach((tr,i)=>{
                setColAttr(colAttr,tr,columns[i]);
            })
            
            
            //内容

            let bodyData=[];
            let _dom=document.createElement("div");//虚拟dom
            if(list?.length>0){
                list.forEach((it,rowIndex)=>{
                    let tds=[];
                    tableHeader?.columns.forEach((td,columnIndex)=>{
                        if(td.type== "selection"){
                            return;
                        }
                        let json={ value:"",  col:1, row:1, t:"s" };
                        let isValue=false;
                        if(typeof(colFormat)=="function"){
                            let column=colFormat(it,td,columnIndex);
                            if(column.t){
                                json.t=column.t;
                                if(json.z){
                                    json.z=column.z;
                                }
                            }
                            if(column.value){
                                json.value=column.value;
                                isValue=true;
                            }
                        }

                        //是否不取渲染中的数据
                        let isRender=true;

                        if(colAttr[columnIndex]){//自定义属性
                            //t  b布尔值，n数字，e错误，s字符串，d日期，z存根
                            json.t=({"number":'n',"text":"s","boolean":"b","date":"d"})[colAttr[columnIndex].format]||"s";
                            if(colAttr[columnIndex].formatz){
                                json.z=colAttr[columnIndex].formatz;
                            }

                            if(colAttr[columnIndex].isRender===false){
                                isRender=false;
                            }
                        }

                        if(!isValue){
                            if(isRender || !td.property){//使用渲染中的数据
                                render(td.renderCell({row:it,column:td,$index:rowIndex,isExcel:true}),_dom);
                                json.value=_dom.textContent;
                                render(null,_dom);
                            }else{//使用原数据值
                                let value=it[td.property]||"";//默认赋值
                                if(typeof(td.formatter)=="function"){//格式化
                                    //row, column, cellValue
                                    value=td.formatter(it,td,it[td.property]);
                                }
                                json.value=value;
                            }
                            
                        }

                        

                        if(json.t=="s"){
                            json.value=(json.value||"").toString();
                        }
                        //合并单元格
                        if(typeof(elTable.spanMethod)=="function"){
                           let span=elTable.spanMethod({row:it, column:td, rowIndex:rowIndex, columnIndex:columnIndex});
                           if(span?.length==2){
                               json.row=span[0];
                               json.col=span[1];
                           }
                        }
                        if(json.row>0 || json.col>0){
                            tds.push(json);
                        }
                        
                    });
                    bodyData.push(tds);
                })
            }

            //底部合计
            let foodData=[]; 
            if(typeof(elTable.summaryMethod)=="function"){
                let arr=elTable.summaryMethod({ columns:tableHeader?.columns, data:list});
                foodData.push(arr.map((val)=>{
                    return { 
                        value:val,  
                        col:1, 
                        row:1, 
                        t:typeof(val)=="number"?"n":"s" 
                    };
                }))
            }

            return exData.concat(hData,bodyData,foodData);
        }
    }
}

/**将elTable 导出成 excel 
 * @param elTable el 表格对象  this.$refs.elTable
 * @param list 要导出的数据
 * @param titleName 导出的文件名称
 * @param colFormat  ({row:"数据",column:"table 列格式",columnIndex:"当前列位置"})=>{} 每列数据转换时 可自定义 设置 输出类型或 数据内容
 * 
 * ``` 
 * colFormat 可不传  没有时 会根据eltable 中  el-table-column  属性判断 数据类型  
 * 例如：<el-table-column data-format="number" /> number 数字  text 字符串
 * 
 * colFormat:(row,column)=>{
 *      let json={};
 *      //t 类型  b布尔值，n数字，e错误，s字符串，d日期，z存根
 *      if(column.property=="Eat_Number"){
 *          json.t='n';
 *      }
 *      return json;
 * }
 * ``` 
*/
function elTableToExcel({elTable,list,titleName,colFormat,headArr}){
    try {
        let cellist=[];
        let exData=addElTableToAll(elTable,list,colFormat);
        cellist=cellist.concat(headArr||[],exData||[]);
        let ws=addSheetCell(cellist);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,titleName);
        let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: false, type: 'array'});
        FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), titleName+'.xlsx')
    } catch (e) {
        if (typeof console !== 'undefined') console.log(e)
    }
}

/**将elTable 导出成 sheet 
 * @param elTable el 表格对象  this.$refs.elTable
 * @param list 要导出的数据
 * @param titleName 导出的文件名称
 * @param colFormat  ({row:"数据",column:"table 列格式",columnIndex:"当前列位置"})=>{} 每列数据转换时 可自定义 设置 输出类型或 数据内容
 * 
 * ``` 
 * colFormat 可不传  没有时 会根据eltable 中  el-table-column  属性判断 数据类型  
 * 例如：<el-table-column data-format="number" /> number 数字  text 字符串
 * 
 * colFormat:(row,column)=>{
 *      let json={};
 *      //t 类型  b布尔值，n数字，e错误，s字符串，d日期，z存根
 *      if(column.property=="Eat_Number"){
 *          json.t='n';
 *      }
 *      return json;
 * }
 * ``` 
*/
function elTableToSheet({elTable,list,colFormat,headArr}){
    try {
        let cellist=[];
        let exData=addElTableToAll(elTable,list,colFormat);
        cellist=cellist.concat(headArr||[],exData||[]);
        let ws=addSheetCell(cellist);
        return ws;
    } catch (e) {
        if (typeof console !== 'undefined') console.log(e)
    }
}

// 阻止所有按钮的键盘事件
document.addEventListener('keydown', function(event) {
    if (event.target.tagName.toLowerCase() === 'button') {
        event.preventDefault();
        return false;
    }
});

//页面快捷键 监听
let shortcutKeyFun=function(){};
(()=>{
    var body = document.querySelector('html');
    body.addEventListener("keyup",(e)=>{
        let KeyBoard="";
        if (e.key!= "Control" && e.key!= "Alt" && e.key!= "Shift") {
            let str="";
            //组合键 Ctrl+Shift+Alt+
            if(e.ctrlKey){
                str+="Ctrl+";
            }
            if(e.shiftKey){
                str+="Shift+";
            }
            if(e.altKey){
                str+="Alt+";
            }

            if(e.keyCode==32){
                KeyBoard=str+"Space".toLocaleUpperCase();
            }else if(e.key=="ArrowUp"){
                KeyBoard=str+"↑";
            }else if(e.key=="ArrowDown"){
                KeyBoard=str+"↓";
            }else if(e.key=="ArrowLeft"){
                KeyBoard=str+"←";
            }
            else if(e.key=="ArrowRight"){
                KeyBoard=str+"→";
            }
            else{
                KeyBoard=str+e.key?.toLocaleUpperCase();
            }
        }else if(e.key== "Control"){
            KeyBoard="Ctrl";
        }else if(e.Menu_KeyBoard== "Alt"){
            KeyBoard="Alt";
        }else if(e.key== "Shift"){
            KeyBoard="Shift";
        }
        if(typeof(shortcutKeyFun)=="function" && KeyBoard) shortcutKeyFun(e,KeyBoard);
    });
})();

/** */
(()=>{
    let fun=(event)=>{
        if(event.target.nodeName=="INPUT" || event.target.nodeName=='TEXTAREA'){
            return;
        }
        const currentFocus = document.activeElement;
        if(currentFocus?.attributes?.focusLock?.value=="true"){
            //阻止默认事件  防止改变焦点
            event.preventDefault && event.preventDefault();
        }
    }
    document.querySelector("body").addEventListener("mousedown",fun);
})();

export {
    aesUtil,
    webBrowser,
    initKeyBoard,
    config,
    newGuid,
    touch,
    animation,
    deepClone,
    differenceTime,
    formateDate,
    differenceTimeseconds,
    getDiffMinutes,
    NumberConversion,
    printView,
    printTrJson,
    printJson,
    on,
    addSheetCell,
    addElTableToAll,
    elTableToExcel,
    elTableToSheet
}


export default {
    install(Vue){
        Vue.use(touch);
        Vue.use(webBrowser);
        Vue.use(layoutRC);
        Vue.use(domResize);
        Vue.use(animation);
        Vue.use(tableElHeight);
        Vue.use(inputPattern);
        Vue.use(customDir);
        
        Vue.config.globalProperties.$addSheetCell=addSheetCell;
        Vue.config.globalProperties.$excelCommon={
            addSheetCell,
            addElTableToAll,
            elTableToExcel,
            elTableToSheet
        }

        Vue.config.globalProperties.$shortcutKey={
            setFun:(fun)=>{
                shortcutKeyFun=fun;
            }
        };
    }
}

