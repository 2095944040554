<template>
    <div class="diningDetail" v-if="orderInfo">
        <div class="desk-box">
            <div class="panel" style="height: 160px;" v-if="!isCheckOut">
                <div class="panel-body">
                    <div class="lock" v-if="DeskLocked && lockEqId!= UUID ">
                        <div class="tag">该台已锁定 点击去解锁</div>
                        <i class="iconfont icon-suo" @click="lockeClick()"></i>
                        <p>解锁</p>
                    </div>
                    <div class="bnt-edit" @click="openshows()" v-else><i class="iconfont icon-pencil-create"></i></div>
                    <div class="trs">
                        <div class="td-5">
                            <i class="iconfont icon-zhuotai"></i>
                            <span class="span-Desk">桌号</span>
                        </div>
                        <div class="td-s">
                            <div style="font-size:18px;color:#333333;font-weight: 700;">{{orderInfo.Eat_DeskName}}</div>
                            <div style="font-size:9px;color:#756F86;">
                                <span class="no">开单号:{{orderInfo.Eat_ID}}</span>
                                <a class="complete" v-show="ProfitRate==true">GM:</a>
                                <span class="gross" v-show="ProfitRate==true">{{orderComplete.achieving}}%</span>
                                <span style="color:#333333;" v-if="orderInfo.Member_CardKind!=''">
                                    <i class="iconfont icon-viptequan-huangjinhuiyuan"></i>{{orderInfo.Member_CardKind}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-5">
                            <div class="lable-txt">人数:</div>
                            <div class="from-input fontBold">{{orderInfo.Eat_ManNum}}</div>
                        </div>
                        <div class="td-5">
                            <div class="lable-txt">席数:</div>
                            <div class="from-input">{{orderInfo.Feast_DeskNum}}</div>
                        </div>
                        <div class="td-5" v-if="orderInfo.Eat_Saler">
                            <div class="lable-txt">销售员:</div>
                            <div class="from-input">{{orderInfo.Eat_Saler}}</div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-5">
                            <div class="lable-txt">服务员:</div>
                            <div class="from-input">{{orderInfo.Eat_WaiterName}}</div>
                        </div>
                        <div class="td-5">
                            <div class="lable-txt">用餐渠道:</div>
                            <div class="from-input">{{channeName}}</div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-5">
                            <div class="lable-txt">开台时间:</div>
                            <div class="from-input" v-show="orderInfo.Eat_BeginTime">{{(new Date(orderInfo.Eat_BeginTime)).Format("hh:mm:ss")}}</div>
                        </div>
                        <div class="td-5">
                            <div class="lable-txt">用餐时间:</div>
                            <div class="from-input fontBold" v-show="orderInfo.Eat_BeginTime">{{DifferenceTime(orderInfo.Eat_BeginTime,2)}}</div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="lable-txt">订单备注:</div>
                        <div class="from-input">{{orderInfo.Order_Remark}}</div>
                    </div>
                </div>
            </div>
            <div class="panel" v-show="isCheckOut" style="height: 228px;">
                <div class="paymentInfo">
                    <div class="tr">
                        <div class="left-lable" >消费总额：</div>
                        <div class="right-money" v-if="orderInfo.Eat_SaleMoney>=0" >¥{{parseFloat(orderInfo.Eat_SaleMoney).toFixed(2)}}</div>
                    </div>
                    <div class="tr">
                        <div class="left-lable" >桌号:</div>
                        <div class="right-money" v-if="unionName==''">{{orderInfo.Eat_DeskName}}</div>
                        <div class="right-money" v-else>
                            <span class="unionstyle">共{{unionNumber}}桌</span>
                            {{unionName}}</div>
                    </div>
                </div>
                <div class="give-box" v-if="orderInfo">
                    <div class="tr">
                        <div class="left-lable">赠送金额(<b class="iconfont icon-jian"></b>)</div>
                        <div class="right-money" v-if="orderInfo.Gift_Money>=0">¥{{ Number(orderInfo.Gift_Money.toFixed(2)) + Number(unionGiftMoney.toFixed(2))}}</div>
                    </div>
                    <div class="tr">
                        <div class="left-lable">折扣金额(<b class="iconfont icon-jian"></b>)</div>
                        <!---->
                        <div class="right-money" >¥{{ Number(Number(orderInfo.ZK_Money)+Number(unionZKMoney)).toFixed(2)}}</div>
                    </div>
                    <div class="tr">
                        <div class="left-lable">服务费(<b class="iconfont icon-jia"></b>)</div>
                        <div class="right-money"  v-if="orderInfo.Service_Money>=0" >¥{{ (Number(orderInfo.Service_Money)+ Number(unionServiceMoney)).toFixed(2)}}</div>
                    </div>
                    <div class="tr">
                        <div class="left-lable">尾数调整(<b class="iconfont icon-jia"></b>)</div>
                        <div class="right-money"  v-if="orderInfo.Mantissa_Money>=0 || orderInfo.Mantissa_Money<=0" >¥{{orderInfo.Mantissa_Money.toFixed(2)}}</div>
                    </div>
                    <div class="tr">
                        <div class="left-lable">其中退菜金额：</div>
                        <div class="right-money" v-if="orderInfo.Refund_Money>=0">¥{{ (Number(orderInfo.Refund_Money)+Number(unionRefundMoney)).toFixed(2)}}</div>
                    </div>
                    <div style="border-bottom:1px dashed #ACA9B6;"></div>
                    <div class="tr">
                        <div class="left-lable">结账单号:</div>
                        <div class="right-money">{{orderInfo.Eat_CheckIndex}}</div>
                    </div>
                    <div class="tr" style="color:red">
                        <div class="bnt-edit"><i class="iconfont icon-pencil-create" @click="popShow=true,this.$keyavail =true"></i></div>
                        <div class="left-lable" style="padding-left:8%" >应付:</div>
                        <div class="right-money">¥{{Number(orderInfo.Payable_Money).toFixed(2)}}</div>
                        <!-- <div class="right-money">¥{{Number(orderInfo.UnPaid_Money).toFixed(2)}}</div> -->
                    </div>
                </div>
            </div>
            <order-menu-box class="panel panel-table flex-column-box" ref="orderMenu"/>
            <div class="footer orderFooter">
                <div class="float-left txt">
                    <span class="money" :class="{service:orderInfo.Service_Money>0}" v-html="deskCount.Total"></span>&nbsp;
                    <span class="small" :class="{service:orderInfo.Service_Money>0}">共{{deskCount.FoodsSum}}道 &nbsp; {{deskCount.SumPortion}}份</span>
                    <span class="service-span" v-if="orderInfo.Service_Money>0">含服务费:¥{{orderInfo.Service_Money}}</span>
                </div>
                <div class="float-right" @mousedown="(e)=>{e.preventDefault()}">
                    <div class="bnt-fangxiang" @click="$refs.orderMenu.pageUp()"><i class="iconfont icon-fangxiangquan-top"></i></div>
                    <div class="bnt-fangxiang" @click="$refs.orderMenu.pageDown()"><i class="iconfont icon-fangxiangquan-bottom"></i></div>
                </div>
            </div>
        </div>
        <div class="operate-button">
            <div class="scroll scrollnot">
                <div class="button-group" v-if="userMiddleMenu">
                    <button class="btn" v-for="(item,ProjectItem_Index) in userMiddleMenu?.filter(p=>p.ProjectItem_Key!='CheckONDesk')" :key="ProjectItem_Index" v-show="item.Is_Show && isCheckOut==false"  @click="MiddleMenuClick(item)">
                        <div v-if="item.ProjectItem_Key=='Plus'"><b class="iconfont icon-jia"></b></div>
                        <div v-else-if="item.ProjectItem_Key=='Minus'"><b class="iconfont icon-jian"></b></div>
                        <div v-else>{{item.ProjectItem_Name}}</div>
                        <span class="shortKey" v-if="item.ProjectItem_ShortcutKey">{{item.ProjectItem_ShortcutKey}}</span>
                    </button>
                    <button class="btn" v-for="(item,ProjectItem_Index) in userCheckOutMiddleMenu" :key="ProjectItem_Index" v-show="item.Is_Show && isCheckOut==true"  @click="CheckMiddleClick(item)">
                        <div v-if="item.ProjectItem_Key=='Plus'"><b class="iconfont icon-jia"></b></div>
                        <div v-else-if="item.ProjectItem_Key=='Minus'"><b class="iconfont icon-jian"></b></div>
                        <div v-else>{{item.ProjectItem_Name}}</div>
                        <span class="shortKey" v-if="item.ProjectItem_ShortcutKey">{{item.ProjectItem_ShortcutKey}}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="menu-box">
            <div class="div-box flex-column-box" v-show="!isCheckOut">
                <div class="food-main-box"  style="overflow:inherit"  v-if="orderMap_Type==2 ||orderMap_Type==1" :style="foodMainStyle">
                    <categorys-page class="filter-nav panel" 
                        :data="foodMainCategory" 
                        :row="foodMainArg.row" 
                        :col="foodMainArg.col" 
                        :selectIndex="mainCategoryIndex"
                        :bottomH="setBottomH"
                        :pageIndex="foodMainPageIndex"
                        navFirstName="全部大类"
                        v-slot="{item}" @navClick="onFoodNav">
                        {{item.MainCategory_Name}}
                    </categorys-page>
                </div>
                <div class="food-sub-box"  style="overflow:inherit;margin-top:5px" v-if="orderMap_Type==3 ||orderMap_Type==1" :style="foodSubStyle">
                    <categorys-page class="filter-nav panel" 
                        :data="foodSubCategorys" 
                        :row="foodSubArg.row" 
                        :col="foodSubArg.col" 
                        :selectIndex="subCategoryIndex"
                        :bottomH="setBottomH"
                        :pageIndex="foodSubPageIndex"
                        navFirstName="全部小类"
                        v-slot="{item}" @navClick="onFoodSubNav">
                        {{item.SubCategory_Name}}
                        <b v-show="subCategoryNumberKey[item.SubCategory_ID]>0">{{subCategoryNumberKey[item.SubCategory_ID]}}</b>
                    </categorys-page>
                </div>
                <div class="panel menu menu-flex-box" :style="menuStyle">
                    <div class="keyboard" v-if="this.$cacheData.Numberkey.getNumberKeyOff()==true">
                        <div class="key-li tr-li" >
                            <span @click="keyNumber(0,0)">0<p class="bubbing" v-show="orderNumber>=0 && orderNumberType==0 && orderNumber">{{orderNumber}}</p></span>
                            <span @click="keyNumber(1,1)">1<p class="bubbing" v-show="orderNumber>0 && orderNumberType==1">{{orderNumber}}</p></span>
                            <span @click="keyNumber(2,2)">2<p class="bubbing" v-show="orderNumber>0 && orderNumberType==2">{{orderNumber}}</p></span>
                            <span @click="keyNumber(3,3)">3<p class="bubbing" v-show="orderNumber>0 && orderNumberType==3">{{orderNumber}}</p></span>
                            <span @click="keyNumber(4,4)">4<p class="bubbing" v-show="orderNumber>0 && orderNumberType==4">{{orderNumber}}</p></span>
                            <span @click="keyNumber(5,5)">5<p class="bubbing" v-show="orderNumber>0 && orderNumberType==5">{{orderNumber}}</p></span>
                            <span @click="keyNumber(6,6)">6<p class="bubbing" v-show="orderNumber>0 && orderNumberType==6">{{orderNumber}}</p></span>
                            <span @click="keyNumber(7,7)">7<p class="bubbing" v-show="orderNumber>0 && orderNumberType==7">{{orderNumber}}</p></span>
                            <span @click="keyNumber(8,8)">8<p class="bubbing" v-show="orderNumber>0 && orderNumberType==8">{{orderNumber}}</p></span>
                            <span @click="keyNumber(9,9)">9<p class="bubbing" v-show="orderNumber>0 && orderNumberType==9">{{orderNumber}}</p></span>
                            <span @click="keyNumber('.')">.</span>
                            <span @click="keyNumber(-1)"><i class="iconfont icon-zuojiantou"></i></span>
                        </div>
                    </div>
                    <!-- 菜单 -->
                    <div class="item-flex-box">
                        <layout-rc class="item-box clearfix" :isVarCss="true" :row="arg.row" :col="arg.col" :marginW="2" :marginH="2" :list="foodsPages">
                            <div class="item-li" :class="{selected:item.Food_ID==choicePosFoods}" v-for="item in foodsPages" :key="item"  @click="onPlaceFoods(item)">
                                <div class="title">
                                    <i class="iconfont icon-tesecai" v-show="sysSetting.RunOrderListShowSpecialFoodFlag && item.Is_Special"></i>{{item.Food_Name}}
                                </div>
                                <b v-show="getFoodNumber(item)>0">{{getFoodNumber(item)}}</b>
                                <div class="bottom-box" v-if="item.Is_Limited && item.Is_LimitedProject">
                                    <div class="text-left" v-if="item.Is_Promotion || (item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price)">剩: <span class="red">{{item.RemainNum}}</span></div>
                                    <div class="bottom-flex-box">
                                        <!-- 促销价 -->
                                        <div class="cxPrice" v-if="item.Is_Promotion">
                                            <div class="cxTag">促</div>
                                            <div class="unit">￥</div>{{item.Promotion_Price}}
                                        </div>
                                        <!-- vip 价格 -->
                                        <div class="vipPrice" v-else-if="item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price">
                                            <div class="vipTag">VIP</div>
                                            <div class="price"> 
                                                <div class="unit">￥</div>{{item.Food_VipPrice}}
                                            </div>
                                        </div>
                                        <div class="text-left" v-else>剩: <span class="red">{{item.RemainNum}}</span></div>
                                        <div class="text-right">￥{{item.Food_Price}}/{{item.Food_Size}}</div>
                                    </div>
                                </div>
                                <div class="bottom-box bottom-flex-box" v-else>
                                    <!-- 促销价 -->
                                    <div class="cxPrice" v-if="item.Is_Promotion">
                                        <div class="cxTag">促</div>
                                        <div class="unit">￥</div>{{item.Promotion_Price}}
                                    </div>
                                    <!-- vip 价格 -->
                                    <div class="vipPrice" v-else-if="item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price">
                                        <div class="vipTag">VIP</div>
                                        <div class="price"> 
                                            <div class="unit">￥</div>{{item.Food_VipPrice}}
                                        </div>
                                    </div>
                                    <div class="text-right">￥{{item.Food_Price}}/{{item.Food_Size}}</div>
                                </div>
                                <tag-sold-out v-if="item.Is_SoldOut"></tag-sold-out>
                                <div class="tag tag-grey" v-if="!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0">售罄</div>
                            </div>
                        </layout-rc>
                    </div>
                    <div class="search-box">
                        <key-board-input class="search"
                            type="text" v-model="varietySelect" ref="searchBox" placeholder="菜品模糊检索"
                            @keydown="keyCursor"
                            isBarcode @barcode="barcode"
                            :focusLock="true"
                            :readonly="$keyavail"
                            :keyOptions="{isKeyDownEnter:true}"
                        ></key-board-input>
                        <div class="bnt menuOrder-bnt" @click="menuOrder()" v-if="$cacheData?.globalVariable?.GlobalSysSetting?.Running_BestMenu_YN">
                            <img src="/images//menuBnt.png"/>
                        </div>
                        <div class="bnt shuaxin-bnt" @click="RefreshData()"><i class="iconfont icon-a-shuaxin3x"></i>刷新</div>
                        <div class="page-box-bottom">
                            <span class="last-page iconfont icon-fangxiangquan-left" @click="posFoodsPage.pageIndex<=1?1:posFoodsPage.pageIndex--"></span>
                            <span class="page-number">{{ posFoodsPage.pageIndex }}/{{ posFoodsPage.pageSize }}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" @click="posFoodsPage.pageIndex>=posFoodsPage.pageSize?posFoodsPage.pageSize:posFoodsPage.pageIndex++" ></span>
                        </div>
                    </div>
                </div>
                
                <div class="panel footer-flex-box" :style="footerStyle">
                    <layout-rc class="btn-box" :isVarCss="true" :list="userBottomMenuPages" :row="footerArg.row" :col="footerArg.col" :marginW="2" :marginH="2">
                        <div class="btn" :class="{btnKey:item.ProjectItem_Key=='goCheckOut',btnReturn:item.ProjectItem_Key=='OrderReturn'}" v-show="item.Is_Show==true" v-for="(item,ProjectItem_Index) in userBottomMenuPages" :key="ProjectItem_Index" @click="BottomMenuClick(item)">
                            {{item.ProjectItem_Name}}
                            <span class="shortKey" v-if="item.ProjectItem_ShortcutKey">{{item.ProjectItem_ShortcutKey}}</span>
                        </div>
                        <div class="page-box" v-if="userBottomMenuPagesCount>1">
                            <page-turning class="page-botton" v-model="userBottomPageIndex" :pageCount="userBottomMenuPagesCount" type="up"><i class="iconfont icon-a-order-up"></i></page-turning>
                            <page-turning class="page-botton" v-model="userBottomPageIndex" :pageCount="userBottomMenuPagesCount"><i class="iconfont icon-a-order-down"></i></page-turning>
                        </div>
                    </layout-rc>
                </div>
            </div>
            <div class="div-box" v-show="isCheckOut">
                <div class="flex-box">
                    <div class="right-box">
                        <div class="panel">
                            <div class="payment-list-box" v-scroll-anime="{up:'pay-up',down:'pay-down',liClass:'.li'}" >
                                <div class="clearfix"  v-layout-rc="args" >
                                    <div class="li" :class="{selected:ispayid==pay.PAY_ID}" rc-item v-for="pay in payType" :key="pay" v-show="pay.PAY_NAME!='多收券'" @click="onChoicePayType(pay)" >
                                        {{pay.PAY_NAME}}
                                    </div>
                                </div>
                            </div>
                            <div class="search-box">
                                <div class="searchPayment">已付：
                                    <span class="money">
                                        ¥{{ Number(orderInfo.Payment_Money).toFixed(2) }}
                                    </span>
                                </div>
                                <div class="searchUnpaid">未付：
                                    <span class="money">
                                        ¥{{Number(orderInfo.UnPaid_Money).toFixed(2)}}
                                    </span>
                                </div>
                                <key-board-input class="search"
                                    type="text" v-model="payTypeSelect" ref="payInput" placeholder="支付方式模糊检索"
                                    :keyOptions="{isKeyDownEnter:true,type:'bottom'}"
                                ></key-board-input>
                                <!-- <div class="search">
                                    <input type="text" placeholder="支付方式模糊检索" v-model="payTypeSelect" ref="payInput"  />
                                    <div class="deskAuto" @click="showKeyBoard($refs.payInput,'bottom')" @mousedown="(e)=>e.preventDefault()">
                                        <span class="iconfont icon-jianpan"></span>
                                    </div>
                                </div> -->
                                <div class="float-right">
                                    <div class="bnt-fangxiang" ref="pay-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                    <div class="bnt-fangxiang" ref="pay-down"><i class="iconfont icon-fangxiang-down"></i></div>
                                </div>
                            </div>
                        </div>
                        <div class="panel payment-type">
                            <div class="payment-sroll">
                                <div class="type-li" v-for="item in orderPaymentDetailList" :key="item">
                                    <span class="tag-payment" v-if="item?.Refund_Status==1">被冲销</span>
                                    <span class="tag-payment" v-if="item?.Refund_Status==2">冲销项</span>
                                    <span class="tag-payment" v-if="item?.Refund_Status==3">部分退</span>
                                    <div class="title">
                                        <div class="nowrap" style="font-size:15px;color:#333333;font-weight:bold;" @click="paymentClick(item,1)">
                                            {{item?.Payment_Name}}
                                            <span v-show="item?.serialNo"><i class="iconfont icon-pencil-create"></i></span>
                                            <span v-show="item?.serialNo"><el-image style="width:45px;height:70px;position: absolute;" src="/images/unpaid.png" fit="contain" /></span>
                                        </div>
                                        <span class="right" style="font-size:18px;color:red;font-weight:bold;" v-if="item?.Refund_Status==2">{{Number(item?.Payment_Money).toFixed(2)}}</span>
                                        <span class="right" v-else style="font-size:18px;color:#333333;font-weight:bold;">{{Number(item?.Payment_Money).toFixed(2)}}</span>
                                    </div>
                                    <div class="align-right" style="height:21px;">
                                        <span class="nowrap" style="font-size:12px;">{{item?.Payment_Remark}}</span>
                                        <span class="nowrap" style="font-size:12px;" v-show="item?.serialNo">{{item?.Card_No}}</span>
                                    </div>
                                    <div class="align-right">
                                        <span style="float:left;font-size:12px;padding-top:4px;">{{item?.Eat_Czy}}&nbsp;&nbsp;&nbsp;{{getCurrentDateTime(item?.Eat_PaymentTime)}}</span>
                                        <i class="iconfont icon-shanchu" @click="RefundPayment(item)" ></i>
                                    </div>
                                </div>
                            </div>
                            <div class="payment-food" v-if="sysSetting?.OpenCheckOutWithSelectPhase">
                                <span>订单归属时段：</span>
                                <el-select v-model="$global.PhaseID">
                                    <el-option v-for="item in eatPhases"
                                        :key="item.EAT_PHASEID"
                                        :label="item.EAT_PHASENAME"
                                        :value="item.EAT_PHASEID">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        
                        <div class="panel payment-money" ref="paymetMoney">
                            <div class="tr">
                                <div class="lable">支付方式：</div><span class="money">{{this.isPayName}}</span>
                                <i class="iconfont icon-bianji" v-if="this.choicePayType.INTERFACE_TYPE==1||this.choicePayType.INTERFACE_TYPE==2" @click="isShowRemark=true"></i>
                                <div class="right" v-if="orderInfo" >找零：<span class="moneys">¥{{Givechange(orderInfo.UnPaid_Money,money,orderInfo.Mantissa_Money)}}</span> </div>
                            </div>
                            <div class="tr">
                                <div class="lable">支付金额：</div>
                                <div class="from-input money-input" >
                                    <!--onkeyup="value=this.value.replace(/\D+/g,'')"-->
                                    <input type="text" ref="money" data-model='money' v-model="money"  @keyup="money=money.replace(/[^\d.]/g,'').replace(/\.{3,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d\d).*$/,'$1$2.$3').replace(/^\./g,'')" 
                                        @focus="(e)=>e.target.select()" />
                                </div>
                                <div v-if="RunningShow" class="running" :class="{selected:!invoice}">发票&nbsp;
                                    <el-switch v-model="invoice" active-color="#13ce66"></el-switch>
                                </div>
                            </div>
                            <div class="tr" >
                                <div class="lableCard" v-if="this.cardMemberList.Mobile" >手机号：</div><span v-if="this.cardMemberList.Mobile" class="cardNo">{{this.cardMemberList.Mobile}}</span>
                                <div class="rightCard" :class="{noneCard:this.cardMemberList.Mobile==''}" v-if="this.cardMemberList.Card_No" >卡号：<span class="cardMobile">{{this.cardMemberList.Card_No}}</span></div>
                            </div>
                            <div class="keyboard" :class="{card:this.cardMemberList==''}">
                                <div class="right-key">
                                    <table>
                                        <tr>
                                            <td><span data-value="10">10</span></td>
                                            <td><span data-value="7">7</span></td>
                                            <td><span data-value="8">8</span></td>
                                            <td><span data-value="9">9</span></td>
                                            <td><span data-value="clean">C</span></td>
                                        </tr>
                                        <tr>
                                            <td><span data-value="20">20</span></td>
                                            <td><span data-value="4">4</span></td>
                                            <td><span data-value="5">5</span></td>
                                            <td><span data-value="6">6</span></td>
                                            <td><span data-value="back"><i class="iconfont icon-zuojiantou"></i></span></td>
                                        </tr>
                                        <tr>
                                            <td><span data-value="50">50</span></td>
                                            <td><span data-value="1">1</span></td>
                                            <td><span data-value="2">2</span></td>
                                            <td><span data-value="3">3</span></td>
                                            <td rowspan='2'><button class="confirm-btn" @click="onPayPreview()">确认</button></td>
                                        </tr>
                                        <tr>
                                            <td><span data-value="100">100</span></td>
                                            <td><span data-value=".">.</span></td>
                                            <td><span data-value="0">0</span></td>
                                            <td><span data-value="00">00</span></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer" style="height:60px">
                    <div class="btn-box" v-mouse-scroll>
                        <button class="btn" :class="{btnKey:item.ProjectItem_Key=='CheckOut',btnReturn:item.ProjectItem_Key=='CheckOutReturn'}" v-show="item.Is_Show==true" v-for="(item,ProjectItem_Index) in userCheckOutMenu.slice(0,userCheckOutMenu.length>bottomMaxCount?bottomMaxCount-1:bottomMaxCount) " :key="ProjectItem_Index" @click="CheckOutMenuClick(item)">
                            {{item.ProjectItem_Name}}
                            <span class="shortKey" v-if="item.ProjectItem_ShortcutKey">{{item.ProjectItem_ShortcutKey}}</span>
                        </button>
                        <div class="btn-more" v-if="userCheckOutMenu.length>bottomMaxCount" >
                            <el-collapse-transition>
                            <div class="more-list" v-show="userCheckOutMenu._more">
                                <button class="btn"  v-show="item.Is_Show==true"  v-for="(item,ProjectItem_Index) in userCheckOutMenu.slice(bottomMaxCount-1)" :key="ProjectItem_Index" 
                                @mousedown="(e)=>{e.preventDefault()}"
                                @click="BottomMenuClick(item)">
                                    {{item.ProjectItem_Name}}
                                    <span class="shortKey" v-if="item.ProjectItem_ShortcutKey">{{item.ProjectItem_ShortcutKey}}</span>
                                </button>
                            </div>
                            </el-collapse-transition>
                            <button class="btn" @click="userCheckOutMenu._more=!userCheckOutMenu._more" @blur="userCheckOutMenu._more=false">...</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-list @modalShow="modalShow" @modalClose="modalClose">
        <!-- 厨房 -->
        <modal-load :isShow="kitchenShow">
            <kitchen-model :isShow="kitchenShow" v-on:closeModel="kitchenShow=false,this.$keyavail=false" :orderMenuList="orderMenuList" :allfoodSubCategorys="allfoodSubCategorys"  @kitchenClick="kitchenClick"></kitchen-model>
        </modal-load>
        <!-- 催菜 -->
        <modal-load :isShow="urgeFoodShow">
            <urge-food-model :isShow="urgeFoodShow" v-on:closeModel="urgeFoodShow=false,this.$keyavail=false" :orderInfo="orderInfo" :orderMenuList="orderMenuList" :allfoodSubCategorys="allfoodSubCategorys" @urgeFoodReturn="urgeFoodReturn"></urge-food-model>
        </modal-load>
        <!-- 菜品要求操作 -->
        <modal-load :isShow="demandShow" :isUnload="true">
            <demand-model :isShow="demandShow" v-on:closeModel="demandShow=false,BindPosFoods='',this.$keyavail=false" :BindPosFoods="BindPosFoods" :allfoodSubCategorys="allfoodSubCategorys" :orderMenuList="orderMenuList" :foodtype="foodtype"  @confirm="confirm" @BindPosconfirm="BindPosconfirm" :masKindsList="masKindsList" :disRadioOrder="disRadioOrder" ></demand-model>
        </modal-load>
        <!-- 菜品要求操作 -->
        <modal-load :isShow="demandFullShow" :isUnload="true">
            <demand-full-model :isShow="demandFullShow" v-on:closeModel="demandFullShow=false,this.$keyavail=false" @confirmall="confirmall" :masKindsList="masKindsList" :orderMenuList="orderMenuList" v-if="masKindsList!=null"></demand-full-model>
        </modal-load>
        <!-- 数量 -->
        <modal-load :isShow="adjusquantityShow">
            <adjust-quantity-model :isShow="adjusquantityShow" v-on:closeModel="adjusquantityShow=false,this.$keyavail=false" :orderMenuList="orderMenuList" :disRadioclass="disRadioclass" :disRadioOrder="disRadioOrder"  @quantityReturn="quantityReturn" ></adjust-quantity-model>
        </modal-load>
        <!-- 赠送操作 -->
        <modal-load :isShow="giveShow">
            <give-return-model :isShow="giveShow" :isReturn="false" v-on:closeModel="giveShow=false,this.$keyavail=false" :isCheckOut="isCheckOut" :disRadioOrder="disRadioOrder" :orderInfo="orderInfo" @GiveFoodFreeClick="GiveFoodFreeClick" ></give-return-model>
        </modal-load>
        <!-- 退菜操作 -->
        <modal-load :isShow="returnShow">
            <give-return-model :isShow="returnShow" :isReturn="true" v-on:closeModel="returnShow=false,this.$keyavail=false" :isCheckOut="isCheckOut" :disRadioOrder="disRadioOrder" :orderInfo="orderInfo" @GiveFoodFreeClick="GiveFoodFreeClick" ></give-return-model>
        </modal-load>
        <!-- 批量退菜操作 -->
        <modal-load :isShow="batchReturnShow">
            <batch-return-model 
                :isShow="batchReturnShow" 
                v-on:closeModel="batchReturnShow=false,this.$keyavail=false" 
                :isCheckOut="isCheckOut" 
                :orderInfo="orderInfo" 
                :unionType="unionType"
                @confirm="batchRetrunConfirm"/> 
        </modal-load>
        <!-- 改量 -->
        <modal-load :isShow="changeNumShow">
            <change-num-price-model :isShow="changeNumShow" 
            :isPrice="false" v-on:closeModel="changeNumShow=false,this.$keyavail=false" 
            :isCheckOut="isCheckOut" :disRadioOrder="disRadioOrder" 
            :orderInfo="orderInfo"
            :unionType="unionType"
            @changenumClick="changenumClick" ></change-num-price-model>
        </modal-load>
        <!-- 改价 -->
        <modal-load :isShow="changePriceShow">
            <change-num-price-model :isShow="changePriceShow" 
            :isPrice="true" v-on:closeModel="changePriceShow=false,this.$keyavail=false" 
            :isCheckOut="isCheckOut" 
            :disRadioOrder="disRadioOrder" 
            :unionType="unionType"
            :orderInfo="orderInfo" 
            @changepriceClick="changepriceClick" ></change-num-price-model>
        </modal-load>
        <!-- 折扣方案 -->
        <modal-load :isShow="discountShow">
            <discount-model :isShow="discountShow" v-on:closeModel="discountShow=false,this.$keyavail=false" :orderInfo="orderInfo" 
                :orderMenuList="orderMenuList" 
                :unionType="unionType" 
                :isCheckOut="isCheckOut" 
                :discounCheck="discounCheck"
                :discountSchemeList="discountScheme" 
                :disRadioOrder="disRadioOrder" 
                :Running_ReadCardToDiscount_YN="Running_ReadCardToDiscount_YN"
                @discountSchemeClic="discountSchemeClic"
                @CancelBillDiscount="CancelBillDiscount" ></discount-model>
        </modal-load>
        <!-- 服务费操作 -->
        <modal-load :isShow="serviceFeeShow">
            <service-fee-model :isShow="serviceFeeShow" v-on:closeModel="serviceFeeShow=false,this.$keyavail=false" :orderInfo="orderInfo" :unionType="unionType" @serviceFeeReturn="serviceFeeReturn" ></service-fee-model>
        </modal-load>
        <!-- 套餐 -->
        <set-Meal-model :isShow="setMealShow" :isDemand="true" v-on:closeModel="setMealShow=false,this.$keyavail=false" :tcChangeType="tcChangeType" :disRadioOrder="disRadioOrder" :orderNumber="orderNumber" :posFoodSetMealInfos="posFoodSetMealInfos"  @setMealClick="setMealClick" > </set-Meal-model>
        <!-- 扫码付 -->
        <modal-load :isShow="scanCodePay">
            <scanCode-Pay-model  :isShow="scanCodePay" :orderInfo="orderInfo" :unionType="unionType" :posDesks="posDesks" :payTypeSSPay="payTypeSSPay" :wlivePayExist="wlivePayExist"
                :cardMemberList="cardMemberList"
                @ThePaymentResultClic="ThePaymentResultClic" 
                @scanCodeclose="scanCodeclose"
                @refreshOrder="refreshOrder"></scanCode-Pay-model>
        </modal-load>
        <!-- 银时智能pos 扫码付 -->
        <modal-load :isShow="yinShiPayShow">
            <yin-shi-scanCode-Pay-model  :isShow="yinShiPayShow" :orderInfo="orderInfo" :unionType="unionType" :posDesks="posDesks" :payTypeSSPay="choicePayType" :wlivePayExist="wlivePayExist"
                @ThePaymentResultClic="ThePaymentResultClic" 
                @scanCodeclose="yinShiPayClose"
                @refreshOrder="refreshOrder"/>
        </modal-load>
        <!--会员支付-->
        <modal-load :isShow="memberPayShow">
            <member-payment-model :isShow="memberPayShow" :payType="payType" 
            :unionEatAutoID="unionEatAutoID" 
            @refreshOrder="refreshOrder" 
            @memberReturn="memberReturn" 
            :orderInfo="orderInfo" 
            :unionType="unionType"
            :choicePayType="choicePayType" 
            @memberPayClick="memberPayClick" 
            @unionTimeUpdate="unionTimeUpdate"
            @cardPriceReturn="cardPriceReturn" 
            @BindCardCleanup="BindCardCleanup"
            @cancelCardPrice="cancelCardPrice" ></member-payment-model>
        </modal-load>
        <!--微生活支付-->
        <modal-load :isShow="wliveMemberPayShow">
            <wlive-member-payment-model :isShow="wliveMemberPayShow" :payType="payType" 
            :unionEatAutoID="unionEatAutoID" 
            @microMemberReturn="microMemberReturn"
            @refreshOrder="refreshOrder"
            :orderInfo="orderInfo" 
            :unionType="unionType"
            :orderPaymentDetailList="orderPaymentDetailList"
            :wlivePayExist="wlivePayExist"
            :choicePayType="choicePayType"
            @paymentClick="paymentClick"
            @cancelCardPrice="cancelCardPrice"
            @BindCardCleanup="BindCardCleanup"
            @microMemberPayClick="microMemberPayClick"  @cardPriceReturn="cardPriceReturn"
            @wliveRecordsPayMessage="wliveRecordsPayMessage" ></wlive-member-payment-model>
        </modal-load>
        <!--新享会支付-->
        <modal-load :isShow="newEnjoyShow">
            <new-enjoy-show-model :isShow="newEnjoyShow" 
            :payType="payType" 
            :type='1'
            :unionEatAutoID="unionEatAutoID" 
            @newEnjoyReturn="newEnjoyReturn" 
            :orderInfo="orderInfo" 
            :choicePayType="choicePayType"
            :unionType="unionType" 
            @newEnjomemberPayClick="newEnjomemberPayClick" @cardPriceReturn="cardPriceReturn" ></new-enjoy-show-model>
        </modal-load>
        <!--二维火支付-->
        <modal-load :isShow="TwoDfireShow">
            <new-enjoy-show-model :isShow="TwoDfireShow" 
            :payType="payType" 
            :type='2'
            :unionEatAutoID="unionEatAutoID" 
            @newEnjoyReturn="newEnjoyReturn" 
            :orderInfo="orderInfo" 
            :choicePayType="choicePayType"
            :unionType="unionType" 
            :isManualParPrice="isManualParPrice"
            @newEnjomemberPayClick="newEnjomemberPayClick" @cardPriceReturn="cardPriceReturn" ></new-enjoy-show-model>
        </modal-load>
        <!-- 昆仑会员支付 -->
        <modal-load :isShow="kunlunPayShow">
            <kun-lun-member-pay-model 
                :isShow="kunlunPayShow" 
                :payType="payType" 
                :unionEatAutoID="unionEatAutoID" 
                :orderInfo="orderInfo" 
                :choicePayType="choicePayType"
                :unionType="unionType" 
                :isManualParPrice="isManualParPrice"
                @payReturn="kunlunPayReturn"
                @payClick="kunlunPayClick" 
                @cardPriceReturn="cardPriceReturn" />
        </modal-load>
        <!--修改会员信息-->
        <modal-load :isShow="openshow">
            <opening :opentype="2" v-on:CloseClick="openshow=false,this.$keyavail=false" :orderType="1" :orderInfo="orderInfo" 
            :orderMenuList="orderMenuList" :checkedOut="false" :openisShow="openshow" :openDeskName="orderInfo.Eat_DeskName" @updateCardClick="updateCardClick" ></opening>
        </modal-load>
        <!--输入弹出框-->
        <modal-load :isShow="popShow">
            <popup-input-model :isShow="popShow" v-on:closeModel="popShow=false,this.$keyavail=false" :orderInfo="orderInfo" @popupReturn="popupReturn" ></popup-input-model>
        </modal-load>
        <!--AR账-->
        <modal-load :isShow="arShow">
            <ar-account-Model :isShow="arShow" 
            v-on:closeModel="arShow=false,this.$keyavail=false" 
            :orderInfo="orderInfo" 
            :unionEatAutoID="unionEatAutoID" 
            :choicePayType="choicePayType" 
            @arAccountReturn="arAccountReturn"
            @publicPayPreview="publicPayPreview"
            @arAccountPayReturn="arAccountPayReturn"></ar-account-Model>
        </modal-load>
        <!--菜品换单位-->
        <modal-load :isShow="unitShow">
            <change-Unit-Model :isShow="unitShow" v-on:closeModel="unitShow=false,this.$keyavail=false" :multipleSizeFoods="theMultipleSizeFoods" @onPlaceFoods="onPlaceFoods" ></change-Unit-Model>
        </modal-load>
        <!--其他功能-->
        <modal-load :isShow="subShow">
            <sub-Item-Model :isShow="subShow" v-on:closeModel="subShow=false,this.$keyavail=false" :otherFuncList="otherFuncList" :orderInfo="orderInfo" @subItemReturn="subItemReturn" ></sub-Item-Model>
        </modal-load>
        <!--联台结账-->
        <modal-load :isShow="unionisShow">
            <union-Bill-model :unionisShow="unionisShow" v-on:closeModel="unionisShow=false,this.$keyavail=false" :BillType="1" :orderInfo="orderInfo" @CloseunionClick="CloseunionClick" ></union-Bill-model>
        </modal-load>
         <!--即配套餐-->
        <modal-load :isShow="readyShow">
            <ready-ToUsePackage-Model :isShow="readyShow" v-on:closeModel="readyShow=false,this.$keyavail=false" :readyAllfoodSubCategorys="allfoodSubCategorys" :readyShowType="readyShowType" @readyPackageReturn="readyPackageReturn" @readyPackageUpdateReturn="readyPackageUpdateReturn" :disRadioOrder="disRadioOrder" :orderInfo="orderInfo" @ReadycloseModel="ReadycloseModel" ></ready-ToUsePackage-Model>
        </modal-load>
        <!--整桌退菜-->
        <modal-load :isShow="wholeShow">
            <whole-retreat-model :isShow="wholeShow" v-on:closeModel="wholeShow=false,this.$keyavail=false" :orderInfo="orderInfo" @WholeReturn="WholeReturn" ></whole-retreat-model>
        </modal-load>
        <!-- 提取暂存 -->
        <modal-load :isShow="lockersShow">
            <lockers-model :isShow="lockersShow" v-on:closeModel="lockersShow=false,this.$keyavail=false" @lockersConfirm="lockersConfirm" ></lockers-model>
        </modal-load>
        <!-- 自定义菜品 -->
        <modal-load :isShow="customDishesShow">
            <custom-dishes-model :isShow="customDishesShow" v-on:closeModel="customDishesShow=false,this.$keyavail=false" @customConfirm="customConfirm" ></custom-dishes-model>
        </modal-load>
        <!-- 复制菜品 -->
        <modal-load :isShow="copyFoodShow">
            <copy-food-model :isShow="copyFoodShow" v-on:closeModel="copyFoodShow=false,this.$keyavail=false" @cpoyFoodReturn="cpoyFoodReturn" :orderInfo="orderInfo"></copy-food-model>
        </modal-load>
        <!-- 转菜操作 -->
        <modal-load :isShow="transferFoodShow">
            <transfer-food-model :isShow="transferFoodShow" v-on:closeModel="transferFoodShow=false,this.$keyavail=false" :orderInfo="orderInfo" @transferConfirm="transferConfirm"></transfer-food-model>
        </modal-load>
        <!-- 商品券 或 现金券 选择-->
        <modal-load :isShow="paymentCouponsShow" :isUnload="true">
            <payment-coupons-model :isShow="paymentCouponsShow" v-on:closeModel="paymentCouponsShow=false,this.$keyavail=false" 
            :data="payCouponsData" 
            :orderInfo="orderInfo" 
            :choicePayType="choicePayType" 
            :unionType="unionType"
            :wlivePayExist="wlivePayExist"
            :unionEatAutoID="unionEatAutoID" 
            @publicPayPreview="publicPayPreview"
            @paymentCouponclose="paymentCouponclose"  @paymentCouponClick="paymentCouponClick"></payment-coupons-model>
        </modal-load>
         <!-- 换套餐 -->
        <modal-load :isShow="cheageSetMealShow">
            <change-set-meal-model :isShow="cheageSetMealShow" v-on:closeModel="cheageSetMealShow=false,this.$keyavail=false" :disRadioOrder="disRadioOrder" :posFoodSetMealInfos="posFoodSetMealInfos" :orderInfo="orderInfo" @changeSetClick="changeSetClick" ></change-set-meal-model>
        </modal-load>
        <!--卡券核销-->
        <modal-load :isShow="saveCouponsShow">
            <save-coupons-model :isShow="saveCouponsShow" v-on:closeModel="saveCouponsShow=false,this.$keyavail=false" 
                :orderInfo="orderInfo" 
                :allfoodSubCategorys="allfoodSubCategorys" 
                @saveCouponRetrun="saveCouponRetrun" 
                @refreshOrder="refreshOrder"></save-coupons-model>
        </modal-load>
        <!--卡券支付-->
        <modal-load :isShow="savePayShow">
            <save-coupons-pay-model 
                :isShow="savePayShow" 
                v-on:closeModel="savePayShow=false,this.$keyavail=false"
                @savePayClose="savePayClose"
                :orderInfo="orderInfo" 
                :choicePayType="choicePayType" 
                :unionType="unionType"
                :unionEatAutoID="unionEatAutoID" 
                @publicPayPreview="publicPayPreview"
                @savePayCouponClick="savePayCouponClick" 
                @refreshOrder="refreshOrder">
            </save-coupons-pay-model>
        </modal-load>
        <!--正品卡券核销-->
        <modal-load :isShow="zpCouponShow" :isUnload="true">
            <zp-couponspay-model
                :isShow="zpCouponShow" 
                v-on:closeModel="zpCouponShow=false,this.$keyavail=false"
                @savePayClose="savePayClose"
                :orderInfo="orderInfo" 
                :choicePayType="choicePayType" 
                :unionType="unionType"
                :unionEatAutoID="unionEatAutoID" 
                @publicPayPreview="publicPayPreview"
                @savePayCouponClick="savePayCouponClick" 
                @refreshOrder="refreshOrder">
            </zp-couponspay-model>
        </modal-load>
        <!--西软挂账-->
        <modal-load :isShow="westShow" :isUnload="true">
            <west-soft-account-model :isShow="westShow" v-on:closeModel="westShow=false,this.$keyavail=false"
                :orderInfo="orderInfo" 
                :unionEatAutoID="unionEatAutoID" 
                :choicePayType="choicePayType" 
                :wlivePayExist="wlivePayExist"
                @publicPayPreview="publicPayPreview"
                @arAccountReturn="arAccountReturn">
            </west-soft-account-model>
        </modal-load>
        <modal-load :isShow="westStoredShow" :isUnload="true">
            <west-storde-model :isShow="westStoredShow" v-on:closeModel="westStoredShow=false,this.$keyavail=false"
                :payType="payType" 
                :unionEatAutoID="unionEatAutoID" 
                @refreshOrder="refreshOrder" 
                @gNiceMemberReturn="gNiceMemberReturn" 
                :orderInfo="orderInfo" 
                :unionType="unionType"
                :choicePayType="choicePayType" 
                @memberPayClick="memberPayClick" @cardPriceReturn="cardPriceReturn">
            </west-storde-model>
        </modal-load>
        <!--G9实体卡消费接口-->
        <modal-load :isShow="gNinePayShow">
            <g-nine-payment-model
                :isShow="gNinePayShow" 
                :payType="payType" 
                :unionEatAutoID="unionEatAutoID" 
                @refreshOrder="refreshOrder" 
                @gNiceMemberReturn="gNiceMemberReturn" 
                :orderInfo="orderInfo" 
                :unionType="unionType"
                :choicePayType="choicePayType" 
                @memberPayClick="memberPayClick" @cardPriceReturn="cardPriceReturn">
            </g-nine-payment-model>
        </modal-load>
        <!--海鼎卡消费-->
        <modal-load :isShow="hdShow">
            <card-abutting-model 
                :isShow="hdShow"
                :payType="payType" 
                :unionEatAutoID="unionEatAutoID" 
                @refreshOrder="refreshOrder" 
                @hdMemberReturn="hdMemberReturn" 
                :orderInfo="orderInfo" 
                :unionType="unionType"
                :choicePayType="choicePayType" 
                @memberPayClick="memberPayClick" @hdMemberdiscount="hdMemberdiscount">
            </card-abutting-model>
        </modal-load>
        <!--辅助单位-->
        <modal-load :isShow="assSizeShow">
            <assist-Size-model :isShow="assSizeShow" v-on:closeModel="assSizeShow=false,this.$keyavail=false" :assistSizeInfo="assistSizeInfo" :orderNumber="orderNumber" @assSizeConfirm="assSizeConfirm" ></assist-Size-model>
        </modal-load>
        <!--会员价-->
        <modal-load :isShow="cardPriceShow">
            <member-Card-Price-model :isShow="cardPriceShow" v-on:closeModel="cardPriceShow=false,this.$keyavail=false" :orderInfo="orderInfo" @cardPriceReturn="cardPriceReturn" ></member-Card-Price-model>
        </modal-load>
        <!--权限控制-->
        <modal-load :isShow="checkShow">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false,this.$keyavail=false" :disRadioOrder="disRadioOrder" :checkBtnlist="checkBtnlist" @checkbtnReturn="checkbtnReturn" ></check-user-button-model>
        </modal-load>
        <!--配菜单-->
        <modal-load :isShow="withMenuShow">
            <with-menu-model :isShow="withMenuShow" :isQuote="true" v-on:closeModel="withMenuShow=false,this.$keyavail=false" @quoteReturn="quoteReturn"></with-menu-model>
        </modal-load>
        <!-- 修改席数 -->
        <modal-load :isShow="setDekNumShow">
            <set-dek-num-model :isShow="setDekNumShow" 
                v-on:closeModel="setDekNumShow=false,this.$keyavail=false" 
                :orderInfo="orderInfo"
                @confirm="setDekNumConfirm"></set-dek-num-model>
        </modal-load>
        <!--押金-->
        <modal-load :isShow="preMoneyShow" :isUnload="true">
            <pre-money-model :isShow="preMoneyShow" v-on:closeModel="preMoneyShow=false,this.$keyavail=false" 
                :orderInfo="orderInfo" 
                :unionEatAutoID="unionEatAutoID" 
                :choicePayType="choicePayType" 
                :wlivePayExist="wlivePayExist"
                @publicPayPreview="publicPayPreview"
                @preMoneyPayReturn="preMoneyPayReturn">
            </pre-money-model>
        </modal-load>
        <!--企业结算-->
        <modal-load :isShow="enterCloseShow" :isUnload="true">
            <enter-close-model :isShow="enterCloseShow" v-on:closeModel="enterCloseShow=false,this.$keyavail=false"
            :orderInfo="orderInfo" :unionEatAutoID="unionEatAutoID" :choicePayType="choicePayType" @discountSchemeClic="discountSchemeClic"
            @enterPayReturn="enterPayReturn"
            @publicPayPreview="publicPayPreview">
            </enter-close-model>
        </modal-load>
        <!--企业团餐绑定-->
        <modal-load :isShow="enterBindingShow" :isUnload="true">
            <enter-binding-model :isShow="enterBindingShow" v-on:closeModel="enterBindingShow=false,this.$keyavail=false"
            :orderInfo="orderInfo" @RdGroupcloseModel="RdGroupcloseModel" ></enter-binding-model>
        </modal-load>
        <!--单品组合套餐-->
        <modal-load :isShow="makeupTCShow" :isUnload="true">
            <makeup-tc-model :isShow="makeupTCShow" v-on:closeModel="makeupTCShow=false,this.$keyavail=false" :orderInfo="orderInfo"
            :AllFoods="foodMainCategory?._all?._all"
            :ProfitRate="ProfitRate"
            @changeOrderInfo="changeOrderInfo"
            @confirm="makeupTCConfirm"
            :RunFixedSetmealSplitToSingleFoods="RunFixedSetmealSplitToSingleFoods"
            ></makeup-tc-model>
        </modal-load>
        <!--微生活短信验证-->
        <modal-load :isShow="wliveCardCodeShow" :isUnload="true">
            <card-code-model :isShow="wliveCardCodeShow" :wliveCardMember="wliveCardMember" v-on:closeModel="wliveCardCodeShow=false" @cardCodeConfirm="cardCodeConfirm"></card-code-model>
        </modal-load>
        <!--微生活密码验证-->
        <modal-load :isShow="wlivePasswordShow" :isUnload="true">
            <password-confirm-model :isShow="wlivePasswordShow" :wliveCardMember="wliveCardMember" v-on:closeModel="wlivePasswordShow=false" @passwordConfirm="passwordConfirm"></password-confirm-model>
        </modal-load>
        <!-- 不关联限量下单 弹层 选择 -->
        <modal class="selectPOrderModal" :isShow="pOrderShow">
            <div class="header">提示
                <span class="header-x" @click="pOrderShow=false,$keyavail=false">×</span>
            </div>
            <div class="modal-body clearfix">
                <div class="item-li" @click="pOrderShow=false;$keyavail=false;onPlaceOrder(false,true);">
                    <p class="title">直接下单</p>
                </div>
                <div class="item-li" @click="pOrderShow=false;$keyavail=false;onPlaceOrder(true,true);">
                    <p class="title">下单不送厨房</p>
                </div>
            </div>
        </modal>
        <!--订单轨迹-->
        <modal-load :isShow="isOrderTrack">
            <order-track-model :isShow="isOrderTrack" v-on:closeModel="isOrderTrack=false" :orderInfo="orderInfo"></order-track-model>
        </modal-load>
        <!--支付备注-->
        <modal-load :isShow="isShowRemark">
            <pay-remark-model :isShow="isShowRemark" v-on:closeModel="isShowRemark=false" :RemarkTitle="RemarkTitle" @payRemarkconfirm="payRemarkconfirm"></pay-remark-model>
        </modal-load>
        <!--应急厨打单-->
        <modal-load :isShow="isShowKitPrintMonitor">
            <kit-print-monitor-model :isShow="isShowKitPrintMonitor" v-on:closeModel="closePrintMonitor" :waitPrintCount="waitPrintCount"></kit-print-monitor-model>
        </modal-load>
        <!--西软卡券核销-->
        <modal-load :isShow="westSoftCouponShow" :isUnload="true">
            <west-soft-couponspay-model
                :isShow="westSoftCouponShow" 
                v-on:closeModel="westSoftCouponShow=false,this.$keyavail=false"
                :orderInfo="orderInfo" 
                :choicePayType="choicePayType" 
                :unionType="unionType"
                :unionEatAutoID="unionEatAutoID" 
                @publicPayPreview="publicPayPreview"
                @refreshOrder="refreshOrder">
            </west-soft-couponspay-model>
        </modal-load>
        <!--大拈会员支付-->
        <modal-load :isShow="daNianPayShow" :isUnload="true">
            <da-nian-pay-model
                :isShow="daNianPayShow" 
                :payType="payType" 
                :unionEatAutoID="unionEatAutoID" 
                @refreshOrder="refreshOrder" 
                @daNianMemberReturn="daNianMemberReturn" 
                :orderInfo="orderInfo" 
                :unionType="unionType"
                :choicePayType="choicePayType" 
                :discountSchemeList="discountScheme" 
                @discountSchemeClic="discountSchemeClic"
                @memberPayClick="memberPayClick" @cardPriceReturn="cardPriceReturn" />
        </modal-load>
        
        <!-- 组合菜品 -->
        <combo-food-model :isShow="isComboFoodShow" 
            :data="comboFoodInfo" 
            :editInfo="editComboFoodInfo"
            :posFoodData="getPosFoodData" 
            @close="this.$keyavail=false;isComboFoodShow=false"
            @confirm="comboConfirm"/>
        <!-- 联台落单 -->
        <union-order-dish-model ref="unionOrder" :isShow="isUnionDishShow" 
            :data="orderInfo"
            @close="this.$keyavail=false;isUnionDishShow=false"
            @confirm="unionDishConfirm"/>
        </modal-list>
    </div>
</template>
<script>

import { initKeyBoard,newGuid,differenceTimeseconds,differenceTime,NumberConversion,animation } from '/src/common' 
import kitchenModel from './kitchenModel/kitchenModel.vue'
import urgeFoodModel from './urgeFoodModel/urgeFoodModel.vue'
import DemandModel from './demandModel/demandModel.vue'
import DemandFullModel from './demandFullModel/demandFullModel.vue'
import adjustQuantityModel from './adjustQuantityModel/adjustQuantityModel.vue'
import popupInputModel from './popupInputModel/popupInputModel.vue'
import giveReturnModel from './giveReturnModel/giveReturnModel.vue'
import changeNumPriceModel from './changeNumPriceModel/changeNumPriceModel.vue'
import discountModel from './discountModel/discountModel.vue'
import serviceFeeModel from './serviceFeeModel/serviceFeeModel.vue'
import scanCodePayModel from './scanCodePayModel/scanCodePayModel.vue'
import setMealModel from './setMealModel/setMealModel.vue'
import MemberPaymentModel from './memberPaymentModel/memberPaymentModel.vue'
import wliveMemberPaymentModel from './wliveMemberPaymentModel/wliveMemberPaymentModel.vue'
import opening from '../model/opening.vue'
import arAccountModel from './arAccountModel/arAccountModel.vue'
import changeUnitModel from './changeUnitModel/changeUnitModel.vue'
import subItemModel from './subItemModel/subItemModel.vue'
import unionBillModel from '../unionBillModel/unionBillModel.vue'
import readyToUsePackageModel from './readyToUsePackageModel/readyToUsePackageModel.vue'
import WholeRetreatModel from './WholeRetreatModel/WholeRetreatModel.vue'
import lockersModel from './lockersModel/lockersModel.vue'
import customDishesModel from './customDishesModel/customDishesModel.vue'
import copyFoodModel from './copyFoodModel/copyFoodModel.vue'
import transferFoodModel from './transferFoodModel/transferFoodModel.vue'
import { httpAES,cacheData } from '/src/services'
import { ElMessage } from "element-plus";
import ModalLoad from '../../../template/modal/modalLoad.vue'
import paymentCouponsModel from './paymentCouponsModel/paymentCouponsModel.vue'
import changeSetMealModel from './changeSetMealModel/changeSetMealModel.vue'
import assistSizeModel from './assistSizeModel/assistSizeModel.vue'
import {config} from '../../../../common'
import WliveMemberPaymentModel from './wliveMemberPaymentModel/wliveMemberPaymentModel.vue'
import memberCardPriceModel from './memberCardPriceModel/memberCardPriceModel.vue'
import checkUserButtonModel from '../../checkUserRightModel/checkUserButtonModel.vue'
import newEnjoyShowModel from './newEnjoyShowModel/newEnjoyShowModel.vue'
import saveCouponsModel from './saveCouponsModel/saveCouponsModel.vue'
import saveCouponsPayModel from './saveCouponsPayModel/saveCouponsPayModel.vue'
import withMenuModel from './withMenuModel/withMenuModel.vue'
import setDekNumModel from './setDekNumModel/setDekNumModel.vue'
import gNinePaymentModel from './gNinePaymentModel/gNinePaymentModel.vue'
import cardAbuttingModel from './cardAbuttingModel/cardAbuttingModel.vue'
import preMoneyModel from './preMoneyModel/preMoneyModel.vue'
import enterCloseModel from './enterCloseModel/enterCloseModel.vue'
import enterBindingModel from './enterBindingModel/enterBindingModel.vue'
import makeupTcModel from './makeupTCModel/makeupTCModel.vue'
import westSoftAccountModel from './westSoftAccountModel/westSoftAccountModel.vue'
import passwordConfirmModel from './model/passwordConfirmModel.vue'
import cardCodeModel from './model/cardCodeModel.vue'
import zpCouponspayModel from './zpCouponspayModel/zpCouponspayModel.vue'
import westStordeModel from './westStoredModel/westStoredModel.vue'
import OrderTrackModel from '../../bill/billDetail/orderTrackModel/orderTrackModel.vue'
import KitPrintMonitorModel from './KitPrintMonitorModel/KitPrintMonitorModel.vue'
import westSoftCouponspayModel from './westSoftCouponspayModel/westSoftCouponspayModel.vue'
import batchReturnModel from './batchReturnModel/batchReturnModel.vue'
import payRemarkModel from './payRemarkModel/payRemarkModel.vue'
import kunLunMemberPayModel from './kunLunMemberPayModel/kunLunMemberPayModel.vue'
import comboFoodModel from './comboFoodModel/comboFoodModel.vue'
import orderMenuBox from './orderMenuBox.vue'
import unionOrderDishModel from './unionOrderDishModel/unionOrderDishModel.vue'
import daNianPayModel from './daNianPayModel/daNianPayModel.vue'
import yinShiScanCodePayModel from './yinShiScanCodePayModel/yinShiScanCodePayModel.vue'

/**全局初始化数据 */
let _data={
    /**点菜界面类别显示方式 1：大类+小类，2：大类，3：小类 */
    orderMap_Type:1,
    arg:{row:6,col:6,marginW:'6',marginH:'6'},
    args:{row:3,col:6,marginW:'8',marginH:'8'},
    MergeSameFoodInOrder:false,/**点单时,相同菜品自动合并一行 */
    OrderLockSendDish:false,/**中餐业态,落单后即刻锁屏 */
    NoNeedPrintBill:false, /**中餐结账前，控制必须打印消费清单 */
    CheckOutChangeNumFood:0, /**结账时，如存在未改量菜品处理方式，0:’允许结账’,1:’仅提醒需改量菜品，允许结账’,2:’存在未改量菜品，不允许结账 */
    RunBackToDeskMapAfterSendDish:false, /**下单后返回桌台界面 */
    Running_InvoicePintByChosen_YN:false, /**开启选择是否打印发票二维码 ，false:结账时默认打印发票，不做弹框选择；true:结账时弹框给收银员选择是否打印发票 */
    OpenCashierHandInvoiceWithDefaultNotIssueInovoice:false,//开启收银员手动选择开票时，结账默认不选择开票 ， true:开启 false未开启
    RunOrderDeviceShowBillProfitRate:false, /**启用点单设备显示账单毛利率，true: 显示，false:不显示 */
    RunFeastDeskNum:false,/**是否启用席数功能 */
    Running_DtFoodCard_YN:false,/**是否启用读卡点菜功能*/
    RunFixedSetmealSplitToSingleFoods:false,/**是否启用允许【固定套餐】被拆分为单菜功能， true:开启 false:未开启*/
    Running_ReadCardToDiscount_YN:false//是否开启读卡全单打折， true:开启 false:未开启
};


export default {
    name:"diningDetail",
    components:{
        kitchenModel,
        urgeFoodModel,
        DemandModel,
        DemandFullModel,
        adjustQuantityModel,
        giveReturnModel,
        changeNumPriceModel,
        discountModel,
        serviceFeeModel,
        scanCodePayModel,
        setMealModel,
        MemberPaymentModel,
        wliveMemberPaymentModel,
        opening,
        popupInputModel,
        arAccountModel,
        changeUnitModel,
        ModalLoad,
        subItemModel,
        unionBillModel,
        readyToUsePackageModel,
        WholeRetreatModel,
        lockersModel,
        customDishesModel,
        copyFoodModel,
        transferFoodModel,
        paymentCouponsModel,
        changeSetMealModel,
        assistSizeModel,
        WliveMemberPaymentModel,
        memberCardPriceModel,
        checkUserButtonModel,
        newEnjoyShowModel,
        saveCouponsModel,
        saveCouponsPayModel,
        withMenuModel,
        setDekNumModel,
        gNinePaymentModel,
        preMoneyModel,
        cardAbuttingModel,
        enterCloseModel,
        enterBindingModel,
        makeupTcModel,
        westSoftAccountModel,
        passwordConfirmModel,
        cardCodeModel,
        zpCouponspayModel,
        westStordeModel,
        OrderTrackModel,
        KitPrintMonitorModel,
        westSoftCouponspayModel,
        payRemarkModel,
        batchReturnModel,
        kunLunMemberPayModel,
        comboFoodModel,
        orderMenuBox,
        unionOrderDishModel,
        daNianPayModel,
        yinShiScanCodePayModel
    },
    data() {
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.orderMap_Type = globalSysSetting.OrderMap_Type;
            _data.arg={row:globalSysSetting.OrderMap_X,col:globalSysSetting.OrderMap_Y,marginW:'6',marginH:'6'};
            _data.args={row:3,col:6,marginW:'8',marginH:'8'};
            _data.MergeSameFoodInOrder = globalSysSetting.MergeSameFoodInOrder;
            _data.OrderLockSendDish = globalSysSetting.RunLockScreenAfterSendDish;
            _data.NoNeedPrintBill = globalSysSetting.RunCheckOutNoNeedPrintBill;
            _data.CheckOutChangeNumFood = globalSysSetting.CheckOutModelWithChangeNumFood;
            _data.RunBackToDeskMapAfterSendDish = globalSysSetting.RunBackToDeskMapAfterSendDish;
            _data.Running_InvoicePintByChosen_YN = globalSysSetting.Running_InvoicePintByChosen_YN;
            _data.RunOrderDeviceShowBillProfitRate = globalSysSetting.RunOrderDeviceShowBillProfitRate;
            _data.RunFeastDeskNum = globalSysSetting.RunFeastDeskNum;
            _data.Running_DtFoodCard_YN=globalSysSetting.Running_DtFoodCard_YN;
            _data.RunFixedSetmealSplitToSingleFoods=globalSysSetting.RunFixedSetmealSplitToSingleFoods;
            _data.Running_ReadCardToDiscount_YN=globalSysSetting.Running_ReadCardToDiscount_YN;
            _data.OpenCashierHandInvoiceWithDefaultNotIssueInovoice=globalSysSetting.OpenCashierHandInvoiceWithDefaultNotIssueInovoice;
        }
        return {
            click:(('ontouchstart' in window) || window.DocumentTouch)?"touchstart":"click",//点击事件 或 触摸
            //大类 样式
            foodMainStyle:{},
            foodMainArg:{row:2,col:8},
            //小类 样式
            foodSubStyle:{},
            foodSubArg:{row:2,col:8},
            //横排底部按钮样式
            footerStyle:{},
            footerArg:{row:1,col:8},
            menuStyle:{},
            /**点菜界面竖排自定义按钮 */
            userMiddleMenu:[],
            /**点菜界面横排自定义按钮 */
            userBottomMenu:[],
            //点菜界面横排自定义按钮 当前页码 
            userBottomPageIndex:1,
            bottomMaxCount:5,
            /**结账界面竖排自定义功能按钮 */
            userCheckOutMiddleMenu:[],
            /**结账界面横排自定义功能按钮 */
            userCheckOutMenu:[],
            /*点菜单 列表是否展开 */
            isChecOutOpen:false,
            /**大类数据 */
            foodMainCategory:[],
            //大类分页 当前页码 
            foodMainPageIndex:1,
            mainCategoryIndex:0,
            /**小类数据 */
            foodSubCategorys:[],
            //小类分页 当前页码 
            foodSubPageIndex:1,
            /**全部小类数据 */
            allfoodSubCategorys:[],
            subCategoryIndex:0,
            //菜品数据 
            posFoodData:{},
            /**菜品数据  */
            posFoods:[],
            /**菜品页码 */
            posFoodsPage:{
                pageIndex:1,
                pageSize:1
            },
            /**选择的菜品 */
            choicePosFoods:0,
            /**套餐菜品操作默认0 选择套餐,1.未下单套餐退菜 */
            tcChangeType:0,
            /**对应套餐数据 */
            posFoodSetMealInfos:[],
            /**下单菜品数据 */
            foodNavPage:{pageIndex:0,pageSize:0},
            foodNavPageSub:{pageIndex:0,pageSize:0},
            arg:_data.arg,
            args:_data.args,
            itemlist:[],
            money:'',
            kitchenShow:false,
            urgeFoodShow:false,
            demandShow:false,
            demandFullShow:false,
            adjusquantityShow:false,
            giveShow:false,
            returnShow:false,
            //批量删除菜品 弹层
            batchReturnShow:false,
            changeNumShow:false,
            changePriceShow:false,
            discountShow:false,
            scanCodePay:false,
            //银时智能pos支付 弹层
            yinShiPayShow:false,
            serviceFeeShow:false,
            setMealShow:false,
            memberPayShow:false,
            wliveMemberPayShow:false,
            gNinePayShow:false,
            hdShow:false,
            openshow:false,
            popShow:false,
            arShow:false,
            westShow:false,/**西软支付弹层 */
            westStoredShow:false,/**西软储值支付 */
            daNianPayShow:false,//大拈会员支付
            unitShow:false,
            unitType:0,
            subShow:false,
            unionisShow:false,
            readyShow:false,
            wholeShow:false,
            assSizeShow:false,
            newEnjoyShow:false,
            enterCloseShow:false,/**企业结算弹层 */
            enterBindingShow:false, /**企业团餐绑定弹层 */
            TwoDfireShow:false,/**二维火弹层 */
            zpCouponShow:false,/**正品卡券弹层 */
            /**昆仑会员支付弹层 */
            kunlunPayShow:false,
            /**单品组合套餐 */
            makeupTCShow:false,
            /**提取暂存 弹层 显示 */
            lockersShow:false,
            /**自定义菜品  弹层 显示*/
            customDishesShow:false,
            /** 复制菜品*/
            copyFoodShow:false,
            /** 转菜操作*/
            transferFoodShow:false,
             /**商品券 或 现金券 选择  弹层 显示*/
            paymentCouponsShow:false,
            /***商品券 或 现金券 显示数据 */
            payCouponsData:{},
            /**换套餐  弹层 显示 */
            cheageSetMealShow:false,
            /**配菜单 */
            withMenuShow:false,
            /**席数 */
            setDekNumShow:false,
            /**不关联限量下单 弹层 选择 */
            pOrderShow:false,
            /**是否结账 */
            isCheckOut:false,
            /**是否下单 */
            isPlace:false,
            /**权限弹框 */
            checkShow:false,
            checkBtnlist:'',
            deskcheckBtnlist:'',
            checkBtnType:0,
            checOutShow:'height:calc(100% - 160px - 5px)',
            isCheckOutclass:'panel-body',
            disRadioclass:-1, //是否选中
            packageShow:-1,
            foodSetmealsclass:-1, //套餐里的菜品选中
            foodtype:0,
            disRadioCheck:false,
            radioTreaty:1,
            disRadioOrder:{},  //选中的菜品
            discountScheme:'', //折扣方案
            masKindsList:'', //菜品口味
            /**菜品组合信息 */
            comboFoodList:[],
            /**要修改的组合菜品 信息 */
            editComboFoodInfo:null,
            /**菜品组合 弹层 */
            isComboFoodShow:false,
            //选中的菜品 组合数据
            comboFoodInfo:null,
            /**联台落单 */
            isUnionDishShow:false,
            /**用户数据 */
            userInfo:'',
            /**桌台数据 */ 
            posDesks:'',
            lockPosDesks:[],
            Crossed_FoodCounts:0,
            /**订单数据 */
            orderInfo:[],
            /**订单菜单集合 */
            orderMenuList:[],
            /**临时存放订单菜品的集合 */
            TemporderMenuList:[],
            /**返回的全单菜品做法口味选择 */
            masKinds:[],
            /**支付类型 */
            PayKinds:[],
            payType:[],
            /**选中的支付id */
            ispayid:0,//
            /**选中的支付类型 */
            choicePayType:[],
            /**支付记录 */
            orderPaymentDetailList:'',
            /**聚合支付类型 */
            payTypeSSPay:[],
            /**选中的付款类型 */
            isPayName:'暂无',
            /**菜品检索 */
            varietySelect:'',
            //记录上一次检索 不记录则按菜品检索
            oldVarietySelect:"",
            /**支付类型搜索 */
            payTypeSelect:'',
            /**用户姓名 */
            Login_Name:'',
            /**消费类型 */
            consumeName:'',
            /**点菜界面类别显示方式 1：大类+小类，2：大类，3：小类 */
            orderMap_Type:_data.orderMap_Type,
            /** 联台桌数 */
            unionNumber:1,
            /**桌台号 */
            unionName:'',
            /**是否联台 */
            unionType:false,
            /**联台应付金额 */
            unionPayableMoney:0,
            /**联台消费总金额 */
            unionMoney:0,
            /**联台支付金额 */
            unionPaymentMoney:0,
            /**联台未付金额 */
            unionUnPaidMoney:0,
            /**联台赠送金额 */
            unionGiftMoney:0,
            /**联台折扣金额 */
            unionZKMoney:0,
            /**联台服务费金额 */
            unionServiceMoney:0,
            /**联台结算金额 */
            unionEatCheckMoney:0,
            /**联台退菜金额 */
            unionRefundMoney:0,
            /**联台订单ID */
            unionEatAutoID:[],
            /**多规格菜品 */
            multipleSizeFoods:[],
            theMultipleSizeFoods:Object,
            /**辅助菜品 */
            assistSizeInfo:Object,
            /**其他功能按钮 */
            otherFuncList:'',
            readyShowType:0,
             /**点击 */
            dianji:false,
            eatXFBMID:'',
            OrderReturnType:0,
            /**用餐渠道 */
            channeName:'',
            DeskLocked:'',
            /**绑定做法菜品 */
            BindPosFoods:'',
            /**是否启用了会员价 */
            carPriceType:true,//是否开启会员价
            cardPriceShow:false,
            /**临时未付金额 */
            UnPaidMoney:0,
            /**套餐内菜品选择 */
            choicefoodOrder:'',
            /**保存设备ID */
            lockEqId:'',
            lockPermiss:false,
            /**会员信息 */
            cardMemberList:'',
            UUID:'',
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            isManualParPrice:false,//是否手动触发了会员价(CRM支付根据该字段判断是否手动取消会员价)
            IsMultipleCardPay:false, //是否进行了多卡支付
            saveCouponsShow:false, //卡券核销
            savePayShow:false,//卡券支付
            preMoneyShow:false,//押金
            invoice:true,//发票是否打印
            RunningShow:false, //是否显示发票打印按钮
            mallMethod:[], //全单口味做法
            aloneMethod:[], //单独口味做法
            MethodNumList:[],//同步临时保存的口味做法数量
            urlType:0, //判断是从哪个页面跳转过来的, 0 桌台界面进入,1上桌顺序加菜,2上桌顺序结账
            orderNumber:'',//点单界面数量
            orderNumberType:-1,
            wgNum:0,//称重数量
            ProfitRate:false,//启用点单设备显示账单毛利率，true: 显示，false:不显示
            discounCheck:'',//折扣按钮
            wlivePayList:[],//微生活支付记录
            wliveCardMember:'',//微生活当前支付的会员卡信息
            wlivePayExist:false, //是否存在记录的微生活信息
            wlivePasswordShow:false,//微生活支付验证密码弹层
            wliveCardCodeShow:false,//微生活短信验证弹层
            wliveVerify:'',//微生活支付验证
            wliveIsCheckOut:true, //是否弹出确认结账按钮
            payPreviewData:'',//支付预览返回的微生活支付记录
            isOrderTrack:false,/**是否显示订单轨迹，true: 显示，false:不显示 */
            isCardMemberBackint:false,
            selectOnDeskConsumedata:{},
			isWindow:this.$webBrowser.isMobile.Windows,/**是否是pc 设备 */
            printInfo:null,//打印服务信息
            isShowKitPrintMonitor:false,/**是否应急厨打单，true: 显示，false:不显示 */
            waitPrintCount:0,//厨房打印可执行条数
            outTime:null,

            westSoftCouponShow:false,
            //支付方式备注
            isShowRemark:false,
            RemarkTitle: '', // 输入的文本内容
            RunFixedSetmealSplitToSingleFoods:_data.RunFixedSetmealSplitToSingleFoods,
            Running_ReadCardToDiscount_YN:_data.Running_ReadCardToDiscount_YN,
            //时段数据
            eatPhases:null,
        }
    },
    computed:{
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting
        },
        /**菜品分页显示 */
        foodsPages(){
            let size=this.arg.row*this.arg.col;
            return this.posFoods?.slice((this.posFoodsPage.pageIndex-1)*size,this.posFoodsPage.pageIndex*size);
        },
        getPosFoodData(){
            return this.posFoodData.data||[];
        },
        /**点单底部按钮 分页显示 */
        userBottomMenuPages(){
            let size=this.footerArg.row*this.footerArg.col;
            if(this.userBottomMenuPagesCount>1){
                size=size-1;
            }
            return this.userBottomMenu?.slice((this.userBottomPageIndex-1)*size,this.userBottomPageIndex*size);
        },
        /**点单底部按钮 总页数 */
        userBottomMenuPagesCount(){
            let size=this.footerArg.row*this.footerArg.col;
            let num=1;
            if(this.userBottomMenu?.length>size){
                size=size-1;
                num=Math.ceil(this.userBottomMenu.length/size);
            }
            return num;
        },
        /**计算账单的总毛利率 */
        orderComplete(){
            let achieving=0 //达成率
            let costMonye=0 //账单总成本金额
            let saleMoney=0 //账单总销售金额
            let foodSaleMoney =0 //套餐子项菜品额外价格
            let EatCostMoney = Number(this.orderInfo.Eat_CostMoney) //已下单的成本价
            let EatSaleMoney = Number(this.orderInfo.Eat_SaleMoney) //已下单的销售金额
            if(this.orderMenuList.length>0){
                this.orderMenuList?.map(item=>{
                    //未下单
                    if(!item?.is_taste && item.Food_Status==-1){
                        //订单里有即配套餐或者套餐
                        if(item?.Food_Type==3 || item?.Food_Type==2){
                            item.FoodSetmeals.map(food=>{
                                if(food?.Food_CostPrice){
                                    costMonye += Number(food?.Food_CostPrice) * Number(food.FoodSetmeal_Number)
                                }else{
                                    costMonye += Number(0) * Number(food.FoodSetmeal_Number)
                                }
                                if(food?.FoodSaleMoney>0){
                                    foodSaleMoney += Number(food?.FoodSaleMoney) * Number(food.FoodSetmeal_Number)
                                }
                            })
                            saleMoney+=Number(item.Eat_SalePrice) * Number(item.Eat_Number)
                        }else if(item?.FoodProperty_Dimension=='套'){
                            item.FoodSetmeals.map(food=>{
                                if(food?.Food_CostPrice){
                                    costMonye += Number(food?.Food_CostPrice) * Number(food.FoodSetmeal_Number)
                                }else{
                                    costMonye += 0 * Number(food.FoodSetmeal_Number)
                                }
                                if(food?.FoodSaleMoney>0){
                                    foodSaleMoney += Number(food?.FoodSaleMoney) * Number(food.FoodSetmeal_Number)
                                }
                            })
                            saleMoney+=Number(item.Eat_SalePrice) * Number(item.Eat_Number)
                        }else{
                            if(!item?.Food_CostPrice){
                                saleMoney +=Number(item.Eat_SalePrice) * Number(item.Eat_Number)
                                costMonye += 0
                            }else{
                                saleMoney +=Number(item.Eat_SalePrice) * Number(item.Eat_Number)
                                costMonye += Number(item?.Food_CostPrice) * Number(item.Eat_Number)
                            }
                        }
                    }
                    if(item?.is_taste){
                        if(item?.Eat_SalePrice){
                            saleMoney +=Number(item?.Eat_SalePrice) * Number(item.Eat_Number)
                        }else if(item?.Eat_CheckPrice){
                            saleMoney +=Number(item.Eat_CheckPrice) * Number(item.Eat_Number)
                        }
                    }
                })
                saleMoney += EatSaleMoney +foodSaleMoney
                costMonye += EatCostMoney
                if(saleMoney>0 || costMonye>0){
                    achieving =(saleMoney-costMonye)/saleMoney
                }
            }
            return {
                achieving:Number(Number(achieving) * 100).toFixed(2)
            } 
        },
        /**计算账单总金额 数量 份数 */
        deskCount(){
            let placeFoodsTotal = 0;
            let placeFoodsSumPortion = 0;
            let placeFoodsSum=0;
            this.orderMenuList?.map(item=>{
                if(item.Eat_Number>0){
                    placeFoodsSum++; //总数量
                    if(item.Eat_TcItemList){
                        item.Eat_TcItemList.map(eat=>{
                            if(item.Food_Type!=4){
                                placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(eat.Eat_Number); //总份量
                            }
                            if(item.ModiPrice){
                                placeFoodsTotal = placeFoodsTotal+((eat.Eat_CheckPrice+eat.ModiPrice) * eat.Eat_Number)  //总价格
                            }else{
                                placeFoodsTotal = placeFoodsTotal+(eat.Eat_CheckPrice * eat.Eat_Number)  //总价格
                            }
                        })
                    }
                    if(item.FoodSetmeals){
                        item.FoodSetmeals.map(food=>{
                            if(food?.Food_Type!=4){
                                 placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(food.FoodSetmeal_Number); //总份量
                            }
                            //food.FoodSaleMoney
                            placeFoodsTotal = placeFoodsTotal+((food.FoodSetmeal_Price) * food.FoodSetmeal_Number)  //总价格
                        })
                    }
                    
                    
                    if(item.Food_Type!=4 && !item?.is_taste){
                        placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(item.Eat_Number); //总份量
                    }
                    if(item.ModiPrice){
                        placeFoodsTotal = placeFoodsTotal+((item.Eat_CheckPrice+item.ModiPrice) * item.Eat_Number)  //总价格
                    }else{
                        //placeFoodsTotal = placeFoodsTotal+(item.Eat_CheckPrice * item.Eat_Number)  //总价格 ---之前写法
                        if(item.Eat_CheckMoney)//如果有结算金额取结算金额，
                        {
                            placeFoodsTotal = placeFoodsTotal+ item.Eat_CheckMoney;
                        }else{
                            placeFoodsTotal = placeFoodsTotal+(item.Eat_CheckPrice * item.Eat_Number)  //总价格
                        }
                    }
                }

                
            })
            // if(this.$refs.orderMenu){//计算满减
            //     placeFoodsTotal=this.$refs.orderMenu.fullReduction(placeFoodsTotal);
            // }
            //加上服务费
            placeFoodsTotal = placeFoodsTotal + Number(this.orderInfo.Service_Money);
            return {
                Total:'<i>￥</i>'+placeFoodsTotal.toFixed(2).replace(/[.]\d*/g,(s)=>{return '<b>'+s+'</b>'}),//总金额
                SumPortion:parseFloat(placeFoodsSumPortion.toFixed(2)),//份数
                FoodsSum:placeFoodsSum//数量
            }

            
        },
        /**确认上桌权限 */
        CheckONDesk(){
            let Desk;
            this.userMiddleMenu?.map(it=>{
                if(it.ProjectItem_Key=='CheckONDesk'){
                    Desk=it;
                }else if(it.ProjectItem_Key== "OtherFunc"){
                    let item= it.SysProjectSubItems?.find(item=>item.ProjectSubItem_Key=='CheckONDesk');
                    if(item){
                        Desk=item;
                    }
                }
            })
            return Desk;
        },
        /**点单菜品的数量 未下单的菜品 切非加价做法 */
        foodNumberKey(){
            let data={};
            this.orderMenuList?.forEach(it=>{
                if(it.Food_Status<0 && !it.is_taste){
                    if(data[it.EAT_XFBMID]){
                        data[it.EAT_XFBMID]=Number((data[it.EAT_XFBMID]+Number(it.Eat_Number)).toFixed(2))
                    }else{
                        data[it.EAT_XFBMID]=Number(it.Eat_Number);
                    }
                    it.FoodSetmeals?.forEach(item=>{
                        if(data[item.FoodSetmeal_ID]){
                            data[item.FoodSetmeal_ID]=Number((data[item.FoodSetmeal_ID]+Number(item.FoodSetmeal_Number)).toFixed(2))
                        }else{
                            data[item.FoodSetmeal_ID]=Number(item.FoodSetmeal_Number);
                        }
                    })
                }
            })
            return data;
        },
        /**小类下 点单菜品的数量 未下单的菜品 */
        subCategoryNumberKey(){
            let subCategory={};
            this.foodSubCategorys?.forEach(it=>{
                let number=0;
                it.PosFoods?.forEach(item=>{
                    if(this.foodNumberKey[item.Food_ID]>0){
                        number+=this.foodNumberKey[item.Food_ID];
                    }

                    item.MultipleSizeFoods?.forEach(it=>{
                        if(this.foodNumberKey[it.Food_ID]>0){
                            number+=this.foodNumberKey[it.Food_ID];
                        }
                    })
                })
                if(number>0){
                    number=Number(number.toFixed(2));
                    subCategory[it.SubCategory_ID]=number;
                }
            })
            return subCategory;
        }
    },
    created(){
       document.addEventListener("keydown",this.keydown)
    },
    mounted(){
        this.$keyavail=false;
        
        if(_data.Running_InvoicePintByChosen_YN){
            if(_data.OpenCashierHandInvoiceWithDefaultNotIssueInovoice){
                this.$global.invoiceOff=true;
            }
            this.invoice=!this.$global.invoiceOff;
            this.RunningShow=true;
        }else{//默认false 打印发票
            this.$global.invoiceOff=false;
            this.invoice=!this.$global.invoiceOff;
        }
        this.$emit("navIndex",0);
        this.userInfo=this.$auth.getUserInfo(); //获取用户id
        this.Login_Name=this.userInfo.Login_Name; //用户姓名
        let Desk_AutoID= this.$route.params.Desk_AutoID
        let Eat_AutoID = this.$route.params.Eat_AutoID
        let Desk_Locked = this.$route.params.Desk_Locked
        this.urlType = this.$route.params.urlType
        if(this.$route.params.PosDesks){
            this.lockPosDesks.push(JSON.parse(this.$route.params.PosDesks))
        }
        this.$nextTick(()=>{
            this.$refs.searchBox.focus();
        })
        this.UUID = config.UUID
        this.posDesks ={Desk_AutoID:Desk_AutoID,Eat_AutoID:Eat_AutoID,Desk_Locked:Desk_Locked}; //拿到桌台数据
        this.ProfitRate = _data.RunOrderDeviceShowBillProfitRate
        //获取订单信息
        let param={
            Body_ID:newGuid(),
            User_ID:this.userInfo.User_ID,
            Operator_Name:this.userInfo?.Login_Name,
            Eat_DeskID:this.posDesks.Desk_AutoID, //桌台ID
            EAT_AUTOID:this.posDesks.Eat_AutoID, //订单Id
            Is_UnionOrder:true,
            Is_LockDesk:true,
        }
        const loading = this.$loading({
            text: '数据加载中',
            spinner: "el-icon-loading",
            background: "rgba(255, 255, 255, 0)",
        });
        httpAES.post('Bestech.CloudPos.GetOrderInfo',param).then((data)=>{
            loading.close();
            if(data?.ResponseHeader.ResultCode!=0){
                this.$router.push({path:'diningTable',name:'diningTable'});
                if(data?.ResponseHeader.ResultDesc==''){
                    this.$message.error('网络错误,请稍后再尝试');
                }else{
                    this.$message.error(data?.ResponseHeader.ResultDesc);
                }
            }else{
                this.orderInfo = data.ResponseBody;
                this.lockPosDesks.Timestamps = this.orderInfo.DeskTimestamps.toString()
                this.orderMenuList=JSON.parse(JSON.stringify(data.ResponseBody.Order_ConsumeList))
                this.posFoodNumbermethod(4)
                this.DeskLocked = this.orderInfo.Is_Locked;
                this.lockEqId = this.orderInfo.Lock_EqId;
                if(data.ResponseBody.Order_PaymentDetailList){
                    this.orderPaymentDetailList = data.ResponseBody.Order_PaymentDetailList;
                }
                if(this.orderMenuList.length>0){
                    this.isPlace=true
                }
                if(this.orderInfo.IsMemberPrice==true){
                    this.userCheckOutMiddleMenu.map(item=>{
                        if(item.ProjectItem_Key=="MemberPrice"){
                            item.ProjectItem_Name='取消会员价'
                        }
                    })
                }
                this.consumeTypeMethod(data.ResponseBody.Consume_TypeID)
                this.diningChannel(this.orderInfo.CHANNEL_ID)
                if(this.urlType==2){
                    this.Initialization()
                    this.onCheckOutOrderInfo()
                }
            }
        }).catch((e)=>{
            loading.close();
            this.$alert(e.message, "提示", {confirmButtonText: "确定"});
        });
       
        this.$cacheData.DeskPosconfigure().then((d)=>{
            this.orderMap_Type=_data.orderMap_Type = d.OrderMap_Type;
            _data.Running_DtFoodCard_YN=d.Running_DtFoodCard_YN;
            this.args=_data.args={row:3,col:6,marginW:'8',marginH:'8'};

            d.DisplayingConfigs?.forEach((it)=>{
                let style={};
                if(it.ScreenPx>0){
                    style["height"]=it.ScreenPx+"%";
                }
                if(it.Button_Color){
                    style["--bgColor"]=it.Button_Color;
                }
                if(it.Font_Name){
                    style["--fontFamily"]=it.Font_Name;
                }
                if(it.Font_Size){
                    style["--fontSize"]=it.Font_Size+"px"
                }
                if(it.Font_Color){
                    style["--fontColor"]=it.Font_Color
                }
                let arg={row:it.IntRow,col:it.IntCol};
                if(it.Config_Kind==11){//菜品大类显示类型
                    this.foodMainArg=arg;
                    this.foodMainStyle=style;
                }else if(it.Config_Kind==12){//为菜品小类显示类型   
                    this.foodSubArg=arg;
                    this.foodSubStyle=style;
                }else if(it.Config_Kind==13){//为菜品显示类型   
                    this.arg=_data.arg={row:it.IntRow,col:it.IntCol,marginW:'6',marginH:'6'};
                    this.menuStyle=style;
                    //this.foodMainArg=arg; 
                    //this.foodMainStyle=style;
                }else if(it.Config_Kind==14){//为功能键显示类型   
                    this.footerArg=arg;
                    this.footerStyle=style;
                }
            })
        }).catch(()=>{
            //this.$alert("基本配置获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
        //菜品数据结构
        this.$cacheData.PosFoods().then((d)=>{
           this.posFoodData=d;
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        /* 获取支付方式 */
        this.$cacheData.PayType().then((data)=>{
            this.PayKinds = data
            /**取出聚合支付 **/
            data.forEach(item=>{
                item.PayTypes.forEach(pay=>{
                    this.payType.push(pay);
                    if(pay.INTERFACE_TYPE==801010301){
                        this.payTypeSSPay = pay;
                    }
                })
            })
        }).catch(e=>{
            this.$alert("支付方式获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
        /**折扣列表*/
        this.$cacheData.DiscountScheme().then((d)=>{
            this.discountScheme = d;
        }).catch((e)=>{
        console.log('e:'+e);
            this.$alert("折扣数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })  
        /**菜品口味 */
        this.$cacheData.MasKinds().then((d)=>{
            this.masKindsList = d;
        }).catch(e=>{
            this.$alert("菜品口味数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
        
        //时段数据
        this.$cacheData.EatPhase().then((d)=>{
            if(d){
                this.eatPhases=d;
                if(d.length>0 && this.sysSetting?.OpenCheckOutWithSelectPhase){
                    console.log(this.$global.PhaseID);
                }else{
                    this.$global.PhaseID=null;
                }
            }
        }).catch((e)=>{
            //this.$message.error('加载时段数据失败：'+e.message);
            console.log('加载时段数据失败：'+e);
        })
        /**菜品组合信息 */
        this.$cacheData.ComboFood().then((d)=>{
            this.comboFoodList = d;
        }).catch(e=>{
            this.$alert("菜品口味数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
        

        if(this.$route.params?.OrderConsumes){
            let SoloOutfood='',limitedProject='',foodType=0
            if(this._orderCouns)clearTimeout(this._orderCouns);
            this._orderCouns=setTimeout(()=>{
                if(foodType==0){
                    if(this.posFoods){
                        foodType=1
                        let _orderConsumes=JSON.parse(this.$route.params?.OrderConsumes)
                        this.posFoods.forEach(pos=>{
                            if(_orderConsumes){
                                _orderConsumes.forEach(food=>{
                                    if(food.Eat_XFBMID==pos.Food_ID){
                                        //已被沽清的菜品
                                        if(pos.Is_SoldOut){
                                            SoloOutfood+=pos.Food_Name+','
                                            return true
                                        }
                                        //是否限量销售
                                        if(pos.Is_Limited){
                                            if(pos.RemainNum==0){
                                                //是否有限量销售方案
                                                if(pos.Is_LimitedProject){
                                                    limitedProject+=pos.Food_Name+','
                                                    return true
                                                }
                                            }
                                        }
                                        this.orderNumber = food.Eat_Number
                                        this.addFood(pos,food.Eat_Number)
                                        this.orderNumber=''
                                    }
                                })
                            }
                        })
                    }
                }
            },1000);
            if(SoloOutfood){
                SoloOutfood = SoloOutfood.substring(0,SoloOutfood.lastIndexOf(','))
                this.$message.warning(SoloOutfood+'是沽清的菜品,无法添加到订单中');
            }
            if(limitedProject){
                limitedProject = limitedProject.substring(0,limitedProject.lastIndexOf(','))
                this.$message.warning(SoloOutfood+'菜品已售完,无法添加到订单中');
            }
        }
        /**自定义功能按钮 */
        let SysProjects=this.$cacheData.SysProjects.get();
        if(SysProjects && SysProjects.length>0){
            SysProjects.forEach(element=> {
            if(element.Project_Key=='Order'){
                element.SysProjectGroups.forEach(project=>{
                    /**桌台界面自定义按钮 */
                    if(project.Group_Key=='DeskMap-Bottom'){
                        project.SysProjectItems.forEach((item)=>{
                            if(item.ProjectItem_Key=='DeskLock'){
                                this.deskcheckBtnlist = item
                            }
                        })
                    }
                    /**点菜界面竖排自定义功能按钮 */
                    if(project.Group_Key=='OrderMain-Middle'){
                        project.SysProjectItems.forEach((item)=>{
                            let Menu=this.userMiddleMenu[item.ProjectItem_Key];
                            if(Menu==null ||Menu=='undefined'){
                                if(project.OtherFunc_YN==false){
                                    if(project.OtherFunc_Key!=item.ProjectItem_Key){
                                        this.userMiddleMenu.push(item);
                                    }
                                }else{
                                    this.userMiddleMenu.push(item);
                                }
                            }
                        })
                    }
                    /**点菜界面横排自定义功能按钮 */
                    if(project.Group_Key=='OrderMain-Bottom'){
                        project.SysProjectItems.forEach(item=>{
                            let Menu=this.userBottomMenu[item.ProjectItem_Key];
                            if(Menu==null ||Menu=='undefined' ){
                                this.userBottomMenu.push(item);
                            }
                        })
                    }
                    /**结账界面竖排自定义功能按钮 */
                    if(project.Group_Key=='CheckOut-Middle'){
                        project.SysProjectItems.forEach((item)=>{
                            let Menu=this.userCheckOutMiddleMenu[item.ProjectItem_Key];
                            if(Menu==null ||Menu=='undefined'){
                                if(project.OtherFunc_YN==false){
                                    if(project.OtherFunc_Key!=item.ProjectItem_Key){
                                        this.userCheckOutMiddleMenu.push(item);
                                    }
                                }else{
                                    this.userCheckOutMiddleMenu.push(item);
                                }
                            }
                        })
                    }
                    /**结账界面横排自定义功能按钮 */
                    if(project.Group_Key=='CheckOut-Bottom'){
                        project.SysProjectItems.forEach(item=>{
                            let Menu=this.userCheckOutMenu[item.ProjectItem_Key];
                            if(Menu==null ||Menu=='undefined' ){
                                this.userCheckOutMenu.push(item);
                            }
                        })
                    }
                })
            }
            });
        }
        //注册快捷键
        this.$shortcutKey.setFun((e,KeyBoard)=>{
            //是否锁屏了
            if(this.$global.isLockScreen)return; 
            if(this.$keyavail==false){
                if(!this.isCheckOut){
                    this.userMiddleMenu.find(it=>{
                        if(it.ProjectItem_ShortcutKey==KeyBoard){
                            this.MiddleMenuClick(it);
                            return true;
                        }else if(it.ProjectItem_Key== "OtherFunc"){
                            let item=it.SysProjectSubItems?.find(item=>item.ProjectSubItem_ShortcutKey==KeyBoard);
                            if(item){
                                this.subItemReturn(item);
                                return true;
                            }
                        }
                        return false;
                    })
                    this.userBottomMenu.find(it=>{
                        if(it.ProjectItem_ShortcutKey==KeyBoard){
                            this.BottomMenuClick(it);
                            return true;
                        }
                        return false;
                    })
                    if(e.key=="+" || e.code=="NumpadAdd"){
                        this.$msgbox.close();
                        this.clickNumAdd();
                    }else if(e.key=="-" || e.code=="NumpadSubtract"){
                        this.$msgbox.close();
                        this.clickNumReduce();
                    }
                }else{
                    this.userCheckOutMiddleMenu.find(it=>{
                        if(it.ProjectItem_ShortcutKey==KeyBoard){
                            this.CheckMiddleClick(it);
                            return true;
                        }else if(it.ProjectItem_Key== "OtherFunc"){
                            let item=it.SysProjectSubItems?.find(item=>item.ProjectSubItem_ShortcutKey==KeyBoard);
                            if(item){
                                this.subItemReturn(item);
                                return true;
                            }
                        }
                        return false;
                    })
                    this.userCheckOutMenu.find(it=>{
                        if(it.ProjectItem_ShortcutKey==KeyBoard){
                            this.CheckOutMenuClick(it);
                            return true;
                        }
                        return false;
                    })
                }
            }
        })
       
    },
    watch:{
        getPosFoodData(){
            this.foodMainCategory=this.getPosFoodData;
            let all=[];//全部大类
            this.foodMainCategory.forEach(category=> {
                if(category.FoodSubCategorys && category.FoodSubCategorys.length>0){
                    all=all.concat(category.FoodSubCategorys);
                }
            });
            all._all=[];//全部小类
            if(all.length>0){
                all.forEach(sub => {
                    let _SubCategory={SubCategory_Name:sub.SubCategory_Name,SubCategory_ID:sub.SubCategory_ID}
                    this.allfoodSubCategorys.push(_SubCategory);
                    if(sub.PosFoods && sub.PosFoods.length>0)
                    all._all=all._all.concat(sub.PosFoods)
                });
            }
            this.foodMainCategory._all=all; //小分类

            if(this.mainCategoryIndex>=this.foodMainCategory?.length){this.mainCategoryIndex=0;}
            if(this.mainCategoryIndex>0){
                this.foodSubCategorys=this.foodMainCategory[this.mainCategoryIndex-1].FoodSubCategorys;
            }else{
                this.foodSubCategorys=this.foodMainCategory._all;
            }
            if(this.subCategoryIndex>=this.foodSubCategorys?.length){this.subCategoryIndex=0;}
            if(this.subCategoryIndex>0){
                this.posFoods=this.foodSubCategorys[this.subCategoryIndex-1].PosFoods;
            }else{
                this.posFoods=this.foodSubCategorys._all;
            }
            this.posFoods.map(pos=>{
                this.foodSubCategorys.map(food=>{
                    if(food.SubCategory_ID==pos.SubCategory_ID){
                        pos = Object.assign(pos,{foodNumber:0,SubCategory_Code:food.SubCategory_Code})
                    }
                })
            })
        },
        /**菜品搜索 */
        varietySelect(newVal,oldVal){
            console.log(newVal,oldVal);
            if(newVal && (newVal.indexOf('+')>=0 || newVal.indexOf('-')>=0 )){
                this.$nextTick(()=>{
                    this.varietySelect=oldVal;
                })
                return;
            }
            let MenuTxt=this.varietySelect.toLowerCase();
            if(MenuTxt=="" && this.oldVarietySelect){
                MenuTxt=this.oldVarietySelect.toLowerCase();
            }else{
                this.oldVarietySelect="";
            }

            if(MenuTxt!=''){
                this.subCategoryIndex=0;
                this.mainCategoryIndex=0;
                this.posFoods=[];
                this.foodMainCategory.map(item=>{
                    item.FoodSubCategorys.map(food=>{
                        food.PosFoods.map(pos=>{
                            if((pos.Food_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (pos.Food_PinYin||"").toLowerCase().indexOf(MenuTxt)>=0 || (pos.Food_Code||"").toLowerCase().indexOf(MenuTxt)>=0){
                                this.posFoods.push(pos)
                            }
                        })
                    })
                })

                this.$nextTick(()=>{
                    if(this.foodsPages && this.foodsPages.length>0 && !this.foodsPages.some(it=>it.Food_ID==this.choicePosFoods)){
                        this.choicePosFoods=this.foodsPages[0].Food_ID;
                    }
                })
            }else{
                this.posFoods =this.foodMainCategory?._all?._all;
            }
        },
        /**支付类型搜索 */
        payTypeSelect(){
            this.payType=[]
            if(this.payTypeSelect!=''){
                let payTxt = this.payTypeSelect.toLowerCase();
                this.PayKinds?.forEach(kind=>{
                    kind.PayTypes.forEach(pay=>{
                        if((pay.PAY_NAME||"").toLowerCase().indexOf(payTxt)>=0 || (pay.PAY_CODE||"").toLowerCase().indexOf(payTxt)>=0){
                           this.payType.push(pay)
                        }
                    })
                })
            }else{
                this.PayKinds.forEach(kind=>{
                    kind.PayTypes.forEach(pay=>{
                        this.payType.push(pay)
                    })
                })
            }
            if(this.payType.length==1){
                this.onChoicePayType(this.payType[0])
            }
        },
        posFoods(){
            let size=this.arg.row*this.arg.col;
            this.posFoodsPage.pageIndex=1;
            this.posFoodsPage.pageSize=Math.ceil(this.posFoods.length/size);
        },
        mainCategoryIndex(){
            this.foodSubPageIndex=1;
        },
        invoice(newVal){
            this.$global.invoiceOff=!newVal;
        },
        /**支付记录变动时,去做排序操作 */
        orderPaymentDetailList(){
            //console.log('排序前:'+JSON.stringify(this.orderPaymentDetailList))
            this.orderPaymentDetailList.order('Sort_ID','asc');
            //console.log('排序后:'+JSON.stringify(this.orderPaymentDetailList))
            //this.orderPaymentDetailList.sort(this.compare('Sort_ID',''));
        },
        isCheckOut(newVal){
            if(newVal){//结账时
                if(this.eatPhases?.length>0 && this.sysSetting?.OpenCheckOutWithSelectPhase && !this.$global.PhaseID){
                    let time=(new Date()).Format("hh:mm");
                    let eatPhases=this.eatPhases.find(it=>
                        (it.EAT_BTIME<=it.EAT_ETIME && it.EAT_BTIME<=time && it.EAT_ETIME>time) ||
                        (it.EAT_BTIME>it.EAT_ETIME && (it.EAT_BTIME<=time || it.EAT_ETIME>time))
                    );
                    if(eatPhases){
                        this.$global.PhaseID=eatPhases.EAT_PHASEID;
                    }else{
                        this.$global.PhaseID=this.eatPhases[0].EAT_PHASEID;
                    }
                    
                }
            }
        }
    },
    /**当前位置的组件将要离开时触发 */
    beforeRouteLeave(to, from, next) {
        if(to.params?.isForced){//强制离开
            next();
            return ;
        }
        if(this.wlivePayExist && this.orderPaymentDetailList.length>0){
            this.$confirm('存在待结算的会员支付方式，返回将清空该类型的支付方式！', '提示', {
                confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
            .then(() => {
                next();//执行离开
            }).catch(() => {});
        }else{
            let _status=0
            if(this?.orderMenuList){
                this.orderMenuList.map((item)=>{
                    if(item.Food_Status==-1){
                        _status=1
                    }
                })
            }
            if(_status==0 || this?.OrderReturnType==1){
                this.userInfo=this.$auth.getUserInfo(); //获取用户id
                /**去解锁 */
                const loading = this.$loading({
                    text: "桌台解锁中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID,
                    Operator_Name:this.userInfo?.Login_Name,
                    Desk_InfoList:this.lockPosDesks,
                    Scene_Type:1
                }
                this.$httpAES.post("Bestech.CloudPos.DeskLockOperate",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode!=0){
                        if(data.ResponseHeader.ResultCode==3005){
                            next();//执行离开
                        }else{
                            //this.$message.warning('桌台解锁失败');
                            next();//执行离开
                        }
                    }else{
                        sessionStorage.setItem("nextPosDesks",this.lockPosDesks[0].Desk_AutoID)
                        next();//执行离开
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    next();//执行离开
                    console.log('桌台解锁失败:'+e);
                })
            }else{
                this.$message.error('账单有新点菜品不能切换请先下单或删除点菜');
                this.$emit("navIndex",0);
                return;
            }
        }
    },
    methods:{
        setBottomH(){
            let top=this.$refs.searchBox?.getBoundingClientRect().top;
            if(top>0){
                return window.innerHeight-top;
            }
            return 0;
        },
        /**弹出修改桌台信息 */
        openshows(){
            if(this.DeskLocked==true && this.lockEqId!= config.UUID ){
                this.$alert('桌台已锁定,无法调整', '提示', {confirmButtonText: '确定',});
                return
            }
            this.openshow=true
            this.$keyavail=true
        },
        //扫描枪 建索商品
        barcode(){
            console.log("barcode")
            let MenuTxt=(this.varietySelect||"").toLowerCase().trim();
            if(MenuTxt){
                let posFoods =this.foodMainCategory?._all?._all;
                let item=posFoods?.find(it=>(it.Food_BarCode||"").toLowerCase()==MenuTxt);
                if(item){
                    this.onPlaceFoods(item);
                    //扫码的 清除记录
                    this.oldVarietySelect=""
                }
            }
        },
        /**控制菜品搜索框 上下左右光标 */
        keyCursor(e){
            if(e.keyCode=='37' || e.keyCode=='38' || e.keyCode=='39' || e.keyCode=='40' || e.keyCode=='13'){
                e.preventDefault();
                if(this.foodsPages && this.foodsPages.length>0){
                    let index=this.foodsPages.findIndex(item=>item.Food_ID==this.choicePosFoods);
                    
                    if(e.keyCode=='37'){//左边
                        index--;
                    }else if(e.keyCode=='38'){//上
                        index=index-this.arg.col
                    }else if(e.keyCode=='39'){//右边
                        index++;
                    }else if(e.keyCode=='40'){//下
                        index=index+this.arg.col;
                    }else if(e.keyCode=='13'){
                        if(this.foodsPages.length>0){
                            this.onPlaceFoods(this.foodsPages[index])
                        }else{
                            this.$message.warning('未找到菜品');
                            return
                        }
                        return;
                    }
                    if(index<0) index=0;
                    if(index>=0 && index<this.foodsPages.length){
                        this.choicePosFoods=this.foodsPages[index].Food_ID;
                    }
                }
            }
        },
        keyChecked(){
            return true;
        },
        /**菜品点菜数量 标识 */
        getFoodNumber(item){
            let num=0;
            if(this.foodNumberKey[item.Food_ID]>0){
                num=this.foodNumberKey[item.Food_ID];
            }
            if(item.MultipleSizeFoods?.some(it=>this.foodNumberKey[it.Food_ID]>0)){
                item.MultipleSizeFoods.forEach(it=>{
                    if(this.foodNumberKey[it.Food_ID]>0){
                        num+=this.foodNumberKey[it.Food_ID];
                    }
                })
                num=Number(num.toFixed(4));
            }
            return num;
        },
        touchLeft(el,direction){
            if(direction=="left"){
                let button= el.querySelectorAll('.slide-content-button');
                if(button && button.length>0){
                   let w= button[0].getBoundingClientRect().width;
                   el.style.transform="translate3d(-" + w+"px, 0, 0)";
                }
            }else if(direction=="right"){
                el.style.transform="translate3d(0, 0, 0)";
            }
        },
         /**应付金额返回 */
        popupReturn(number){
            this.popShow=false
            this.$keyavail = false
            this.orderInfo.Payable_Money = Number(number)
            /**尾数调整金额:输入金额-结算金额-服务费 */
            if(this.unionType==true){
                this.orderInfo.UnPaid_Money = Number(number).compute(this.orderInfo.Payment_Money,'-');
                this.orderInfo.Mantissa_Money =Number(number).compute(this.orderInfo.Eat_CheckMoney,this.unionEatCheckMoney,this.orderInfo.Service_Money,this.unionServiceMoney,'-');

            }else{
                this.orderInfo.UnPaid_Money = Number(number).compute(this.orderInfo.Payment_Money,'-');
                this.orderInfo.Mantissa_Money =Number(number).compute(this.orderInfo.Eat_CheckMoney,this.orderInfo.Service_Money,'-');
            }
        },
        /**消费类型*/
        consumeTypeMethod(Consume_TypeID){
            let _ConsumeName=''
            this.$cacheData.ConsumeTypes()
            .then((d)=>{
                d.map(item=>{
                    item.ConsumeTypeList.map(consum=>{
                        if(consum.ConsumeType_AutoID==Consume_TypeID){
                            _ConsumeName = consum.ConsumeType_Name
                        }
                    })
                })
                this.consumeName = _ConsumeName
            })
            .catch(()=>{
            })
        },
        /**点菜界面竖排自定义按钮点击 */
        MiddleMenuClick(item){
            this.checkBtnType=0
            this.checkBtnlist = JSON.parse(JSON.stringify(item))
            if(this.DeskLocked==true && this.lockEqId!= config.UUID){
                this.$alert('桌台已锁定,无法调整', '提示', {confirmButtonText: '确定',});
                return
            }
            /**如果是点击的是赠送按钮,并且是取消赠送的菜品,则跳过权限验证 */
            let GiftGiveFree=0
            if(item.ProjectItem_Key=='GiveFree' && this.disRadioOrder.Is_Gift==true){
                GiftGiveFree=1
            }
            if(item.Permission==false && GiftGiveFree==0){
                this.$confirm('该账户权限不足,不支持'+item.ProjectItem_Name+'功能', '提示', {
                    confirmButtonText: '去验证',
                    cancelButtonText: '我知道了',
                    type: 'warning'})
                .then(() => {
                    this.checkBtnType=1
                    this.checkShow = true
                    this.$keyavail=true
                }).catch(()=>{});
                return;
            }
            switch(item.ProjectItem_Key){
                case 'SingleDel':
                    this.clickDelete(this.disRadioclass,this.disRadioOrder)  /**单删 */
                    break
                case 'Kit':
                    this.clickkitchenShow() /**厨房 */
                    break
                case 'Mas':
                    this.clickDemandShow() /**要求 */
                    break
                case 'Plus':
                    this.clickNumAdd() /**+ */
                    break
                case 'Minus':
                    this.clickNumReduce() /**- */
                    break
                case 'NumKeybord':
                    this.clickQuantity() /**数量 */
                    break
                case 'GiveFree': 
                        this.clickGivereturn(1) /**赠送 */
                    break
                case 'Refund':
                        this.clickGivereturn(2) /**退菜 */
                    break
                case 'BatchRefundEAT':
                    if(this.orderMenuList.some(it=>it.Food_Status==-1)){
                        this.$message.error('存在新点菜品,请先落单再使用此功能')
                        return
                    }
                    this.$keyavail=true;
                    this.batchReturnShow=true; /**批量退菜 */
                    break
                case 'ModifyPrice':
                        this.clickPriceChange(1) /**改价 */
                    break
                case 'ModifyNum':
                        this.clickPriceChange(2) /**改量 */
                    break
                case 'Rushing':
                    {
                        let status=0
                        this.orderMenuList.map(meu=>{
                            if(meu.Food_Status==-1){
                                status=1
                                return true
                            }
                        })
                        if(status==1){
                            this.$message.error('存在新点菜品,请先落单再使用此功能')
                            return
                        }
                        this.urgeFoodShowChange() /**催菜 */
                    }
                    break
                case 'AllMas':
                    this.clickDemandFullShow() /**全单 */
                    break
                case 'OtherFunc':
                    {
                        item.SysProjectSubItems.map(sys=>{
                            if(sys.ProjectSubItem_Key=='AddPromotion'){
                                if(this.orderInfo?.IsPromotionPrice==true){
                                    sys.ProjectSubItem_Name='取消促销方案'
                                }else{
                                    sys.ProjectSubItem_Name='参与促销方案'
                                }
                            }
                        })
                        this.otherFuncList = item.SysProjectSubItems.filter(p=>p.ProjectSubItem_Key!='CheckONDesk');
                        this.subShow = true
                        this.$keyavail=true
                    }
                    break
                case 'TemporaryStorage'://暂存菜品
                    this.$confirm('您确定暂存当前菜品?', "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText:"取消",
                        callback: (name) => {
                            if(name=='confirm'){
                                let _data=[];
                                this.orderMenuList.map(item=>{
                                    if(item.Food_Status==-1){
                                        let json=Object.assign({},item);
                                        json.OrderComboConsumes=undefined;
                                        _data.push(json);
                                    }
                                })
                                if(_data.length>0){
                                   this.$cacheData.lockersCache.set(_data)
                                   this.$message.success("暂存成功");
                                    this.orderMenuList.map((item,index)=>{
                                        if(item.Food_Status==-1){
                                            this.orderMenuList.splice(index,this.orderMenuList.length)
                                        }
                                    })
                                    this.posFoodNumbermethod(4)
                                }else{
                                    this.$message.error('没有新菜品不需要暂存')
                                }
                            }
                        },
                    });
                    break
                case 'DiyTc': //即配套餐
                    this.$cacheData.DeskPosconfigure().then((d)=>{
                        if(!d.DiyTcInfo){
                            this.$message.error('请先去配置即配套餐相关信息')
                        }else{
                            this.readyShowType=0
                            this.readyShow=true;
                            this.$keyavail=true;
                        }
                    }).catch(()=>{
                        //this.$alert("基本配置获取失败："+e.message, "提示", {confirmButtonText: "确定"});
                    })
                    break
                case 'SendButNoToKit': //下单不送达厨房
                    this.onPlaceOrder(true)
                    break
                case 'NoSaleNumSave'://不关联限量下单
                    this.pOrderShow=true;//
                    break
                case 'AllDeskRefund': //整桌退菜
                    {
                        let status=0
                        this.orderMenuList.map(meu=>{
                            if(meu.Food_Status==-1){
                                status=1
                                return true
                            }
                        })
                        if(status==1){
                            this.$message.error('存在新点菜品,请先落单再使用此功能')
                            return
                        }
                        this.wholeShow = true;
                        this.$keyavail=true;
                    }
                    break
                case 'GetTemporaryStorage'://提取暂存菜品
                    {
                        let lockersCache = this.$cacheData.lockersCache.getLockersList()
                        if(lockersCache.length>0){
                            this.lockersShow=true;
                            this.$keyavail=true;
                        }else{
                            this.$message.error('没有找到需要提取的暂存菜品')
                        }
                    }
                    break
                case 'DiyFood'://自定义菜品
                    this.customDishesShow=true;
                    this.$keyavail=true;
                    break
                case 'ChangeSize': //换单位
                    this.unitClick()
                    break
                case 'CopyMenu'://复制菜品
                    {
                        let status=0
                        this.orderMenuList.some(meu=>{
                            if(meu.Food_Status==-1){
                                status=1
                                return true
                            }
                        })
                        if(status==1){
                            this.$message.error('存在新点菜品,请先落单再使用此功能')
                            return
                        }
                        this.copyFoodShow=true;
                        this.$keyavail=true;
                    }
                    break
                case 'TransferFood'://转菜操作
                    {
                        let status=0
                        this.orderMenuList.map(meu=>{
                            if(meu.Food_Status==-1){
                                status=1
                                return true
                            }
                        })
                        if(status==1){
                            this.$message.error('存在新点菜品,请先落单再使用此功能')
                            return
                        }
                        this.transferFoodShow=true;
                        this.$keyavail=true;
                    }
                    break
                case 'TcChangeFood'://套餐换菜
                    if(this.disRadioOrder.Eat_CheckMoney<=0){
                        this.$message.error('即配套餐换菜请选择套餐头');
                        return;
                    }
                    if(this.disRadioOrder.Food_Type==1){
                        this.$message.error('该功能仅适用于套餐菜品');
                        return;
                    }
                    if(this.disRadioOrder.Eat_Number<=0){
                        this.$message.error('套餐菜品数量为0,不能进行套餐换菜操作');
                        return;
                    }
                    if(this.disRadioclass==-1){
                         this.$alert('请先选择左边的菜品', '提示', {confirmButtonText: '确定',});
                         return
                    }
                    this.posFoods.some(pos=>{
                        if(pos.Food_ID==this.disRadioOrder.EAT_XFBMID){
                            this.posFoodSetMealInfos=[]
                            this.posFoodSetMealInfos.push(pos);
                            return true
                        }
                    })
                    /**菜品未下单*/
                    if(this.disRadioOrder.Food_Status==-1){
                        this.tcChangeType = 1
                        if(!this.disRadioOrder.FoodSetmeals){
                            this.$message.error('请选择对应套餐');
                            return;
                        }else{
                            this.subShow = false
                        }
                        /**即配套餐 */
                        if(this.disRadioOrder.Food_Type==3){
                            this.$message.error('即配套餐下单后才可调整');
                            return;
                        }else{
                            this.setMealShow = true
                            this.$keyavail=true;
                        }
                    }else{
                         /**即配套餐 */
                        if(this.disRadioOrder.Food_Type==3){
                            this.readyShowType=2
                            this.readyShow=true
                            this.$keyavail=true;
                            //this.$message.error('下单的即配套餐暂无改菜功能')
                        }else{
                            /**已经下单的菜品 */
                            this.cheageSetMealShow=true;
                            this.$keyavail=true;
                        }
                    }
                    break
                case 'SaveCoupons': //卡券核销
                    {
                        let status=0
                        this.orderMenuList.map(meu=>{
                            if(meu.Food_Status==-1){
                                status=1
                                return true
                            }
                        })
                        if(status==1){
                            this.$message.error('存在新点菜品,请先落单再使用此功能')
                            return
                        }
                        this.saveCouponsShow=true;
                        this.$keyavail=true;
                    }
                    break
                case 'AllDelete'://全删
                    {
                        let _order =[]
                        let _deleteorder=[]
                        this.orderMenuList.map(order=>{
                            if(order.Food_Status!=-1){
                                _order.push(order)
                            }
                            if(order.Food_Status==-1){
                                _deleteorder.push(order)
                            }
                        })
                        if(_deleteorder.length==0){
                            this.$message.warning('没有需要删除的菜品')
                            return
                        }
                        this.$confirm('确定删除所有未下单的菜品吗?', '提示', {
                            confirmButtonText: '确认删除',
                            cancelButtonText: '取消',
                            type: 'warning'})
                        .then(() => {
                            this.orderMenuList = _order
                            this.posFoodNumbermethod(4)
                        }).catch(()=>{});
                    }
                    break
                case 'SavePeiCai'://配菜单
                {
                    this.withMenuShow=true;
                    this.$keyavail=true;
                    break
                }
                case 'AddPromotion'://促销方案
                    {
                        let status=0
                        if(this.orderMenuList.length>0){
                            this.orderMenuList.map(meu=>{
                                if(meu.Food_Status==-1){
                                    status=1
                                    return true
                                }
                            })
                            if(status==1){
                                this.$message.warning('存在新点菜品,请先落单再使用促销方案功能')
                                return
                            }
                            this.doPromotionPrice(item)
                        }else{
                            this.$message.warning('没有订单数据,请先点菜落单,再执行促销方案功能')
                            return
                        }
                    }
                    break
                case 'BindingPre': //团餐绑定
                {
                    this.$keyavail=true;
                    this.enterBindingShow=true;
                    break
                }
                case 'makeupTC':{//单品组合套餐
                    if(this.orderMenuList.some((item)=>item.Food_Status==-1)){
                        this.$message.error('账单有新点菜品不能进行单品组合套餐操作');
                        return ;
                    }

                    this.$keyavail=true;
                    this.makeupTCShow=true;
                    break
                }
                case 'UpdateDeskNum'://席数
                {
                    let isBo=false;
                    this.orderInfo?.Order_ConsumeList?.map((item)=>{
                        if(item.Eat_TcItemList && item.Eat_TcItemList.length>0){
                            isBo=true;
                        }
                    })
                    if(isBo){
                        this.$keyavail=true;
                        this.setDekNumShow=true;
                    }else{
                        this.$message.warning("没有套餐无法修改席数!");
                    }

                    
                    break
                }
                case 'ReadCardOrder'://读卡点菜
                {
                    this.readCardFood((foodids)=>{
                        this.$alert("本次读卡点菜"+foodids.length+"道菜！").then(()=>{
                            this.readCardAddFood(foodids);
                        })
                    })
                    break
                }
                case 'OrderUpdateLog'://订单轨迹
                {
                    this.isOrderTrack=true;
                    this.$keyavail=true;
                    break
                }
                case 'GetReserveFood'://提取预排菜
                {
                    this.GetReserveFoodMethods()
                    this.$keyavail=true;
                    break;
                }
                case 'GetKitPrint'://应急获取厨打单
                {
                    this.GetKitPrint()
                    this.$keyavail=true;
                    break;
                }
                case 'GroupUpdateXFBM'://组合修改
                {
                    
                    if(this.disRadioOrder){
                        if(this.orderMenuList[this.disRadioclass].Food_Status!=-1){//已下单菜品不能操作
                            this.$message.warning('该功能仅适用于未下单的组合菜品');
                        }else{
                            //是否是组合菜品 
                            let foodInfo=this.disRadioOrder;
                            if(foodInfo.Food_Type==1 && foodInfo.Eat_TcAutoID_Batch && !foodInfo.Eat_TcName){
                                if(foodInfo.Is_Combo){//组合明细
                                    foodInfo=this.orderMenuList.find(it=>it.Eat_TcIndex==1 && it.Eat_TcAutoID_Batch== this.disRadioOrder.Eat_TcAutoID_Batch);
                                }
                                this.comboFoodInfo=this.comboFoodList?.find(it=>it.Food_ID==foodInfo.EAT_XFBMID);
                                if(this.comboFoodInfo){
                                    this.editComboFoodInfo=foodInfo;
                                    this.isComboFoodShow=true;
                                    this.$keyavail=true;
                                }else{
                                    this.$message.warning("未查询到该组合,无法修改!");
                                }
                            }else{
                                this.$message.warning('该功能仅适用于组合菜品');
                            }
                        }
                    }else{
                        this.$message.warning("请先选择左边组合菜品进行修改!");
                    }
                    break;
                }
            }
        },
        /**点菜界面横排自定义按钮 */
        BottomMenuClick(item){
            this.checkBtnType=0
            this.checkBtnlist = JSON.parse(JSON.stringify(item))
            if(this.DeskLocked==true && this.lockEqId!= config.UUID && item.ProjectItem_Key!="OrderReturn"){
                this.$alert('桌台已锁定,无法调整', '提示', {confirmButtonText: '确定',});
                return
            }
            if(item.Permission==false){
                this.$confirm('该账户权限不足,不支持'+item.ProjectItem_Name+'功能', '提示', {
                    confirmButtonText: '去验证',
                    cancelButtonText: '我知道了',
                    type: 'warning'})
                .then(() => {
                    this.checkBtnType=2
                    this.checkShow = true
                    this.$keyavail=true
                }).catch(()=>{});
                return;
            }
            if(item.ProjectItem_Key!="OrderReturn" && item.ProjectItem_Key!="SendDish"){
                let status=0
                this.orderMenuList.map(meu=>{
                    if(meu.Food_Status==-1){
                        status=1
                        return true
                    }
                })
                if(status==1){
                    this.$alert('存在新点菜品,请先落单再使用此功能', '提示', {confirmButtonText: '确定',});
                    return
                }
            }
            switch(item.ProjectItem_Key){
                case 'SendDish': /**落单 */
                    this.onPlaceOrder(false);
                    break
                case 'PreViewBill': /**消费清单 */
                    this.GetPrintContent(1)
                    break
                case 'DiscountPlan': /**折扣方案 */
                    this.discounCheck=item
                    this.discountShow=true;
                    this.$keyavail=true
                    break
                case 'SplitBill': /**分单结账 */
                    break
                case 'ServiceCharge': /**服务费 */
                    if(Number(this.orderInfo.Payment_Money).toFixed(2)==0){
                        this.serviceFeeShow =true
                        this.$keyavail=true
                    }else{
                        this.$alert('已有付款 不能添加服务费项目', '提示', {confirmButtonText: '确定',});
                    }
                    break
                case 'SSPay': /**聚合支付 */
                    this.scanCodePay=true
                    this.$keyavail=true
                    break
                case 'goCheckOut': /**结账 */
                    if(this.orderMenuList.length==0){
                        this.$message.warning('您还未点菜,无需结账');
                        return;
                    }
                    this.onCheckOut()
                    break
                case 'CstmView':/**客看单 */
                    this.GetPrintContent(8)
                    break
                case 'OrderReturn': /**返回 */
                {
                    let _status=0
                    this.orderMenuList.map(meu=>{
                        if(meu.Food_Status==-1){
                            _status=1
                            return true
                        }
                    })
                    if(_status==1){
                        this.$confirm('您已点菜，但未下单到厨房，请确认继续退出吗？', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'})
                        .then(() => {
                            this.orderInterReturn(1)
                        }).catch(()=>{});
                    }else{
                        this.orderInterReturn(1)
                    }
                    break
                }
            }
        },
        /**结账界面竖排自定义功能按钮 */
        CheckMiddleClick(item){
            this.checkBtnType=0
            this.checkBtnlist = JSON.parse(JSON.stringify(item))
            /**如果是点击的是赠送按钮,并且是取消赠送的菜品,则跳过权限验证 */
            let GiftGiveFree=0
            if((item.ProjectItem_Key=='GiveFree' && this.disRadioOrder.Is_Gift==true) || item.ProjectItem_Key=='MemberPrice'){
                GiftGiveFree=1
            }
            if(item.Permission==false && GiftGiveFree==0){
                this.$confirm('该账户权限不足,不支持'+item.ProjectItem_Name+'功能', '提示', {
                    confirmButtonText: '去验证',
                    cancelButtonText: '我知道了',
                    type: 'warning'})
                .then(() => {
                    this.checkBtnType=3
                    this.checkShow = true
                    this.$keyavail=true
                }).catch(()=>{});
                return;
            }
            switch(item.ProjectItem_Key){
                case 'SingleDel':
                    this.clickDelete(this.disRadioclass,this.disRadioOrder)  /**单删 */
                    break
                case 'PreViewBill': /**消费清单 */
                    this.GetPrintContent(1)
                    break
                case 'SSPay':
                    this.money="",this.choicePayType = [],this.ispayid=0
                    this.scanCodePay=true /**聚合支付 */
                    this.$keyavail=true
                    break
                case 'OpenCashBox': /**开钱箱 */
                    this.$webBrowser.openCashBoxBnt().then(d=>{
                        if(d?.state!=0){
                            this.$message.error('钱箱打开失败:'+(d?.message||""));
                        }
                    });
                    break
                case 'GiveFree': 
                    this.clickGivereturn(1) /**赠送 */
                    break
                case 'Refund':
                    this.clickGivereturn(2) /**退菜 */
                    break
                case 'BatchRefundEAT':

                    if(this.orderMenuList.some(it=>it.Food_Status==-1)){
                        this.$message.error('存在新点菜品,请先落单再使用此功能')
                        return
                    }

                    this.$keyavail=true;
                    this.batchReturnShow=true; /**批量退菜 */
                    break
                case 'ModifyPrice':
                    this.clickPriceChange(1) /**改价 */
                    break
                case 'ModifyNum':
                    this.clickPriceChange(2) /**改量 */
                    break
                case 'DiscountPlan': /**折扣方案 */
                    this.discounCheck=item
                    this.discountShow=true;
                    this.$keyavail=true
                    break
                case 'ServiceCharge': /**服务费 */
                    {
                        if(Number(this.orderInfo.Payment_Money).toFixed(2)==0){
                            this.serviceFeeShow =true
                            this.$keyavail=true
                        }else{
                            this.$alert('已有付款 不能添加服务费项目', '提示', {confirmButtonText: '确定',});
                        }
                    }
                    break
                case 'UnionDesk':
                       /**联台结账 */
                       this.unionisShow=true
                       this.$keyavail=true
                    break
                /**会员价 */
                case 'MemberPrice':
                    {
                        if(this.carPriceType==true){
                            if(this.orderInfo.IsMemberPrice==false){
                                this.cardPriceShow=true; 
                                this.$keyavail=true
                            }else{
                                this.$confirm('是否取消会员价', '提示', {
                                    confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
                                .then(() => {
                                    this.cancelCardPrice()
                                    this.isManualParPrice=false
                                }).catch(() => {
                                });
                            }
                        }else{
                            this.$message.warning('该账号权限不足，不支持会员价功能')
                            return;
                        }
                    }
                    break
            }
        },
        /**结账界面横排自定义功能按钮 */
        CheckOutMenuClick(item){
            this.checkBtnType=0
            this.checkBtnlist = JSON.parse(JSON.stringify(item))
            if(item.Permission==false){
                this.$confirm('该账户权限不足,不支持'+item.ProjectItem_Name+'功能', '提示', {
                    confirmButtonText: '去验证',
                    cancelButtonText: '我知道了',
                    type: 'warning'})
                .then(() => {
                    this.checkBtnType=4
                    this.checkShow = true
                    this.$keyavail=true
                }).catch(()=>{});
                return;
            }
            switch(item.ProjectItem_Key){
                case 'SendDish': /**开钱箱 */
                    break
                case 'PreViewBill': /**消费清单 */
                    this.GetPrintContent(1)
                    break
                case 'SSPay': 
                    this.scanCodePay=true /**聚合支付 */
                    this.$keyavail=true
                    break
                case 'CheckOutReturn':
                    {
                        if(this.wlivePayExist && this.orderPaymentDetailList.length>0){
                            this.$confirm('存在待结算的会员支付方式，返回将清空该类型的支付方式！', '提示', {
                                confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
                            .then(() => {
                                this.isCheckOutclass='panel-body'
                                this.checOutShow='height:calc(100% - 160px  - 5px)',
                                this.foodNavPage={pageIndex:0,pageSize:0},
                                this.foodNavPageSub={pageIndex:0,pageSize:0}
                                this.unionType=false
                                this.GetOrderInfo(0)
                                this.isCheckOut=false /**返回 */
                                this.unionNumber=1
                                this.wlivePayExist=false
                            }).catch(() => {});
                        }else{
                            this.isCheckOutclass='panel-body'
                            this.checOutShow='height:calc(100% - 160px  - 5px)',
                            this.foodNavPage={pageIndex:0,pageSize:0},
                            this.foodNavPageSub={pageIndex:0,pageSize:0}
                            this.unionType=false
                            this.GetOrderInfo(0)
                            this.isCheckOut=false /**返回 */
                            this.unionNumber=1
                        }
                    }
                    break
                case 'CheckOut': 
                    this.PerformingCheck() /**完成结账 */
                    break
            }
        },
        /**权限控制 */
        checkbtnReturn(date){
            this.checkShow = false;
            this.$keyavail=false;
            this.checkBtnlist.Permission = true;
            sessionStorage.setItem("tempUserID",date.User_ID)
            sessionStorage.setItem("tempName",date.Login_Name)
            if(this.checkBtnType==1){
                this.MiddleMenuClick(this.checkBtnlist)
            }
            if(this.checkBtnType==2){
                this.BottomMenuClick(this.checkBtnlist)
            }
            if(this.checkBtnType==3){
                this.CheckMiddleClick(this.checkBtnlist)
            }
            if(this.checkBtnType==4){
                this.CheckOutMenuClick(this.checkBtnlist)
            }
            if(this.checkBtnType==5){
                this.lockeClick()
            }
            if(this.checkBtnType==6){
                this.OnDeskClick(this.checkBtnlist)
            }
            
        },
        /**点击大类*/
        onFoodNav(item,index){
            if(item){
                if(!item.FoodSubCategorys._all){
                    item.FoodSubCategorys._all=[];//全部菜品
                    item.FoodSubCategorys.forEach(sub => {
                        if(sub.PosFoods && sub.PosFoods.length>0)
                        item.FoodSubCategorys._all=item.FoodSubCategorys._all.concat(sub.PosFoods)
                    });
                }
                this.foodSubCategorys=item.FoodSubCategorys;
                this.mainCategoryIndex=index;
                this.onFoodSubNav();
            }else if(this.foodMainCategory && this.foodMainCategory._all){//全部
                this.foodSubCategorys=this.foodMainCategory._all;
                this.mainCategoryIndex=0;
                this.onFoodSubNav();
            }
            this.oldVarietySelect="";
        },
        /**点击小类*/
        onFoodSubNav(item,index){
            if(item){
                this.posFoods=item.PosFoods;
                this.subCategoryIndex=index;
            }else if(this.foodSubCategorys && this.foodSubCategorys._all){//全部
                this.posFoods=this.foodSubCategorys._all;
                this.subCategoryIndex=0
            }
            this.varietySelect="";
            this.oldVarietySelect="";
        },
        /**点击菜品,添加到数组中 */
        onPlaceFoods(item){
            console.log('item:',item)
            if(this.$cacheData.Numberkey.getNumberKeyOff()==false){
                this.orderNumber=''
                this.orderNumberType=-1
            }
            let _RemainNum=0
            this.assistSizeInfo={}
            if(this.DeskLocked==true && this.lockEqId!= config.UUID ){
                this.$alert('桌台已锁定,无法调整', '提示', {confirmButtonText: '确定',});
                return
            }
            if(item.Is_SoldOut){
                this.$message({message: '该菜品已售完',type: 'warning'});
                return
            }
            if(item.Is_Limited==true){
                if(item.RemainNum==0){
                    if(item.Is_LimitedProject){
                        this.$message({message: '该菜品已售完',type: 'warning'});
                        return;
                    }
                }else{
                    this.orderMenuList.map(order=>{
                        if(order.EAT_XFBMID==item.Food_ID && order.Eat_Number>=1 && order.Food_Status==-1){
                            _RemainNum = _RemainNum+order.Eat_Number
                        }
                    })
                    if(this.isUnitWeight(item.Food_Size) && Number(this.wgNum)>0){   
                        if((_RemainNum+Number(this.wgNum))>item.RemainNum){
                            this.$message({message: '不能超过该菜品的剩余数量',type: 'warning'});
                            return
                        }
                    }
                    if(Number(this.orderNumber)>0){
                        if((_RemainNum+Number(this.orderNumber))>item.RemainNum){
                            this.$message({message: '不能超过该菜品的剩余数量',type: 'warning'});
                            return
                        }
                    }else{
                        if(_RemainNum>=item.RemainNum){
                            this.$message({message: '不能超过该菜品的剩余数量',type: 'warning'});
                            return
                        }
                    }
                }
            }

            

            /**判断是否多规格菜品 */
            if(item.Is_MultipleSize){
                if(item.Is_MultipleSize==true){
                    this.unitShow=true;
                    this.$keyavail=true;
                    if(this.multipleSizeFoods.length==0){
                        this.multipleSizeFoods.push(item)
                    }else{
                        let _SizeNum=0
                        this.multipleSizeFoods.map(food=>{
                            if(food.Food_ID==item.Food_ID){
                                _SizeNum=1
                            }
                        })
                        if(_SizeNum==0){
                            this.multipleSizeFoods.push(item)
                        }
                    }
                    this.theMultipleSizeFoods=item;
                    return
                }
            }
            this.unitShow=false;
            //是否是组合菜品
            let comboFood=this.comboFoodList?.find(it=>it.Food_ID==item.Food_ID);
            if(comboFood){
                this.comboFoodInfo=comboFood;
                this.editComboFoodInfo=null;
                if(this.orderNumber>0){
                    this.editComboFoodInfo={
                        Eat_Number:this.orderNumber
                    };
                }
                this.isComboFoodShow=true;
                this.$keyavail=true;
                return;
            }
            /**判断是否是辅助单位菜品 */
            if(item.Food_AssSize){
                if(item.Food_AssSize!='' && this.assSizeShow==false && this.BindPosFoods==''){
                    this.assSizeShow = true
                    this.$keyavail=true
                    this.assistSizeInfo = JSON.parse(JSON.stringify(item))
                    return
                }
            }
            
            /**判断是否是绑定做法菜品 */
            if(item.Is_BindEatMas){
                if(item.Is_BindEatMas==true){
                    if(!this.masKindsList){
                        this.$message.warning('该菜品有默认口味,先请先去后台配置相对应的菜品口味信息');
                        return
                    }else{
                        this.demandShow=true
                        this.$keyavail=true
                        this.BindPosFoods = JSON.parse(JSON.stringify(item))
                        return
                    }
                }
            }
            let _item=''
            this.disRadioCheck = true;
            this.choicePosFoods = item.Food_ID;
            /**是否是套餐 */
            if(item.Is_SetMeal){
                if(!item.SetMealInfo){
                    this.$message.warning('该套餐后台没有搭配对应的菜品');
                    return
                }else{
                    this.tcChangeType=0
                    this.posFoodSetMealInfos=[]
                    this.posFoodSetMealInfos.push(item);
                    this.$keyavail=true;
                    this.setMealShow=true;
                    return
                }
            }else{
                


                this.orderMenuList.map((order,index)=>{
                    if(order.EAT_XFBMID==this.disRadioOrder.EAT_XFBMID && order.Food_Status==-1 && this.unitType==1){
                        this.orderMenuList.splice(index,1)
                        this.unitType=0
                    }
                })
                
                if(_data.MergeSameFoodInOrder==false){
                    _item = this.onPlaceFoodsChildren(item)
                    //setTimeout(()=>{this.$refs.box.scrollTop = this.$refs.box.scrollHeight;},0)
                    this.radioClick(this.orderMenuList.length-1,_item);
                }else{
                    let orderIndex = this.orderMenuList.findIndex(order=>
                        !(order.Eat_TcAutoID_Batch && !order.Eat_TcName) //排除组合菜品
                        && !order.isPromotion2 && order.EAT_XFBMID==item.Food_ID 
                        && order.Food_Status==-1 && order.Eat_MasName=='')
                    if(orderIndex>=0){
                        let order=this.orderMenuList[orderIndex]
                        if(Number(this.orderNumber)>0){
                            order.Eat_Number = this.addNum(Number(this.orderNumber),order.Eat_Number)
                        }else if(this.isUnitWeight(item.Food_Size) && Number(this.wgNum)>0){
                            order.Eat_Number = this.addNum(Number(this.wgNum),order.Eat_Number)
                        }else{
                            order.Eat_Number++
                        }
                        if(item.Food_AssSize){
                            order.AssSizeNumber +=item.AssSizeNumber
                        }
                        this.radioClick(orderIndex,order);
                   }else{
                        _item = this.onPlaceFoodsChildren(item)
                        //setTimeout(()=>{this.$refs.box.scrollTop = this.$refs.box.scrollHeight;},0)
                        this.radioClick(this.orderMenuList.length-1,_item);
                   }
                }
                this.posFoodNumbermethod(1)
                this.orderNumber=''
                this.orderNumberType=-1
                this.wgNum=0
            }
            //选择菜品时记录一下
            if(this.varietySelect) {
                this.oldVarietySelect=this.varietySelect;
            }
            this.varietySelect='';
            this.$keyavail =false
        },
        onPlaceFoodsChildren(item){
            let _item ={
                Eat_ConsumeID:newGuid(),
                SubCategory_ID:item.SubCategory_ID,
                EAT_XFBMID:item.Food_ID,
                EAT_XFCode:item.Food_Code,
                EAT_XFName:item.Food_Name,
                Eat_Number:1,
                Eat_CheckPrice:item.Food_Price,
                ModiPrice:0,
                Eat_SalePrice:item.Food_Price,
                Food_CostPrice:item.Food_CostPrice,//成本金额
                Eat_MasName:'',
                Food_Status:-1,
                Food_AssSize:item.Food_AssSize,
                AssSizeNumber:'',
                Eat_XFSize:item.Food_Size,
                FoodModifys:null,
                FoodSetmeals:null,
                Is_Special:item.Is_Special,//是否是推荐菜
                Is_Discounted:item.Is_Discounted,
                Is_Seasonal:item.Is_Seasonal,
                RemainNum:item.RemainNum,
                Is_Limited:item.Is_Limited,
                Is_ChangedNum:item.Is_ChangedNum,
                Is_ChargeForService:item.Is_ChargeForService,
                Is_SetMeal:item.Is_SetMeal,
                Promotion_Price:0,
                ConsumeOperations:[]};
            //促销价
            if(item?.Promotion_Price || item?.Promotion_Price==0){
                _item.Eat_CheckPrice = item?.Promotion_Price
                _item.Promotion_Price = item?.Promotion_Price
                _item.ConsumeOperations=[{OperateType:5}]
            }
            if(item.Food_AssSize){
                _item.Eat_Number= item.Number
                _item.AssSizeNumber=item.AssSizeNumber
            }
            if(Number(this.orderNumber)>0){
                _item.Eat_Number = Number(this.orderNumber)
            }else if(this.isUnitWeight(item.Food_Size) && Number(this.wgNum)>0){
                _item.Eat_Number = Number(this.wgNum)
            }
            this.orderMenuList.push(_item);
            return _item
        },
        /**辅助单位选择返回 */
        assSizeConfirm(assSizeInfo,priceNum,assSizeNum){
            assSizeInfo = Object.assign(assSizeInfo, {Number:priceNum,AssSizeNumber:Number(assSizeNum)})
            this.onPlaceFoods(assSizeInfo)
            this.assSizeShow=false
            this.posFoodNumbermethod(5)
            this.$keyavail=false
        },
        /**自定义菜品返回,添加到数组中 */
        customConfirm(customInfo,type){
            let _item ={
                Eat_ConsumeID:newGuid(),
                SubCategory_ID:customInfo.SubCategory_ID,
                EAT_XFBMID:customInfo.Food_ID,//EAT_XFBMID:newGuid(),
                EAT_XFCode:customInfo.Food_Code,
                EAT_XFName:customInfo.Food_Name,
                Eat_Number:Number(customInfo.Eat_Number),
                Eat_CheckPrice:Number(customInfo.Food_Price),
                ModiPrice:0,
                Eat_SalePrice:Number(customInfo.Food_Price),
                Food_CostPrice:customInfo?.Food_CostPrice,//成本金额
                Eat_MasName:customInfo.Eat_MasName,
                Food_Status:-1,
                Eat_XFSize:customInfo.Food_Size,
                FoodModifys:customInfo.FoodModifys,
                FoodSetmeals:null,
                Is_Discounted:customInfo.Is_Discounted,
                Is_Seasonal:customInfo.Is_Seasonal,
                RemainNum:customInfo.RemainNum,
                Is_Limited:customInfo.Is_Limited,
                Is_ChangedNum:customInfo.Is_ChangedNum,
                Is_ChargeForService:customInfo.Is_ChargeForService,
                Is_SetMeal:customInfo.Is_SetMeal,
                is_custom_ID:newGuid(),
                AssSizeNumber:0
            };
            this.orderMenuList.push(_item);
            
            this.radioClick(this.orderMenuList.length-1,_item);
            this.confirm(_item.FoodModifys,_item,'')
            if(type==0){
                this.customDishesShow=false
                this.subShow = false
                this.$keyavail =false
            }
        },
        /**菜单选择菜品 */
         radioClick(index,item){
            if(item){
                this.foodSetmealsclass=-1
                this.disRadioOrder =item
                this.foodtype = 0
                this.choicefoodOrder=''
                this.disRadioclass=-1;
                this.disRadioclass=index;
            }else{
                this.disRadioOrder={}
                this.disRadioclass=-1
            }
            this.$nextTick(()=>{
                this.$refs.orderMenu?.scrollRow();
            })
        },
        /**下拉 */
        xialadianji(item,index){
            this.packageShow = index
            if(this.dianji==true && this.eatXFBMID!=item.Eat_ConsumeID){
                this.dianji=true
            }else{
                this.dianji=!this.dianji
            }
            this.eatXFBMID = item.Eat_ConsumeID
        },
        /**套餐下菜品点击 */
        EatTcClick(food,index,item){
            this.disRadioOrder = food;
            this.choicefoodOrder=item;
            this.foodSetmealsclass=index;
            this.foodtype=1;
            this.orderMenuList.forEach((order,i)=>{
                if(order.FoodSetmeals){
                    order.FoodSetmeals.forEach(fd=>{
                        if(fd.FoodSetmeal_ID==food.FoodSetmeal_ID){
                            //this.foodSetmealsclass=-1
                            this.disRadioclass=-1;
                            this.disRadioclass=i;
                        }
                    })
                }
            })
        },
        /**修改会员信息返回 */
        updateCardClick(data){
            this.openshow=false
            this.$keyavail = false
            this.orderInfo.Eat_ManNum = data.ResponseBody.Eat_ManNum;
            this.orderInfo.Feast_DeskNum = data.ResponseBody.Feast_DeskNum;
            this.orderInfo.Eat_WaiterName = data.ResponseBody.Eat_WaiterName;
            this.orderInfo.Order_Remark = data.ResponseBody.Order_Remark;
            this.orderInfo.Taste = data.ResponseBody.Taste;
            this.orderInfo.Timestamps = data.ResponseBody.Timestamps;
            this.orderInfo.CHANNEL_ID = data.ResponseBody.CHANNEL_ID
            this.orderInfo.Member_CardNo = data.ResponseBody.Member_CardNo
            this.orderInfo.Consume_TypeID = data.ResponseBody.Consume_TypeID
            this.orderInfo.Eat_Saler = data.ResponseBody.Eat_Saler
            this.orderInfo.Mobile = data.ResponseBody.Mobile
            this.orderInfo.Member_CardKind = data.ResponseBody.Member_CardKind
            this.orderInfo.Member_Name = data.ResponseBody.Member_Name
            this.diningChannel(this.orderInfo.CHANNEL_ID)
        },
        /**套餐返回 */
        setMealClick(setMealNum,posFoodSetMeal){
            /**1.套餐换菜返回,清理掉之前的 */
            if(this.tcChangeType==1){
                this.clickDelete(this.disRadioclass,this.disRadioOrder)
            }
            this.setMealShow = false;
            this.$keyavail=false
            let _FoodSetmeals=[];
            let _setNumber= setMealNum;
            this.orderNumber = setMealNum;

            let foodList=[];//记录套餐明细 口味做法
            /**添加到订单中 */
            posFoodSetMeal.SetMealInfo.SetMealKinds.map(item=>{
                item.SetMealDetails.map(food=>{
                    if(food.Is_Selected){
                        if(posFoodSetMeal?.Is_SetMealCountAvgPrice){
                            _setNumber = 1
                        }
                        let eatConsume={
                            SubCategory_ID:food.SubCategory_ID,
                            FoodSetmeal_ID:food.Food_ID,
                            FoodSetmeal_Code:food.Food_Code,
                            FoodSetmeal_Name:food.Food_Name,
                            FoodSetmeal_Number:food.Food_Number * _setNumber,
                            FoodSetmeal_Price:food.Food_TcPrice,
                            FoodSetmeal_Size:food.Food_Size,
                            FoodSaleMoney:food.Food_TcPrice,
                            Food_CostPrice:food?.Food_CostPrice,
                            FoodModifys:null,
                            Eat_MasName:"",
                            ModiPrice:0,
                            Food_Status:-1,
                            Eat_ConsumeID:newGuid()
                        }
                        _FoodSetmeals.push(eatConsume)

                        if(food._FoodModifys){
                            foodList.push({
                                item:eatConsume,
                                FoodModifys:food._FoodModifys
                            });
                        }
                    }
                })
            })
            let _item={
                SubCategory_ID:posFoodSetMeal.SubCategory_ID,
                EAT_XFBMID:posFoodSetMeal.Food_ID,
                EAT_XFCode:posFoodSetMeal.Food_Code,
                EAT_XFName:posFoodSetMeal.Food_Name,
                Eat_Number:setMealNum,
                Eat_CheckPrice:posFoodSetMeal.Food_Price,
                ModiPrice:0,
                Eat_SalePrice:posFoodSetMeal.Food_Price,
                Food_CostPrice:posFoodSetMeal?.Food_CostPrice,//成本金额
                Eat_MasName:'',
                Food_Status:-1,
                Food_Speed:'',
                Eat_XFSize:posFoodSetMeal.Food_Size,
                FoodModifys:null,
                FoodSetmeals:_FoodSetmeals,
                Food_Type:2,
                Is_Special:posFoodSetMeal.Is_Special,//是否是推荐菜
                Is_Discounted:posFoodSetMeal.Is_Discounted,
                Is_Seasonal:posFoodSetMeal.Is_Seasonal,
                RemainNum:posFoodSetMeal.RemainNum,
                Is_Limited:posFoodSetMeal.Is_Limited,
                Is_ChangedNum:posFoodSetMeal.Is_ChangedNum,
                Is_ChargeForService:posFoodSetMeal.Is_ChargeForService,
                Is_SetMeal:posFoodSetMeal.Is_SetMeal,
                Is_SetMealCountAvgPrice:false, //是否是人均计价套餐
                FoodProperty_Dimension:'套',
                Eat_ConsumeID:newGuid()
            };
            if(posFoodSetMeal?.Is_SetMealCountAvgPrice){
                _item.Is_SetMealCountAvgPrice = posFoodSetMeal?.Is_SetMealCountAvgPrice
            }
            //促销价
            if(posFoodSetMeal?.Promotion_Price){
                _item.Eat_CheckPrice = posFoodSetMeal?.Promotion_Price
                _item.Promotion_Price = posFoodSetMeal?.Promotion_Price
                _item.ConsumeOperations=[{OperateType:5}]
            }
            this.orderMenuList.push(_item);

            /**给套餐明细 添加口味做法 */
            foodList?.forEach(it=>{
                let _opt={disRadioOrder:it.item,optFlavors:it.FoodModifys}
                if(this.aloneMethod.length>0){
                    if(this.aloneMethod.findIndex(alone=>alone.disRadioOrder.Eat_ConsumeID===_opt.disRadioOrder.Eat_ConsumeID)==-1){
                        this.aloneMethod.push(_opt)
                    }
                    this.aloneMethod.some(alone=>{
                        if(alone.disRadioOrder.Eat_ConsumeID==_opt.disRadioOrder.Eat_ConsumeID){
                            alone.optFlavors = it.FoodModifys
                        }
                    })
                }else{
                    this.aloneMethod.push(_opt)
                }
                this.synchMethods(it.FoodModifys,'',it.item);
            })


            this.radioClick(this.orderMenuList.length-1,_item);
            this.posFoodNumbermethod(1)
            this.orderNumber=''
            this.orderNumberType=-1
        },
        /**组合菜品返回
         * @param comboFoodInfo 组合数据
         */
        comboConfirm(comboFoodInfo){
            /**1.组合菜品换菜返回,清理掉之前的 */
            if(this.editComboFoodInfo && this.editComboFoodInfo.Eat_ConsumeID){
                this.foodSetmealsclass=-1
                this.disRadioOrder =this.editComboFoodInfo;
                this.foodtype = 0
                this.choicefoodOrder=''
                this.clickDelete(this.disRadioclass,this.disRadioOrder);
            }
            this.isComboFoodShow = false;
            this.$keyavail=false
            let _setNumber= comboFoodInfo.Food_Number;
            this.orderNumber = _setNumber;

            let Eat_TcAutoID_Batch=newGuid();
            let foodList=[];//记录组合明细 口味做法
            let tcIndex=1;
            let _item=this.getPlaceFood(comboFoodInfo);
            _item.Eat_Number=_setNumber;
            _item.Eat_TcIndex=tcIndex;
            _item.Eat_TcAutoID_Batch=Eat_TcAutoID_Batch;//组id
            
            if(comboFoodInfo._FoodModifys){
                foodList.push({
                    item:_item,
                    FoodModifys:comboFoodInfo._FoodModifys
                });
            }
            this.orderMenuList.push(_item);

            /**添加到订单中 */
            comboFoodInfo.ComboKinds.forEach(kind=>{
                kind.ComboKindFoods.forEach(food=>{
                    if(food.Is_Selected){
                        let eatConsume=this.getPlaceFood(food);
                        eatConsume.Kind_Name=kind.Kind_Name;
                        eatConsume.Eat_Number=food.Food_Number;
                        eatConsume.Eat_TcIndex=++tcIndex;
                        eatConsume.Eat_TcAutoID_Batch=Eat_TcAutoID_Batch;//组id
                        this.orderMenuList.push(eatConsume);
                        if(food._FoodModifys){
                            foodList.push({
                                item:eatConsume,
                                FoodModifys:food._FoodModifys
                            });
                        }
                    }
                })
            })

            /**给明细 添加口味做法 */
            foodList?.forEach(it=>{
                let _opt={disRadioOrder:it.item,optFlavors:it.FoodModifys}
                if(this.aloneMethod.length>0){
                    if(this.aloneMethod.findIndex(alone=>alone.disRadioOrder.Eat_ConsumeID===_opt.disRadioOrder.Eat_ConsumeID)==-1){
                        this.aloneMethod.push(_opt)
                    }
                    this.aloneMethod.some(alone=>{
                        if(alone.disRadioOrder.Eat_ConsumeID==_opt.disRadioOrder.Eat_ConsumeID){
                            alone.optFlavors = it.FoodModifys
                        }
                    })
                }else{
                    this.aloneMethod.push(_opt)
                }
                this.synchMethods(it.FoodModifys,'',it.item);
            })


            this.radioClick(this.orderMenuList.length-1,_item);
            this.posFoodNumbermethod(1)
            this.orderNumber=''
            this.orderNumberType=-1
        },
         /**返回一个添加单品的数据 */
        getPlaceFood(item){
            let _item ={
                Eat_ConsumeID:newGuid(),
                Food_Type:1,
                SubCategory_ID:item.SubCategory_ID,
                SubCategory_Code:item.SubCategory_Code,
                EAT_XFBMID:item.Food_ID,
                EAT_XFCode:item.Food_Code,
                EAT_XFName:item.Food_Name,
                Eat_Number:1,
                Eat_CheckPrice:item.Food_Price,
                ModiPrice:0,
                Eat_SalePrice:item.Food_Price,
                Eat_VipPrice:item.Food_VipPrice,
                Eat_MasName:'',
                Food_Status:-1,
                Food_AssSize:item.Food_AssSize,
                AssSizeNumber:'',
                Eat_XFSize:item.Food_Size,
                FoodModifys:null,
                FoodSetmeals:null,
                Is_Special:item.Is_Special,//是否是推荐菜
                Is_Discounted:item.Is_Discounted,
                Is_Seasonal:item.Is_Seasonal,
                RemainNum:item.RemainNum,
                Is_Limited:item.Is_Limited,
                Is_ChangedNum:item.Is_ChangedNum,
                Is_ChargeForService:item.Is_ChargeForService,
                Is_SetMeal:false,
                Promotion_Price:0,
                Exchanged_Number:0,
                ConsumeOperations:[]
            };
            //促销价
            if(item?.Promotion_Price){
                _item.Eat_CheckPrice = item?.Promotion_Price;
                _item.Promotion_Price = item?.Promotion_Price;
                _item.ConsumeOperations=[{OperateType:5}];
            }
            if(item.Food_AssSize){
                _item.Eat_Number= item.Number||0;
                _item.AssSizeNumber=item.AssSizeNumber||0;
            }
            return _item;
        },
        /**即配套餐新增返回 */
        readyPackageReturn(DiyTcInfo){
            this.readyShow = false
            this.subShow = false
            this.$keyavail = false
            let DiyType=0
            if(DiyTcInfo.FoodModifys?.length==0){
                DiyTcInfo.FoodModifys=null;
            }
            DiyTcInfo.FoodSetmeals.forEach(food=>{
                if(food.FoodModifys?.length==0){
                    food.FoodModifys=null
                }
            })
            this.orderMenuList.some((order,index)=>{
                if(order.Eat_ConsumeID==DiyTcInfo.Eat_ConsumeID){
                    this.orderMenuList[index] = DiyTcInfo
                    DiyType=1
                    return true
                }
            })
            if(DiyType==0){
                this.orderMenuList.push(DiyTcInfo);
                if(DiyTcInfo.FoodSetmeals.length>0){
                    DiyTcInfo.FoodSetmeals.map(food=>{
                        if(food.FoodModifys){
                            /**把口味添加到菜单 */
                            food.FoodModifys.map(item=>{
                                if(item.Is_AddMoney==true){
                                    //按量加价
                                    if(item.Price_Kind==0){
                                        item.Mas_Num = food.FoodSetmeal_Number
                                    }
                                    let param={
                                        Eat_ConsumeID:food.Eat_ConsumeID,
                                        Mas_AutoID:item.Mas_AutoID,
                                        EAT_XFBMID:DiyTcInfo.EAT_XFBMID,
                                        EAT_XFCode:DiyTcInfo.EAT_XFCode,
                                        EAT_XFName:item.Mas_Name+'@'+item.Mas_Price,
                                        Eat_Number:food.FoodSetmeal_Number,
                                        Eat_CheckPrice:item.Mas_Price,
                                        ModiPrice:0,
                                        Food_Status:-1,
                                        Is_Discounted:DiyTcInfo.Is_Discounted,
                                        Is_Seasonal:DiyTcInfo.Is_Seasonal,
                                        RemainNum:DiyTcInfo.RemainNum,
                                        Is_Limited:DiyTcInfo.Is_Limited,
                                        Is_ChangedNum:DiyTcInfo.Is_ChangedNum,
                                        Is_ChargeForService:DiyTcInfo.Is_ChargeForService,
                                        Is_SetMeal:DiyTcInfo.Is_SetMeal,
                                        is_taste:true,
                                        IsAllRequired:0,
                                        Price_Kind:item.Price_Kind
                                    }
                                    this.orderMenuList.push(param);
                                }
                            })
                        }
                    })
                }
            }
            this.quoteAllMasKind(this.orderMenuList,'')
            this.radioClick(this.orderMenuList.length-1,DiyTcInfo);
        },
        /**即配套餐更新返回 */
        readyPackageUpdateReturn(data){
            this.OrderInfoUpdate(data,0)
            this.readyShow = false
            this.subShow = false
            this.$keyavail = false
            this.radioClick(this.disRadioclass,data?.ResponseBody?.Order_ConsumeList[0]);
        },
        /**即配套餐取消*/
        ReadycloseModel(tempTimestamps){
            this.disRadioclass=-1
            this.disRadioOrder={}
            if(tempTimestamps!=this.orderInfo.Timestamps.toString()){
                this.refreshOrder()
            }
            this.readyShow = false
            this.$keyavail =false
        },
        /**套餐换菜返回 */
        changeSetClick(ExchangeData){
            if(ExchangeData!=0){
                this.orderInfo = ExchangeData
                this.orderMenuList = this.orderInfo.Order_ConsumeList
            }
            this.subShow = false
            this.$keyavail=false
            this.cheageSetMealShow = false
        },
        /**厨房点击 */
        clickkitchenShow(){
            let _status=0;
            this.orderMenuList.map(item=>{
                if(item.Food_Status==-1){
                    _status=1;
                }
            })
            if(_status==1){
                this.kitchenShow =true;
                this.$keyavail=true;
            }else{
                this.$alert('没有新点菜品暂时无法调整', '提示', {confirmButtonText: '确定',});
            }
        },
        /**厨房返回 */
        kitchenClick(temporderMenuList){
            let orderMenuList=this.orderMenuList.filter(it=>it.Food_Status==-1);
            temporderMenuList.forEach(item=>{
                if(item.Is_SetMeal==true){//套餐
                    orderMenuList.some(order=>{
                        if(item.Eat_ConsumeID==order.Eat_ConsumeID){
                            order.Food_Speed = item.Food_Speed
                            order.KitSpeed_Dimension = item.Food_Speed
                            if(order.FoodSetmeals!=null){
                                order.Food_Speed = temporderMenuList[0].Food_Speed
                                order.KitSpeed_Dimension = temporderMenuList[0].Food_Speed
                                order.FoodSetmeals.forEach(food=>{
                                    if(item.FoodSetmeal_ID==food.FoodSetmeal_ID){
                                        food = Object.assign(food,{Food_Speed:item.Food_Speed,KitSpeed_Dimension:item.Food_Speed})
                                    }
                                })
                            }
                            return true;
                        }
                        return false;
                    })
                }else{
                    orderMenuList.some(order=>{
                        if(item.Eat_ConsumeID==order.Eat_ConsumeID){
                            if(order.Food_Status==-1){
                                order.Food_Speed = item.Food_Speed;
                                order.KitSpeed_Dimension = item.Food_Speed;
                            }
                            return true;
                        }else{
                            return false;
                        }
                    })
                }
            })
            this.kitchenShow =false;
            this.$keyavail=false;
        },
        /**催菜点击 */
        urgeFoodShowChange(){
            let _status =-1;
            this.orderMenuList.map(item=>{
                if(item.Food_Status<3){
                    _status =0
                }
            })
            if(_status==0){
                this.urgeFoodShow =true;
                this.$keyavail=true;
            }else{
                this.$alert('没有可催菜的菜品', '提示', {confirmButtonText: '确定',});
            }
        },
        /**催菜返回 */
        urgeFoodReturn(data,Order_ConsumeList){
            Order_ConsumeList.map(item=>{
                this.orderMenuList.map(order=>{
                    if(item.Eat_ConsumeID==order.Eat_ConsumeID){
                        order.Food_Speed = item.Food_Speed
                        order.RushFoodNum = item.RushFoodNum
                        order.Food_Status = item.Food_Status
                    }
                })
            })
            this.orderInfo = data.ResponseBody
            this.orderMenuList=JSON.parse(JSON.stringify(data.ResponseBody.Order_ConsumeList))
            this.urgeFoodShow = false;
            this.$keyavail=false;
        },
        /**菜单要求 */
        clickDemandShow(){
            if(this.disRadioclass>-1){
                if(this.disRadioOrder.Food_Status==-1){
                    if(!this.masKindsList){
                         this.$message.warning('请先去后台配置相对应的菜品口味信息');
                    }else{
                        this.demandShow=true
                        this.$keyavail=true
                    }
                }else{
                    this.$alert('该菜品已下单暂时无法调整', '提示', {confirmButtonText: '确定',});
                }
            }else{
                this.$alert('请先选择左边的菜品', '提示', {confirmButtonText: '确定',});
            }
        },
        /**全单点击 */
        clickDemandFullShow(){
            let _status=0;
            this.orderMenuList.map(item=>{
                if(item.Food_Status==-1){
                    _status=1;
                }
            })
            if(_status==1){
                if(this.masKindsList){
                    this.demandFullShow=true
                    this.$keyavail=true
                }else{
                    this.$message.warning('请先去后台配置相对应的菜品口味信息');
                }
            }else{
                this.$alert('没有新点菜品暂时无法调整', '提示', {confirmButtonText: '确定',});
            }
        },
        /**增加数量 */
        clickNumAdd(index,item,adType){
            if(this.foodtype==1){
                this.$alert('套餐菜品不允许单独改量', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.disRadioOrder?.Is_Combo){
                this.$alert('组合菜品下的菜品不允许单独改量', '提示', {confirmButtonText: '确定',});
                return
            }
            if(index!=null){
                if(item.Food_Status>=0){
                    this.$alert('该菜品已下单暂时无法调整', '提示', {confirmButtonText: '确定',});
                }else{
                    /**判断是否是辅助单位菜品 */
                    if(this.orderMenuList[index].Food_AssSize){
                        if(adType==null){
                            this.adjusquantityShow=true;
                            return
                        }
                    }
                    //this.orderMenuList[index].Eat_Number++;
                    this.orderMenuList[index].Eat_Number = this.addNum(1,this.orderMenuList[index].Eat_Number)
                    if(this.orderMenuList[index].FoodModifys){
                        this.orderMenuList[index].FoodModifys.map(item=>{
                            if(item.Price_Kind==0){
                                item.Mas_Num = this.addNum(1,item.Mas_Num)
                            }
                        })
                    }
                    this.posFoodNumbermethod(1,true)
                }
            }else{
                if(this.disRadioclass>-1){
                    if(this.disRadioOrder.Food_Status!=-1){
                        this.$alert('该菜品已下单暂时无法调整', '提示', {confirmButtonText: '确定',});
                    }else{
                        /**判断是否是辅助单位菜品 */
                        if(this.orderMenuList[this.disRadioclass].Food_AssSize){
                            if(adType==null){
                                this.adjusquantityShow=true;
                                return
                            }
                        }
                        let EatNumber= this.orderMenuList[this.disRadioclass].Eat_Number
                        let _EATXFBMID = this.orderMenuList[this.disRadioclass].EAT_XFBMID
                        let Limited = '',RemainNum=0
                        this.orderMenuList.map(order=>{
                            if(order.EAT_XFBMID==_EATXFBMID && !order?.is_taste){
                                if(order.Is_Limited==true){
                                    RemainNum += order.Eat_Number
                                    if(order.RemainNum!=0){
                                        if(RemainNum>=order.RemainNum){
                                            Limited='已超过菜品的最大剩余数量'
                                        }
                                    }
                                }
                            }
                        })
                        if(Limited!=''){    
                            this.$message.error(Limited);
                            return
                        }
                        this.FoodModifysNumberAdd(EatNumber)
                        this.posFoodNumbermethod(1,true)
                    }
                }else{
                    this.$alert('请先选择左边的菜品', '提示', {confirmButtonText: '确定',});
                }
            }
        },
        /**口味要求同步增加数量 */
        FoodModifysNumberAdd(EatNumber){
            /**选择的口味 */
            this.orderMenuList = JSON.parse(JSON.stringify(this.orderMenuList))
            if(this.disRadioOrder?.is_taste){
                this.orderMenuList[this.disRadioclass].Eat_Number++
                this.orderMenuList.map(order=>{
                    if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID && !order?.is_taste){
                        if(order.FoodModifys){
                            order.FoodModifys.map(item=>{
                                if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID){
                                    item.Mas_Num=this.addNum(1,item.Mas_Num)
                                }
                            })
                        }
                        /**有套餐 && order?.Is_SetMealCountAvgPrice==false*/
                        if(order.FoodSetmeals){
                            order.FoodSetmeals.map(food=>{
                                if(food.FoodModifys){
                                    food.FoodModifys.map(item=>{
                                        if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID && food.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                            item.Mas_Num=this.addNum(1,item.Mas_Num)
                                        }
                                    })
                                }
                            })
                        }
                    }else{
                        if(order.FoodSetmeals){
                            order.FoodSetmeals.map(food=>{
                                if(food.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                    if(food.FoodModifys){
                                        food.FoodModifys.map(item=>{
                                            if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID){
                                                item.Mas_Num=this.addNum(1,item.Mas_Num)
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }
                })
            }else{
                /**普通菜品数量调整 */
                if(this.foodtype==0){
                    this.orderMenuList.map(order=>{
                        if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                            if(order?.Price_Kind!=1){
                                order.Eat_Number=this.addNum(1,order.Eat_Number)
                            }
                            if(order?.FoodModifys){
                                order.FoodModifys.map(item=>{
                                    if(item.Price_Kind==0){
                                        item.Mas_Num=this.addNum(1,item.Mas_Num)
                                    }
                                })
                            }
                            /**有套餐 */
                            if(order?.FoodSetmeals && order?.Is_SetMealCountAvgPrice==false){
                                order.FoodSetmeals.map(food=>{
                                    let num=1
                                    if(food.FoodSetmeal_Number>1 && food.FoodSetmeal_Number>= EatNumber){
                                        num = food.FoodSetmeal_Number / EatNumber
                                        food.FoodSetmeal_Number = Number(Number(Number(food.FoodSetmeal_Number)+Number(num)).toFixed(2))
                                    }else{
                                        food.FoodSetmeal_Number=this.addNum(1,food.FoodSetmeal_Number)
                                    }
                                    if(food.FoodModifys){
                                        let orNumber=0
                                        this.orderMenuList.map(or=>{
                                            if(or.Eat_ConsumeID==food.Eat_ConsumeID){
                                                if(or.Price_Kind==0 && or.Eat_Number<=food.FoodSetmeal_Number){
                                                    or.Eat_Number = food.FoodSetmeal_Number
                                                    orNumber = or.Eat_Number
                                                }
                                                else{
                                                    if(or.Price_Kind==0){
                                                        or.Eat_Number= or.Eat_Number + num
                                                        orNumber = or.Eat_Number
                                                    }
                                                }
                                            }
                                        })
                                        food.FoodModifys.map(mod=>{
                                            if(mod.Price_Kind==0){
                                                mod.Mas_Num =orNumber
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    })
                }else if(this.foodtype==1){
                    this.orderMenuList.map(order=>{
                        if(order.Eat_ConsumeID==this.choicefoodOrder.Eat_ConsumeID){
                            if(order?.is_taste){
                                if(order.Price_Kind==0){
                                    order.Eat_Number=this.addNum(1,order.Eat_Number)
                                    if(order.FoodModifys){
                                        order.FoodModifys.map(item=>{
                                            if(item.Price_Kind==0){
                                                item.Mas_Num=this.addNum(1,item.Mas_Num)
                                            }
                                        })
                                    }
                                }
                            }else{
                                order.Eat_Number=this.addNum(1,order.Eat_Number)
                                if(order.FoodModifys){
                                    order.FoodModifys.map(item=>{
                                        if(item.Price_Kind==0){
                                            item.Mas_Num=this.addNum(1,item.Mas_Num)
                                        }
                                    })
                                }
                            }
                            if(order.FoodSetmeals && order?.Is_SetMealCountAvgPrice==false){
                                order.FoodSetmeals.map(food=>{
                                    if(food.FoodSetmeal_Number>1 && food.FoodSetmeal_Number>= order.Eat_Number){
                                        let num = food.FoodSetmeal_Number / (order.Eat_Number-1)
                                        food.FoodSetmeal_Number = Number(food.FoodSetmeal_Number)+ Number(num)
                                    }else{
                                        food.FoodSetmeal_Number=this.addNum(1,food.FoodSetmeal_Number)
                                    }
                                    if(food.FoodModifys){
                                        food.FoodModifys.map(item=>{
                                            if(item.Price_Kind==0){
                                                if(Number(food.FoodSetmeal_Number)>(Number(order.Eat_Number) -1)){
                                                    let masNum = Number(food.FoodSetmeal_Number) / Number(order.Eat_Number)
                                                    item.Mas_Num = Number(item.Mas_Num) + masNum
                                                }else{
                                                    item.Mas_Num=this.addNum(1,item.Mas_Num)
                                                }
                                                this.orderMenuList.map(or=>{
                                                    if(or.Eat_ConsumeID==food.Eat_ConsumeID && or?.is_taste==true){
                                                        or.Eat_Number = item.Mas_Num
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        }
                        // else{
                        //     this.orderFoodSyn(order,1)
                        // }
                    })
                }
            }
        },
        /**订单口味数量同步 */
        orderFoodSyn(order,type){
            if(order?.is_taste){
                if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                    if(order?.Price_Kind==0){
                        if(type==1){
                            order.Eat_Number++
                        }else{
                            order.Eat_Number = this.accSub(1,order.Eat_Number);
                        }
                    }
                }
            }
        },
        /**减少数量 */
        clickNumReduce(index,item,adType){
            if(this.foodtype==1){
                this.$alert('套餐菜品不允许单独改量', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.disRadioOrder?.Is_Combo){
                this.$alert('组合菜品下的菜品不允许单独改量', '提示', {confirmButtonText: '确定',});
                return
            }
            if(index!=null){
                if(item.Food_Status>=0){
                    this.$alert('该菜品已下单暂时无法调整', '提示', {confirmButtonText: '确定',});
                }else{
                    if(this.orderMenuList[index].Eat_Number>1){
                            /**判断是否是辅助单位菜品 */
                            if(this.orderMenuList[index].Food_AssSize){
                                if(adType==null){
                                    this.adjusquantityShow=true;
                                    return
                                }
                            }
                            this.orderMenuList[index].Eat_Number=this.accSub(1,this.orderMenuList[index].Eat_Number);
                            if(this.orderMenuList[index].FoodModifys){
                                this.orderMenuList[index].FoodModifys.map(item=>{
                                    if(item.Price_Kind==0){
                                        item.Mas_Num=this.accSub(1,item.Mas_Num);
                                    }
                                })
                            }
                            this.posFoodNumbermethod(2)
                        }
                }
            }else{
                if(this.disRadioclass>-1){
                    if(this.disRadioOrder.Food_Status!=-1){
                            this.$alert('该菜品已下单暂时无法调整', '提示', {confirmButtonText: '确定',});
                    }else{
                        this.orderMenuList = JSON.parse(JSON.stringify(this.orderMenuList))
                        if(this.orderMenuList[this.disRadioclass].Eat_Number>1){
                            /**判断是否是辅助单位菜品 */
                            if(this.orderMenuList[this.disRadioclass].Food_AssSize){
                                if(adType==null){
                                    this.adjusquantityShow=true;
                                    return
                                }
                            }
                            let EatNumber= this.orderMenuList[this.disRadioclass].Eat_Number
                            /**选择的口味 */
                            if(this.disRadioOrder?.is_taste){
                                this.orderMenuList[this.disRadioclass].Eat_Number=this.accSub(1,this.orderMenuList[this.disRadioclass].Eat_Number);
                                this.orderMenuList.map(order=>{
                                    if(order.FoodModifys){
                                        order.FoodModifys.map(item=>{
                                            if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID && order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                                item.Mas_Num=this.accSub(1,item.Mas_Num);
                                            }
                                        })
                                    }
                                    if(order.FoodSetmeals){
                                        order.FoodSetmeals.map(food=>{
                                            if(food.FoodModifys){
                                                food.FoodModifys.map(item=>{
                                                    if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID && food.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                                        item.Mas_Num=this.accSub(1,item.Mas_Num);
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            }else{
                                /**选的普通菜品 */
                                if(this.foodtype==0){
                                    this.orderMenuList.map(order=>{
                                        /**普通菜品调整 */
                                        if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                            if(order.Price_Kind!=1){
                                                if(order.Eat_Number>1){
                                                    order.Eat_Number=this.accSub(1,order.Eat_Number);
                                                }
                                            }
                                            /**有口味 */
                                            if(order.FoodModifys){
                                                order.FoodModifys.map(item=>{
                                                    if(item.Price_Kind==0){
                                                        if(item.Mas_Num>1){
                                                            item.Mas_Num=this.accSub(1,item.Mas_Num);
                                                        }
                                                    }
                                                })
                                            }
                                            if(order.FoodSetmeals && order?.Is_SetMealCountAvgPrice==false){
                                                order.FoodSetmeals.map(food=>{
                                                    let num=1
                                                    if(food.FoodSetmeal_Number>1 && food.FoodSetmeal_Number>= EatNumber){
                                                        num = food.FoodSetmeal_Number / EatNumber
                                                        food.FoodSetmeal_Number = this.accSub(Number(Number(num).toFixed(2)),food.FoodSetmeal_Number)
                                                    }else{
                                                       food.FoodSetmeal_Number = this.accSub(num,food.FoodSetmeal_Number)
                                                    }
                                                    if(food.FoodModifys){
                                                        let orNumber=0
                                                        this.orderMenuList.map(or=>{
                                                            if(or.Eat_ConsumeID==food.Eat_ConsumeID){
                                                                if(or.Price_Kind==0 && or.Eat_Number<=food.FoodSetmeal_Number){
                                                                    or.Eat_Number = food.FoodSetmeal_Number
                                                                    orNumber = or.Eat_Number
                                                                }
                                                                else{
                                                                    if(or.Price_Kind==0){
                                                                        or.Eat_Number = this.accSub(num,or.Eat_Number)
                                                                        orNumber = or.Eat_Number
                                                                    }
                                                                }
                                                            }
                                                        })
                                                        food.FoodModifys.map(item=>{
                                                            if(item.Price_Kind==0){
                                                                item.Mas_Num =orNumber
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        }
                                    })
                                }else if(this.foodtype==1){/**选的套餐菜品 */
                                    this.orderMenuList.map(order=>{
                                        if(order.Eat_ConsumeID==this.choicefoodOrder.Eat_ConsumeID){
                                            if(Number(order.Eat_Number)>1){
                                                if(order?.is_taste){
                                                    if(order.Price_Kind==0){
                                                        order.Eat_Number =this.accSub(1,order.Eat_Number)
                                                    }
                                                }else{
                                                    order.Eat_Number =this.accSub(1,order.Eat_Number)
                                                }
                                                if(order.FoodModifys){
                                                    order.FoodModifys.map(item=>{
                                                        if(item.Price_Kind==0){
                                                            item.Mas_Num=this.accSub(1,item.Mas_Num)
                                                        }
                                                    })
                                                }
                                                if(order.FoodSetmeals && order?.Is_SetMealCountAvgPrice==false){
                                                    order.FoodSetmeals.map(food=>{
                                                        if(food.FoodSetmeal_Number>=EatNumber){
                                                            let num = food.FoodSetmeal_Number / EatNumber
                                                            food.FoodSetmeal_Number = this.accSub(Number(Number(num).toFixed(2)),food.FoodSetmeal_Number)
                                                        }else{
                                                            food.FoodSetmeal_Number = this.accSub(1,food.FoodSetmeal_Number)
                                                        }
                                                        if(food.FoodModifys){
                                                            let _orNumbers=0
                                                            this.orderMenuList.map((or,index)=>{
                                                                if(or.Eat_ConsumeID==food.Eat_ConsumeID){
                                                                    if(or.Price_Kind==0){
                                                                        _orNumbers = or.Eat_Number
                                                                    }
                                                                }
                                                            })
                                                            food.FoodModifys.map(item=>{
                                                                if(item.Price_Kind==0){
                                                                    item.Mas_Num =food.FoodSetmeal_Number
                                                                    //item.Mas_Num =this.accSub(1,_orNumbers)
                                                                    this.orderMenuList.map(or=>{
                                                                        if(or.Eat_ConsumeID==food.Eat_ConsumeID && or?.is_taste==true){
                                                                            or.Eat_Number = item.Mas_Num
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                            // food.FoodModifys.map(item=>{
                                                            //     if(item.Price_Kind==0){
                                                            //         //let masNum = item.Mas_Num / EatNumber
                                                            //         item.Mas_Num =this.accSub(1,item.Mas_Num)
                                                            //     }
                                                            // })
                                                        }
                                                    })
                                                }
                                            }
                                        }
                                        // else{
                                        //     this.orderFoodSyn(order,2)
                                        // }
                                    })
                                }
                            }
                            this.posFoodNumbermethod(2)
                        }   
                    }
                }else{
                    this.$alert('请先选择左边的菜品', '提示', {confirmButtonText: '确定',});
                }
            }
        },
        /**减法函数 */
        accSub(arg1,arg2){ 
             var r1,r2,m,n;
            try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
            try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
            m=Math.pow(10,Math.max(r1,r2));
            //动态控制精度长度
            n=(r1>=r2)?r1:r2;
            return  Number(((arg2*m-arg1*m)/m).toFixed(n));
        },

        /**数量*/
        clickQuantity(){
            if(this.foodtype==1){
                this.$alert('套餐菜品不允许单独改量', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.disRadioOrder?.Is_Combo){
                this.$alert('组合菜品下的菜品不允许单独改量', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.disRadioclass>-1){
                if(this.disRadioOrder.Food_Status!=-1){
                    this.$alert('该菜品已下单暂时无法调整', '提示', {confirmButtonText: '确定',});
                }else{
                    this.adjusquantityShow = true;
                    this.$keyavail=true;
                }
            }else{
                this.$alert('请先选择左边的菜品', '提示', {confirmButtonText: '确定',});
            }
        },
        /**数量返回 */
        quantityReturn(number,assNumber){
            this.adjusquantityShow = false;
            this.$keyavail=false;
            /**选择的口味 */
            if(this.disRadioOrder?.is_taste){
                this.orderMenuList[this.disRadioclass].Eat_Number=number
                this.orderMenuList.map(order=>{
                    if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID && !order?.is_taste){
                        if(order.FoodModifys){
                            order.FoodModifys.map(item=>{
                                if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID){
                                    item.Mas_Num=number
                                }
                            })
                        }
                        /**有套餐 && order?.Is_SetMealCountAvgPrice==false*/
                        if(order.FoodSetmeals){
                            order.FoodSetmeals.map(food=>{
                                if(food.FoodModifys){
                                    food.FoodModifys.map(item=>{
                                        if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID){
                                            item.Mas_Num=number
                                        }
                                    })
                                }
                            })
                        }
                    }else{
                        //&& order?.Is_SetMealCountAvgPrice==false
                        if(order.FoodSetmeals){
                            order.FoodSetmeals.map(food=>{
                                if(food.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                    if(food.FoodModifys){
                                        food.FoodModifys.map(item=>{
                                            if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID){
                                                item.Mas_Num=number
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }
                })
            }else{
                /**普通菜品数量调整 */
                if(this.foodtype==0){
                    this.orderMenuList.map(order=>{
                        if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                            /**有套餐 */
                            if(order.FoodSetmeals && order?.Is_SetMealCountAvgPrice==false){
                                order.FoodSetmeals.map(food=>{
                                    if(order.Eat_Number>1){
                                        food.FoodSetmeal_Number = food.FoodSetmeal_Number / order.Eat_Number
                                        food.FoodSetmeal_Number = Number(Number(Number(food.FoodSetmeal_Number) * number).toFixed(2))
                                    }else{
                                        food.FoodSetmeal_Number= Number(Number(Number(food.FoodSetmeal_Number) * number).toFixed(2))
                                    }
                                    if(food.FoodModifys){
                                        food.FoodModifys.map(item=>{
                                            if(item.Price_Kind==0){
                                                item.Mas_Num=food.FoodSetmeal_Number
                                            }
                                        })
                                        this.orderMenuList.map(or=>{
                                            if(or.Eat_ConsumeID==food.Eat_ConsumeID){
                                                if(or.Price_Kind==0){
                                                    or.Eat_Number=food.FoodSetmeal_Number
                                                }
                                            }
                                        })
                                    }
                                })
                            }
                            if(order.Price_Kind!=1){
                                order.Eat_Number=number;
                            }
                            if(order.FoodModifys){
                                order.FoodModifys.map(item=>{
                                    if(item.Price_Kind==0){
                                        item.Mas_Num=number
                                    }
                                })
                            }
                        }
                    })
                }else if(this.foodtype==1){
                    this.orderMenuList.map(order=>{
                        if(order.Eat_ConsumeID==this.choicefoodOrder.Eat_ConsumeID){
                            if(order?.is_taste){
                                if(order.Price_Kind==0){
                                    order.Eat_Number++
                                    if(order.FoodModifys){
                                        order.FoodModifys.map(item=>{
                                            if(item.Price_Kind==0){
                                                item.Mas_Num=number
                                            }
                                        })
                                    }
                                }
                            }else{
                                order.Eat_Number=number
                                if(order.FoodModifys){
                                    order.FoodModifys.map(item=>{
                                        if(item.Price_Kind==0){
                                            item.Mas_Num=number
                                        }
                                    })
                                }
                            }
                            if(order.FoodSetmeals && order?.Is_SetMealCountAvgPrice==false){
                                order.FoodSetmeals.map(food=>{
                                    food.FoodSetmeal_Number=number
                                    if(food.FoodModifys){
                                        food.FoodModifys.map(item=>{
                                            if(item.Price_Kind==0){
                                                item.Mas_Num=number
                                            }
                                        })
                                    }
                                })
                            }
                        }else{
                           if(order?.is_taste){
                                if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                    if(order?.Price_Kind==0){
                                            order.Eat_Number = number
                                    }
                                }
                            }
                        }
                    })
                }
            }
            if(this.orderMenuList[this.disRadioclass].Food_AssSize){
                this.orderMenuList[this.disRadioclass].AssSizeNumber = Number(assNumber)
            }
            this.posFoodNumbermethod(5)
        },
        /**单选菜品口味返回 */
        confirm(optFlavors,disRadioOrder,temporderMenuList){
            this.demandShow =false;
            this.$keyavail=false;
            this.synOrderMethodsNum(1)
            /**把之前添加的口味先删除 */
            for(let i=0; i<this.orderMenuList.length; i++){
                if(this.orderMenuList[i].Food_Status==-1){
                    this.orderMenuList[i].Eat_MasName=null
                    this.orderMenuList[i].FoodModifys=[]
                    //如果套餐不为空
                    if(this.orderMenuList[i].FoodSetmeals){
                        for (let j = 0; j < this.orderMenuList[i].FoodSetmeals.length; j++) {
                            this.orderMenuList[i].FoodSetmeals[j].FoodModifys=[]
                            this.orderMenuList[i].FoodSetmeals[j].Eat_MasName=null
                        }
                    }
                    if(this.orderMenuList[i]?.is_taste){
                        this.orderMenuList.splice(i, 1);
                        i--;
                    }
                }
            }
            
            if(this.foodSetmealsclass!=-1){
                this.disRadioOrder = Object.assign(this.disRadioOrder,{foodSetmealsclass:this.foodSetmealsclass})
            }
            let _opt={disRadioOrder:this.disRadioOrder,optFlavors:optFlavors}
            if(this.aloneMethod.length>0){
                if(this.aloneMethod.findIndex(alone=>alone.disRadioOrder.Eat_ConsumeID===this.disRadioOrder.Eat_ConsumeID)==-1){
                    this.aloneMethod.push(_opt)
                }
                this.aloneMethod.some(alone=>{
                    if(alone.disRadioOrder.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                        alone.optFlavors = optFlavors
                    }
                })
            }else{
                this.aloneMethod.push(_opt)
            }
            /**有同步的菜品 */
            if(temporderMenuList){
                this.temporderMethod(temporderMenuList,optFlavors)
            }
            //单独口味添加方法
            this.aloneAddMethods()
            //再去执行全单口味
            this.mallMethodMethods()
            this.synOrderMethodsNum(2)
        },
        aloneAddMethods(){
            if(this.aloneMethod.length==0){
                return
            }
            this.aloneMethod.map(am=>{
                this.synchMethods(am.optFlavors,'',am.disRadioOrder)
                this.TemporderMenuList=[]
            })
        },
        /**同步菜品口味要求做法 */
        temporderMethod(temporderMenuList,optFlavors){
            temporderMenuList.forEach(tm=>{
                if(tm.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                    tm.is_type=0
                }
                //需要添加的菜
                if(tm.is_type==1){
                    let _opt={disRadioOrder:tm,optFlavors:optFlavors}
                    if(this.aloneMethod.findIndex(alone=>alone.disRadioOrder.Eat_ConsumeID===tm.Eat_ConsumeID)==-1){
                        this.aloneMethod.push(_opt)
                    }else{
                        this.aloneMethod.forEach(alone=>{
                            if(alone.disRadioOrder.Eat_ConsumeID==tm.Eat_ConsumeID && tm.is_type==1){
                                if(optFlavors){
                                    optFlavors.forEach(fm=>{
                                        let optype=0
                                        alone.optFlavors.forEach(op=>{
                                            if(op.Mas_AutoID==fm.Mas_AutoID){
                                                optype=1
                                            }
                                        })
                                        if(optype==0){
                                            alone.optFlavors.push(fm)
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            })
        },
        /**同步订单口味要求的数量 */
        synOrderMethodsNum(type){
            if(type==1){
                this.MethodNumList=[]
                this.orderMenuList.forEach(order=>{
                    if(!order?.is_taste){
                        if(order?.FoodModifys){
                            order.FoodModifys.forEach(food=>{
                                let _metNum={Eat_ConsumeID:order.Eat_ConsumeID,Mas_Num:food.Mas_Num,Mas_AutoID:food.Mas_AutoID,is_taste:false,type:1}
                                this.MethodNumList.push(_metNum)
                            })
                        }
                        //套餐
                        order.FoodSetmeals?.forEach(set=>{
                            if(set.FoodModifys){
                                set.FoodModifys.forEach(food=>{
                                    let _metNum={Eat_ConsumeID:set.Eat_ConsumeID,Mas_Num:food.Mas_Num,Mas_AutoID:food.Mas_AutoID,is_taste:false,type:2}
                                    this.MethodNumList.push(_metNum)
                                })
                            }
                        })
                    }else{
                        let _metNum={Eat_ConsumeID:order.Eat_ConsumeID,Mas_Num:order.Eat_Number,Mas_AutoID:order.Mas_AutoID,is_taste:true,type:1}
                        this.MethodNumList.push(_metNum)
                    }
                })
            }
            if(type==2){
                this.orderMenuList.forEach(order=>{
                    if(!order?.is_taste){
                        this.MethodNumList.forEach(met=>{
                            if(met.is_taste==false){
                                if(order?.FoodModifys){
                                    order.FoodModifys.forEach(food=>{
                                        if(order.Eat_ConsumeID==met.Eat_ConsumeID && met.type==1 && food.Mas_AutoID==met.Mas_AutoID){
                                            food.Mas_Num=met.Mas_Num
                                        }
                                    })
                                }
                                //套餐
                                order.FoodSetmeals?.forEach(set=>{
                                    if(set.FoodModifys){
                                        set.FoodModifys.forEach(food=>{
                                            if(set.Eat_ConsumeID==met.Eat_ConsumeID && met.type==2 && food.Mas_AutoID==met.Mas_AutoID){
                                                food.Mas_Num=met.Mas_Num
                                            }
                                        })
                                    }
                                })
                            }else if(met.is_taste==true){
                                //订单里的口味要求
                                if(order.Eat_ConsumeID==met.Eat_ConsumeID && met.type==1 && order.Mas_AutoID==met.Mas_AutoID){
                                    order.Eat_Number=met.Mas_Num
                                }
                            }
                        })
                    }else{
                        this.MethodNumList.map(met=>{
                            if(met.is_taste==true){
                                //订单里的口味要求
                                if(order.Eat_ConsumeID==met.Eat_ConsumeID && met.type==1 && order.Mas_AutoID==met.Mas_AutoID){
                                    order.Eat_Number=met.Mas_Num
                                }
                            }
                        })
                    }
                })
            }
        },
        /**绑定菜品口味做法返回 */
        BindPosconfirm(optFlavors){
            this.BindPosFoods.Is_BindEatMas=false
            this.onPlaceFoods(this.BindPosFoods)
            this.BindPosFoods=''
            this.confirm(optFlavors,this.disRadioOrder,'')
        },
        /**下单前口味添加到菜单 */
        tasteMenu(optFlavors,item,disRadioOrder,IsAllRequired){
            let _item=[]
            if(optFlavors){
                optFlavors.map(num=>{
                    if(num.Is_AddMoney==true){
                        /**把口味添加到菜单 */
                        let param={
                            Eat_ConsumeID:item.Eat_ConsumeID,
                            Mas_AutoID:num.Mas_AutoID,
                            EAT_XFBMID:item.EAT_XFBMID,
                            EAT_XFCode:item.EAT_XFCode,
                            EAT_XFName:num.Mas_Name+'@'+num.Mas_Price,
                            Eat_Number:1,
                            Eat_CheckPrice:num.Mas_Price,
                            ModiPrice:0,
                            Food_Status:-1,
                            Is_Discounted:item.Is_Discounted,
                            Is_Seasonal:item.Is_Seasonal,
                            RemainNum:item.RemainNum,
                            Is_Limited:item.Is_Limited,
                            Is_ChangedNum:item.Is_ChangedNum,
                            Is_ChargeForService:item.Is_ChargeForService,
                            Is_SetMeal:item.Is_SetMeal,
                            is_taste:true,
                            IsAllRequired:IsAllRequired,
                            Price_Kind:num.Price_Kind
                        }
                        if(num.Price_Kind==0){
                            param.Eat_Number=item.Eat_Number
                        }
                        if(disRadioOrder!=''){
                            if(num.Price_Kind==0 && disRadioOrder?.FoodSetmeal_Number){
                                param.Eat_Number=disRadioOrder.FoodSetmeal_Number
                            }
                            param.Eat_ConsumeID = disRadioOrder.Eat_ConsumeID   
                        }
                        _item.push(param);
                    }
                })
            }
            return _item;
        },
        synchMethods(optFlavors,temporderMenuList,disRadioOrder){
            /**同步菜品 */
            this.orderMenuList.forEach((order,index)=>{
                if(!order?.is_taste){
                    let data = this.optFlavorsMap(optFlavors,disRadioOrder)
                    disRadioOrder.Eat_MasName = data._MasName
                    disRadioOrder.FoodModifys = data._foodModifys
                    //disRadioOrder.FoodSetmeal_Price = data._Price
                    disRadioOrder.FoodModifys.map(mod=>{
                        if(mod.Price_Kind==0 && disRadioOrder?.FoodSetmeal_Number){
                            mod.Mas_Num = disRadioOrder.FoodSetmeal_Number
                        }
                    })
                    let tasteMenu = this.tasteMenu(optFlavors,order,disRadioOrder,0)
                    if(tasteMenu.length>0){
                        tasteMenu.forEach(item=>{
                            if(item.Eat_ConsumeID==order.Eat_ConsumeID && !order.is_taste){
                                index++
                                let data = this.optFlavorsMap(optFlavors,order)
                                order.Eat_MasName = data._MasName;
                                order.FoodModifys=data._foodModifys;
                                order.ModiPrice =  data._Price
                                this.orderMenuList.splice(index,0,item)
                            }else{
                                order?.FoodSetmeals?.forEach(food=>{
                                    if(item.Eat_ConsumeID==food.Eat_ConsumeID && !order?.is_taste){
                                        food.Eat_MasName = data._MasName;
                                        food.FoodModifys=data._foodModifys;
                                        food.ModiPrice =  data._Price
                                        index++
                                        this.orderMenuList.splice(index,0,item)
                                    }
                                })
                                
                            }
                        })
                    }else{
                        if(disRadioOrder.Eat_ConsumeID==order.Eat_ConsumeID && !order.is_taste){
                            index++
                            let data = this.optFlavorsMap(optFlavors,order)
                            order.Eat_MasName = data._MasName;
                            order.FoodModifys=data._foodModifys;
                            order.ModiPrice =  data._Price
                        }
                        if(!order.is_taste){
                            order.FoodSetmeals?.forEach(food=>{
                                if(food.Eat_ConsumeID==disRadioOrder.Eat_ConsumeID){
                                    food.Eat_MasName = data._MasName;
                                    food.FoodModifys=data._foodModifys;
                                    food.ModiPrice =  data._Price
                                }
                            })  

                        }
                    }
                }
            })
        },
        /**口味变动放到临时订单集合 */
        tempoptFlavorAdd(item,optFlavorsList,type,temptype){
            if(temptype==true){
                if(item){
                    this.TemporderMenuList.push(item);
                }
            }
            if(optFlavorsList.length>0 && optFlavorsList != undefined){
                optFlavorsList.map(op=>{
                    this.TemporderMenuList.push(op)
                })
            }
            if(type==true){
                this.orderMenuList=this.TemporderMenuList
            }
        },
        /**提取预排菜 */
        GetReserveFoodMethods(){
            let param = {
                Body_ID: newGuid(),
                User_ID: this.userInfo?.User_ID,
                Operator_Name: this.userInfo?.Login_Name, //操作员
                Desk_AutoID: this.posDesks.Desk_AutoID, //桌台ID
            }
            const loading = this.$loading({
                text: "获取预排菜中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            this.$httpAES.post("Bestech.CloudPos.GetReserveOrderDish", param).then((data) => {
                loading.close();
                if (data.ResponseHeader.ResultCode != 0) {
                    this.$message.error(data.ResponseHeader.ResultDesc);
                } else {
                    if(data.ResponseBody?.length<=0){
                        this.$message.warning('本桌台没有预排菜单！');
                        return;
                    }
                    //报错
                    let error=[];
                    let foodArr=[];
                    data.ResponseBody?.forEach(order=>{
                        let posItem=null;
                        this.foodMainCategory?.find(main=>
                            main.FoodSubCategorys?.find(sub=>
                                sub.PosFoods?.some(item=>{
                                    if(item.Food_ID==order.EAT_XFBMID){
                                        posItem=item;
                                        return true;
                                    }
                                    return false;
                                })
                            )
                        );
                        if(posItem){
                            //已被沽清的菜品
                            if(posItem.Is_SoldOut){
                                error.push("【"+posItem.Food_Name+"】已沽清")
                                return true
                            }
                            //是否限量销售
                            if(posItem.Is_Limited){
                                if(posItem.RemainNum==0){
                                    //是否有限量销售方案
                                    if(posItem.Is_LimitedProject){
                                        error.push("【"+posItem.Food_Name+"】已售完")
                                        return;
                                    }
                                }
                            }
                            
                            posItem.Eat_MasName=order.Eat_MasName;
                            //是否存在套餐
                            if(posItem.Is_SetMeal){
                                if(!posItem.SetMealInfo){
                                    error.push("【"+posItem.Food_Name+"】该套餐后台没有搭配对应的菜品")
                                    return ;
                                }
                            }
                            foodArr.push(Object.assign({},posItem,{_Eat_MasName:order.Eat_MasName,_Eat_Number:order.Eat_Number}));
                        }else{
                            error.push("【"+order.EAT_XFName+"】在本店不存在，请核查！")
                        }
                    })
                    
                    if(error.length>0){
                        this.$confirm('预排菜中'+error.join(",")+",是否确认继续！", '提示', { type: 'warning'}).then(() => {
                           this.addReserveFood(foodArr);
                        }).catch(()=>{});
                    }else{
                        this.addReserveFood(foodArr);
                    }
                    
                }
            }).catch((e) => {
                loading.close();
                this.$alert(e.message, "提示", { confirmButtonText: "确定" });
            })
        },
        //添加预排菜菜品
        addReserveFood(foods){
            foods.forEach(pos=>{
                pos.Eat_MasName=pos._Eat_MasName;
                //是否存在套餐
                if(pos.Is_SetMeal){
                    if(pos.SetMealInfo){
                       this.setMealClick(pos._Eat_Number,pos);
                    }
                }
                else{
                    this.addFood(pos,pos._Eat_Number);
                }

                this.optFlavors=[];
                if(pos.Eat_MasName){
                    let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
                    if(globalSysSetting?.DiyEatMas){
                        let EatMas = {
                            Mas_AutoID:globalSysSetting.DiyEatMas.MAS_AUTOID,
                            Mas_Code:globalSysSetting.DiyEatMas.MAS_CODE,
                            Mas_Name:pos.Eat_MasName,
                            Is_AddMoney:globalSysSetting.DiyEatMas.IS_ADDMONEY,
                            Price_Kind:globalSysSetting.DiyEatMas.PRICE_KIND,
                            Mas_Price:globalSysSetting.DiyEatMas.MAS_PRICE
                        }
                        this.optFlavors.push(EatMas);
                    }
                    this.confirm(this.optFlavors,pos,'');
                }
                this.orderNumber=''
            })
        },
        OnDeskClick(btn){
            this.checkBtnType=0
            let newbtn={
                Is_Show:btn.Is_Show,
                Permission:btn.Permission,
                ProjectItem_Key:btn.ProjectSubItem_Key,
                ProjectItem_Name:btn.ProjectSubItem_Name,
                ProjectItem_Type:btn.ProjectSubItem_Type,
            }
            this.checkBtnlist = JSON.parse(JSON.stringify(newbtn))
            if(btn.Permission==false){
                this.$confirm('该账户权限不足,不支持'+btn.ProjectSubItem_Name+'功能', '提示', {
                    confirmButtonText: '去验证',
                    cancelButtonText: '我知道了',
                    type: 'warning'})
                .then(() => {
                    this.checkBtnType=6
                    this.checkShow = true
                    this.$keyavail=true
                }).catch(()=>{});
                return;
            }
            else
            {
                let param = {
                    Body_ID: newGuid(),
                    User_ID: this.userInfo?.User_ID,
                    Operator_Name: this.userInfo?.Login_Name, //操作员
                    CheckOut_Type:0,//按菜品明细核销
                    Eat_ConsumeID:this.selectOnDeskConsumedata.Eat_ConsumeID,
                    Bar_Code: "", //桌台ID
                }
                const loading = this.$loading({
                    text: "菜品确认上桌中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                if(sessionStorage.getItem("tempUserID")){
                    param.User_ID = sessionStorage.getItem("tempUserID")
                    param.Operator_Name = sessionStorage.getItem("tempName")
                    sessionStorage.removeItem("tempUserID")
                    sessionStorage.removeItem("tempName")
                }
                this.$httpAES.post("Bestech.CloudPos.ConfirmOnDesk", param).then((data) => {
                    loading.close();
                    if (data.ResponseHeader.ResultCode != 0) {
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    } else {
                        if(data.ResponseBody?.length<=0){
                            this.$message.warning('菜品上桌失败');
                            return;
                        }else
                        {
                            this.refreshOrder();
                        }

                    }
                }).catch((e) => {
                    loading.close();
                    this.$alert(e.message, "提示", { confirmButtonText: "确定" });
                })
            }
        },
        /**菜品确认上桌 */
        ConfirmOnDesk(btn,item) {
            this.selectOnDeskConsumedata=item;
            this.OnDeskClick(btn)
        },
        GetKitPrint() {
            const loading = this.$loading({
                text: "加载打印服务数据...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetPrintServer",{}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(d.ResponseBody){
                        this.printInfo=d.ResponseBody;
                        if(this.isWindow && this.printInfo && this.$auth.getUserInfo() && this.printInfo?.Site_Serial==this.$auth.getUserInfo()?.Site_Serial) {
                            this.GetCreatedPrintTask();
                        }else  {
                            this.$message.warning('禁止使用【应急获取厨打单】功能,只允许在【运行厨房打印服务】的电脑上使用！');
                        }

                    }else{
                        this.printInfo=null;
                        this.$message.warning('禁止使用【应急获取厨打单】功能,只允许在【运行厨房打印服务】的电脑上使用！');
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('禁止使用【应急获取厨打单】功能,只允许在【运行厨房打印服务】的电脑上使用！:'+e.message);
                console.error('载打印服务站点数据失败:',e);
            })
        },
        GetCreatedPrintTask(){
            // const loading = this.$loading({
            //     text: "获取厨房打印任务中...",
            //     spinner: "el-icon-loading",
            //     background: "rgba(0, 0, 0, 0.7)",
            // });
            this.$httpAES.post("Bestech.CloudPos.GetCreatedPrintTask", {}).then((data) => {
                if(this.outTime){
                    clearTimeout(this.outTime);
                }
                this.outTime=setTimeout(()=>{
                    this.GetCreatedPrintTask();
                },5000)
                // loading.close();
                if (data.ResponseHeader.ResultCode != 0) {
                    this.$message.error(data.ResponseHeader.ResultDesc);
                } else {
                    if(data.ResponseBody?.length<=0){
                        this.waitPrintCount=0;
                        this.isShowKitPrintMonitor=true;
                    }
                    else {
                        this.isShowKitPrintMonitor=true;
                        this.waitPrintCount=data.ResponseBody?.length;
                        this.$webBrowser.KitPrint(data.ResponseBody);
                    }
                }
            }).catch((e) => {
                if(this.outTime){
                    clearTimeout(this.outTime);
                }
                if(this.isShowKitPrintMonitor){
                    this.outTime=setTimeout(()=>{
                        this.GetCreatedPrintTask();
                    },5000)
                }
                
                // loading.close();
                this.$alert(e.message, "提示", { confirmButtonText: "确定" });
            })
        },
        closePrintMonitor(){
            if(this.outTime) clearTimeout(this.outTime);
            this.isShowKitPrintMonitor=false;
        },
        /**套餐口味变动 */
        optFlavorsMap(optFlavors,item){
            let data =''
            let _foodModifys=[]
            let _MasName ='';
            let _Price = 0
            if(optFlavors){
                optFlavors.map(num=>{
                    _MasName += num.Mas_Name+'/';
                    //加价的做法口味
                    if(num.Is_AddMoney==true){
                        //按量加价
                        if(num.Price_Kind==0){
                            _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:item.Eat_Number,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                        }
                        //按次加价
                        if(num.Price_Kind==1){
                            _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                        }
                         _Price = Number(_Price) + Number(0)
                    }else{
                        //不加价的做法口味
                       _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                    }
                }) 
            }
            data ={_MasName:_MasName,_foodModifys:_foodModifys,_Price:_Price}
            return data
        },
        /**全选菜品口味 */
        confirmall(optFlavors){
            this.TemporderMenuList=[]
            this.demandFullShow=false;
            this.$keyavail=false;
            this.synOrderMethodsNum(1)
            /**把之前添加的口味先删除 */
            for(let i=0; i<this.orderMenuList.length; i++){
                if(this.orderMenuList[i].Food_Status==-1){
                    this.orderMenuList[i].Eat_MasName=null
                    this.orderMenuList[i].FoodModifys=[]
                    //如果套餐不为空
                    if(this.orderMenuList[i].FoodSetmeals){
                        for (let j = 0; j < this.orderMenuList[i].FoodSetmeals.length; j++) {
                            this.orderMenuList[i].FoodSetmeals[j].FoodModifys=[]
                            this.orderMenuList[i].FoodSetmeals[j].Eat_MasName=null
                        }
                    }
                    if(this.orderMenuList[i]?.is_taste){
                        this.orderMenuList.splice(i, 1);
                        i--;
                    }
                }
            }
            this.mallMethod=[]
            this.orderMenuList.map(order=>{
                //let _opt={disRadioOrder:this.disRadioOrder,optFlavors:optFlavors}
                let _order = {order:order,optFlavors:JSON.parse(JSON.stringify(optFlavors))} 
                this.mallMethod.push(_order)
            })
            //let _opt={orderMenuListopt:this.orderMenuList,optFlavors:optFlavors}
            //this.mallMethod = _opt
            //单独口味添加方法
            this.aloneAddMethods()
            //全单添加方法
            this.mallMethodMethods()
            this.synOrderMethodsNum(2)
        },
        //全单口味添加方法
        mallMethodMethods(){
            if(this.mallMethod.length==0){
                return
            }
            this.orderMenuList.forEach((item,index)=>{
                let _type=0
                let _optFlavors=[]
                /**全单口味里面已有的菜品做法才会同步 */
                this.mallMethod.forEach(mall=>{
                    if(mall.order.Eat_ConsumeID==item.Eat_ConsumeID){
                        _type=1
                        _optFlavors = mall.optFlavors
                    }
                })
                if(item.Food_Status==-1 && !item.is_taste){
                    let _foodModifys=[]
                    let _Eat_MasName =''
                    if(item.Eat_MasName){
                        _Eat_MasName = item.Eat_MasName
                    }
                    if(_type==1){
                       _optFlavors.map(num=>{
                            _Eat_MasName += num.Mas_Name+'/';
                            //加价的做法口味
                            if(num?.Is_AddMoney==true){
                                //按量加价
                                if(num.Price_Kind==0){
                                    _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:item.Eat_Number,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:1});
                                }
                                //按次加价
                                if(num.Price_Kind==1){
                                    _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:1});
                                }
                            }else{
                                //不加价的做法口味
                                _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:1});
                            }
                        })
                        this.tempoptFlavorAdd(item,this.tasteMenu(_optFlavors,item,'',1),true,true)
                    }else{
                        this.tempoptFlavorAdd(item,'',true,true)
                    }
                    //套餐
                    item.FoodSetmeals?.forEach(food=>{
                        if(!food.Eat_MasName){
                            food.Eat_MasName=''
                        }
                        food.Eat_MasName += _Eat_MasName
                        if(food.FoodModifys){
                            _foodModifys.map(fm=>{
                                food.FoodModifys.push(Object.assign({},fm));
                            })
                        }else{
                            food.FoodModifys =JSON.parse(JSON.stringify(_foodModifys));
                        }
                        food.FoodModifys?.forEach(mod=>{
                            if(mod.Price_Kind==0 && food?.FoodSetmeal_Number){
                                mod.Mas_Num = food.FoodSetmeal_Number
                            }
                        })

                        let _food = {
                            Eat_ConsumeID:food.Eat_ConsumeID,
                            SubCategory_ID:item.SubCategory_ID,
                            EAT_XFBMID:item.EAT_XFBMID,
                            EAT_XFCode:item.EAT_XFCode,
                            EAT_XFName:food.FoodSetmeal_Name,
                            Eat_Number:food.FoodSetmeal_Number,
                            Eat_CheckPrice:item.Eat_CheckPrice,
                            Eat_SalePrice:item.Eat_SalePrice,
                            Eat_MasName:item.Eat_MasName,
                            Food_Status:item.Food_Status,
                            Is_SetMeal:true,
                            FoodSetmeal_ID:food.FoodSetmeal_ID,
                            FoodSetmeal_Code:food.FoodSetmeal_Code,
                            FoodSetmeal_Number:food.FoodSetmeal_Number,
                            FoodSetmeal_Price:food.FoodSetmeal_Price,
                            FoodModifys:food.FoodModifys,
                            is_type:0,
                            Food_Speed:food.Food_Speed
                        }
                        this.tempoptFlavorAdd('',this.tasteMenu(_optFlavors,_food,'',1),true,true)
                    })

                    item.Eat_MasName = _Eat_MasName;
                    if(item.FoodModifys){
                        _foodModifys.map(fm=>{
                            item.FoodModifys.push(fm)
                        })
                    }else{
                        item.FoodModifys =JSON.parse(JSON.stringify(_foodModifys));
                    }
                }
                else{
                    this.TemporderMenuList.push(item);
                }
            })
            this.TemporderMenuList=[]
        },
        /**删除 */
        clickDelete(index,item){
            if(item.Food_Status>=0){
                this.$alert('已下单的菜品暂时无法删除', '提示', {confirmButtonText: '确定',});
            }else{
                if(this.disRadioclass>-1){
                    if(this.foodtype==1){
                        this.$alert('套餐下的菜品不允许单独删除', '提示', {confirmButtonText: '确定',});
                        return
                    }
                    if(this.disRadioOrder?.Is_Combo){
                        this.$alert('组合菜品下的菜品不允许单独删除', '提示', {confirmButtonText: '确定',});
                        return
                    }
                    if(this.disRadioOrder.Food_Status!=-1){
                        this.$alert('该菜品已下单暂时无法调整', '提示', {confirmButtonText: '确定',});
                    }
                    else{
                        this.posFoodNumbermethod(3)
                        if(item?.is_taste){
                            this.orderMenuList.map((order,i)=>{
                                /**找到选择口味对应的菜品 */
                                if(order.Eat_ConsumeID==item.Eat_ConsumeID && order?.FoodModifys){
                                    /**找到对应的口味删除 */
                                    order?.FoodModifys.map((food,j)=>{
                                        if(food.Mas_AutoID==item.Mas_AutoID){
                                            order?.FoodModifys.splice(j--,1)
                                        }
                                    })
                                    let data = this.optFlavorsMap(order?.FoodModifys,order)
                                    order.Eat_MasName = data._MasName
                                }
                                if(order.Eat_ConsumeID==item.Eat_ConsumeID && order.Mas_AutoID==item.Mas_AutoID){
                                    this.orderMenuList.splice(i,1)
                                    i--
                                }
                            })
                            this.orderMenuList.forEach(order=>{
                                /**如果是套餐的话 */
                                order.FoodSetmeals?.forEach(food=>{
                                    if(food.Eat_ConsumeID==item.Eat_ConsumeID){
                                        if(food.FoodModifys){
                                            food.FoodModifys.forEach((mo,j)=>{
                                                if(mo.Mas_AutoID==item.Mas_AutoID){
                                                    food?.FoodModifys.splice(j--,1)
                                                }
                                            })
                                            let data = this.optFlavorsMap(food?.FoodModifys,order)
                                            food.Eat_MasName = data._MasName
                                        }
                                    }
                                })
                            })
                            /**删除对应储存的口味要求和做法 */
                            if(this.aloneMethod){
                                this.aloneMethod.forEach((alon,i)=>{
                                    if(alon.disRadioOrder.Eat_ConsumeID==item.Eat_ConsumeID){
                                        if(alon?.optFlavors){
                                            alon.optFlavors.forEach((opt,j)=>{
                                                if(opt.Mas_AutoID==item.Mas_AutoID){
                                                    alon.optFlavors.splice(j,1)
                                                    j--
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                            if(this.mallMethod){
                                this.mallMethod.map(me=>{
                                    if(me.order.Eat_ConsumeID==item.Eat_ConsumeID){
                                        if(me.optFlavors){
                                            me.optFlavors.map((opt,j)=>{
                                                if(opt.Mas_AutoID==item.Mas_AutoID){
                                                    me.optFlavors.splice(j,1)
                                                    j--
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        }else{
                            let arr=[]
                            this.orderMenuList.filter(order=>{
                                //过滤 要删除的菜品 
                                if(order.Eat_ConsumeID!=item.Eat_ConsumeID){
                                    //要删除组合菜品下的菜品
                                    if(item.OrderComboConsumes?.some(it=>it.Eat_ConsumeID==order.Eat_ConsumeID)){
                                        return;
                                    }
                                    arr.push(order)
                                }
                            })
                            if(item?.FoodSetmeals){
                                for(let i=0; i<arr.length; i++){
                                    item.FoodSetmeals.forEach(food=>{
                                        if(food.Eat_ConsumeID==arr[i]?.Eat_ConsumeID){
                                            arr.splice(i,1)
                                            i--;
                                        }
                                    })
                                }
                            }
                                
                            this.orderMenuList = arr
                        }
                        this.clickEndConsume(index-1);
                    }
                    
                }else{
                    this.$alert('请先选择左边的菜品', '提示', {confirmButtonText: '确定',});
                }
            }
        },
        /**点击下一条 或 最后一条 明细数据*/
        clickEndConsume(index){
            if(index==undefined || index==null){
                index=this.orderMenuList.length-1;
            }else if(this.orderMenuList.length<=index){
                index=this.orderMenuList.length-1;
            }
            if(index<0){
                index=0;
            }
            if(index>=0){
                for(let i=index;i>=0;i--){
                    if(this.orderMenuList[i] && !this.orderMenuList[i].Is_Combo){
                        index=i;
                        this.radioClick(index,this.orderMenuList[index]);
                        break;
                    }
                }
            }
        },
        /**改价,改量 */
        clickPriceChange(type){
            let _TilteName=''
            if(type==1){ 
                _TilteName='改价'
            }else{
                _TilteName='改量'
            }
            if(this.disRadioclass>-1){
                if(this.disRadioOrder.Eat_CheckMoney<=0){
                    this.$alert('该菜品无法调整', '提示', {confirmButtonText: '确定',});
                    return;
                }
                if(this.disRadioOrder.Food_Type==4){
                    this.$alert('口味无法调整', '提示', {confirmButtonText: '确定',});
                    return;
                }
                if(this.disRadioOrder.Food_Status==-1){
                    this.$alert('只能调整下单后的菜品', '提示', {confirmButtonText: '确定',});
                    return
                }
                if(this.disRadioOrder.Eat_TcItemList?.length>0){
                    this.$alert('套餐菜品无法进行'+_TilteName+'操作', '提示', {confirmButtonText: '确定',});
                    return
                }
                if(this.foodSetmealsclass>=0 && this.choicefoodOrder?.Eat_ComboItemList?.length>0 && type!=1){
                    this.$alert('组合菜品子菜品无法进行改量操作', '提示', {confirmButtonText: '确定',});
                    return
                }

                if(this.orderMenuList.some(meu=>meu.Food_Status==-1)){
                    this.$message.error('存在新点菜品,请先落单再使用此功能')
                    return
                }
                if(type==1){
                    this.changePriceShow=true 
                    this.$keyavail=true
                }else{
                    if(!this.disRadioOrder.Is_ChangedNum){
                        this.$alert('该菜品不允许下单后'+_TilteName, '提示', {confirmButtonText: '确定',});
                        return
                    }
                    this.changeNumShow = true
                    this.$keyavail=true
                }
            }else{
                this.$alert('请先选择左边的菜品', '提示', {confirmButtonText: '确定',});
            }
        },
        /**改量返回 */
        changenumClick(num,data,assSizeNumber){
            this.changeNumShow = false;
            this.$keyavail=false;
            this.disRadioOrder.Eat_Number = num;
            if(assSizeNumber!=''){
                this.disRadioOrder.Eat_AssNumber = Number(assSizeNumber)
            }
            this.OrderInfoUpdate(data,1);
            this.posFoodNumbermethod(5);
        },
        /**改价返回 */
        changepriceClick(price,data){
            this.changePriceShow = false;
            this.$keyavail=false;
            this.disRadioOrder.Eat_SalePrice =Number(price);
            this.disRadioOrder.Eat_CheckPrice = Number(price);
            this.OrderInfoUpdate(data,1);
        },
        /**赠送,退菜 */
        clickGivereturn(type){
            if(this.disRadioclass>-1){
                if(type==1 && this.disRadioOrder.Is_Gift){
                    this.$confirm('是否取消'+this.disRadioOrder.EAT_XFName+'的赠送', '提示', {
                        confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
                    .then(() => {
                        this.cancelGiveFood()
                    }).catch(() => {
                    });
                    return
                }
                if(this.disRadioOrder.Eat_CheckMoney<=0 && this.disRadioOrder.Eat_TcAutoID_Batch=='' && this.disRadioOrder.Eat_Number==0 && this.disRadioOrder.Is_Gift==false){
                    this.$alert('该菜品无法调整', '提示', {confirmButtonText: '确定',});
                    return;
                }
                if(this.disRadioOrder.Food_Status==-1){
                    this.$alert('只能调整下单后的菜品', '提示', {confirmButtonText: '确定',});
                    return
                }
                if(this.disRadioOrder.Eat_Number<=0){
                    this.$alert('没有可操作剩余数量', '提示', {confirmButtonText: '确定',});
                    return;
                }
                let status=0
                this.orderMenuList.map(meu=>{
                    if(meu.Food_Status==-1){
                        status=1
                        return true
                    }
                })
                if(status==1){
                    this.$message.error('存在新点菜品,请先落单再使用此功能')
                    return
                }
                if(type==1){
                    if(this.foodSetmealsclass!=-1 && !this.choicefoodOrder?.Eat_ComboItemList){
                        this.$alert('套餐里的菜品无法进行赠送调整', '提示', {confirmButtonText: '确定',});
                        return
                    }
                    this.giveShow=true 
                    this.$keyavail=true
                }else{
                    let TcisType=false
                    //说明选择的是套餐里的子项
                    if(this.foodSetmealsclass!=-1){
                        //判断套餐头是否赠送,如果赠送则不可去退菜
                        TcisType = this.orderMenuList.some(order=>{
                            if(order.Eat_TcAutoID_Batch==this.disRadioOrder.Eat_TcAutoID_Batch && order.Is_Gift==true){
                                let title="套餐已被赠送,套餐里的菜已无法退菜";
                                if(this.choicefoodOrder?.Eat_ComboItemList){
                                    title="组合菜品已被赠送,组合里的菜已无法退菜";
                                }
                                this.$alert(title, '提示', {confirmButtonText: '确定',});
                                return true
                            }else{
                                return false
                            }
                        })
                    }
                    if(TcisType==false){
                        this.returnShow=true
                        this.$keyavail=true
                    }
                }
            }else{
                this.$alert('请先选择左边的菜品', '提示', {
                    confirmButtonText: '确定',
                });
            }
        },
        /**赠送,退菜返回 */
        GiveFoodFreeClick(type,data){
            this.$keyavail=false;
            console.log('data:'+JSON.stringify(data))
            if(type==false){
                /**赠送 */
                this.giveShow = false;
                data.ResponseBody.Order_ConsumeList.map(order=>{
                    if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                        this.disRadioOrder = order
                    }
                })
            }else{
                /**退菜 */
                this.returnShow = false;
                data.ResponseBody.Order_ConsumeList.map(order=>{
                    if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                        this.disRadioOrder = order
                    }
                })
            }
            this.OrderInfoUpdate(data,1);
        },
        /**取消赠送的菜品 */
        cancelGiveFood(){
            let tempTimestamps = this.orderInfo.Timestamps;
            if(this.unionType==true){ /**联台查询 */
                if(this.orderInfo.UnionOrderInfoList){
                    this.orderInfo.UnionOrderInfoList.forEach(union=>{
                        union.Order_ConsumeList.find(order=>{
                            if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                tempTimestamps = union.Timestamps;
                                return true;
                            }
                            return order.Eat_ComboItemList?.some(it=>{
                                if(it.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                    tempTimestamps = union.Timestamps;
                                    return true;
                                }
                                return false;
                            })
                        })
                    })
                }
            }
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID, //登陆用户ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Eat_ConsumeID:this.disRadioOrder.Eat_ConsumeID, //菜品的明细
                IsReturn_CheckOutInfo:true, //是否返回结账信息
                Is_UnionOrder:true,
                Timestamps:tempTimestamps.toString(),
            }
            /**取消赠送菜品接口 */
            const loading = this.$loading({
                text: "取消菜品赠送中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.CancelGiveFoodFree",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                    this.orderInfo.Timestamps = data.ResponseBody?.Timestamps.toString()
                }else{
                    data.ResponseBody.Order_ConsumeList.map(order=>{
                        if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                            this.disRadioOrder = order
                        }
                    })
                    this.OrderInfoUpdate(data,1)
                    this.$message.success("取消赠送成功");
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**整桌退菜返回 */
        WholeReturn(data){
            this.orderInfo = data.ResponseBody
            this.orderMenuList=JSON.parse(JSON.stringify(data.ResponseBody.Order_ConsumeList))
            this.wholeShow = false
            this.$keyavail = false
            this.posFoodNumbermethod(5)
        },
        /**折扣返回 */
        discountSchemeClic(data){
            this.discountShow = false;
            this.$keyavail = false;
            this.OrderInfoUpdate(data,0)
        },
        /**取消折扣返回 */
        CancelBillDiscount(data){
            this.discountShow = false;
            this.$keyavail = false;
            this.OrderInfoUpdate(data,0)
        },
        /**会员支付返回 */
        memberPayClick(data){
            this.memberPayShow = false
            this.$keyavail = false
            let _Union = []
            let unionTime=[]
            this.isPayName='暂无'
            this.choicePayType=[]
            if(data.ResponseHeader.ResultCode==0){
                let PosDesk = {Desk_AutoID:this.posDesks.Desk_AutoID,DeskTimestamps:data.ResponseBody?.DeskTimestamps.toString()}
                if(this.unionType==true){
                    if(this.orderInfo.UnionOrderInfoList){
                        this.orderInfo.UnionOrderInfoList.map(union=>{
                            let unionDesk ={Eat_DeskID:union.Eat_DeskID}
                            _Union.push(unionDesk)
                        })
                    }
                    if(data.ResponseBody?.UnionOrders){
                        data.ResponseBody.UnionOrders.map(union=>{
                            let _Time={Desk_ID:union?.Desk_ID,DeskTimestamps:union?.DeskTimestamps.toString()}
                            unionTime.push(_Time)
                        })
                        PosDesk = Object.assign(PosDesk,{unionList:_Union,unionDeskTime:unionTime})
                    }
                }
                this.$router.push({
                    path: 'diningTable',name:'diningTable',
                    params:{
                        PosDesksRetrun: JSON.stringify(PosDesk),
                        type:1
                    }
                });
            }else{
                /**重新获取订单信息 */
                this.refreshOrder()
            }
        },
        /**微生活支付返回 */
        microMemberPayClick(data){
            this.wliveMemberPayShow = false
            this.$keyavail = false
            let _Union = []
            let PosDesk = {Desk_AutoID:this.posDesks.Desk_AutoID,DeskTimestamps:data.ResponseBody?.DeskTimestamps.toString()}
            let unionTime=[]
            if(this.unionType==true){
                if(this.orderInfo.UnionOrderInfoList){
                    this.orderInfo.UnionOrderInfoList.map(union=>{
                        let unionDesk ={Eat_DeskID:union.Eat_DeskID}
                        _Union.push(unionDesk)
                    })
                }
                if(data.ResponseBody?.UnionOrders){
                    data.ResponseBody.UnionOrders.map(union=>{
                        let _Time={Desk_ID:union?.Desk_ID,DeskTimestamps:union?.DeskTimestamps.toString()}
                        unionTime.push(_Time)
                    })
                    PosDesk = Object.assign(PosDesk,{unionList:_Union,unionDeskTime:unionTime})
                }
            }
            this.$router.push({
                path: 'diningTable',name:'diningTable',
                params:{
                        PosDesksRetrun: JSON.stringify(PosDesk),
                        type:1
                    }
            });
        },
        /**微生活支付记录返回 */
        wliveRecordsPayMessage(param,meberInfo,memberorderInfo){
            this.wliveMemberPayShow = false
            this.$keyavail = false
            let poinPay=0
            if(this.orderPaymentDetailList.length>0){
                for(let i=0; i<this.orderPaymentDetailList.length; i++){
                    //找到这张卡所有使用积分的付款方式
                    if(this.orderPaymentDetailList[i]?.Payment_ID=='CardPointPay' && this.orderPaymentDetailList[i]?.Card_No==meberInfo?.Card_No){
                        poinPay+=this.orderPaymentDetailList[i]?.Payment_Money
                    }
                    if(this.orderPaymentDetailList[i]?.isPitch==true){
                        this.orderInfo.UnPaid_Money =this.addNum(Number(Number(this.orderPaymentDetailList[i].Payment_Money).toFixed(2)),this.orderInfo.UnPaid_Money)
                        this.orderInfo.Payment_Money = this.accSub(Number(Number(this.orderPaymentDetailList[i].Payment_Money).toFixed(2)),this.orderInfo.Payment_Money) 
                        this.orderPaymentDetailList.splice(i,1);
                        i--;
                    }
                }
            }
            if(this.wlivePayList){
                this.wlivePayList.some((wli,i)=>{
                    let tr=wli.Payments.some(pay=>{
                        if(pay.isPitch==true){
                            return true
                        }
                    })
                    if(wli.Payments.length==0){
                        tr=true
                    }
                    if(tr){
                        this.wlivePayList.splice(i,1)
                        return true
                    }
                })
            }
            if(this.wlivePayList){
                this.wlivePayExist=false
            }
            if(param?.Payments){
                param?.Payments.forEach(pay=>{
                    if(pay.Payment_ID=='CardPointPay'){
                        poinPay+=pay.Payment_Money
                    }
                })
                //如果这张卡积分使用的积分大于本单支付的最大积分限制则提示
                if(poinPay>meberInfo.PointPay_Limit && meberInfo.Is_Limit_PointPay==1){
                    this.$message.error('会员卡:'+meberInfo?.Card_No+'积分使用限制数量为:'+meberInfo?.PointPay_Limit+',准备支付的积分为:'+poinPay+'');
                    return 
                }
                let serialNo=newGuid()
                let time=new Date()
                let list={Member:param?.Member,isForbidBalanc:meberInfo.Is_ForbidBalanceAndPointUseSimultaneously,Payments:[]}
                param?.Payments.forEach(pay=>{
                    let p={
                        Payment_ID:pay.Payment_ID,
                        Payment_Code:pay.Payment_Code,
                        Payment_Name:pay.Payment_Name,
                        Payment_Type:pay.Payment_Type,
                        Payment_Money:pay.Payment_Money,
                        Payment_FactMoney:pay.Payment_FactMoney,
                        Card_No:pay.Card_No,
                        Hide_YN:false,
                        CouponInfo:pay.CouponInfo,
                        serialNo:serialNo,
                        soleNo:newGuid(),
                        Eat_PaymentTime:time,
                        Payment_Remark:'',
                        isPitch:false,
                        Sort_ID:0,
                        Refund_Status:0
                    }
                    if(p.Payment_ID=='CardMoneyPay'){
                        p.Payment_Name='微生活储值'
                    }
                    if(p.Payment_ID=='CardPointPay'){
                        p.Payment_Name='微生活积分'
                    }
                    if(p.Payment_ID=='CardCouponsPay'){
                        p.Payment_Name='微生活会员券'
                    }
                    list.Payments.push(p)
                    this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money);
                    this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                })
                this.orderPaymentDetailList=list.Payments.concat(this.orderPaymentDetailList)
                this.wlivePayExist=true
                this.wlivePayList.push(list)
                //更新订单时间戳
                if(memberorderInfo){
                    if(memberorderInfo.Timestamps.toString()!=this.orderInfo.Timestamps.toString()){
                        this.refreshOrder()
                    }
                }
                
            }
        },
        /**会员点击返回 */
        memberReturn(memberorderInfo,meberInfo,type,data){
            this.memberPayShow=false
            this.$keyavail = false
            this.isPayName='暂无'
            this.choicePayType=[]
            this.orderInfo.Timestamps = memberorderInfo.Timestamps.toString()
            /**保存新时间戳 */
            if(data?.UnionOrders){
                this.unionTimeUpdate(data)
            }
            this.ispayid=0
            if(type==2){
                if(data.Payments){
                    this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data.Payments)
                    this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(data.Payments)
                    data.Payments.map(pay=>{
                        this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money)
                        this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-')
                    })
                }
                this.IsMultipleCardPay = data.IsMultipleCardPay
            }
            if(meberInfo?.Is_Have_ParPrice==1 && this.isManualParPrice==false && type==1 && this.IsMultipleCardPay==false){
                if(this.orderPaymentDetailList.length==0 && Number(this.orderInfo.Payment_Money)<=0){
                    this.userCheckOutMiddleMenu.map(item=>{
                        if(item.ProjectItem_Key=='MemberPrice' && item.ProjectItem_Name=='取消会员价'){
                            this.cancelCardPrice()
                        }
                    })
                }
            }
        },
        /**G9点击返回 */
        gNiceMemberReturn(memberorderInfo,data){
            this.gNinePayShow=false;
            this.westStoredShow=false;
            this.daNianPayShow=false;
            this.$keyavail = false;
            this.isPayName='暂无'
            this.choicePayType=[]
            this.orderInfo.Timestamps = memberorderInfo.Timestamps.toString()
            if(data){
                if(data?.Payments){
                    this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data?.Payments)
                    this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(data?.Payments)
                    data?.Payments.map(pay=>{
                        this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money)
                        this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                    })
                }
            }
            this.ispayid=0
        },
        /**大拈会员点击返回 */
        daNianMemberReturn(memberorderInfo,data){
            this.daNianPayShow=false;
            this.$keyavail = false;
            this.isPayName='暂无'
            this.choicePayType=[]
            this.orderInfo.Timestamps = memberorderInfo.Timestamps.toString()
            if(data){
                if(data?.Payments){
                    this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data?.Payments)
                    this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(data?.Payments)
                    data?.Payments.map(pay=>{
                        this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money);
                        this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                    })
                }
                if(this.orderInfo.UnionOrderInfoList){
                    this.unionTimeUpdate(data)
                }
            }
            this.ispayid=0
        },
        /**海鼎点击返回 */
        hdMemberReturn(memberorderInfo,data){
            this.hdShow=false
            this.$keyavail = false
            this.isPayName='暂无'
            this.choicePayType=[]
            this.orderInfo.Timestamps = memberorderInfo.Timestamps.toString()
            if(data){
                if(data?.Payments){
                    this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data?.Payments)
                    this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(data?.Payments)
                    data?.Payments.map(pay=>{
                        this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money);
                        this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                    })
                }
            }
            this.ispayid=0
        },
        /**海鼎触发全单折扣返回 */
        hdMemberdiscount(data){
            this.orderInfo = data.ResponseBody;
            this.orderMenuList=JSON.parse(JSON.stringify(data.ResponseBody.Order_ConsumeList))
            if(this.unionType==true){
                this.cardOrderunionType(data)
            }
            this.orderPaymentAdd()
        },
        /**新享会返回 */
        newEnjoyReturn(memberorderInfo,data){
            this.newEnjoyShow=false
            this.TwoDfireShow=false
            this.$keyavail = false
            this.isPayName='暂无'
            this.choicePayType=[]
            this.orderInfo.Timestamps = memberorderInfo.Timestamps.toString()
            if(data){
                this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data.Payments)
                this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(data.Payments)
                data.Payments.map(pay=>{
                    this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money)
                    this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                })
                if(this.orderInfo.UnionOrderInfoList){
                    this.unionTimeUpdate(data)
                }
            }
            this.ispayid=0
        },
        newEnjomemberPayClick(data){
            this.newEnjoyShow = false
            this.TwoDfireShow=false
            this.$keyavail = false
            let _Union = []
            let PosDesk = {Desk_AutoID:this.posDesks.Desk_AutoID,DeskTimestamps:data.ResponseBody?.DeskTimestamps.toString()}
            let unionTime=[]
            if(this.unionType==true){
                if(this.orderInfo.UnionOrderInfoList){
                    this.orderInfo.UnionOrderInfoList.map(union=>{
                        let unionDesk ={Eat_DeskID:union.Eat_DeskID}
                        _Union.push(unionDesk)
                    })
                }
                if(data.ResponseBody?.UnionOrders){
                    data.ResponseBody.UnionOrders.map(union=>{
                        let _Time={Desk_ID:union?.Desk_ID,DeskTimestamps:union?.DeskTimestamps.toString()}
                        unionTime.push(_Time)
                    })
                    PosDesk = Object.assign(PosDesk,{unionList:_Union,unionDeskTime:unionTime})
                }
            }
            
            this.$router.push({
                path: 'diningTable',name:'diningTable',
                params:{
                    PosDesksRetrun: JSON.stringify(PosDesk),
                    type:1
                }
            });
        },
        /**昆仑会员支付 返回 */
        kunlunPayReturn(memberorderInfo,data){
            this.kunlunPayShow = false;
            this.$keyavail = false
            this.isPayName='暂无'
            this.choicePayType=[]
            this.orderInfo.Timestamps = memberorderInfo.Timestamps.toString()
            if(data){
                this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data.Payments)
                this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(data.Payments)
                data.Payments.forEach(pay=>{
                    this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money)
                    this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                })
                if(this.orderInfo.UnionOrderInfoList){
                    this.unionTimeUpdate(data)
                }
            }
            this.ispayid=0
        },
        kunlunPayClick(data){
            this.kunlunPayShow = false;
            this.$keyavail = false;
            let _Union = []
            let PosDesk = {Desk_AutoID:this.posDesks.Desk_AutoID,DeskTimestamps:data.ResponseBody?.DeskTimestamps.toString()}
            let unionTime=[]
            if(this.unionType==true){
                if(this.orderInfo.UnionOrderInfoList){
                    this.orderInfo.UnionOrderInfoList.forEach(union=>{
                        let unionDesk ={Eat_DeskID:union.Eat_DeskID}
                        _Union.push(unionDesk)
                    })
                }
                if(data.ResponseBody?.UnionOrders){
                    data.ResponseBody.UnionOrders.forEach(union=>{
                        let _Time={Desk_ID:union?.Desk_ID,DeskTimestamps:union?.DeskTimestamps.toString()}
                        unionTime.push(_Time)
                    })
                    PosDesk = Object.assign(PosDesk,{unionList:_Union,unionDeskTime:unionTime})
                }
            }
            
            this.$router.push({
                path: 'diningTable',name:'diningTable',
                params:{
                    PosDesksRetrun: JSON.stringify(PosDesk),
                    type:1
                }
            });
        },
        /**微生活返回 */
        microMemberReturn(memberorderInfo,meberInfo,type,data){
            this.wliveMemberPayShow=false
            this.$keyavail = false
            if(this.wlivePayExist==false){
                this.isPayName='暂无'
                this.choicePayType=[]
            }
            this.orderInfo.Timestamps = memberorderInfo.Timestamps.toString()
            /**保存新时间戳 */
            if(data?.UnionOrders){
                this.unionTimeUpdate(data)
                this.unionEatAutoID=[]
            }
            this.ispayid=0
            if(type==2){
                if(data.Payments){
                    this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data.Payments)
                    this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(data.Payments)
                    data.Payments.map(pay=>{
                        this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money)
                        this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-')
                    })
                }
                this.IsMultipleCardPay = data.IsMultipleCardPay
            }
            if(meberInfo?.Is_Have_ParPrice==1 && this.isManualParPrice==false && type==1 && this.IsMultipleCardPay==false){
                if(this.orderPaymentDetailList.length==0 && Number(this.orderInfo.Payment_Money)<=0){
                    this.userCheckOutMiddleMenu.map(item=>{
                        if(item.ProjectItem_Key=='MemberPrice' && item.ProjectItem_Name=='取消会员价'){
                            this.cancelCardPrice()
                        }
                    })
                    
                }
            }
            this.orderPaymentDetailList.forEach(pay=>{
                if(pay.isPitch==true){
                    pay.isPitch=false
                }
            })
        },
        /**AR账点击返回 */
        arAccountReturn(data,Timestamps,isPaySubmit){
            this.arShow=false
            this.westShow=false
            this.$keyavail = false
            this.isPayName='暂无'
            this.choicePayType=[]
            if(data.Payments && isPaySubmit==1){
                this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data.Payments)
                this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(data.Payments)
                data.Payments.map(pay=>{
                    this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money)
                    this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                })
            }
            /**保存新时间戳 */
            if(data?.UnionOrders && data?.UnionOrders.length>0){
                this.unionTimeUpdate(data)
            }
            this.orderInfo.Timestamps = Timestamps
            this.ispayid=0
        },
        /**AR支付返回 */
        arAccountPayReturn(data){
            this.arShow=false
            this.$keyavail = false
            let PosDesk = {Desk_AutoID:this.posDesks.Desk_AutoID,DeskTimestamps:data.ResponseBody.DeskTimestamps.toString()}
            let unionTime=[]
            if(data.ResponseBody?.UnionOrders){
                data.ResponseBody.UnionOrders.map(union=>{
                    let _Time={Desk_ID:union?.Desk_ID,DeskTimestamps:union?.DeskTimestamps.toString()}
                    unionTime.push(_Time)
                })
                PosDesk = Object.assign(PosDesk,{unionDeskTime:unionTime})
            }
            this.$router.push({
                path: 'diningTable',name:'diningTable',
                params:{
                        PosDesksRetrun: JSON.stringify(PosDesk),
                        type:1
                    }
            });
        },
        /**企业中台挂账返回 */
        enterPayReturn(data,type){
            this.enterCloseShow=false
            this.$keyavail = false
            if(type==2){
                this.isPayName='暂无'
                this.choicePayType=[]
            }
            if(data.ResponseBody){
                /**保存新时间戳 */
                if(data){
                    if(data.ResponseBody?.UnionOrders && data.ResponseBody?.UnionOrders.length>0){
                        this.unionTimeUpdate(data.ResponseBody)
                    }
                }
                if(type==2){
                    if(data.ResponseBody?.Payments){
                        this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data.ResponseBody?.Payments)
                        this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(data.ResponseBody?.Payments)
                        data.ResponseBody?.Payments.forEach(pay=>{
                            this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money);
                            this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                        })
                    }
                    this.orderInfo.Timestamps = data.ResponseBody?.Timestamps.toString()
                    this.ispayid=0
                }
                if(type==1){
                    // let PosDesk = {Desk_AutoID:this.posDesks.Desk_AutoID,DeskTimestamps:data.ResponseBody?.DeskTimestamps.toString()}
                    // let unionTime=[]
                    // if(data.ResponseBody?.UnionOrders){
                    //     data.ResponseBody.UnionOrders.map(union=>{
                    //         let _Time={Desk_ID:union?.Desk_ID,DeskTimestamps:union?.DeskTimestamps.toString()}
                    //         unionTime.push(_Time)
                    //     })
                    //     PosDesk = Object.assign(PosDesk,{unionDeskTime:unionTime})
                    // }
                    if(data.ResponseBody?.Timestamps){
                        this.orderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                    }
                    // this.$router.push({
                    //     path: 'diningTable',name:'diningTable',
                    //     params:{
                    //             PosDesksRetrun: JSON.stringify(PosDesk),
                    //             type:1
                    //         }
                    // });
                }
            }
        },
        /**押金单返回 */
        preMoneyPayReturn(data,Payments,Timestamps,isPaySubmit){
            this.preMoneyShow=false
            this.$keyavail = false
            if(data){
                let PosDesk = {Desk_AutoID:this.posDesks.Desk_AutoID,DeskTimestamps:data.ResponseBody.DeskTimestamps.toString()}
                let unionTime=[]
                if(data.ResponseBody?.UnionOrders){
                    data.ResponseBody.UnionOrders.map(union=>{
                        let _Time={Desk_ID:union?.Desk_ID,DeskTimestamps:union?.DeskTimestamps.toString()}
                        unionTime.push(_Time)
                    })
                    PosDesk = Object.assign(PosDesk,{unionDeskTime:unionTime})
                }
                this.$router.push({
                    path: 'diningTable',name:'diningTable',
                    params:{
                            PosDesksRetrun: JSON.stringify(PosDesk),
                            type:1
                        }
                });
            }else{
                this.isPayName='暂无'
                this.choicePayType=[]
                if(Payments && isPaySubmit==1){
                    this.orderPaymentDetailList=this.orderPaymentDetailList.concat(Payments)
                    this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(Payments)
                    Payments.map(pay=>{
                        this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money)
                        this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                    })
                }
                this.orderInfo.Timestamps = Timestamps
                this.ispayid=0
            }
        },
        /**现金/商品券点击返回 */
        paymentCouponclose(Payments,Timestamps,data){
            this.paymentCouponsShow = false
            this.$keyavail = false
            this.isPayName='暂无'
            this.choicePayType=[]
            this.ispayid=0
            if(Payments){
                this.orderPaymentDetailList=this.orderPaymentDetailList.concat(Payments)
                this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(Payments)
                Payments.map(pay=>{
                    this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money);
                    this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                })
            }
            /**保存新时间戳 */
            if(data){
                if(data?.UnionOrders && data?.UnionOrders.length>0){
                    this.unionTimeUpdate(data)
                }
            }
            if(Timestamps){
                this.orderInfo.Timestamps = Timestamps
            }
        },
        /**现金/商品券返回 */
        paymentCouponClick(){
            this.paymentCouponsShow = false
            this.$keyavail = false
            let PosDesk = {Desk_AutoID:this.posDesks.Desk_AutoID}
            this.$router.push({
                path: 'diningTable',name:'diningTable',
                params:{
                        PosDesksRetrun: JSON.stringify(PosDesk),
                        type:1
                    }
            });
        },
        /**聚合支付查询返回 
         * @param data 支付预览 或 查询 结果数据
         * @param ResuType 0 无结果提示 1 有返回提示
         * @param d 支付预览成功记录的结果数据
         * @param Is_NotRockover 是否不翻台
        */
        ThePaymentResultClic(data,ResuType,d,Is_NotRockover){
            this.scanCodePay=false;
            this.yinShiPayShow=false;
            this.$keyavail = false;
            if(data.ResponseBody){
                if(data.ResponseBody.Payments){
                    if(!this.orderPaymentDetailList){
                        this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data.ResponseBody.Payments[0])
                        this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(data.ResponseBody.Payments[0])
                        this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(data.ResponseBody.Payments[0].Payment_Money,'-');
                        this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(data.ResponseBody.Payments[0].Payment_Money);
                    }else{
                        let _repay = false
                        _repay = this.orderPaymentDetailList.some(pay=>{
                            if(pay.Eat_PayDetailID==data.ResponseBody.Payments[0].Eat_PayDetailID){
                               return true
                            }
                        })
                        if(_repay==false){
                            this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data.ResponseBody.Payments[0])
                            this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(data.ResponseBody.Payments[0])
                            this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(data.ResponseBody.Payments[0].Payment_Money,'-');
                            this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(data.ResponseBody.Payments[0].Payment_Money);
                        }
                    }
                }
                this.orderInfo.Timestamps = data.ResponseBody.Timestamps
                if(this.orderInfo.UnionOrderInfoList){
                    if(d?.ResponseBody?.UnionOrders){
                        this.unionTimeUpdate(d?.ResponseBody)
                    }
                }
                

                if(ResuType==0 && this.wlivePayExist==false){
                    if(Is_NotRockover){
                        return;
                    }
                    this.wliveIsCheckOut=false;
                    this.PerformingCheckOut()
                }else if(this.wlivePayExist && data.ResponseBody.Payments){
                    //存在微生活的支付记录
                    this.wliveIsCheckOut=false;
                    this.wlivePayPreview(Is_NotRockover)
                }
            }
        },
        /**聚合支付取消 */
        scanCodeclose(){
            this.scanCodePay=false
            this.$keyavail = false
            this.isPayName='暂无'
            this.choicePayType=[]
            this.ispayid=0
        },
        /**银时支付取消 */
        yinShiPayClose(){
            this.yinShiPayShow=false
            this.$keyavail = false
            this.isPayName='暂无'
            this.choicePayType=[]
            this.ispayid=0
        },
        /**菜品换单位 */
        unitClick(){
            if(this.disRadioOrder.Food_Status!=-1){
                this.$alert('只能调整未下单的菜品', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.disRadioOrder?.is_taste){
                this.$alert('口味无法调整', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.disRadioclass>-1){
                let _foodtype=0
                this.multipleSizeFoods.map(food=>{
                    if(food.Food_ID==this.disRadioOrder.EAT_XFBMID){
                        _foodtype=1
                        this.theMultipleSizeFoods = food
                        this.unitShow=true
                        this.$keyavail=true
                        this.unitType=1
                        return true
                    }else{
                        food.MultipleSizeFoods.some(mu=>{
                            if(mu.Food_ID==this.disRadioOrder.EAT_XFBMID){
                                _foodtype=1
                                this.theMultipleSizeFoods = food
                                this.unitShow=true
                                this.$keyavail=true
                                this.unitType=1
                                return true
                            }
                        })
                    }
                })
                if(_foodtype==0){
                    this.$alert('该菜品没有多单位可更换', '提示', {
                        confirmButtonText: '确定',
                    });
                }
            }else{
                this.$alert('请先选择左边的菜品', '提示', {
                    confirmButtonText: '确定',
                });
            }
        },
        /**获取下单之后到现在的时间差 */
        DifferenceTime(beginTime,type) {
            let time=''
            if(type==0){
                time =new Date(beginTime).getHours()+':'+new Date(beginTime).getMinutes()
            }else if(type==2){
                time= differenceTime(new Date(beginTime))+'分'
                let reg=new RegExp(':','g')
                time = time.replace(reg,'时')
                if(time=='-1时-1分'){
                    time='0时0分'
                }
            }else{
                time= differenceTimeseconds(beginTime,type)
            }
            return time;
        },
        /**下单 
         * @param NotSendBool  下单不送打厨房
         * @param NotLimitFoodInventory 下单不关联菜品库存数 默认 false
         * */
        onPlaceOrder(NotSendBool,NotLimitFoodInventory){
            let _orderConsumes=[];
            let isRunOrderNum=0
            this.orderMenuList.forEach((item)=>{
                //判断套餐
                if((item?.Food_Type==2 || item?.Food_Type==3) && (item.Food_Status==-1 || item.Food_Status==0)){
                    isRunOrderNum =this.addNum(item.Eat_Number,isRunOrderNum)
                }
                if(item.Food_Status==-1 && !item.is_taste && !item.Is_Combo){
                    let SizeNumber = Number(item?.AssSizeNumber)
                    let Food_Types =1;
                    let _seep= item.Food_Speed
                    if(item.Food_Speed=='' || item.Food_Speed==null){
                        _seep='即'
                    }
                    if(item.FoodSetmeals!=null){
                        Food_Types=2
                        SizeNumber=0
                    }
                    if(item.DiyTcType){
                        Food_Types=item.DiyTcType
                    }
                    let orders={
                        Food_ID:item.EAT_XFBMID,
                        Food_Code:item.EAT_XFCode,
                        Food_Type:Food_Types,
                        Food_Speed:_seep,
                        Food_Size:item.Eat_XFSize,
                        Food_Number:item.Eat_Number,
                        Food_Price:item.Eat_SalePrice,
                        FoodModifys:item.FoodModifys,
                        FoodSetmeals:item.FoodSetmeals,
                        OrderComboConsumes:item.OrderComboConsumes?.map(it=>{
                            return {
                                Food_ID:it.EAT_XFBMID,
                                Food_Code:it.EAT_XFCode,
                                Food_Name:it.EAT_XFName,
                                Food_Size:it.Eat_XFSize,
                                Food_Type:1,
                                Food_Speed:it.Food_Speed||'即',
                                Food_Number:it.Eat_Number,
                                Food_AssNumber:Number(it.AssSizeNumber),
                                Food_Price:it.Eat_SalePrice,
                                Food_CheckPrice:it.Eat_CheckPrice,
                                ConsumeOperations:it.ConsumeOperations,
                                FoodModifys:it.FoodModifys
                            }
                        }),
                        Food_Name:item?.EAT_XFName,
                        Food_AssNumber:SizeNumber,
                        Food_CheckPrice:item.Eat_CheckPrice,
                        ConsumeOperations:item.ConsumeOperations
                    };
                    _orderConsumes.push(orders);
                } 
            })
            
            if(_orderConsumes.length==0){
                this.$alert('请先选择需要落单的菜品!', '提示', {confirmButtonText: '确定',});
                return
            }

            //开启了席数功能
            if(_data.RunFeastDeskNum==true && Number(isRunOrderNum)>0 && this.orderInfo.Feast_DeskNum>=1){
                if(Number(isRunOrderNum)!= Number(this.orderInfo.Feast_DeskNum)){
                    this.$confirm('本桌台为'+this.orderInfo.Feast_DeskNum+'席,但套餐份数为'+Number(isRunOrderNum)+'/份,请确认数量,是否继续下单?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.placeOrder(NotSendBool,NotLimitFoodInventory,_orderConsumes)
                    }).catch(() => {});
                }else{
                    this.placeOrder(NotSendBool,NotLimitFoodInventory,_orderConsumes)
                }   
            }else{
                this.placeOrder(NotSendBool,NotLimitFoodInventory,_orderConsumes)
            }
        },
        placeOrder(NotSendBool,NotLimitFoodInventory,_orderConsumes){
            if(this.orderInfo.UnionOrderInfoList?.length>0){//联台下单
                
                this.$refs.unionOrder.param={
                    NotSendBool:NotSendBool,
                    NotLimitFoodInventory:NotLimitFoodInventory,
                    _orderConsumes:_orderConsumes
                }
                this.isUnionDishShow=true;
                this.$keyavail=true;
            }else{
                this.placeOrderMethod(NotSendBool,NotLimitFoodInventory,null,_orderConsumes);//单台下单
            }
        },
        /**联台下单 */
        unionDishConfirm(unionList,param){
            this.placeOrderMethod(param.NotSendBool,param.NotLimitFoodInventory,unionList,param._orderConsumes);
        },
        /**调用下单接口 
         * @param NotSendBool  下单不送打厨房
         * @param NotLimitFoodInventory 下单不关联菜品库存数 默认 false
         * @param sendUnionOrders 联台信息
         * @param _orderConsumes 下单明细
        */
        placeOrderMethod(NotSendBool,NotLimitFoodInventory,sendUnionOrders,_orderConsumes){
            //参数
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_DeskID:this.posDesks.Desk_AutoID, //桌台ID
                Order_ID:this.posDesks.Eat_AutoID, //订单Id
                Eat_AllRemark:'', //订单全单备注
                Order_Remark:this.orderInfo.Order_Remark,
                Order_BatchNo:newGuid(), //订单批次号
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Eat_WaiterName:this.orderInfo?.Eat_WaiterName,
                NotSendToKitPrint:NotSendBool, //下单不送达厨房
                NotLimitFoodInventory:NotLimitFoodInventory===true,//下单不关联菜品库存数
                Timestamps:this.orderInfo?.Timestamps.toString(), //订单时间戳
                OrderConsumes:_orderConsumes, //菜品
                SendUnionOrders:sendUnionOrders,//联台信息
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }

            let url="Bestech.CloudPos.SendOrderDish";
            if(sendUnionOrders?.length>0){//联台下单
                url="Bestech.CloudPos.SendUnionOrderDish";
            }
            const loading = this.$loading({
                text: "数据传输中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post(url,param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error({ message: data.ResponseHeader.ResultDesc,showClose:true,duration:0});
                }else{
                    this.isUnionDishShow=false;
                    this.$keyavail=false;

                    ElMessage.success({message:data.ResponseHeader.ResultDesc});
                    this.isPlace=true
                    /**清理页面选择的订单数据 */
                    this.mallMethod=[], //全单口味做法
                    this.aloneMethod=[], //单独口味做法
                    this.orderInfo.Order_ConsumeList =  this.orderInfo.Order_ConsumeList.filter(item=>{
                        return item.Food_Status!=-1
                    })
                    /**重新填充订单数据 */
                    data.ResponseBody.Order_ConsumeList.map(order=>{
                        this.orderInfo.Order_ConsumeList.push(order)
                    })
                    this.orderInfo.IsPromotionPrice=data.ResponseBody.IsPromotionPrice
                    this.orderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                    //this.orderInfo.Eat_SaleMoney += data.ResponseBody.Eat_SaleMoney
                    this.orderInfo.Eat_SaleMoney = data.ResponseBody.Eat_SaleMoney
                    this.orderInfo.Eat_CostMoney = data.ResponseBody.Eat_CostMoney
                    this.orderInfo.Eat_CheckMoney = data.ResponseBody.Eat_CheckMoney
                    this.orderInfo.UnPaid_Money = data.ResponseBody.UnPaid_Money
                    this.orderMenuList = this.orderInfo.Order_ConsumeList
                    this.disRadioclass=-1,this.disRadioCheck=false
                    this.disRadioOrder={}
                    //下单后打印
                    if(data.ResponseBody?.PrintsInfo){
                        this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                    }
                    this.posFoodNumbermethod(4);

                    if(_data.OrderLockSendDish){
                        this.orderInterReturn(2)
                    }else if(_data.RunBackToDeskMapAfterSendDish){
                        this.orderInterReturn(1)
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**结账跳转 */
        onCheckOut(){
            this.BindCardCleanup('','delete')
            /**获取开台的会员信息 */
            if(this.orderInfo.Member_CardNo!=''){
                //获取海鼎配置的终端
                this.$webBrowser.getEntityCards().then(d=>{
                    if(d){
                        this.onMemberInfo(d.TerminationID,d.ServerID)
                    }else{
                        this.onMemberInfo('','')
                    }
                })
            }
            this.choicePayType=[]
            this.Initialization()
            if(this.orderInfo.UnionOrderInfoList!=null && this.orderInfo.UnionOrderInfoList.length>0){
                this.unionName =this.orderInfo.Eat_DeskName+',';
                this.unionNumber=1;
                this.orderInfo.UnionOrderInfoList.forEach(union=>{
                    this.unionNumber++
                    this.unionName += union.Eat_DeskName+','
                    this.unionMoney = this.unionMoney + union.Eat_SaleMoney
                    this.unionPaymentMoney += union.Payment_Money
                    this.unionUnPaidMoney += union.UnPaid_Money
                    this.unionGiftMoney +=union.Gift_Money
                    this.unionZKMoney +=union.ZK_Money
                    this.unionServiceMoney += union.Service_Money
                    this.unionEatCheckMoney +=union.Eat_CheckMoney
                    this.unionRefundMoney += union.Refund_Money
                    this.unionPayableMoney +=union.Payable_Money
                })
                this.unionName = this.unionName.substring(0,this.unionName.lastIndexOf(','))
                this.$confirm('共'+this.unionNumber+'桌  关联桌台'+this.unionName+'一起结账吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.unionType = true
                    this.onCheckOutOrderInfo()
                }).catch(() => {
                    this.unionType=false
                    this.unionName =''
                    this.Initialization()
                    this.onCheckOutOrderInfo()
                });
            }else{
                this.onCheckOutOrderInfo()
            }
            /**调整结账中间按钮样式 */
        },
        /**结账获取下单信息 */
        onCheckOutOrderInfo(){
            initKeyBoard.call(this,this.$refs.paymetMoney,this.$refs.money);
            /**结账时，如存在未改量菜品处理方式，0:’允许结账’,1:’仅提醒需改量菜品，允许结账’,2:’存在未改量菜品，不允许结账’ */
            if(_data.CheckOutChangeNumFood==1 || _data.CheckOutChangeNumFood==2){
                let _FoodName= this.orderFoodName()
                if(_FoodName!='' && _data.CheckOutChangeNumFood==1){
                    this.$message.warning('订单中:'+_FoodName+',菜品暂未改量');
                }else if(_FoodName!='' && _data.CheckOutChangeNumFood==2){
                    this.$message.warning('订单中:'+_FoodName+',菜品暂未改量需先去调整菜品数量');
                    this.Initialization()
                    return
                }
            }
            this.isCheckOut=true;
            this.checOutShow='height:calc(100% - 228px  - 5px)';
            this.unionEatAutoID=[]
            /**获取下单信息 */
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_DeskID:this.posDesks.Desk_AutoID, //桌台ID
                EAT_AUTOID:this.posDesks.Eat_AutoID, //订单Id
                Is_UnionOrder:this.unionType
            }
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetOrderInfo",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                }else{
                    console.log(data)
                    this.orderInfo = data.ResponseBody;
                    this.orderMenuList=JSON.parse(JSON.stringify(data.ResponseBody.Order_ConsumeList))
                    this.orderPaymentDetailList = data.ResponseBody.Order_PaymentDetailList;
                    if(this.orderInfo.Eat_CheckIndex!=''){
                        _data.NoNeedPrintBill=false
                    }
                    if(this.unionType){
                        if(this.orderInfo.UnionOrderInfoList){
                            this.Initialization();
                            this.unionName =this.orderInfo.Eat_DeskName+',';
                            this.unionNumber=1;
                            this.orderInfo.UnionOrderInfoList.forEach(union=>{
                                if(union.IsMemberPrice){
                                    this.userCheckOutMiddleMenu.forEach(item=>{
                                        if(item.ProjectItem_Key=="MemberPrice"){
                                            item.ProjectItem_Name='取消会员价'
                                        }
                                    })
                                }
                                union.Order_ConsumeList.forEach(order=>{
                                    this.orderMenuList.push(order)
                                })
                                if(union.Order_PaymentDetailList){
                                    //data.ResponseBody.Order_PaymentDetailList.concat(union.Order_PaymentDetailList)
                                    this.orderPaymentDetailList = this.orderPaymentDetailList.concat(union.Order_PaymentDetailList)
                                }
                                let unionEatList={Order_ID:union.Eat_AutoID,Service_Charge:union.Service_Money,Timestamps:union.Timestamps.toString()}
                                this.unionEatAutoID.push(unionEatList)

                                this.unionNumber++
                                this.unionName += union.Eat_DeskName+','
                                this.unionMoney = this.unionMoney + union.Eat_SaleMoney
                                this.unionPaymentMoney += union.Payment_Money
                                this.unionUnPaidMoney += union.UnPaid_Money
                                this.unionGiftMoney +=union.Gift_Money
                                this.unionZKMoney +=union.ZK_Money
                                this.unionServiceMoney += union.Service_Money
                                this.unionEatCheckMoney +=union.Eat_CheckMoney
                                this.unionRefundMoney += union.Refund_Money
                                this.unionPayableMoney +=union.Payable_Money
                            })
                            this.unionName=(this.unionName||"").replace(/[,]$/,"");
                            this.orderInfo.Eat_SaleMoney += this.unionMoney
                            this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(this.unionUnPaidMoney);
                            this.orderInfo.Payment_Money +=this.unionPaymentMoney
                            this.orderInfo.Payable_Money +=this.unionPayableMoney
                            // this.orderInfo.Eat_CheckMoney +=this.unionEatCheckMoney
                            // this.orderInfo.Service_Money += this.unionServiceMoney
                        }
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**获取支付方式*/
        onChoicePayType(pay){
            console.log(pay);
            this.money="";
            /**判断是否有结账的权限 */
            let proKey=this.checkoutPermission()
            if(proKey==false){
                this.money=''
                this.$alert('您没有结账权限，请联系后台开通结账权限！', '提示', {confirmButtonText: '确定',});
                return
            }
            this.choicePayType = pay
            if(pay.INTERFACE_TYPE=="801010401"){
                /**会员支付 */
                if(this.wlivePayExist==false){
                    this.ispayid = pay.PAY_ID
                    this.isPayName = pay.PAY_NAME
                    this.memberPayShow = true;
                    this.$keyavail=true;
                }else{
                    this.$message.warning('微生活会员和正品会员无法一起支付');
                    this.$keyavail=true;
                    return
                }
            }else if(pay.INTERFACE_TYPE=="801010501"){ /**微生活801010501 */
                this.ispayid = pay.PAY_ID
                this.isPayName = pay.PAY_NAME
                this.wliveMemberPayShow = true;
                this.$keyavail=true;
            }else if(pay.INTERFACE_TYPE=="9022001"){ /**新享会*/
                this.ispayid = pay.PAY_ID
                this.isPayName = pay.PAY_NAME
                this.newEnjoyShow = true;
                this.$keyavail=true;
            }else if(pay.INTERFACE_TYPE=='9031001'){/**二维火券支付 */
                this.ispayid = pay.PAY_ID
                this.isPayName = pay.PAY_NAME
                this.TwoDfireShow = true;
                this.$keyavail=true;
            }else if(pay.INTERFACE_TYPE=="801010301"){
                this.payTypeSSPay = pay
                this.ispayid = pay.PAY_ID
                this.isPayName = pay.PAY_NAME
                this.scanCodePay=true
                this.$keyavail=true;
            }else if(pay.INTERFACE_TYPE=="9046001"){//银石智能POS支付
                this.ispayid = pay.PAY_ID;
                this.isPayName = pay.PAY_NAME;
                this.yinShiPayShow=true;
                this.$keyavail=true;
            }else if(pay.INTERFACE_TYPE=='5'){ /**AR账 */
                this.ispayid = pay.PAY_ID
                this.isPayName = pay.PAY_NAME
                this.arShow = true
                this.$keyavail=true;
            }else if(pay.INTERFACE_TYPE=='9023001'){ /**润点中台挂账 */
                this.ispayid = pay.PAY_ID
                this.isPayName = pay.PAY_NAME
                this.enterCloseShow = true
                this.$keyavail=true;
            }else if(pay.INTERFACE_TYPE=='9017001'){ /**西软挂账 */
                this.westShow=true
                this.$keyavail=true;
                this.ispayid = pay.PAY_ID
                this.isPayName = pay.PAY_NAME
            }
            else if(pay.INTERFACE_TYPE=='9024001'){
                this.ispayid = pay.PAY_ID
                this.isPayName = pay.PAY_NAME
                this.savePayShow=true
                this.$keyavail=true;
            }else if(pay.INTERFACE_TYPE=='9033001'){
                this.ispayid = pay.PAY_ID
                this.isPayName = pay.PAY_NAME
                this.zpCouponShow=true
                this.$keyavail=true;
            }else if(pay.INTERFACE_TYPE=='9036002'){/**昆仑会员支付 */
                this.ispayid = pay.PAY_ID
                this.isPayName = pay.PAY_NAME
                this.kunlunPayShow = true;
                this.$keyavail=true;
            }else if(pay.INTERFACE_TYPE=='9026001'){
                if(this.wlivePayExist==false){
                    this.ispayid = pay.PAY_ID
                    this.isPayName = pay.PAY_NAME
                    this.gNinePayShow = true;
                    this.$keyavail=true;
                }else{
                    this.$message.warning('微生活会员和G9暂时无法同时支付');
                    this.$keyavail=true;
                    return
                }
            }else if(pay.INTERFACE_TYPE=='9030001'){
                if(this.wlivePayExist==false){
                    this.ispayid = pay.PAY_ID
                    this.isPayName = pay.PAY_NAME
                    this.hdShow=true;
                    this.$keyavail=true;
                }else{
                    this.$message.warning('微生活会员和海鼎支付暂时无法同时支付');
                    this.$keyavail=true;
                    return
                }
            }else if(pay.INTERFACE_TYPE=='9017002'){
                if(this.wlivePayExist==false){
                    this.ispayid = pay.PAY_ID
                    this.isPayName = pay.PAY_NAME
                    this.westStoredShow=true;
                    this.$keyavail=true;
                }else{
                    this.$message.warning('微生活会员和西软XMS储值暂时无法同时支付');
                    this.$keyavail=true;
                    return
                }
            }else if(pay.INTERFACE_TYPE=='11'){//预付金，押金
                this.ispayid = pay.PAY_ID
                this.isPayName = pay.PAY_NAME
                this.preMoneyShow=true;
                this.$keyavail=true;
            }
            else if(pay.INTERFACE_TYPE=='9017004'){//西软券核销
                this.ispayid = pay.PAY_ID
                this.isPayName = pay.PAY_NAME
                this.westSoftCouponShow=true
                this.$keyavail=true;
            }else if(pay.INTERFACE_TYPE=='9044001'){//大拈会员支付
                this.ispayid = pay.PAY_ID;
                this.isPayName = pay.PAY_NAME;
                this.daNianPayShow=true;
                this.$keyavail=true;
            }
            else{
                if(this.ispayid != pay.PAY_ID){
                    this.ispayid = pay.PAY_ID
                    this.isPayName = pay.PAY_NAME
                }
                if(pay.INTERFACE_TYPE=='7' || pay.INTERFACE_TYPE=='8'){
                    this.paymentCouponsShow=true;
                    this.payCouponsData=pay;
                }else{
                    this.$nextTick(()=>{
                        let money=Number(this.orderInfo.UnPaid_Money).toFixed(2);
                        this.money=parseFloat(money);
                        if(this.money<0){
                            this.money=0
                        }
                        this.$refs.money?.focus();
                        setTimeout(()=>{
                            this.$refs.money?.select();
                        },100)
                    })
                }
            }
        },
        /**找零 */
        Givechange(UnPaid_Money,money,Mantissa_Money){
            if(money>=0 && this.choicePayType.INTERFACE_TYPE ==1){
                if(this.UnPaidMoney>0){
                    if(Number(money)<(this.UnPaidMoney)){
                        return 0
                    }else{
                        return  Number((Number(money)-(this.UnPaidMoney)).toFixed(2))
                    }
                }else{
                    if(Number(money)<(UnPaid_Money)){
                        return 0
                    }else{
                        return  Number((Number(money)-(UnPaid_Money)).toFixed(2))
                    }
                }
            }else{
                return 0
            }
        },
        /**支付预览 isCardMember:是否需要传会员信息*/
        onPayPreview(){
            /**判断是否有结账的权限 */
            let proKey=this.checkoutPermission()
            if(proKey==false){
                this.money=''
                this.$alert('您没有结账权限，请联系后台开通结账权限！', '提示', {confirmButtonText: '确定',});
                return
            }
            /**中餐结账前，不控制必须打印消费清单 */
            if(_data.NoNeedPrintBill==true){
                this.$message.warning('请先打印消费清单');
                return
            }
            if(this.money===''){
                this.$message.error('支付金额不能为空');
                return
            }
            if(Number(this.orderInfo.UnPaid_Money).compute(this.orderInfo.Mantissa_Money,'-')==0){
                this.$message.error('没有需要支付的订单,请直接使用完成结账功能');
                return
            }
            if(this.choicePayType.PAY_ID==null){
                this.$alert('请先选择支付类型', '提示', {confirmButtonText: '确定',});
                return
            }
            let FactMoney=[];
            if(Number(this.money)>0){
                FactMoney.push({
                    Payment_ID:this.choicePayType.PAY_ID, //支付方式ID
                    Payment_Code:this.choicePayType.PAY_CODE,  //支付方式编号
                    Payment_Name:this.choicePayType.PAY_NAME,  //支付方式名称
                    Payment_Type:this.choicePayType.INTERFACE_TYPE,  //支付方式类型
                    Payment_Money:Number(this.money), //支付方式金额
                    Payment_FactMoney:Number(this.money),  //支付实收金额
                    Payment_Remark:this.RemarkTitle,
                    Qr_Code:"", //扫码付款码值
                    Card_No:"", //卡号
                    CouponInfo:null, //该支付金额核销的券信息
                });
            }
            let cashPayType=0
             //当实付金额大于剩余结账金额时  +Number(this.orderInfo.Mantissa_Money)
            if(Number(Number(this.money).toFixed(2))>Number(Number(this.orderInfo.UnPaid_Money).toFixed(2))){
                FactMoney.push({
                    Payment_ID:this.choicePayType.PAY_ID, //支付方式ID
                    Payment_Code:this.choicePayType.PAY_CODE,  //支付方式编号
                    Payment_Name:this.choicePayType.PAY_NAME,  //支付方式名称
                    Payment_Type:this.choicePayType.INTERFACE_TYPE,  //支付方式类型
                    Payment_Money:Number(this.orderInfo.UnPaid_Money)-Number(this.money), //支付方式金额
                    Payment_FactMoney:Number(this.orderInfo.UnPaid_Money)-Number(this.money),  //支付实收金额
                    Payment_Remark:this.RemarkTitle,
                    Qr_Code:"", //扫码付款码值
                    Card_No:"", //卡号
                    CouponInfo:null, //该支付金额核销的券信息
                });
                if(this.choicePayType.INTERFACE_TYPE==1){
                    cashPayType=1
                }
            }else{
                cashPayType=1
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            //支付预览
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.orderInfo.Eat_AutoID, //结账单ID
                UnionOrders:this.unionEatAutoID,//联台订单集合
                Timestamps:this.orderInfo.Timestamps.toString(),//账单时间戳
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Eat_DeskName:this.orderInfo.Eat_DeskName,//桌牌号
                Service_Charge:this.orderInfo.Service_Money,//服务费
                MantissaAdjustment_Money:Number(Number(this.orderInfo.Mantissa_Money).toFixed(2)), //尾数调整金额
                Member:null,//会员信息
                Payments:FactMoney,//支付方式
            }
            //&& isCardMember
            if(Number(Number(this.orderInfo.UnPaid_Money).toFixed(2))==Number(this.money) && this.cardMemberList && this.wlivePayExist==false){
                param.Member=this.cardMemberList
                this.$confirm('本单已绑定会员信息，是否确认提交并结账？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                   this.wliveIsCheckOut=false
                   this.PayPreview(param,cashPayType);
                })
            }else{
                this.PayPreview(param,cashPayType);
            }
        },
        PayPreview(param,cashPayType){
            /**支付预览提交 */
            const loading = this.$loading({
                text: "支付提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.PayPreview",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                    if(data.ResponseBody?.Timestamps){
                        this.orderInfo.Timestamps = data.ResponseBody?.Timestamps.toString()
                    }
                    if(data.ResponseHeader.ResultCode==3002){
                        this.refreshOrder()
                    }
                }else{
                    this.orderInfo.Timestamps = data.ResponseBody?.Timestamps.toString()
                    this.orderInfo.Eat_CheckIndex = data.ResponseBody?.Eat_CheckIndex
                    this.tempUserId = data.ResponseBody?.User_ID
                    this.tempOperatorName = data.ResponseBody?.User_Name
                    if(this.orderInfo.UnionOrderInfoList){
                        this.unionTimeUpdate(data.ResponseBody)
                    }
                    //不需要走提交接口的是，直接将支付方式加入页面
                    if(data.ResponseBody?.Is_NeedSubmit==0)
                    {
                        if(data.ResponseBody?.Payments){
                            this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data.ResponseBody?.Payments);
                        }
                    }
                    if(Number(this.money)>=Number(Number(this.orderInfo.UnPaid_Money).toFixed(2))){
                        this.UnPaidMoney= Number(Number(this.orderInfo.UnPaid_Money).toFixed(2))
                        this.orderInfo.UnPaid_Money = 0;
                    }else{
                        this.orderInfo.UnPaid_Money =Number(this.orderInfo.UnPaid_Money).compute(this.money,'-');
                        this.money=''
                    }
                    if(data.ResponseBody?.Payments){
                        if(data.ResponseBody?.Payments.length>0){
                            data.ResponseBody?.Payments.forEach(pay=>{
                                this.orderInfo.Payment_Money = this.orderInfo.Payment_Money+Number(pay.Payment_Money);
                            })
                        }
                    }
                    //是否需要调用提交接口 0不需要，1需要 && 已付金额等于消费金额时去调用结算接口
                    if(data.ResponseBody?.Is_NeedSubmit==1 &&  Number(this.orderInfo.UnPaid_Money).toFixed(2)<=0){
                        /**支付提交 */
                        this.PaySubmit(data)
                    }
                    if(data.ResponseBody?.Is_NeedSubmit==0 && Number(this.orderInfo.UnPaid_Money).toFixed(2)<=0){
                        /**结账 */
                        if(cashPayType==1){
                            //存在微生活的支付记录
                            if(this.wlivePayExist==true){
                                this.wlivePayPreview()
                            }else{
                                this.PerformingCheckOut()
                            }
                        }
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**微生活支付预览
         * @param Is_NotRockover 是否不翻台
         */
        wlivePayPreview(Is_NotRockover){
            let FactMoney=[]
            FactMoney = JSON.parse(JSON.stringify(this.wlivePayList[0].Payments))
            this.wliveCardMember =JSON.parse(JSON.stringify(this.wlivePayList[0].Member))
            //说明是多卡支付
            if(this.wlivePayList.length>1){
                let rechargeMoney=0
                this.wlivePayList.forEach((wli,i)=>{
                    if(i>0){
                        wli.Payments.forEach(pay=>{
                            rechargeMoney+=Number(pay.Payment_Money)
                        })
                    }
                })
                if(FactMoney.length>0){
                    FactMoney.push({
                        Payment_ID:'CardMultiplePay',  //支付方式ID
                        Payment_Code:FactMoney[0].Payment_Code, //支付方式编号
                        Payment_Name:FactMoney[0].Payment_Name, //支付方式名称
                        Payment_Type:FactMoney[0].Payment_Type, //支付方式类型
                        Payment_Money: Number(rechargeMoney), //支付金额
                        Payment_FactMoney:0, //计营业额
                        Qr_Code:'', //扫码付款的码值
                        Card_No:'', //卡号
                        CouponInfo:null, //券的详情
                    })
                }
            }
            //同一笔支付,储值和积分不能同时使用，0：不限制， 1：开启限制
            if(this.wliveCardMember.isForbidBalanc==1){
                let _cardmoney=0,_cardPoint=0
                FactMoney.forEach(fa=>{
                    if(fa?.Payment_ID=='CardMoneyPay'){
                        _cardmoney=1
                    }
                    if(fa?.Payment_ID=='CardPointPay'){
                        _cardPoint=1
                    }
                })
                if(_cardmoney==1 && _cardPoint==1){
                    this.$message.error('储值和积分不能同时使用');
                    return
                }
            }
            //支付预览
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.orderInfo.Eat_AutoID, //结账单ID
                UnionOrders:this.unionEatAutoID,//联台订单集合
                Timestamps:this.orderInfo.Timestamps.toString(),//账单时间戳
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Eat_DeskName:this.orderInfo.Eat_DeskName,//桌牌号
                Service_Charge:this.orderInfo.Service_Money,//服务费
                MantissaAdjustment_Money:Number(Number(this.orderInfo.Mantissa_Money).toFixed(2)), //尾数调整金额
                Member:this.wliveCardMember,//会员信息
                Payments:FactMoney,//支付方式
            }
            /**支付预览提交 */
            const loading = this.$loading({
                text: "支付提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.PayPreview",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                    if(data.ResponseBody?.Timestamps){
                        this.orderInfo.Timestamps = data.ResponseBody?.Timestamps.toString()
                    }
                }else{
                    this.orderInfo.Timestamps = data.ResponseBody?.Timestamps.toString()
                    this.orderInfo.Eat_CheckIndex = data.ResponseBody?.Eat_CheckIndex
                    this.tempUserId = data.ResponseBody?.User_ID
                    this.tempOperatorName = data.ResponseBody?.User_Name
                    this.payPreviewData = data
                    if(this.orderInfo.UnionOrderInfoList){
                        this.unionTimeUpdate(data.ResponseBody)
                    }
                    /**Is_NeedSMSCode：是否需要短信验证码0不需要1需要 */
                    if(data.ResponseBody.Is_NeedSMSCode==1){
                        this.wliveCardCodeShow = true
                        return
                    }
                    /**Is_NeedPassWord：是否需要密码验证0不需要1需要 */
                    if(data.ResponseBody.Is_NeedPassWord==1){
                        this.wlivePasswordShow=true
                        return
                    }
                    //不需要走提交接口的是，直接将支付方式加入页面
                    if(data.ResponseBody?.Is_NeedSubmit==0)
                    {
                        this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data.ResponseBody?.Payments);
                    }
                    if(data.ResponseBody?.Payments.length>0){
                        //this.orderInfo.Payment_Money = this.orderInfo.Payment_Money+Number(this.money);
                        if(Number(this.money)>=Number(Number(this.orderInfo.UnPaid_Money).toFixed(2))){
                            this.UnPaidMoney= Number(Number(this.orderInfo.UnPaid_Money).toFixed(2))
                            this.orderInfo.UnPaid_Money = 0;
                        }else{
                            this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(this.money,'-');
                            this.money=''
                        }
                        // if(data.ResponseBody?.Payments.length>0){
                        //     data.ResponseBody?.Payments.forEach(pay=>{
                        //         this.orderInfo.Payment_Money = this.orderInfo.Payment_Money+Number(pay.Payment_Money);
                        //     })
                        // }
                    }
                    //需要走支付提交接口
                    if(data.ResponseBody?.Is_NeedSubmit==1){
                        /**支付提交 */
                        this.PaySubmit(data,Is_NotRockover)
                     }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**完成结账 */
        PerformingCheck(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            if(this.orderInfo.UnPaid_Money<0){
                this.money=0
            }
           /**如果未付金额为空并且支付记录为空,则传一条价格为0的支付记录过去 */
           if((this.orderInfo.UnPaid_Money).toFixed(2)==0){
               if(this.orderInfo.Order_PaymentDetailList?.length>0 || this.orderPaymentDetailList.length>0){
                    /**结账 */
                    this.PerformingCheckOut()
               }else{
                    let FactMoney=[];

                    let pay=this.payType?.find(it=>it.INTERFACE_TYPE==1);
                    if(!pay){
                        this.$message.error('支付账单金额为0,门店支付方式缺少现金支付,需要去后台调整相对应的配置信息');
                        return
                    }
                    FactMoney.push({
                        Payment_ID:pay.PAY_ID, //支付方式ID
                        Payment_Code:pay.PAY_CODE,  //支付方式编号
                        Payment_Name:pay.PAY_NAME,  //支付方式名称
                        Payment_Type:pay.INTERFACE_TYPE,  //支付方式类型
                        Payment_Money:Number(this.orderInfo.UnPaid_Money), //支付方式金额
                        Payment_FactMoney:Number(this.orderInfo.UnPaid_Money),  //支付实收金额
                        Qr_Code:"", //扫码付款码值
                        Card_No:"", //卡号
                        CouponInfo:null, //该支付金额核销的券信息
                    });
                    //支付预览
                    let param={
                        Eat_AutoID:this.orderInfo.Eat_AutoID, //结账单ID
                        Eat_CheckIndex:this.orderInfo.Eat_CheckIndex, //结账单号
                        Eat_ID:this.orderInfo.Eat_ID,//账单流水号
                        Timestamps:this.orderInfo.Timestamps.toString(),//账单时间戳
                        User_ID:this.userInfo.User_ID,  //用户id
                        Operator_Name:this.userInfo?.Login_Name, //操作员
                        Operate_EquipmentID:'',
                        Pos_StationName:this.userInfo?.Site_Name,//站点名称
                        Eat_DeskName:this.orderInfo.Eat_DeskName,//桌牌号
                        Service_Charge:this.orderInfo.Service_Money,//服务费
                        MantissaAdjustment_Money:Number(Number(this.orderInfo.Mantissa_Money).toFixed(2)), //尾数调整金额
                        Member:null,//会员信息
                        Payments:FactMoney,//支付方式
                    }
                    // if(Number(this.orderInfo.UnPaid_Money)==Number(this.money) && this.cardMemberList){
                    //     param.Member=this.cardMemberList
                    // }
                    const loading = this.$loading({
                        text: "支付提交中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    this.$httpAES.post("Bestech.CloudPos.PayPreview",param).then((data)=>{
                        loading.close();
                        if(data.ResponseHeader.ResultCode!=0){
                            this.$message.error(data.ResponseHeader.ResultDesc)
                        }else{
                            this.orderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                            this.tempUserId = data.ResponseBody?.User_ID
                            this.tempOperatorName = data.ResponseBody?.User_Name
                            /**保存新时间戳 */
                            if(data.ResponseBody?.UnionOrders){
                                this.unionTimeUpdate(data.ResponseBody)
                            }
                            /**完成结账 */
                            this.PerformingCheckOut()
                        }
                    }).catch((e)=>{
                        loading.close();
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    })
                }
           }else if(Number(this.money).toFixed(2)==Number(this.orderInfo.UnPaid_Money).toFixed(2) && this.isPayName!=''){
                this.onPayPreview() //支付预览
           }else{
               this.$message.error('支付金额和消费金额不一致');
           }
        },
        /**支付提交方法 
         * @param Is_NotRockover 是否不翻台
        */
        PaySubmit(data,Is_NotRockover){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID, //登陆用户ID
                Eat_AutoID: this.orderInfo.Eat_AutoID,//结账单ID
                Member_Type:data.ResponseBody.Member_Type,  //会员类型
                Biz_ID:data.ResponseBody.Biz_ID, //业务交易流
                Verify_Code:'', //验证码
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Timestamps:data.ResponseBody.Timestamps.toString(),
                Payments:data.ResponseBody.Payments
            }
            //微生活支付验证
            if(this.wliveVerify){
                param.Verify_Code = this.wliveVerify
            }
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.tempOperatorName
            }
            /**支付提交 */
            const loading = this.$loading({
                text: "支付提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.PaySubmit",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.UnPaidMoney=0                    
                    this.orderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    //存在微生活的支付记录
                    if(this.wlivePayExist){
                        this.wliveIsCheckOut=true
                    }
                }else{
                    //支付提交成功后，返回的支付方式
                    if(data.ResponseBody?.Payments){
                        data.ResponseBody?.Payments.forEach(pay=>{
                            this.orderPaymentDetailList=this.orderPaymentDetailList.concat(pay);
                        })
                    }
                    if(this.wlivePayExist==true){
                        let serialNo=this.wlivePayList[0].Payments[0]?.serialNo
                        if(serialNo){
                            for(let i=0; i<this.orderPaymentDetailList.length; i++){
                                if(this.orderPaymentDetailList[i]?.serialNo==serialNo){ 
                                    this.orderPaymentDetailList.splice(i,1);
                                    i--;
                                }
                            }
                            this.wlivePayList.splice(0,1)
                        }else{
                            this.wlivePayList.splice(0,1)
                        }
                    }
                    this.orderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                    if(this.orderInfo.UnionOrderInfoList){
                        this.unionTimeUpdate(data.ResponseBody)
                    }
                    this.orderInfo.Eat_CheckIndex = data.ResponseBody.Eat_CheckIndex
                    if(this.wlivePayList.length==0){
                        this.wlivePayExist=false
                    }else{
                        this.wlivePayPreview(Is_NotRockover)
                    }
                    if(data.ResponseBody?.TryCheckOut==true){
                        if(!Is_NotRockover){
                            /**完成结账 */
                            this.PerformingCheckOut()
                        }
                       
                    }
                    
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**结账方法 */
        PerformingCheckOut(){
            let isshowconfirm=false;
            if(this.wliveIsCheckOut){
                isshowconfirm=true;
                if(this.isCardMemberBackint){
                    isshowconfirm=false;
                }
            }
            /**结账 */
            if(isshowconfirm){
                this.$confirm('确定结账吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'})
                .then(() => {
                    this.subPerformingCheckOut()
                }).catch(() => {});
            }else{
                this.subPerformingCheckOut()
            }
        },
        subPerformingCheckOut(){
            //存在微生活的支付记录
            if(this.wlivePayExist){
                this.wliveIsCheckOut=false
                this.wlivePayPreview()
                return
            }
            let isloadPayPreview=false;
            isloadPayPreview=this.orderPaymentDetailList.some((item)=>{
                if(item.Payment_Code=="1011")
                {
                    return true;
                }
            });
            if(isloadPayPreview){
                if(!this.isCardMemberBackint&&this.cardMemberList){         
                    this.isCardMemberBackint=true;   
                    let param={
                        Body_ID:newGuid(),
                        User_ID:this.userInfo?.User_ID, //登陆用户ID
                        Eat_AutoID: this.orderInfo.Eat_AutoID,//结账单ID
                        UnionOrders:this.unionEatAutoID,//联台订单ID集合
                        Timestamps:this.orderInfo.Timestamps.toString(), //账单时间戳
                        Operator_Name:this.userInfo?.Login_Name,//操作员
                        Eat_DeskName:this.orderInfo.Eat_DeskName, //桌牌号
                        Service_Charge:this.orderInfo.Service_Money,  //服务费
                        MantissaAdjustment_Money:this.orderInfo.Mantissa_Money,  //尾数调整金额
                        Member:this.cardMemberList,//会员信息
                    }
                    param.Member=this.cardMemberList;
                    let cashPayType=0;
                    if(Number(Number(this.money).toFixed(2))==Number(Number(this.orderInfo.UnPaid_Money).toFixed(2))){
                        cashPayType=1;
                    }
                    this.PayPreview(param,cashPayType)
                    return;
                }
            }
            let EatAutoIDList=[]
            let orderEatAutoList={Order_ID:this.orderInfo.Eat_AutoID,Timestamps:this.orderInfo.Timestamps.toString(),Service_Charge:this.orderInfo.Service_Money}
            EatAutoIDList.push(orderEatAutoList)
            let _Union = []
            if(this.unionType==true){
                if(this.orderInfo.UnionOrderInfoList){
                    this.orderInfo.UnionOrderInfoList.map(union=>{
                        orderEatAutoList = {Order_ID:union.Eat_AutoID,Timestamps:union.Timestamps,Service_Charge:union.Service_Money}
                        EatAutoIDList.push(orderEatAutoList)
                        let unionDesk ={Eat_DeskID:union.Eat_DeskID}
                        _Union.push(unionDesk)
                    })
                }
            }
            let param={
                User_ID:this.userInfo.User_ID,  //用户id
                CheckOutOrders:EatAutoIDList, //结账订单ID
                MantissaAdjust_Money:Number(Number(this.orderInfo.Mantissa_Money).toFixed(2)), //尾数调整金额
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Print_YN:true,
                ElectronicInvoice_PrintForbidden:this.$global.invoiceOff,//是否选择禁止打印发票二维码 false: 默认打印发票二维码,true: 不打印发票二维码
                PhaseID:this.$global.PhaseID,//指定时段ID
            }
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.tempOperatorName
            }
            const loading = this.$loading({
                text: "结账中请稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log('结账提交param:',param);
            this.$httpAES.post("Bestech.CloudPos.PerformingCheckOut",param).then((data)=>{
                loading.close();
                //console.log('结账提交data:'+JSON.stringify(data))
                if(data?.ResponseHeader.ResultCode!=0){
                    this.refreshOrder()
                    this.isPayName='暂无'
                    this.choicePayType=[]
                    this.money=''
                    this.$message.error(data?.ResponseHeader.ResultDesc);
                }else{
                    if(data.ResponseBody?.OpenCashBox===true){
                        this.$webBrowser.openCashBoxBnt().then(d=>{
                            if(d?.state!=0){
                                this.$message.error('钱箱打开失败:'+(d?.message||""));
                            }
                        });
                    }
                    if(param.Print_YN===true){//返回结账单打印内容
                        setTimeout(()=>{
                            this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                        },500);
                    }
                    
                    this.orderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                    ElMessage.success({ message: "结账完成"});
                    let PosDesk = {Desk_AutoID:this.posDesks.Desk_AutoID,DeskTimestamps:data.ResponseBody.DeskTimestamps.toString()}
                    if(this.unionType==true){
                        let unionTime=[]
                        data.ResponseBody.UnionOrders.map(union=>{
                            let _Time={Desk_ID:union?.Desk_ID,DeskTimestamps:union?.DeskTimestamps.toString()}
                            unionTime.push(_Time)
                        })
                    PosDesk = Object.assign(PosDesk,{unionList:_Union,unionDeskTime:unionTime})
                    }
                    this.$router.push({
                        path: 'diningTable',name:'diningTable',
                        params:{
                            PosDesksRetrun: JSON.stringify(PosDesk),
                            type:1
                        }
                    });

                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**联台时间戳更新 */
        unionTimeUpdate(data){
            this.unionEatAutoID=[]
            if(data?.UnionOrders){
                if(data?.UnionOrders.length>0){
                    if(this.orderInfo?.UnionOrderInfoList){
                        this.orderInfo.UnionOrderInfoList.forEach(union=>{
                            data?.UnionOrders.forEach(preunion=>{
                                if(union.Eat_AutoID==preunion.Order_ID){
                                    union.Timestamps = preunion.Timestamps.toString()
                                }
                            })
                            let unionEatList={Order_ID:union.Eat_AutoID,Service_Charge:union.Service_Money,Timestamps:union.Timestamps.toString()}
                            this.unionEatAutoID.push(unionEatList)
                        })
                    }
                }
            }
        },
        /**退款冲销 */
        RefundPayment(item){
            if(item.Payment_Name=='新享会券'){
                this.$message.warning('已核销,无法退款冲销');
                return
            }
            /**判断是否有结账的权限 */
            let proKey=this.checkoutPermission()
            if(proKey==false){
                this.money=''
                this.$alert('您没有结账权限，请联系后台开通结账权限！', '提示', {confirmButtonText: '确定',});
                return
            }
            //如果是微生活的支付记录
            if(item?.soleNo){
                for(let i=0; i<this.orderPaymentDetailList.length; i++){
                    if(this.orderPaymentDetailList[i]?.soleNo==item.soleNo){
                        this.orderInfo.UnPaid_Money =this.addNum(Number(Number(this.orderPaymentDetailList[i].Payment_Money).toFixed(2)),this.orderInfo.UnPaid_Money)
                        this.orderInfo.Payment_Money = this.accSub(Number(Number(this.orderPaymentDetailList[i].Payment_Money).toFixed(2)),this.orderInfo.Payment_Money) 
                        this.orderPaymentDetailList.splice(i,1);
                        i--;
                    }
                }
                this.wlivePayList.forEach((wli,i)=>{
                    if(wli.Payments.length>0){
                        wli.Payments.forEach((pay,j)=>{
                            if(pay.soleNo==item?.soleNo){
                                wli.Payments.splice(j,1)
                                j--
                            }
                        })
                    }
                    if(wli.Payments.length==0){
                        this.wlivePayList.splice(i,1)
                        i--
                    }
                })
                if(this.wlivePayList.length==0){
                    this.wlivePayExist=false
                }
                return
            }
            /**现金或普通类型 */
            if(item.Payment_Type==1 || item.Payment_Type==2 || item.Payment_Type==7 || item.Payment_Type==8){
                this.$confirm('<div></div><span style="color:#000;font-size:23px;display:inline-block;">您确定<span style="color:red;">￥</span><span style="color:red;font-size:30px;">'+item.Payment_Money+'</span>&nbsp;['+item.Payment_Name+']&nbsp;&nbsp;退款吗?</span><br/>', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',dangerouslyUseHTMLString: true,type:'warning'})
                .then(()=>{
                    this.RefundPaymentApiMethod(item)
                }).catch(() =>{});
            }else{
                let title='<div style="position:absolute;line-height:80px;"><i class="iconfont icon-jinggao" style="color:#faad14;font-size:30px;"></i></div><span style="color:#000;font-size:23px;padding-left:50px;display:inline-block;">您确定<span style="color:red;">￥</span><span style="color:red;font-size:30px;">'+item.Payment_Money+'</span>&nbsp;['+item.Payment_Name+']&nbsp;&nbsp;退款吗?</span><br/><span style="color: rgb(153, 153, 153);font-size: 19px;display:inline-block;padding-left:50px;">该操作涉及财务退款,请谨慎操作!</span>'
                this.$confirm(title, '提示', {confirmButtonText: '确定',cancelButtonText: '取消',dangerouslyUseHTMLString: true})
                .then(() => {
                    this.RefundPaymentApiMethod(item)
                }).catch(() =>{});
            }
        },
        RefundPaymentApiMethod(item){
            let _Money=0
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID, //登陆用户ID
                Operator_Name:this.userInfo?.Login_Name,//操作员
                Pos_StationName:this.userInfo?.Site_Name,//站点名称
                Eat_AutoID: this.orderInfo.Eat_AutoID,//结账单ID
                Eat_CheckIndex:this.orderInfo.Eat_CheckIndex,//结账单号
                Eat_PayDetailID:item.Eat_PayDetailID,//支付明细记录ID
                Is_PartialRefund:false,//是否部分退款,不传默认false,代表支付金额全部退款
                //Refund_Money:_Money,
                Refund_Reason:'',//退款原因
                Timestamps:this.orderInfo.Timestamps.toString(),//时间戳
            }
            /**部分退 */
            if(item.Refund_Status==3){
                _Money = item.Payment_Money-item.Refund_Money
                param.Is_PartialRefund=true
                //退款金额Is_PartialRefund为true时,传入退款金额
                param = Object.assign(param,{Refund_Money:_Money})
            }
            const loading = this.$loading({
                text: "退款冲销中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.RefundPayment",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    if(data.ResponseBody?.Timestamps){
                        this.orderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                    }
                }else{
                    data.ResponseBody.Payments.forEach(pay=>{
                        this.orderPaymentDetailList.forEach((orderpay,index)=>{
                            if(orderpay.Eat_PayDetailID==pay.Eat_PayDetailID){
                                orderpay.Refund_Status = pay.Refund_Status
                            }
                            if(orderpay.Eat_PayDetailID==pay.Eat_PayDetailID && pay.Hide_YN==true){
                                this.orderPaymentDetailList.splice(index,1)
                            }
                        })
                        if(pay.Payment_Remark=='退款'){
                                if(pay.Hide_YN!=true){
                                    this.orderPaymentDetailList.push(pay)
                                }
                                if(pay.Payment_Money>0){
                                    //if(item.Eat_PayDetailID==pay.Eat_PayDetailID){
                                    this.orderInfo.UnPaid_Money = this.accSub(Number(Number(pay.Payment_Money).toFixed(2)),this.orderInfo.UnPaid_Money)
                                    //}
                                    if(this.orderInfo.Payment_Money!=0){
                                        this.orderInfo.Payment_Money= this.addNum(Number(Number(pay.Payment_Money).toFixed(2)),this.orderInfo.Payment_Money)
                                    }
                                }
                                //部分退
                                if(param.Is_PartialRefund){
                                    this.orderInfo.UnPaid_Money =this.accSub(Number(Number(pay.Payment_Money).toFixed(2)),this.orderInfo.UnPaid_Money)
                                    this.orderInfo.Payment_Money = this.addNum(Number(Number(pay.Payment_Money).toFixed(2)),this.orderInfo.Payment_Money)
                                }
                        }else{
                            if(pay.Payment_Money>0){
                                //不部分退款 
                                if(!param.Is_PartialRefund){
                                    this.orderInfo.UnPaid_Money =this.addNum(Number(Number(pay.Payment_Money).toFixed(2)),this.orderInfo.UnPaid_Money)
                                    this.orderInfo.Payment_Money = this.accSub(Number(Number(pay.Payment_Money).toFixed(2)),this.orderInfo.Payment_Money)
                                }
                            }
                        }
                    })
                    this.orderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                    if(data.ResponseBody.UnionOrders &&  this.orderInfo.UnionOrderInfoList){
                        this.unionTimeUpdate(data.ResponseBody)
                    }
                    this.orderPaymentDetailList.order('Sort_ID','asc');
                    if(data.ResponseBody.RefreshOrderFromApp)
                    {
                        this.refreshOrder();
                    }
                    //this.orderPaymentDetailList.sort(this.compare('Sort_ID',''));
                }
                console.log('更新后orderPaymentDetailList:'+JSON.stringify(this.orderPaymentDetailList))
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**退款冲销点击 */
        paymentClick(item,type){
            //如果是微生活的支付记录
            if(type==1){
                if(!item?.serialNo){
                    return;
                }
                this.orderPaymentDetailList.forEach(pay=>{
                    if(pay?.serialNo==item.serialNo){
                        pay.isPitch=true
                    }
                })
                this.payType.some(pt=>{
                    if(pt.INTERFACE_TYPE=="801010501"){
                        this.ispayid = pt.PAY_ID
                        this.isPayName = pt.PAY_NAME
                        this.choicePayType = pt
                        return true
                    }
                })
                for(let i=0; i<this.orderPaymentDetailList.length; i++){
                    if(this.orderPaymentDetailList[i]?.serialNo==item.serialNo){
                        this.orderInfo.UnPaid_Money =this.addNum(Number(Number(this.orderPaymentDetailList[i].Payment_Money).toFixed(2)),this.orderInfo.UnPaid_Money)
                        this.orderInfo.Payment_Money = this.accSub(Number(Number(this.orderPaymentDetailList[i].Payment_Money).toFixed(2)),this.orderInfo.Payment_Money) 
                    }
                }
                this.wliveMemberPayShow = true
                return
            }else{
                if(item?.serialNo){
                    for(let i=0; i<this.orderPaymentDetailList.length; i++){
                        if(this.orderPaymentDetailList[i]?.serialNo==item.serialNo){
                            this.orderPaymentDetailList.splice(i,1);
                            i--;
                        }
                    }
                    this.wlivePayList.some((wli,i)=>{
                    let tr=wli.Payments.some(pay=>{
                            if(pay.serialNo==item?.serialNo){
                                return true
                            }
                        })
                        if(tr){
                            this.wlivePayList.splice(i,1)
                            return true
                        }
                    })
                    if(this.wlivePayList.length==0){
                        this.wlivePayExist=false
                    }
                }
            }
        },
        /**服务费 */
        serviceFeeReturn(data,type){
            /**获取订单时,已把服务费添加到未付金额中,所以更新服务费需要先从未付金额中删除之前的服务费 */
            this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(this.orderInfo.Service_Money , this.orderInfo.Mantissa_Money,'-');
            this.orderInfo.Payable_Money = Number(this.orderInfo.Payable_Money).compute(this.orderInfo.Service_Money, - this.orderInfo.Mantissa_Money,'-');
            data.ResponseBody.forEach(ser=>{
                if(this.orderInfo.Eat_AutoID==ser.Order_ID){
                    this.orderInfo.Service_Money = ser.ServiceFee
                    this.orderInfo.Mantissa_Money = ser.Mantissa_Money
                    this.orderInfo.Eat_CheckMoney = ser.Eat_CheckMoney
                    this.orderInfo.Payable_Money = this.orderInfo.Payable_Money + ser.ServiceFee+ser.Mantissa_Money
                    this.orderInfo.UnPaid_Money = this.orderInfo.UnPaid_Money+ser.ServiceFee+ser.Mantissa_Money
                    this.orderInfo.Timestamps=ser.Timestamps.toString()
                }
                /**联台情况下 */
                if(this.unionType==true){
                    if(this.orderInfo.UnionOrderInfoList){
                        this.orderInfo.UnionOrderInfoList.forEach(union=>{
                            if(union.Eat_AutoID==ser.Order_ID){
                                union.UnPaid_Money = union.UnPaid_Money-union.Service_Money
                                this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(union.Service_Money,'-') /**如果有联台也要先去掉联台的服务费 */
                                this.orderInfo.Payable_Money = Number(this.orderInfo.Payable_Money).compute(union.Service_Money,'-');
                                union.Service_Money = ser.ServiceFee;
                                this.unionServiceMoney=ser.ServiceFee;
                                union.UnPaid_Money = union.UnPaid_Money + ser.ServiceFee;
                                union.Timestamps=ser.Timestamps.toString();
                                this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(union.Service_Money,ser.Mantissa_Money);
                                this.orderInfo.Payable_Money = Number(this.orderInfo.Payable_Money).compute(union.Service_Money,ser.Mantissa_Money);
                            }
                        })
                    }
                }
            })
            this.serviceFeeShow = false
            this.$keyavail=false
        },
        /**联台返回 */
        CloseunionClick(data){
            this.unionType=true
            this.unionEatAutoID=[]
             /**获取下单信息 */
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_DeskID:this.posDesks.Desk_AutoID, //桌台ID
                EAT_AUTOID:this.posDesks.Eat_AutoID, //订单Id
                Is_UnionOrder:this.unionType
            }
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetOrderInfo",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                }else{
                    this.orderInfo = data.ResponseBody;
                    this.orderMenuList=JSON.parse(JSON.stringify(data.ResponseBody.Order_ConsumeList))
                    this.orderPaymentDetailList = data.ResponseBody.Order_PaymentDetailList;
                    this.unionName = this.orderInfo.Eat_DeskName+','
                    this.unionNumber=1
                    if(this.unionType==true){
                        if(this.orderInfo.UnionOrderInfoList){
                            this.orderInfo.UnionOrderInfoList.map(union=>{
                                union.Order_ConsumeList.map(order=>{
                                    this.orderMenuList.push(order)
                                })
                                if(union.Order_PaymentDetailList){
                                    //this.orderPaymentDetailList = data.ResponseBody.Order_PaymentDetailList.concat(union.Order_PaymentDetailList)
                                    this.orderPaymentDetailList = this.orderPaymentDetailList.concat(union.Order_PaymentDetailList)
                                }
                                let unionEatList={Order_ID:union.Eat_AutoID,Service_Charge:union.Service_Money,Timestamps:union.Timestamps.toString()}
                                this.unionEatAutoID.push(unionEatList)
                                this.unionServiceMoney +=union.Service_Money
                                this.orderInfo.Eat_SaleMoney += union.Eat_SaleMoney
                                this.orderInfo.UnPaid_Money += union.UnPaid_Money
                                this.orderInfo.Eat_DeskName +=','+union.Eat_DeskName+''
                                this.orderInfo.Refund_Money +=union.Refund_Money
                                this.orderInfo.Payable_Money +=union.Payable_Money
                                this.orderInfo.ZK_Money += union.ZK_Money
                                this.unionName += union.Eat_DeskName+','
                                this.unionNumber++
                            })
                        }else{
                            //取消联台
                            this.unionType==false
                            this.unionNumber=1
                            this.unionName=''
                        }
                    }
                    this.orderPaymentAdd()
                    this.unionName = this.unionName.substring(0,this.unionName.lastIndexOf(','))
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
            this.unionisShow=false
            this.$keyavail = false
        },
        /**获取消费清单，客看单，结账单等类型的打印数据内容 */
        GetPrintContent(type){
            if(this.isPlace==false){
                this.$message.error('没有需要打印的订单');
                return
            }
            let param={
                    Body_ID:newGuid(),
                    UserID:this.userInfo?.User_ID,
                    PrintBusiness:this.orderInfo.Order_Source,  //1.触摸屏
                    PrintType:type,    //1.消费清单2.结账清单8.客看单
                    Eat_CheckIndex:this.orderInfo.Eat_CheckIndex, //结账单号
                    Order_ID:this.orderInfo.Eat_AutoID,//订单id
                    Operator_Name:this.userInfo?.Login_Name,
                    Is_UnionPrint:false,  //是否联台打印
                    Is_CheckedOut:false,
            }
            if(type==1 && _data.CheckOutChangeNumFood!=0){
                let _FoodName= this.orderFoodName()
                if(_FoodName!=''){
                    this.$confirm('订单中:'+_FoodName+',菜品暂未改量,是否继续打印消费清单?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
                        this.GetPrintContentUnion(param)
                    }).catch(() =>{});
                }else{
                    this.GetPrintContentUnion(param)
                }
            }else{
                this.GetPrintContentUnion(param)
            }
        },
        GetPrintContentUnion(param){
            if(this.orderInfo.UnionOrderInfoList!=null){
                this.$confirm('关联桌台一起打印'+this.unionName+'吗', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
                    param.Is_UnionPrint = true
                    this.GetPrintContentApi(param)
                }).catch(() => { 
                    this.GetPrintContentApi(param)
                });
            }else{
                this.GetPrintContentApi(param)
            }
        },
        /**打印接口 */
        GetPrintContentApi(param){
            const loading = this.$loading({
                text: "获取信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetPrintContent",param).then((data)=>{
                loading.close();
                if(param.PrintType==1){
                    _data.NoNeedPrintBill=false
                }
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    if(data?.ResponseBody){
                        if(data.ResponseBody[0]?.OrderTimestamps){
                            data.ResponseBody[0].OrderTimestamps.map(time=>{
                                if(time.Order_ID==this.orderInfo.Eat_AutoID){
                                    this.orderInfo.Timestamps=time.Timestamps.toString()
                                    this.orderInfo.Eat_CheckIndex = time?.Eat_CheckIndex
                                    if(time?.DeskTimestamps){
                                        this.lockPosDesks.Timestamps = time?.DeskTimestamps.toString()
                                    }
                                }
                            })
                            if(param.Is_UnionPrint==true){
                                this.unionEatAutoID=[]
                                data.ResponseBody[0].OrderTimestamps.map(time=>{
                                    this.orderInfo.UnionOrderInfoList.map(union=>{
                                        if(union.Eat_AutoID==time.Order_ID){
                                            union.Timestamps = time.Timestamps.toString()
                                            union.Eat_CheckIndex = time.Eat_CheckIndex
                                        }
                                        let unionEatList={Order_ID:union.Eat_AutoID,Service_Charge:union.Service_Money,Timestamps:union.Timestamps.toString()}
                                        this.unionEatAutoID.push(unionEatList)
                                    })
                                })
                            }
                        }
                    }
                }else{
                    if(data?.ResponseBody){
                        data.ResponseBody[0].OrderTimestamps.map(time=>{
                            if(time.Order_ID==this.orderInfo.Eat_AutoID){
                                this.orderInfo.Timestamps=time.Timestamps.toString()
                                this.orderInfo.Eat_CheckIndex = time.Eat_CheckIndex
                                if(time?.DeskTimestamps){
                                    this.lockPosDesks.Timestamps = time?.DeskTimestamps.toString()
                                }
                            }
                            if(param.Is_UnionPrint==true){
                                this.unionEatAutoID=[]
                                this.orderInfo.UnionOrderInfoList.map(union=>{
                                    if(union.Eat_AutoID==time.Order_ID){
                                        union.Timestamps = time.Timestamps.toString()
                                        union.Eat_CheckIndex = time.Eat_CheckIndex
                                    }
                                    let unionEatList={Order_ID:union.Eat_AutoID,Service_Charge:union.Service_Money,Timestamps:union.Timestamps.toString()}
                                    this.unionEatAutoID.push(unionEatList)
                                })
                            }
                        })
                    }
                    setTimeout(()=>{
                        this.$webBrowser.posPrint(data.ResponseBody);
                    },100);
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**本地更新订单信息 type:判断触发更新的是什么事件 */
        OrderInfoUpdate(data,type){
            /**isCheckOut为true结账界面，否则点菜界面 */
            if(this.isCheckOut==true || type==1){
                let eatDeskName = this.orderInfo.Eat_DeskName
                this.orderInfo = data.ResponseBody
                this.orderInfo.Eat_DeskName = eatDeskName
                // if(this.isCheckOut==false && type==1){
                //     this.orderInfo.UnPaid_Money = this.orderInfo.UnPaid_Money - this.orderInfo.Mantissa_Money
                // }
                this.unionRefundMoney=0
                this.unionEatAutoID=[]
                this.orderMenuList=[]
                if(this.orderInfo.IsMemberPrice==false){
                    this.userCheckOutMiddleMenu.map(item=>{
                        if(item.ProjectItem_Key=='MemberPrice'){
                            item.ProjectItem_Name='会员价'
                        }
                    })
                }
                if(this.orderInfo?.Order_PaymentDetailList){
                    this.orderPaymentDetailList = this.orderInfo?.Order_PaymentDetailList;
                }
                /**是否联台数据变动调整 */
                if(this.unionType==true){
                    this.orderInfo.UnionOrderInfoList?.map(union=>{
                        this.orderInfo.Gift_Money = (this.orderInfo.Gift_Money) + union.Gift_Money
                        this.orderInfo.Eat_TotalCounts = (this.orderInfo.Eat_TotalCounts) + union.Eat_TotalCounts
                        this.orderInfo.Eat_CheckMoney =(this.orderInfo.Eat_CheckMoney) + union.Eat_CheckMoney
                        this.orderInfo.ZK_Money = (this.orderInfo.ZK_Money) + union.ZK_Money
                        this.orderInfo.Refund_Money = (this.orderInfo.Refund_Money) + union.Refund_Money
                        this.orderInfo.Eat_SaleMoney = (this.orderInfo.Eat_SaleMoney) + union.Eat_SaleMoney
                        this.orderInfo.UnPaid_Money += union.UnPaid_Money
                        this.orderInfo.Payable_Money +=union.Payable_Money
                        this.orderInfo.Payment_Money +=union.Payment_Money
                        let unionEatList={Order_ID:union.Eat_AutoID,Service_Charge:union.Service_Money,Timestamps:union.Timestamps.toString()}
                        this.unionEatAutoID.push(unionEatList)
                    })
                    this.orderInfo.UnionOrderInfoList?.map(union=>{
                        union.Order_ConsumeList?.map(order=>{
                            this.orderMenuList.push(order)
                        })
                        if(union.Order_PaymentDetailList){
                            this.orderPaymentDetailList = this.orderPaymentDetailList.concat(union.Order_PaymentDetailList)
                        }
                    })
                    this.orderInfo.Order_ConsumeList?.map(order=>{
                         this.orderMenuList.push(order)
                    })
                }
                this.orderPaymentAdd()
            }else{
                if(data.ResponseBody.Order_ConsumeList){
                    data.ResponseBody.Order_ConsumeList.forEach(Cons=>{
                        let TcType=0 //是否存在未添加进order中的套餐信息
                        /**this.orderInfo.Order_ConsumeList */
                        this.orderInfo.Order_ConsumeList.forEach(order=>{
                            if(order.Eat_ConsumeID==Cons.Eat_ConsumeID){
                                TcType=1
                                order.Eat_Number = Cons.Eat_Number
                                order.Eat_SalePrice = Cons.Eat_SalePrice
                                order.Eat_CheckPrice = Cons.Eat_CheckPrice
                                order.Eat_SaleMoney = Cons.Eat_SaleMoney
                                order.Eat_CheckMoney = Cons.Eat_CheckMoney
                                order.Refund_Number = Cons.Refund_Number
                                order.Food_Status = Cons.Food_Status
                                order.Is_Gift = Cons.Is_Gift
                                order.Service_Money = Cons.Service_Money
                                order.FoodDiscount_Dimension = Cons.FoodDiscount_Dimension
                                order.EAT_XFName = Cons?.EAT_XFName
                                order.Eat_ComboItemList=Cons.Eat_ComboItemList;
                                if(Cons.Eat_TcItemList){
                                    order.Eat_TcItemList = Cons.Eat_TcItemList
                                }
                            }else if(TcType!=1){
                                TcType=2
                            }
                        })
                        if(TcType==2){
                            this.orderInfo.Order_ConsumeList.push(Cons)
                        }
                    })
                }
                this.orderInfo.IsPromotionPrice=data.ResponseBody.IsPromotionPrice
                this.orderInfo.Timestamps=data.ResponseBody.Timestamps.toString()
                this.orderInfo.Eat_SaleMoney = data.ResponseBody.Eat_SaleMoney
                this.orderInfo.Eat_CostMoney = data.ResponseBody.Eat_CostMoney
                this.orderInfo.Eat_TotalCounts = data.ResponseBody.Eat_TotalCounts
                this.orderInfo.Eat_CheckMoney = data.ResponseBody.Eat_CheckMoney
                this.orderInfo.Gift_Money = data.ResponseBody.Gift_Money
                this.orderInfo.ZK_Money = data.ResponseBody.ZK_Money
                this.orderInfo.Service_Money = data.ResponseBody.Service_Money
                this.orderInfo.Mantissa_Money = data.ResponseBody.Mantissa_Money
                this.orderInfo.Refund_Money = data.ResponseBody.Refund_Money
                this.orderInfo.Payment_Money = data.ResponseBody.Payment_Money
                this.orderInfo.Payable_Money =data.ResponseBody.Payable_Money
                this.orderInfo.IsMemberPrice = data.ResponseBody.IsMemberPrice
                this.orderInfo.UnPaid_Money = data.ResponseBody.UnPaid_Money
                if(this.orderInfo.IsMemberPrice==false){
                    this.userCheckOutMiddleMenu.map(item=>{
                        if(item.ProjectItem_Key=='MemberPrice'){
                            item.ProjectItem_Name='会员价'
                        }
                    })
                }
            }
            if(this.unionType==false){
                this.orderMenuList = this.orderInfo.Order_ConsumeList
            }
            this.orderInfo.Order_ConsumeList.sort(this.compare('Eat_ConsumeIndex','Eat_Time'));
        },
        /**数组排序 */
        compare(attr,Time) {
            return function(a,b){
                var val1 = a[attr];
                var val2 = b[attr];
                var Times1 = a[Time];
                var Times2 = b[Time];
                return val1 - val2 || Times2 - Times1;
            }
        },
        getCurrentDateTime(time){
            let date = new Date(time);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            let h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            let m = date.getMinutes();
            m = m < 10 ? ('0' + m) : m;
            let s = date.getSeconds();
            s = s < 10 ? ('0' + s) : s;
            return MM + '-' + d + ' ' + h + ':' + m;
        },
        /**初始化联台金额信息 */
        Initialization(){
            this.unionMoney=0,this.unionPaymentMoney =0,this.unionUnPaidMoney=0,this.unionGiftMoney=0,this.unionZKMoney=0,this.unionServiceMoney=0,this.unionEatCheckMoney=0,
            this.unionRefundMoney=0,this.UnPaidMoney=0,this.ispayid='',this.isPayName='',this.money='',this.unionPayableMoney=0,this.multipleSizeFoods=[],
            this.theMultipleSizeFoods={},this.IsMultipleCardPay=false,this.wlivePayList=[],this.wliveCardMember='',this.wlivePayExist=false,this.wlivePasswordShow=false,
            this.wliveCardCodeShow=false,this.wliveVerify='',this.payPreviewData=''
        },
        Inituniontion(){
            this.unionMoney=0,this.unionPaymentMoney =0,this.unionUnPaidMoney=0,this.unionGiftMoney=0,this.unionZKMoney=0,this.unionServiceMoney=0,this.unionEatCheckMoney=0,
            this.unionRefundMoney=0,this.unionPayableMoney=0
        },
        /**获取订单信息接口 */
        GetOrderInfo(type){
            //获取订单信息
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_DeskID:this.posDesks.Desk_AutoID, //桌台ID
                EAT_AUTOID:this.posDesks.Eat_AutoID, //订单Id
                Is_UnionOrder:true  //是否获取连台信息
            }
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetOrderInfo",param).then((data)=>{
                loading.close();
                if(data?.ResponseHeader.ResultCode!=0){
                    this.$router.push({path:'diningTable',name:'diningTable'});
                    this.$message.error(data?.ResponseHeader.ResultDesc);
                }else{
                    this.orderInfo = data.ResponseBody;
                    this.orderMenuList=JSON.parse(JSON.stringify(data.ResponseBody.Order_ConsumeList))
                    if(this.unionType==true && type==1){
                        this.orderInfo.UnionOrderInfoList.map(union=>{
                            union.Order_ConsumeList.map(order=>{
                                this.orderMenuList.push(order)
                            })
                        })
                        this.orderInfo.Eat_SaleMoney = (this.orderInfo.Eat_SaleMoney) + this.unionMoney
                    }
                    //计算应付金额
                    this.consumeTypeMethod(data.ResponseBody.Consume_TypeID)
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**其他功能页面返回 */
        subItemReturn(item){
             this.MiddleMenuClick({
                Is_Show:item.Is_Show,
                Permission:item.Permission,
                ProjectItem_Key:item.ProjectSubItem_Key,
                ProjectItem_Name:item.ProjectSubItem_Name,
                ProjectItem_Type:item.ProjectSubItem_Type,
            });
            this.subShow = false
            //this.$keyavail = false
        },
        /**提取暂存返回 */
        lockersConfirm(loakersDetailed){
            loakersDetailed.map(loak=>{
                this.orderMenuList.push(loak)
            })
            this.$message.success("提取暂存成功");
            this.posFoodNumbermethod(5)
            this.subShow = false
            this.lockersShow = false
            this.$keyavail = false
        },
        /**复制菜品返回 */
        cpoyFoodReturn(orderList){
            orderList.map(item=>{
                this.orderMenuList.push(item);
            })
            this.orderCompleteGross(2)
             /**自动滚动到最底部 */
            let _box = this.$refs.box;
            setTimeout(()=>{_box.scrollTop = _box.scrollHeight;},0)
            this.$message.success("复制菜品成功");
            this.posFoodNumbermethod(5)
            this.subShow = false
            this.copyFoodShow = false
            this.$keyavail = false
        },
        /**转菜返回 */
        transferConfirm(data){
            this.orderInfo = data.ResponseBody.OrderInfo
            this.orderMenuList = this.orderInfo.Order_ConsumeList
            this.$message.success("转菜成功");
            this.subShow = false
            this.transferFoodShow = false
            this.$keyavail =false
        },
        /**会员价返回 */
        cardPriceReturn(data,type){
            this.orderInfo = data.ResponseBody;
            this.orderMenuList=JSON.parse(JSON.stringify(data.ResponseBody.Order_ConsumeList))
            this.orderPaymentDetailList = data.ResponseBody.Order_PaymentDetailList;
            if(this.unionType==true){
                this.cardOrderunionType(data)
            }
            this.orderPaymentAdd()
            this.cardPriceShow = false;
            this.$keyavail=false
            if(this.orderInfo.IsMemberPrice==true){
                this.userCheckOutMiddleMenu.map(item=>{
                    if(item.ProjectItem_Key=='MemberPrice'){
                        item.ProjectItem_Name='取消会员价'
                    }
                })
            }
            if(type==''){
                this.isManualParPrice=true
                this.$message.success("会员价调整完成");
            }
        },
        /**取消会员价 */
        cancelCardPrice(payTimestamps){
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_AutoID:this.orderInfo.Eat_AutoID, //订单ID
                Timestamps:this.orderInfo.Timestamps.toString()
            }
            if(payTimestamps){
                param.Timestamps=payTimestamps
            }
            const loading = this.$loading({
                text: "取消会员价中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.CancelVipPrice",param).then((data)=>{
                loading.close();
                if(data?.ResponseHeader.ResultCode!=0){
                    this.$message.error(data?.ResponseHeader.ResultDesc);
                }else{
                    this.orderInfo = data.ResponseBody;
                    this.orderMenuList=JSON.parse(JSON.stringify(data.ResponseBody.Order_ConsumeList))
                    this.orderPaymentDetailList = data.ResponseBody.Order_PaymentDetailList;
                    if(this.unionType==true){
                        this.cardOrderunionType(data)
                    }
                    this.orderPaymentAdd()
                    if(this.orderInfo.IsMemberPrice==false){
                        this.userCheckOutMiddleMenu.map(item=>{
                            if(item.ProjectItem_Key=='MemberPrice'){
                                item.ProjectItem_Name='会员价'
                            }
                        })
                    }
                    //账单绑定卡号已释放,清空会员信息
                    if(data.ResponseBody.IsBindCardRelease==true){
                        this.BindCardCleanup('','delete')
                    }
                    
                    this.$message.success("取消会员价完成");
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**会员价联台订单变动调整 */
        cardOrderunionType(data){
            if(this.orderInfo.UnionOrderInfoList){
                
                let _wlivePayList=this.wlivePayList
                let _wliveCardMember = this.wliveCardMember
                let _wlivePayExist = this.wlivePayExist

                this.Initialization()

                this.wlivePayList=_wlivePayList
                this.wliveCardMember=_wliveCardMember
                this.wlivePayExist=_wlivePayExist,

                this.unionEatAutoID=[]
                this.orderPaymentDetailList = data.ResponseBody.Order_PaymentDetailList
                this.orderInfo.UnionOrderInfoList.map(union=>{
                    union.Order_ConsumeList.map(order=>{
                        this.orderMenuList.push(order)
                    })
                    if(union.Order_PaymentDetailList){
                        //this.orderPaymentDetailList = data.ResponseBody.Order_PaymentDetailList.concat(union.Order_PaymentDetailList)
                        this.orderPaymentDetailList = this.orderPaymentDetailList.concat(union.Order_PaymentDetailList)
                    }
                    this.unionServiceMoney +=union.Service_Money
                    this.orderInfo.Eat_SaleMoney += union.Eat_SaleMoney
                    this.orderInfo.UnPaid_Money += union.UnPaid_Money
                    this.orderInfo.Eat_DeskName +=','+union.Eat_DeskName+''
                    //this.orderInfo.Refund_Money +=union.Refund_Money
                    this.orderInfo.Payable_Money +=union.Payable_Money
                    this.unionMoney = this.unionMoney + union.Eat_SaleMoney
                    this.unionPaymentMoney += union.Payment_Money
                    this.unionUnPaidMoney += union.UnPaid_Money
                    this.unionGiftMoney +=union.Gift_Money
                    this.unionZKMoney +=union.ZK_Money
                    this.unionEatCheckMoney +=union.Eat_CheckMoney
                    this.unionRefundMoney += union.Refund_Money
                    this.unionPayableMoney +=union.Payable_Money
                    let unionEatList={Order_ID:union.Eat_AutoID,Service_Charge:union.Service_Money,Timestamps:union.Timestamps.toString()}
                    this.unionEatAutoID.push(unionEatList)
                })
            }
        },
        /**用餐渠道 */
        diningChannel(channelId){
            this.channeName=''
            this.$cacheData.OrderChannel().then((d)=>{
                let orderChannel = d;
                orderChannel?.map(order=>{
                    order.OrderChannelList.map(item=>{
                        if(item.OrderChannel_AutoID==channelId){
                            this.channeName = item.OrderChannel_Name
                        }
                    })
                })
            }).catch(()=>{
                this.$message.error('订单渠道获取失败');
            })
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num)
        },
        /**桌台解锁 */
        lockeClick(){
            this.checkBtnlist = this.deskcheckBtnlist
            if(this.checkBtnlist.Permission==false){
                this.$confirm('该账户权限不足,不支持解锁功能', '提示', {
                    confirmButtonText: '去验证',
                    cancelButtonText: '我知道了',
                    type: 'warning'})
                .then(() => {
                    this.checkBtnType=5
                    this.checkShow = true
                    this.$keyavail=true
                }).catch(()=>{});
            }else{
                this.$confirm('您确定解锁'+this.orderInfo.Eat_DeskName+'桌台吗?', '提示', {confirmButtonText: '解锁',cancelButtonText: '取消',type: 'warning'}).then(() => {
                    let param={
                        Body_ID:newGuid(),
                        User_ID:this.userInfo?.User_ID,
                        Operator_Name:this.userInfo?.Login_Name,
                        Desk_InfoList:this.lockPosDesks,
                        Scene_Type:2,
                        Desk_Lock:false
                    }
                    if(this.checkBtnType==5){
                        param.User_ID = sessionStorage.getItem("tempUserID")
                        param.Operator_Name = sessionStorage.getItem("tempName")
                        sessionStorage.removeItem("tempUserID")
                        sessionStorage.removeItem("tempName")
                    }
                    const loading = this.$loading({
                        text: "桌台解锁中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    this.$httpAES.post("Bestech.CloudPos.DeskLockOperate",param).then((data)=>{
                        loading.close();
                        if(data.ResponseHeader.ResultCode!=0){
                            this.$message.error('桌台解锁操作失败:'+data.ResponseHeader.ResultDesc);
                            //this.lockPosDesks[0].Timestamps = data.ResponseHeader?.Timestamps
                        }else{
                            this.$message.success("桌台解锁操作成功");
                            this.DeskLocked = false
                        }
                    }).catch((e)=>{
                        loading.close();
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                        console.log('桌台解锁操作失败:'+e);
                    })
                }).catch(() => {
                });
            }
        },
        /**点单界面返回 */
        orderInterReturn(returnType){
            let PosDesk=''
            this.OrderReturnType=1
            /**计算划菜数量 */
            if(this.orderInfo.Order_ConsumeList){
                this.orderInfo.Order_ConsumeList.map(item=>{
                    if(item.Food_Status==4){
                        this.Crossed_FoodCounts = this.Crossed_FoodCounts +item.Eat_Number
                        if(item.Eat_TcItemList){
                            item.Eat_TcItemList.map(tc=>{
                                if(tc.Food_Status==4){
                                    this.Crossed_FoodCounts = this.Crossed_FoodCounts +tc.Eat_Number
                                }
                            })
                        }
                    }
                })
            }
            if(this.isPlace==true){
                let num = /^\d+(\.\d{1,2})?$/;
                if (!num.test(this.orderInfo.Eat_CheckMoney)){
                    this.orderInfo.Eat_CheckMoney = Number(this.orderInfo.Eat_CheckMoney).toFixed(2)
                }
                PosDesk={Desk_AutoID:this.posDesks.Desk_AutoID,Eat_CheckMoney:this.orderInfo.Eat_CheckMoney,deskCount:this.deskCount.SumPortion,Eat_Number:this.Crossed_FoodCounts}
            }
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            if(this.urlType==0){
                this.$router.push({
                    path: 'diningTable',name:'diningTable',
                    params:{
                        PosDesksRetrun: JSON.stringify(PosDesk),
                        type:0,
                        isPlace:returnType
                    }
                });
            }else{
                let _RoomDeskList = this.$route.params.RoomDeskList
                this.$router.push({
                    path: 'servingOrder',name:'servingOrder'
                });
            }
            
        },
        /**获取会员信息 */
        onMemberInfo(terminationID,serverID){
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                Body_ID:newGuid(),
                User_ID:userInfo?.User_ID,
                DynamicNo:this.orderInfo.Member_CardNo,
                Card_AutoID:'',
                Card_SN:''
            }
            if(terminationID && serverID){
                param = Object.assign(param,{HdExtendInfo:{terminalId:terminationID,serverId:serverID,media:''}})
            }
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",param).then((data)=>{
                if(data.ResponseHeader.ResultCode==0){
                    this.BindCardCleanup(data.ResponseBody[0],'update')
                }else{
                    this.$message.error("会员信息读取失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.$message.error('会员信息读取失败：'+e);
            })
        },
        /**清空或更新会员信息 */
        BindCardCleanup(data,cardType){
            if(cardType=='update'){
                this.cardMemberList={
                    Member_Sys:data?.Member_Sys, //会员系统类型 2：正品CRM3.0，4：微生活
                    DynamicType:data?.DynamicType, //查询码类型 5i会员类型：1手机会员、2卡会员
                    Card_AutoID:data?.Bestech_Card_AutoID, //卡主键
                    Card_No:data?.Card_No, //卡号
                    Member_Name:data?.MemberName, //姓名
                    MemberCard_Kind:data?.Bestech_Card_KindName, //会员等级
                    Mobile:data?.Mobile,//会员手机号
                    NotJoinPointProgram:false,//不参与线上积分
                    Invoice_Money:0, //本次消费开票金额
                    Invoice_No:'',//发票号码
                }
                this.orderInfo.Member_CardNo = data?.Card_No
            }
            if(cardType=='delete'){
                this.cardMemberList=''
            }
        },
        /**判断是否有结账的权限 */
        checkoutPermission(){
            let proKey=false
            this.userCheckOutMenu.map(it=>{
                if(it.ProjectItem_Key=='CheckOut' && it.Permission==true){
                    proKey=true
                }
            })
            return proKey
        },
        /**分页  下一页 */
        pageDown(type){
            if(type==""){//大类
                if(this.foodMainPageIndex<this.foodMainCategoryPagesNum){
                    this.foodMainPageIndex++
                }
            }
        },
        //刷新菜品数据
        RefreshData(){
            const loading = this.$loading({
                text: "信息加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$cacheData.RefreshSoldInfo().then(()=>{
                loading.close();
            }).catch((e)=>{ 
                loading.close();
                this.$message.error("刷新失败:"+e.message);
            });
        },
        /**刷新订单数据 */
        refreshOrder(){
            /**获取下单信息 */
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_DeskID:this.posDesks.Desk_AutoID, //桌台ID
                EAT_AUTOID:this.posDesks.Eat_AutoID, //订单Id
                Is_UnionOrder:this.unionType
            }
            this.$httpAES.post("Bestech.CloudPos.GetOrderInfo",param).then((data)=>{
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                }else{
                    this.orderInfo = data.ResponseBody;
                    this.orderMenuList=JSON.parse(JSON.stringify(data.ResponseBody.Order_ConsumeList))
                    this.orderPaymentDetailList = data.ResponseBody.Order_PaymentDetailList;
                    if(this.orderInfo.Eat_CheckIndex!=''){
                        _data.NoNeedPrintBill=false
                    }
                    if(this.unionType==true){
                        if(this.orderInfo.UnionOrderInfoList){
                            this.Inituniontion();
                            this.unionEatAutoID=[]
                            this.orderInfo.UnionOrderInfoList.map(union=>{
                                this.unionServiceMoney +=union.Service_Money
                                this.unionMoney = this.unionMoney + union.Eat_SaleMoney
                                this.unionPaymentMoney += union.Payment_Money
                                this.unionUnPaidMoney += union.UnPaid_Money
                                this.unionGiftMoney +=union.Gift_Money
                                this.unionZKMoney +=union.ZK_Money
                                this.unionEatCheckMoney +=union.Eat_CheckMoney
                                this.unionRefundMoney += union.Refund_Money
                                this.unionPayableMoney +=union.Payable_Money
                                if(union.IsMemberPrice==true){
                                    this.userCheckOutMiddleMenu.map(item=>{
                                        if(item.ProjectItem_Key=="MemberPrice"){
                                            item.ProjectItem_Name='取消会员价'
                                        }
                                    })
                                }
                                union.Order_ConsumeList.map(order=>{
                                    this.orderMenuList.push(order)
                                })
                                if(union.Order_PaymentDetailList){
                                    this.orderPaymentDetailList = this.orderPaymentDetailList.concat(union.Order_PaymentDetailList)
                                }
                                let unionEatList={Order_ID:union.Eat_AutoID,Service_Charge:union.Service_Money,Timestamps:union.Timestamps.toString()}
                                this.unionEatAutoID.push(unionEatList)
                                
                            })
                            this.orderInfo.Eat_SaleMoney += this.unionMoney;
                            this.orderInfo.UnPaid_Money =Number(this.orderInfo.UnPaid_Money).compute(this.unionUnPaidMoney);
                            this.orderInfo.Payment_Money +=this.unionPaymentMoney;
                            this.orderInfo.Payable_Money +=this.unionPayableMoney;
                        }
                    }
                    this.orderPaymentAdd()
                }
            }).catch((e)=>{
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**订单更新如果有微生活预支付的则追加进去 */
        orderPaymentAdd(){
            //刷新订单数据,如果之前存在微生活的预付款方式,则追加进去
            if(this.wlivePayExist){
                this.wlivePayList.forEach(wli=>{
                    wli.Payments.forEach(pay=>{
                        this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money);
                        this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                    })
                    this.orderPaymentDetailList=wli.Payments.concat(this.orderPaymentDetailList)
                })
            }
        },
        /**卡券核销返回 */
        saveCouponRetrun(data){
            this.$message.success("卡券核销完成");
            this.saveCouponsShow = false
            this.refreshOrder()
        },
        /**配菜单 引用数据 */
        quoteReturn({data,AllFoods,AllMasKinds,DiyTcInfo}){
            if(data){
                let orderList=[];
                //判断数据
                //错误提示
                let errMessage=[];
                let foodData=AllFoods;//全部菜品数据 包含自定义菜品
                let _ItmeRemainNum={};
                //自定义菜品
                let diyEatMas=this.$cacheData.globalVariable?.GlobalSysSetting?.DiyEatMas||{};
                
                data.forEach((item)=>{
                    let info={};
                    if(item.Food_Type==3){//即配套餐
                        let FoodSetmeals=[];
                        item.FoodSetmeals.forEach((foods)=>{
                            let food=foodData.find(food=>food.Food_ID==foods.Eat_XFBMID || (food.Is_MultipleSize && food.MultipleSizeFoods.find(it=>it.Food_ID==foods.Eat_XFBMID)));
                            if(!food){
                                errMessage.push(foods.Eat_XFName+"该菜品未查询到!")
                                return
                            }
                            FoodSetmeals.push({
                                SubCategory_ID:foods.SubCategory_ID,
                                FoodSetmeal_ID:foods.Eat_XFBMID,
                                FoodSetmeal_Code:foods.Eat_XFCode,
                                FoodSetmeal_Name:foods.Eat_XFName,
                                FoodSetmeal_Number:foods.Eat_Number,
                                FoodSetmeal_Price:foods.Eat_SalePrice,
                                Food_CostPrice:foods?.Food_CostPrice,
                                FoodSetmeal_Size:foods.Eat_XFSize,
                                FoodSaleMoney:foods.Eat_SalePrice,
                                FoodModifys:foods.FoodModifys,
                                Eat_MasName:foods.Eat_MasName,
                                Food_Speed:foods.Food_Speed,
                                KitSpeed_Dimension:foods.Food_Speed,
                                ModiPrice:0,
                                Food_Status:-1,
                                Eat_ConsumeID:newGuid()
                            })
                        });
                        if(FoodSetmeals.length==0){return;}
                        info={
                            Eat_ConsumeID:newGuid(),
                            SubCategory_ID:item.SubCategory_ID,
                            EAT_XFBMID:item.Eat_XFBMID,
                            EAT_XFCode:item.Eat_XFCode,
                            EAT_XFName:item.Eat_XFName,
                            Eat_Number:item.Eat_Number,
                            Eat_CheckPrice:item.Eat_CheckPrice,
                            ModiPrice:0,
                            Eat_SalePrice:item.Eat_SalePrice,
                            Food_CostPrice:item?.Food_CostPrice,
                            Eat_MasName:item.Eat_MasName,
                            Food_Status:-1,
                            Food_Speed:'',
                            Eat_XFSize:item.Eat_XFSize,
                            FoodModifys:item.FoodModifys,
                            FoodSetmeals:FoodSetmeals,
                            Is_Special:item.Is_Special,//是否是推荐菜
                            Is_Discounted:false,
                            Is_Seasonal:false,
                            RemainNum:0,
                            Is_Limited:false,
                            Is_ChangedNum:false,
                            Is_ChargeForService:false,
                            Is_SetMeal:true,
                            Food_Type:3,
                            DiyTcType:3,
                            FoodProperty_Dimension:'套',
                            Promotion_Price:0,
                            Is_SetMealCountAvgPrice:DiyTcInfo,
                            ConsumeOperations:[]
                        };
                    }else{
                        let food=foodData.find(food=>food.Food_ID==item.Eat_XFBMID || (food.Is_MultipleSize && food.MultipleSizeFoods.find(it=>it.Food_ID==item.Eat_XFBMID)));
                        if(!food){
                            errMessage.push(item.Eat_XFName+"该菜品未查询到!")
                            return
                        }
                        //套餐内是否却少菜品
                        let isNoTCFood=item.FoodSetmeals?.some((foods)=>{
                            if(!food.SetMealInfo.SetMealKinds.some((set)=>set?.SetMealDetails?.find(meal=>foods.Eat_XFBMID==meal.Food_ID))){
                                errMessage.push(item.Eat_XFName+"该套餐内"+foods.Eat_XFName+"菜品未找到!")
                                return true;
                            }
                            return false;
                        });
                        if(isNoTCFood){
                            return;
                        }
                        let FoodSetmeals=item.FoodSetmeals?.map((foods)=>{
                            return {
                                SubCategory_ID:foods.SubCategory_ID,
                                FoodSetmeal_ID:foods.Eat_XFBMID,
                                FoodSetmeal_Code:foods.Eat_XFCode,
                                FoodSetmeal_Name:foods.Eat_XFName,
                                FoodSetmeal_Number:foods.Eat_Number,
                                FoodSetmeal_Price:foods.Eat_SalePrice,
                                FoodSetmeal_Size:foods.Eat_XFSize,
                                FoodSaleMoney:foods.Eat_SalePrice,
                                Food_CostPrice:foods?.Food_CostPrice,
                                FoodModifys:foods.FoodModifys,
                                Eat_MasName:foods.Eat_MasName,
                                Food_Speed:foods.Food_Speed,
                                KitSpeed_Dimension:foods.Food_Speed,
                                ModiPrice:0,
                                Food_Status:-1,
                                Eat_ConsumeID:newGuid()
                            }
                        });
                        info={
                            Eat_ConsumeID:newGuid(),
                            SubCategory_ID:item.SubCategory_ID,
                            EAT_XFBMID:item.Eat_XFBMID,
                            EAT_XFCode:item.Eat_XFCode,
                            EAT_XFName:item.Eat_XFName,
                            Eat_Number:item.Eat_Number,
                            Eat_CheckPrice:item.Eat_SalePrice,
                            ModiPrice:0,
                            Eat_SalePrice:item.Eat_SalePrice,
                            Food_CostPrice:food?.Food_CostPrice,
                            Eat_MasName:item.Eat_MasName,
                            Food_Status:-1,
                            Food_AssSize:item.Eat_AssSize,
                            AssSizeNumber:item.Eat_AssNum,
                            Eat_XFSize:item.Eat_XFSize,
                            FoodModifys:item.FoodModifys,
                            Food_Speed:item.Food_Speed,
                            KitSpeed_Dimension:item.Food_Speed,
                            FoodSetmeals:FoodSetmeals,
                            Is_Special:food.Is_Special,//是否是推荐菜
                            Is_Discounted:food.Is_Discounted,
                            Is_Seasonal:food.Is_Seasonal,
                            RemainNum:food.RemainNum,
                            Is_Limited:food.Is_Limited,
                            Is_ChangedNum:food.Is_ChangedNum,
                            Is_ChargeForService:food.Is_ChargeForService,
                            Is_SetMeal:food.Is_SetMeal,
                            FoodProperty_Dimension:FoodSetmeals?.length>0?"套":undefined,
                            Promotion_Price:0,
                            ConsumeOperations:[],
                            Is_SetMealCountAvgPrice:false
                        };
                        if(food?.Is_SetMealCountAvgPrice){
                            info.Is_SetMealCountAvgPrice = food?.Is_SetMealCountAvgPrice
                        }
                    }
                    if(info.FoodModifys){
                        let MasNames=(info.Eat_MasName||"").split('/');
                        let FoodModifys=[];
                        let Eat_MasName=[];
                        let isBo=false;//是否有查询不到的口味做法
                        info.FoodModifys?.forEach((modifys,index)=>{
                            if(diyEatMas.MAS_AUTOID==modifys.Mas_AutoID){//自定义口味
                                Eat_MasName.push(MasNames[index]);
                                FoodModifys.push(Object.assign({
                                    MAS_AUTOID:diyEatMas.MAS_AUTOID,
                                    Mas_Code:diyEatMas.MAS_CODE,
                                    Mas_Name:modifys.Mas_Name,
                                    Is_AddMoney:diyEatMas.IS_ADDMONEY,
                                    Price_Kind:diyEatMas.PRICE_KIND,
                                    Mas_Price:diyEatMas.MAS_PRICE,
                                },{Mas_Num:diyEatMas.Price_Kind==1?1:item.Eat_Number}));
                                return;
                            }

                            let kind=AllMasKinds.find(kind=>kind.Mas_AutoID==modifys.Mas_AutoID);
                            if(kind){
                                Eat_MasName.push(kind.Mas_Name);
                                FoodModifys.push(Object.assign({},kind,{Mas_Num:kind.Price_Kind==1?1:item.Eat_Number,Mas_Name:kind.Mas_Name}));
                            }else{
                                isBo=true;
                            }
                        })
                        //把口味要求临时存起来

                        if(isBo){
                            errMessage.push(info.EAT_XFName+"该菜品做法未查询到!")
                        }
                        info.FoodModifys=FoodModifys;
                        info.Eat_MasName=Eat_MasName.join('/');

                        info.FoodSetmeals?.forEach((foods)=>{
                            let FoodMasNames=(foods.Eat_MasName||"").split('/');
                            let FoodModifys=[];
                            let FoodEat_MasName=[];
                            let isBo=false;//是否有查询不到的口味做法
                            foods.FoodModifys?.forEach((modifys,index)=>{
                                if(diyEatMas.MAS_AUTOID==modifys.Mas_AutoID){//自定义口味
                                    FoodEat_MasName.push(FoodMasNames[index]);
                                    FoodModifys.push(Object.assign({
                                        MAS_AUTOID:diyEatMas.MAS_AUTOID,
                                        Mas_Code:diyEatMas.MAS_CODE,
                                        Mas_Name:modifys.Mas_Name,
                                        Is_AddMoney:diyEatMas.IS_ADDMONEY,
                                        Price_Kind:diyEatMas.PRICE_KIND,
                                        Mas_Price:diyEatMas.MAS_PRICE,
                                    },{Mas_Num:diyEatMas.Price_Kind==1?1:item.Eat_Number}));
                                    return;
                                }

                                let kind=AllMasKinds.find(kind=>kind.Mas_AutoID==modifys.Mas_AutoID);
                                if(kind){
                                    FoodEat_MasName.push(kind.Mas_Name);
                                    FoodModifys.push(Object.assign({},kind,{Mas_Num:kind.Price_Kind==1?1:item.FoodSetmeal_Number,Mas_Name:kind.Mas_Name}));
                                }else{
                                    isBo=true;
                                }
                            })
                            if(isBo){
                                errMessage.push(foods.EAT_XFName+"该菜品做法未查询到!")
                            }
                            foods.FoodModifys=FoodModifys;
                            foods.Eat_MasName=FoodMasNames.join('/');
                        })
                    }
                    orderList.push(info);
                })
                
                if(errMessage.length>0){
                    this.$confirm(errMessage.join(";")+" 是否继续引用,点击继续报错菜品则不会被引用！","报错", {
                        type:"warning",
                        confirmButtonText: "继续",
                        cancelButtonText:"取消",
                        callback: (name) => {
                            if(name=='confirm'){
                                this.quoteAddItem(orderList,AllMasKinds);
                            }
                        },
                    })
                }else{
                    this.quoteAddItem(orderList,AllMasKinds);
                }
                
                this.quoteAllMasKind(orderList,AllMasKinds);
                this.orderCompleteGross(2)
            }
            this.posFoodNumbermethod(5);
            this.$keyavail =false
        },
        //添加菜品到列表
        quoteAddItem(orderList,AllMasKinds){
            //加入列表中
            let index=this.orderMenuList.length;
            orderList.map((_item)=>{
                if(_item.Food_Type==1){
                    if(_data.MergeSameFoodInOrder && _item.Food_Status==-1 && !_item.Eat_MasName){
                        let order = this.orderMenuList.find(order=> order.EAT_XFBMID==_item.EAT_XFBMID && order.Food_Status==-1 && order.Eat_MasName=='' )
                        if(order){
                            order.Eat_Number=order.Eat_Number+_item.Eat_Number
                            if(_item.Eat_AssSize){
                                order.AssSizeNumber +=_item.Eat_AssNum;
                            }
                            return;
                        }
                    }
                }
                this.orderMenuList.push(_item);
                //判断口味 是否新增口味菜品
                let tasteMenu = this.tasteMenu(_item.FoodModifys,_item,'',0);
                tasteMenu?.map(item=>{
                    if(item.Eat_ConsumeID==_item.Eat_ConsumeID && !_item.is_taste){
                        this.orderMenuList.push(item);
                    }
                })
                _item.FoodSetmeals?.forEach(food=>{
                    if(food?.FoodModifys){
                        food.FoodModifys.map(modi=>{
                            let kind=AllMasKinds.find(kind=>kind.Mas_AutoID==modi.Mas_AutoID);
                            if(kind){
                                modi = Object.assign(modi,{Mas_Name:kind.Mas_Name,Mas_Code:kind.Mas_Code,Is_AddMoney:kind.Is_AddMoney,Price_Kind:kind.Price_Kind})
                            }
                        })
                    } 
                    let tasteMenu = this.tasteMenu(food.FoodModifys,_item,food,0);
                    tasteMenu.map(item=>{
                        this.orderMenuList.push(item);
                    })
                })
            })
            this.radioClick(index,this.orderMenuList.length[index]);
            this.withMenuShow=false;
            
        },
        //配菜单的口味要求放入口味要求的临时集合
        quoteAllMasKind(orderList,AllMasKinds){
            orderList.map(order=>{
                //口味要求不为空
                if(order.FoodModifys){
                    order.FoodModifys.map(food=>{
                        if(!food?.IsAllRequired){
                            food = Object.assign(food,{IsAllRequired:0})
                        }
                    })
                    let _quoteFlavors = order.FoodModifys
                    order.FoodModifys.map(food=>{
                        let _quoteOrder = order
                        let _opt={disRadioOrder:_quoteOrder,optFlavors:food}
                        if(this.aloneMethod.length>0){
                            if(this.aloneMethod.findIndex(alone=>alone.disRadioOrder.Eat_ConsumeID===_quoteOrder.Eat_ConsumeID)==-1){
                                this.aloneMethod.push(_opt)
                            }
                            this.aloneMethod.some(alone=>{
                                if(alone.disRadioOrder.Eat_ConsumeID==_quoteOrder.Eat_ConsumeID){
                                    alone.optFlavors = _quoteFlavors
                                }
                            })
                        }else{
                            this.aloneMethod.push(_opt)
                        }
                    })
                }
                //如果是套餐
                order.FoodSetmeals?.forEach(food=>{
                    let _quoteOrder = food
                    if(food?.FoodModifys){
                        let _quoteFlavors=[]
                        food.FoodModifys.map(mod=>{
                            if(!mod?.Mas_Name){
                                if(AllMasKinds){
                                    let kind=AllMasKinds.find(kind=>kind.Mas_AutoID==mod.Mas_AutoID);
                                    if(kind){
                                        mod = Object.assign(mod,{Mas_Name:kind.Mas_Name,Mas_Code:kind.Mas_Code,Is_AddMoney:kind.Is_AddMoney,Price_Kind:kind.Price_Kind})
                                    }
                                }
                            }
                            _quoteFlavors.push(mod)
                            let _opt={disRadioOrder:_quoteOrder,optFlavors:_quoteFlavors}
                            if(this.aloneMethod.length>0){
                                if(this.aloneMethod.findIndex(alone=>alone.disRadioOrder.Eat_ConsumeID===_quoteOrder.Eat_ConsumeID)==-1){
                                    this.aloneMethod.push(_opt)
                                }
                                this.aloneMethod.some(alone=>{
                                    if(alone.disRadioOrder.Eat_ConsumeID==_quoteOrder.Eat_ConsumeID){
                                        alone.optFlavors = _quoteFlavors
                                    }
                                })
                            }else{
                                this.aloneMethod.push(_opt)
                            }
                        })
                    }
                })
            })
        },
        //修改席位 点击确认
        setDekNumConfirm(d){
            if(d){
                this.setDekNumShow=false;
                this.$keyavail=false;

                this.orderInfo = d;
                this.orderMenuList=JSON.parse(JSON.stringify(d.Order_ConsumeList))
                this.DeskLocked = this.orderInfo.Is_Locked;
                this.lockEqId = this.orderInfo.Lock_EqId;
                if(d.Order_PaymentDetailList){
                    this.orderPaymentDetailList = d.Order_PaymentDetailList;
                }
                if(this.orderMenuList.length>0){
                    this.isPlace=true
                }
                if(this.orderInfo.IsMemberPrice==true){
                    this.userCheckOutMiddleMenu.map(item=>{
                        if(item.ProjectItem_Key=="MemberPrice"){
                            item.ProjectItem_Name='取消会员价'
                        }
                    })
                }
                this.consumeTypeMethod(d.Consume_TypeID)
                this.diningChannel(this.orderInfo.CHANNEL_ID)
            }
        },
        /**翼码/正品支付返回*/
        savePayClose(Payments,Timestamps,selectCoupon){
            this.savePayShow = false
            this.zpCouponShow=false
            this.$keyavail = false
            this.isPayName='暂无'
            this.choicePayType=[]
            this.ispayid=0
            if(Payments){
                this.orderPaymentDetailList=this.orderPaymentDetailList.concat(Payments)
                this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(Payments)
                Payments.forEach(pay=>{
                    this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money);
                    this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                })
            }
            if(Timestamps){
                this.orderInfo.Timestamps = Timestamps
            }
            if(selectCoupon){
                /**本地更新卡券核销数量 */
                selectCoupon.map(tick=>{
                    this.orderInfo.Order_ConsumeList.map(order=>{
                        if(tick?.ConsumeID==order.Eat_ConsumeID){
                            order.Exchanged_Number += tick.Exchange_Num
                        }
                    })
                })
            }
        },
        /**翼码/正品 卡券支付结账返回 */
        savePayCouponClick(data){
            this.savePayShow = false
            this.zpCouponShow=false
            this.$keyavail = false
            let PosDesk = {Desk_AutoID:this.posDesks.Desk_AutoID,DeskTimestamps:data.ResponseBody.DeskTimestamps.toString()}
            this.$router.push({
                path: 'diningTable',name:'diningTable',
                params:{
                        PosDesksRetrun: JSON.stringify(PosDesk),
                        type:1
                    }
            });
        },
        /**未改量菜品名称返回 */
        orderFoodName(){
            let _FoodName=''
            this.orderInfo.Order_ConsumeList.map(order=>{
                if(order.ChangeNum_Status==1 && order.Eat_Number>0 && order.FoodDiscount_Dimension!='赠' && order.Food_Type!=2){
                    _FoodName+=order.EAT_XFName+','
                }
            })
            if(this.unionType==true){
                this.orderInfo.UnionOrderInfoList.map(union=>{
                    union.Order_ConsumeList.map(order=>{
                        if(order.ChangeNum_Status==1 && order.Eat_Number>0 && order.FoodDiscount_Dimension!='赠' && order.Food_Type!=2){
                            _FoodName+=order.EAT_XFName+','
                        }
                    })
                })
            }
            return _FoodName = _FoodName.substring(0,_FoodName.lastIndexOf(','))
        },
        /**点单界面横排数量按钮 */
        keyNumber(number,type){
            if(this.orderNumber.indexOf('.')!=-1 && number=='.'){
                return 
            }
            if(type || type==0){
                this.orderNumberType=type
            }
            if(number>=0 || number=='.'){
                if(this.orderNumber.length>0 && number=='.'){
                    this.orderNumber =  this.orderNumber+number;
                }else if(number!='.'){
                    this.orderNumber =  this.orderNumber+number;
                }
            }else if(number=='-1' && this.orderNumber.length>0){
                this.orderNumber= this.orderNumber.substring(0,this.orderNumber.length-1);
            }

            let index=this.orderNumber?.indexOf(".");
            if(index>=0){//保留小数位4位
                this.orderNumber= this.orderNumber.substring(0,index+5);
            }
        },
        /**控制菜品红点数量 */
        posFoodNumbermethod(type,operate){
            if(type==4){//全删
                //如果有大类直接大类删除
                if(this.foodMainCategory){
                    this.foodMainCategory.map(main=>{
                        main.FoodSubCategorys.map(food=>{
                            food.PosFoods.map(pos=>{
                                if(pos.foodNumber>=0){
                                    pos.foodNumber=0
                                }
                            })
                        })
                    })
                }else{
                //小类删除
                    this.foodSubCategorys.map(food=>{
                        food.PosFoods.map(pos=>{
                            if(pos.foodNumber>=0){
                                pos.foodNumber=0
                            }
                        })
                    })
                }
            }else if(type==5){
                this.foodSubCategorys.map(food=>{
                    food.PosFoods.map(pos=>{
                        pos.foodNumber=0
                        this.orderMenuList.map(order=>{
                            if(order.EAT_XFBMID==pos.Food_ID && order.Food_Status==-1 && !order?.is_taste){
                                pos.foodNumber += order.Eat_Number
                            }
                        })
                    })
                })
            }else{
                if(this.orderMenuList){
                    this.orderMenuList.map(order=>{
                        if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID && !order?.is_taste){
                            this.disRadioOrder.Eat_Number = order.Eat_Number
                        }
                    })
                }
                this.foodSubCategorys.map(food=>{
                    food.PosFoods.map(pos=>{
                        if(pos.Food_ID==this.disRadioOrder.EAT_XFBMID && !this.disRadioOrder?.is_taste){
                            if(type==1){//+
                                if(Number(this.orderNumber>0) && !operate){
                                    pos.foodNumber=this.addNum(Number(this.orderNumber),pos.foodNumber)
                                }else if(this.isUnitWeight(pos.Food_Size) && Number(this.wgNum)>0){
                                    pos.foodNumber=this.addNum(Number(this.wgNum),pos.foodNumber)
                                }else{
                                    pos.foodNumber=this.addNum(1,pos.foodNumber)
                                }
                            }else if(type==2){//-
                                pos.foodNumber= this.accSub(1,pos.foodNumber);
                            }else if(type==3){//单删
                                pos.foodNumber = this.accSub(this.disRadioOrder.Eat_Number,pos.foodNumber);
                                if(pos.foodNumber<0){
                                    pos.foodNumber=0
                                }
                            }else if(type==5){
                                pos.foodNumber = this.disRadioOrder.Eat_Number
                            }
                        }else{
                            //多规格菜品
                            if(pos?.MultipleSizeFoods){
                                pos?.MultipleSizeFoods.map(mu=>{
                                    if(mu.Food_ID==this.disRadioOrder.EAT_XFBMID && !this.disRadioOrder?.is_taste){
                                        if(type==1){//+
                                            if(Number(this.orderNumber>0) && !operate){
                                                pos.foodNumber=this.addNum(Number(this.orderNumber),pos.foodNumber)
                                            }else if(this.isUnitWeight(mu.Food_Size) && Number(this.wgNum)>0){
                                                pos.foodNumber=this.addNum(Number(this.wgNum),pos.foodNumber)
                                            }else{
                                                pos.foodNumber=this.addNum(1,pos.foodNumber)
                                            }
                                        }else if(type==2){//-
                                            pos.foodNumber= this.accSub(1,pos.foodNumber);
                                        }else if(type==3){//单删
                                            pos.foodNumber = this.accSub(this.disRadioOrder.Eat_Number,pos.foodNumber);
                                            if(pos.foodNumber<0){
                                                pos.foodNumber=0
                                            }
                                        }else if(type==5){
                                            pos.foodNumber = this.disRadioOrder.Eat_Number
                                        }
                                    }
                                })
                            }
                        }
                    })
                })
            }
        },
        /**拿到菜品的成本价,计算毛利率 */
        orderCompleteGross(type){
            this.foodSubCategorys.map(food=>{
                food.PosFoods.map(pos=>{
                    pos.foodNumber=0
                    if(this.orderMenuList){
                        this.orderMenuList?.map(order=>{
                            if(order.Food_Status==-1){
                                //如果是即配套餐
                                if(order?.Food_Type==3 || order?.Food_Type==2){
                                    if(type==1){
                                        if(order?.Eat_TcItemList){
                                            order?.Eat_TcItemList.map(eat=>{
                                                if(eat.EAT_XFBMID==pos.Food_ID && eat.Food_Status==-1 && !eat?.is_taste){
                                                    eat = Object.assign(eat,{Food_CostPrice:pos.Food_CostPrice})
                                                }
                                            })
                                        }
                                    }
                                    if(type==2){
                                        order?.FoodSetmeals.map(eat=>{
                                            if(eat.FoodSetmeal_ID==pos.Food_ID && eat.Food_Status==-1){
                                                eat = Object.assign(eat,{Food_CostPrice:pos.Food_CostPrice})
                                            }
                                        })
                                    }
                                    if(order?.Food_Type==2){
                                        if(order.EAT_XFBMID==pos.Food_ID && order.Food_Status==-1 && !order?.is_taste){
                                            if(pos?.Promotion_Price){
                                                order.Promotion_Price=pos?.Promotion_Price
                                                order.Eat_CheckPrice = pos?.Promotion_Price
                                                order.ConsumeOperations=[{OperateType:5}]
                                            }
                                        }
                                    }
                                }else if(order?.FoodProperty_Dimension=='套'){
                                    order.FoodSetmeals?.forEach(eat=>{
                                        if(eat.FoodSetmeal_ID==pos.Food_ID && eat.Food_Status==-1){
                                            eat = Object.assign(eat,{Food_CostPrice:pos.Food_CostPrice})
                                        }
                                    })
                                    if(order.EAT_XFBMID==pos.Food_ID && order.Food_Status==-1 && !order?.is_taste){
                                        if(pos?.Promotion_Price){
                                            order.Promotion_Price=pos?.Promotion_Price
                                            order.Eat_CheckPrice = pos?.Promotion_Price
                                            order.ConsumeOperations=[{OperateType:5}]
                                        }
                                    }
                                    
                                }else if(order.EAT_XFBMID==pos.Food_ID && order.Food_Status==-1 && !order?.is_taste){
                                    order = Object.assign(order,{Food_CostPrice:pos.Food_CostPrice})
                                    if(pos?.Promotion_Price){
                                        order.Promotion_Price=pos?.Promotion_Price
                                        order.Eat_CheckPrice = pos?.Promotion_Price
                                        order.ConsumeOperations=[{OperateType:5}]
                                    }
                                }
                                //存在多规格菜品
                                if(pos?.MultipleSizeFoods){
                                    pos.MultipleSizeFoods.map(mu=>{
                                        if(order.EAT_XFBMID==mu.Food_ID && order.Food_Status==-1 && !order?.is_taste){
                                            order = Object.assign(order,{Food_CostPrice:mu.Food_CostPrice})
                                            if(mu?.Promotion_Price){
                                                order.Promotion_Price=mu?.Promotion_Price
                                                order.Eat_CheckPrice = mu?.Promotion_Price
                                                order.ConsumeOperations=[{OperateType:5}]
                                            }
                                        }
                                    })
                                }
                            }
                        })
                    } 
                })
            })
        },
        /**执行促销方案 */
        doPromotionPrice(item){
            /**执行促销方案 */
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Do_Type:1,
                Eat_AutoID:this.orderInfo.Eat_AutoID, //订单Id
                Timestamps:this.orderInfo?.Timestamps.toString(), //时间戳
            }
            if(item.ProjectItem_Name=='参与促销方案'){
                param.Do_Type=1
            }else{
                param.Do_Type=2
            }
            const loading = this.$loading({
                text: "执行促销中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.DoPromotionPrice",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                }else{
                    this.orderInfo = data.ResponseBody;
                    this.orderMenuList=JSON.parse(JSON.stringify(data.ResponseBody.Order_ConsumeList))
                    this.$message.success(item.ProjectItem_Name+"成功");
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**团餐绑定返回 */
        RdGroupcloseModel(Timestamps){
            if(Timestamps){
                this.orderInfo.Timestamps = Timestamps.toString()
            }
        },
        addNum(arg1, arg2) {//保留4个小数位 计算
            return Number((Number(arg1||0) +Number(arg2||0)).toFixed(4));
        },
        //订单数据更新 
        changeOrderInfo(orderInfo){
            this.orderInfo = orderInfo;
            this.orderMenuList=JSON.parse(JSON.stringify(orderInfo.Order_ConsumeList))
        },
        /** 单品组合套餐  点击确认 */
        makeupTCConfirm(orderInfo){
            this.changeOrderInfo(orderInfo);
            this.makeupTCShow=false;
            this.$keyavail =false;
        },
        /**读卡识别菜品 
         * @param callback Function  返回 菜品id 数组
        */
        readCardFood(callback){
            if(!_data.Running_DtFoodCard_YN){
                this.$message.warning("该门店未注册读卡点菜功能!");
                return ;
            }
            const loading = this.$loading({
                text: "读卡中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.readCardOrder().then(ids=>{
                loading.setText("查找对应菜品中");
                console.log("卡数据：",ids)
                this.$httpAES.post("Bestech.CloudPos.GetDtCardsMappingFoods", {Card_NOList:ids}).then((d)=>{
                    loading.close();
                    if(d.ResponseHeader.ResultCode!=0){
                        this.$message.error(d.ResponseHeader.ResultDesc)
                        return;
                    }else{
                        typeof(callback)=="function" && callback(d.ResponseBody);
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert("卡号绑定的菜品数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
                });
            }).catch(d=>{
                loading.close();
                this.$message.warning(d.message);
            })
        },
        /**读卡 返回多个卡数据 */
        readCardOrder(){
            return new Promise((resolve,reject) => {
                this.$webBrowser.getReadCardOrder().then(d=>{
                    if(d){
                        if(d.isEnableReadCard){
                            this.$webBrowser.readCardOrder(d.brand,d.port,d.baud).then(d=>{//读卡器开启成功
                                if(d && d.state==0){
                                    if(d.data?.length>0){
                                        resolve(d.data);
                                        
                                    }else{
                                        reject({message:'未读到卡片!'})
                                    }
                                }else{
                                    console.log("读卡失败",d);
                                    reject({message:'未读到卡片'})
                                }
                            });
                        }else{
                            reject({message:'未启用读卡点菜功能!'})
                        }
                    }else{
                        reject({message:'读卡点菜未配置!'})
                    }
                });
            });
        },
        /**读卡点菜添加到数组中 */
        readCardAddFood(food){
            let SoloOutfood='',limitedProject='',cardNO=''
            food=food.filter(it=>{
                if(!it.EAT_XFBMID){
                    cardNO+=it.Card_NO+','
                    return false;
                }
                return true;
            })
            this.posFoods.forEach(pos=>{
                food.forEach(food=>{
                    if(food.EAT_XFBMID==pos.Food_ID){
                        //已被沽清的菜品
                        if(pos.Is_SoldOut){
                            SoloOutfood+=pos.Food_Name+','
                            return true
                        }
                        //是否限量销售
                        if(pos.Is_Limited){
                            if(pos.RemainNum==0){
                                //是否有限量销售方案
                                if(pos.Is_LimitedProject){
                                    limitedProject+=pos.Food_Name+','
                                    return true
                                }
                            }
                        }
                        //点单时,相同菜品自动合并一行
                        if(_data.MergeSameFoodInOrder){
                            let _exists = this.orderMenuList.some(order=> order.EAT_XFBMID==pos.Food_ID && order.Food_Status==-1 && order.Eat_MasName=='' )
                            if(_exists){
                                this.orderMenuList.some((order,i)=>{
                                    if(order.EAT_XFBMID==pos.Food_ID && order.Food_Status==-1 && order.Eat_MasName==''){
                                        order.Eat_Number++
                                        if(pos.Food_AssSize){
                                            order.AssSizeNumber ++
                                        }
                                        return true;
                                    }
                                    return false;
                                })
                            }else{
                                this.addFood(pos,1)
                            }
                        }else{
                            this.addFood(pos,1)
                        }
                    }
                })
            })
            if(cardNO){
                cardNO = cardNO.substring(0,cardNO.lastIndexOf(','))
                this.$message({
                    dangerouslyUseHTMLString:true,
                    message:'<div style="word-wrap:break-word;max-width:600px;win-width:100px;">'+cardNO+'</div>卡号下没有菜品信息',
                    type:'warning'
                })
            }
            if(SoloOutfood){
                SoloOutfood = SoloOutfood.substring(0,SoloOutfood.lastIndexOf(','))
                this.$message.warning(SoloOutfood+'是沽清的菜品,无法添加到订单中');
            }
            if(limitedProject){
                limitedProject = limitedProject.substring(0,limitedProject.lastIndexOf(','))
                this.$message.warning(SoloOutfood+'菜品已售完,无法添加到订单中');
            }
        },
        /**微生活验证码返回 */
        cardCodeConfirm(code){
            this.wliveVerify=code
            this.wliveCardCodeShow = false
            this.PaySubmit(this.payPreviewData)
        },
        /**微生活密码返回 */
        passwordConfirm(pwd){
            this.wliveVerify=pwd
            this.wlivePasswordShow = false
            this.PaySubmit(this.payPreviewData)
        },
        /**通用支付预览方法
         * param 支付预览参数
         * payType 哪一种支付
         * selectCoupon 卡券支付本地更新卡券核销数量
         */
        publicPayPreview(param,payType,selectCoupon){
            /**支付预览 */
            const loading = this.$loading({
                text: "支付预览中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.PayPreview",param).then((data)=>{
                loading.close();
                console.log('支付预览返回data:'+JSON.stringify(data))
                
                if(data.ResponseHeader.ResultCode!=0){
                    this.isPayPreviewSucc=false
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    if(data.ResponseBody?.Timestamps){
                        this.orderInfo.Timestamps = data.ResponseBody?.Timestamps.toString()
                    }
                    /**保存新时间戳 */
                    if(data.ResponseBody?.UnionOrders){
                        this.unionTimeUpdate(data.ResponseBody)
                    }
                }else{
                    this.isPayPreviewSucc=true
                    this.orderInfo.Timestamps = data.ResponseBody?.Timestamps.toString()
                    /**保存新时间戳 */
                    if(data.ResponseBody?.UnionOrders){
                        this.unionTimeUpdate(data.ResponseBody)
                    }
                    /**Is_NeedSMSCode：是否需要短信验证码0不需要1需要 */
                    if(data.ResponseBody.Is_NeedSMSCode==1){
                        this.wliveCardCodeShow = true
                        return
                    }
                    /**Is_NeedPassWord：是否需要密码验证0不需要1需要 */
                    if(data.ResponseBody.Is_NeedPassWord==1){
                        this.wlivePasswordShow=true
                        return
                    }
                    /**卡券支付本地更新卡券核销数量 */
                    if(selectCoupon){
                        selectCoupon.forEach(tick=>{
                            this.orderInfo.Order_ConsumeList.map(order=>{
                                if(tick.ConsumeID==order.Eat_ConsumeID){
                                    order.Exchanged_Number += tick.Exchange_Num
                                }
                            })
                        })
                    }
                    /**支付提交判断 0不需要 1需要 */
                    if(data.ResponseBody.Is_NeedSubmit==1){
                        this.publicPaySubmit(data,payType)
                    }else{
                        this.pagetypeHide(payType)
                        this.isPayName='暂无'
                        this.choicePayType=[]
                        if(data.ResponseBody.Payments.length>0){
                            this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data.ResponseBody.Payments)
                            this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(data.ResponseBody.Payments)
                            data.ResponseBody.Payments.map(pay=>{
                                this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money);
                                this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                            })
                        }
                        /**保存新时间戳 */
                        if(data.ResponseBody?.UnionOrders && data.ResponseBody?.UnionOrders.length>0){
                            this.unionTimeUpdate(data.ResponseBody)
                        }
                        this.orderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        if(payType=='paymentCouponsShow' || payType=='savePayShow' || payType=='zpCouponShow'|| payType=='westsoftCouponShow'){
                            ElMessage.success({ message: "支付完成,剩余需要支付金额:"+Number(this.orderInfo.UnPaid_Money).toFixed(2)+""});
                        }
                        if(Number(Number(this.orderInfo.UnPaid_Money).toFixed(2))<=0 && this.wlivePayExist==false){
                            this.publicPerformingCheckOut(data,payType)
                        }
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**通用支付提交方法 */
        publicPaySubmit(data,payType){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID, //登陆用户ID
                Eat_AutoID: this.orderInfo.Eat_AutoID,//结账单ID
                Member_Type:data.ResponseBody.Member_Type,  //会员类型
                Biz_ID:data.ResponseBody.Biz_ID, //业务交易流
                Verify_Code:'', //验证码
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Timestamps:data.ResponseBody.Timestamps.toString(),
                Payments:data.ResponseBody.Payments
            }
            /**支付提交 */
            const loading = this.$loading({
                text: "支付提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.PaySubmit",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.orderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.isPayName='暂无'
                    this.choicePayType=[]
                    if(data.ResponseBody.Payments.length>0){
                        this.orderPaymentDetailList=this.orderPaymentDetailList.concat(data.ResponseBody.Payments)
                        this.orderInfo.Order_PaymentDetailList=this.orderInfo.Order_PaymentDetailList.concat(data.ResponseBody.Payments)
                        data.ResponseBody.Payments.forEach(pay=>{
                            this.orderInfo.Payment_Money = Number(this.orderInfo.Payment_Money).compute(pay.Payment_Money)
                            this.orderInfo.UnPaid_Money = Number(this.orderInfo.UnPaid_Money).compute(pay.Payment_Money,'-');
                        })
                    }
                    /**保存新时间戳 */
                    if(data.ResponseBody?.UnionOrders && data.ResponseBody?.UnionOrders.length>0){
                        this.unionTimeUpdate(data.ResponseBody)
                    }
                    this.orderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                    //是否走完成结账接口
                    if(data.ResponseBody?.TryCheckOut && this.wlivePayExist==false){
                            /**完成结账 */
                            this.publicPerformingCheckOut(data,payType)
                        }else{
                            this.pagetypeHide(payType)
                        }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**通用结账方法 */
        publicPerformingCheckOut(data,payType){
            let EatAutoIDList=[]
            let orderEatAutoList={Order_ID:this.orderInfo.Eat_AutoID,Timestamps:this.orderInfo.Timestamps.toString(),Service_Charge:this.orderInfo.Service_Money}
            EatAutoIDList.push(orderEatAutoList)
            let _Union = []
            if(this.unionType==true){
                if(this.orderInfo.UnionOrderInfoList){
                    this.orderInfo.UnionOrderInfoList.map(union=>{
                        orderEatAutoList = {Order_ID:union.Eat_AutoID,Timestamps:union.Timestamps,Service_Charge:union.Service_Money}
                        EatAutoIDList.push(orderEatAutoList)
                        let unionDesk ={Eat_DeskID:union.Eat_DeskID}
                        _Union.push(unionDesk)
                    })
                }
            }
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo.User_ID, //登陆用户ID
                CheckOutOrders:EatAutoIDList, //结账订单ID
                MantissaAdjust_Money:this.orderInfo.Mantissa_Money, //尾数调整金额
                Service_Charge:this.orderInfo.Service_Money,//服务费
                Print_YN:true, //是否返回结账单打印内容
                Operator_Name:this.userInfo?.Login_Name, //操作员
                ElectronicInvoice_PrintForbidden:this.$global.invoiceOff,//是否选择禁止打印发票二维码 false: 默认打印发票二维码,true: 不打印发票二维码
                PhaseID:this.$global.PhaseID,//指定时段ID
            }
            const loading = this.$loading({
                text: "结账中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log('结账提交:',param)
            this.$httpAES.post("Bestech.CloudPos.PerformingCheckOut",param).then((data)=>{
                loading.close();
                console.log('结账返回data:'+JSON.stringify(data))
                if(data.ResponseHeader.ResultCode!=0){
                    this.refreshOrder()
                    this.$message.error(data?.ResponseHeader.ResultDesc);
                }else{
                    
                    if(data.ResponseBody?.OpenCashBox===true){
                        this.$webBrowser.openCashBoxBnt().then(d=>{
                            if(d?.state!=0){
                                this.$message.error('钱箱打开失败:'+(d?.message||""));
                            }
                        });
                    }
                    if(param.Print_YN===true && data.ResponseBody?.PrintsInfo){//返回结账单打印内容
                        setTimeout(()=>{
                            this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                        },500)
                    }
                    ElMessage.success({ message: "结账成功"});
                    this.pagetypeHide(payType)
                    this.$keyavail = false
                    let PosDesk = {Desk_AutoID:this.posDesks.Desk_AutoID}
                    this.$router.push({
                        path: 'diningTable',name:'diningTable',
                        params:{
                                PosDesksRetrun: JSON.stringify(PosDesk),
                                type:1
                            }
                    });
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**隐藏页面 */
        pagetypeHide(payType){
            /**AR账 */
            if(payType=='arShow'){
                this.arShow=false
            }
            /**商品券/现金券 */
            if(payType=='paymentCouponsShow'){
                this.paymentCouponsShow=false
            }
            /**押金 */
            if(payType=='preMoneyShow'){
                this.preMoneyShow=false
            }
            /**西软挂账 */
            if(payType=='westShow'){
                this.westShow=false
            }
            /**企业结算 */
            if(payType=='enterCloseShow'){
                this.enterCloseShow=false
            }
            /**翼码券支付 */
            if(payType=='savePayShow'){
                this.savePayShow=false
            }
            /**正品卡券核销 */
            if(payType=='zpCouponShow'){
                this.zpCouponShow=false
            }
            /**西软卡券核销 */
            if(payType=='westsoftCouponShow'){
                this.westSoftCouponShow=false
            }
        },
        addFood(pos,number){
            //添加到订单
            let _item ={
                Eat_ConsumeID:newGuid(),
                SubCategory_ID:pos.SubCategory_ID,
                EAT_XFBMID:pos.Food_ID,
                EAT_XFCode:pos.Food_Code,
                EAT_XFName:pos.Food_Name,
                Eat_Number:number,
                Eat_CheckPrice:pos.Food_Price,
                ModiPrice:0,
                Eat_SalePrice:pos.Food_Price,
                Food_CostPrice:pos.Food_CostPrice,//成本金额
                Eat_MasName:'',
                Food_Status:-1,
                Food_AssSize:pos.Food_AssSize,
                AssSizeNumber:'',
                Eat_XFSize:pos.Food_Size,
                FoodModifys:null,
                FoodSetmeals:null,
                Is_Special:pos.Is_Special,//是否是推荐菜
                Is_Discounted:pos.Is_Discounted,
                Is_Seasonal:pos.Is_Seasonal,
                RemainNum:pos.RemainNum,
                Is_Limited:pos.Is_Limited,
                Is_ChangedNum:pos.Is_ChangedNum,
                Is_ChargeForService:pos.Is_ChargeForService,
                Is_SetMeal:pos.Is_SetMeal,
                Promotion_Price:0,
                ConsumeOperations:[]};
            //促销价
            if(pos?.Promotion_Price || pos?.Promotion_Price==0){
                _item.Eat_CheckPrice = pos?.Promotion_Price
                _item.Promotion_Price = pos?.Promotion_Price
                _item.ConsumeOperations=[{OperateType:5}]
            }
            //有辅助单位
            if(pos.Food_AssSize){
                _item.AssSizeNumber=1
            }
            this.orderMenuList.push(_item);
            this.radioClick(this.orderMenuList.length-1,_item);
            this.posFoodNumbermethod(1)
        },
        /**跳转到菜单点菜 */
        menuOrder(){
            if(this.DeskLocked && this.lockEqId!= this.UUID){
                this.$alert("本桌号已锁定，请先解锁后进入菜单点餐！")
            }else{
                this.$router.push({
                    name:'ipdUserOrderMode',
                    params:{
                        Desk_AutoID:this.posDesks.Desk_AutoID,
                        Eat_AutoID:this.posDesks.Eat_AutoID,
                    }
                });
            }
        },
        /** 厨房状态 标签 显示htnl
         * @param KitSpeed_Dimension 厨房速度维度 ： 即，等，起，催，划
         * @param Food_Status 划菜状态，0：待切配 ，1：已切配 待制作， 2：已制作 待划菜， 3：已划菜 待上桌 ，4：已上桌
        */
        kitSpeedHtml(KitSpeed_Dimension,Food_Status){
            //已上桌 状态 优先于其他厨房速度维度
            if(Food_Status==4){
                return "<span class='tag img'><img src='/images/served.png'/></span>";
            }else if(KitSpeed_Dimension=="划"){
                return "<span class='tag iconfont icon-yihuacai'></span>";
            }else if(KitSpeed_Dimension=="即"){
                return "<span class='tag tagJi'>即</span>";
            }else if(KitSpeed_Dimension=="等"){
                return "<span class='tag tagDeng'>等</span>";
            }else if(KitSpeed_Dimension=="起"){
                return "<span class='tag tagQi'>起</span>";
            }else if(KitSpeed_Dimension=="催"){
                return "<span class='tag tagCui'>催</span>";
            }else if(KitSpeed_Dimension){
                return '<span class="tag delimit">'+KitSpeed_Dimension+'</span>'
            }   
        },
        payRemarkconfirm(title){
            this.RemarkTitle = title;
            this.isShowRemark=false;
            this.$keyavail=false;
        },
        /**当有弹层显示出来时 */
        modalShow(){
            if(this.isCheckOut){
                this.$refs.payInput?.close(true);//关闭键盘并标记
            }else{
                this.$refs.searchBox?.close(true);//关闭键盘并标记
            }
        },
        /**当所有弹层关闭时 */
        modalClose(){
            if(this.isCheckOut){
                this.$refs.payInput.focus();//获取焦点
                //判断键盘 未标记前是否是显示的
                if(this.$refs.payInput?.oldIsShow) {
                    this.$refs.payInput?.showKeyBoard();//显示键盘
                }
            }else{
                this.$refs.searchBox.focus();//获取焦点
                //判断键盘 未标记前是否是显示的
                if(this.$refs.searchBox?.oldIsShow){
                    this.$refs.searchBox?.showKeyBoard();//显示键盘
                }
            }
        },
        /**批量退菜 成功 更新订单数据*/
        batchRetrunConfirm(data){
            this.batchReturnShow=false;
            
            this.orderInfo.Order_ConsumeList =  data.Order_ConsumeList;
           
            this.orderInfo.IsPromotionPrice=data.IsPromotionPrice;
            this.orderInfo.Timestamps = data.Timestamps.toString();
            this.orderInfo.Eat_SaleMoney = data.Eat_SaleMoney;
            this.orderInfo.Eat_CostMoney = data.Eat_CostMoney;
            this.orderInfo.Eat_CheckMoney = data.Eat_CheckMoney;
            this.orderInfo.UnPaid_Money = data.UnPaid_Money;
            this.orderMenuList =this.orderInfo.Order_ConsumeList;
        },
        /**是否标准称重单位 */
        isUnitWeight(unit){
            if(unit=="斤" || unit=="公斤" || unit=="克" || unit=="市斤" || (unit||"").toUpperCase() =="KG" || (unit||"").toUpperCase()=="G" ){
                return true;
            }
            return false;
        }
    },
    //被卸载时
    unmounted(){
        this.$shortcutKey.setFun();
        this.$keyBoard.close();
        if(this.outTime) clearTimeout(this.outTime);
    }
}
</script>
<style lang="scss">
@import "./diningDetail.scss";
</style>