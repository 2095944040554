<template>
    <modal class="preMoneyModel" :isShow="isShow">
        <div class="header">押金支付</div>
        <div class="modal-body">
            <div class="left-box">
                <div class="filter-box">
                    <key-board-input class="search" placeholder="押金编码、客户姓名、手机号检索"
                        v-model="varietySelect"
                        :isBlurClose="true" :isKeyEnterClose="true"
                        :keyOptions="{isKeyDownEnter:true}"
                    /> 
                    <div class="query-bnt" @click="loadData()">查询</div>
                </div>
                <div class="data-box">
                    <div class="panel-heading">
                        <div class="td order">序号</div>
                        <div class="td name">客户姓名</div>
                        <div class="td phone">手机号</div>
                        <div class="td deposit">收取押金金额</div>
                        <div class="td money">余额</div>
                        <div class="td number">押金编号</div>
                    </div>
                    <div class="scroll-box">
                        <div class="item-box" :class="{selected:selectData==item}" v-for="(item,index) in pageList" :key="index" @click="currentChange(item)">
                            <div class="tr">
                                <div class="td order">{{(pageIndex-1)*pageSize+index+1}}</div>
                                <div class="td name">{{item.DepositName}}</div>
                                <div class="td phone">{{item.DepositPhone}}</div>
                                <div class="td deposit red">¥{{item.DepositMoney}}</div>
                                <div class="td money red">¥{{Number((item.DepositMoney-item.ConsumeMoney).toFixed(4))}}</div>
                                <div class="td number">{{item.DepositCode}}</div>
                            </div>
                            <div class="more-box">
                                <p>交易时间：{{(new Date(item.CREAT_TIME)).Format("yyyy/MM/dd hh:mm:ss")}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-search-box">
                    <div class="page-box-bottom">
                        <page-turning class="last-page" :pageCount="pageCount" v-model="pageIndex" type="up"><i class=" iconfont icon-fangxiangquan-left"></i></page-turning>
                        <span class="page-number">{{pageIndex}}/{{pageCount}}页</span>
                        <page-turning class="next-page" :pageCount="pageCount" v-model="pageIndex"><i class=" iconfont icon-fangxiangquan-right"></i></page-turning>
                    </div>
                </div>
            </div>

            <div class="right-box" ref="recharRight">
                <div class="title">待支付金额：<span>{{Number(unPaidMoney).toFixed(2)}}</span></div>
                <div class="from-tr">
                    客户姓名： {{selectData?.DepositName}}
                </div>
                <div class="from-tr">
                    手机号码： {{selectData?.DepositPhone}}
                </div>
                <div class="from-tr">
                    押金余额： <span class="money" v-show="selectData">¥{{Number((selectData?.DepositMoney-selectData?.ConsumeMoney).toFixed(4))}}</span>
                </div>
                <div class="from-tr">
                    扣除押金： 
                    <div class="from-input">
                        <input-pattern type="text" pattern="money" v-model="money" ref="money"/>
                    </div>
                </div>
                <div class="keyboard">
                    <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                            data-value="9">9</span></div>
                    <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                            data-value="6">6</span></div>
                    <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                            data-value="3">3</span></div>
                    <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                            data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>
import {initKeyBoard} from '/src/common' 
import { httpAES } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"
// 押金管理
export default {
    name:'preMoneyModel',
    props:{
        isShow:Boolean,
        orderInfo:Object,
        choicePayType:Object,
        unionEatAutoID:Object,
        wlivePayExist:Boolean
    },
    data(){
        return{
            varietySelect:'',
            pageIndex:1,
            listData:[],
            pageSize:6,
            //选中的数据
            selectData:null,
            /**临时订单数据 */
            memberorderInfo:'',  
            unPaidMoney:0,
            money:0,
            userInfo:'',
            /**支付记录 */
            Payments:[],
        }
    },
    computed:{
        //操作类型
        DepositEnum(){
            return {
                1:"收取押金",
                2:"抵扣押金" ,
                3:"冲正抵扣押金",
                4:"冲销收取押金"
            }
        },
        //余额
        total(){
            if(this.selectData){
                let money= this.selectData.DepositMoney-this.selectData.ConsumeMoney;
                return money.toFixed(2).replace(/[.]\d*/g,(s)=>{return '<i>'+s+'</i>'});//余额
            }
            return "";
        },
        /**条件筛选 */
        listDataFilter(){
            let text=(this.varietySelect||"").trim().toUpperCase();
            if(text){
                return this.listData?.filter(it=>(it.DepositCode||'').toUpperCase().indexOf(text)>=0 || (it.DepositName||'').toUpperCase().indexOf(text)>=0 
                                    ||(it.DepositPhone||'').toUpperCase().indexOf(text)>=0);
            }
            return this.listData||[];
        },
        pageList(){
            return this.listDataFilter.slice((this.pageIndex-1)*this.pageSize,this.pageIndex*this.pageSize);
        },
        pageCount(){
            return Math.ceil(this.listDataFilter.length/this.pageSize)||1;
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.emptyData();
            this.loadData();
            initKeyBoard.call(this,this.$refs.recharRight,this.$refs.money.$refs.input);
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
            this.unPaidMoney = this.memberorderInfo.UnPaid_Money
            
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.emptyData();
                this.loadData();
                this.selectData=null;
                this.money=0;
                this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
                this.unPaidMoney = this.memberorderInfo.UnPaid_Money
                
            }
        },
        varietySelect(){
            this.pageIndex=1
        },
        orderInfo:{
            immediate:true,
            handler(vla){
                this.emptyData()
                this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
                this.unPaidMoney = this.memberorderInfo.UnPaid_Money
            }
        }
    },
    methods:{
        hide(){
            this.$emit('closeModel')
            //this.$emit("preMoneyPayReturn",'',this.Payments,this.memberorderInfo.Timestamps,this.isPaySubmit);
        },
        //加载数据
        loadData(){
            const loading = this.$loading({
                text: "获取押金数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            this.$httpAES.post("Bestech.CloudPos.GetDepositsInfo", {
                User_ID:userInfo.User_ID,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    if(d.ResponseBody){
                        this.listData=[]
                        d.ResponseBody.map(item=>{
                            if(item.OrderFlag!=1){
                                this.listData.push(item)
                            }
                        })
                    }
                    this.pageIndex=1
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("押金管理数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        currentChange(item){
            this.selectData=item;
            if(Number(this.selectData.DepositMoney-this.selectData.ConsumeMoney)>Number(this.unPaidMoney.toFixed(2))){
                 this.money = this.unPaidMoney.toFixed(2)
            }else{
                 this.money = Number(this.selectData.DepositMoney-this.selectData.ConsumeMoney)
            }
            initKeyBoard.call(this,this.$refs.recharRight,this.$refs.money.$refs.input);
        },
        /**点击确认  */
        confirm(){
            if(Number(this.money)<=0){
                this.$message.error('输入的押金金额必须大于0!');
                return
            }
            if(Number(this.money)>Number(this.selectData.DepositMoney-this.selectData.ConsumeMoney)){
                this.$message.error('结账金额超出押金最大额度');
                return
            }
            if(Number(this.money)>Number(this.unPaidMoney.toFixed(2))){
                this.$message.error('结账金额不可大于押金金额');
                return
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            /**支付方式 */
            let _payment=[]
            _payment.push({
                Payment_ID:this.choicePayType.PAY_ID,  //支付方式ID
                Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                Payment_Money: Number(this.money), //支付金额
                Payment_FactMoney:0, //计营业额
                Qr_Code:'', //扫码付款的码值
                Card_No:this.memberorderInfo.Card_No, //卡号
                Deposit_AutoID:this.selectData.Autoid,//押金单ID
                CouponInfo:null, //券的详情
            })
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID, //登陆用户ID
                Eat_AutoID: this.memberorderInfo.Eat_AutoID,//结账单ID
                UnionOrders:this.unionEatAutoID,//联台订单ID集合
                Timestamps:this.memberorderInfo.Timestamps.toString(), //账单时间戳
                Operator_Name:this.userInfo?.Login_Name,//操作员
                Eat_DeskName:this.memberorderInfo.Eat_DeskName, //桌牌号
                Service_Charge:this.memberorderInfo.Service_Money,  //服务费
                MantissaAdjustment_Money:this.memberorderInfo.Mantissa_Money,  //尾数调整金额
                Member:null,//会员信息
                Payments:_payment, //支付方式列表
            }
            this.$emit("publicPayPreview",param,'preMoneyShow')
        },
        /**清空页面数据 */
        emptyData(){
            this.selectData=null
            //this.listData=[]
            this.Payments='',
            this.pageIndex=1
        },
    }
}
</script>

<style lang="scss">
@import './preMoneyModel.scss'
</style>