<template>
    <div class="bill">
        <div class="filter-box">
            <div class="flex-box">
                <div class="inline-block">
                    <span class="lable-txt">营业日期：</span>
                    <el-date-picker class="from-date"
                        :clearable="false"
                        :editable="false"
                        v-model="businessHours"
                        type="date"
                        placeholder=""
                        :disabled-date="disabledDate"
                        :default-value="new Date()" v-if="navType==0">
                    </el-date-picker>
                    <el-date-picker class="from-date"
                        :clearable="false"
                        :editable="false"
                        v-model="userInfo.Rpt_Date"
                        type="date"
                        placeholder=""
                        readonly
                        :default-value="new Date()" v-else>
                    </el-date-picker>
                </div>
                <div class="inline-block search-box">
                    <el-select v-model="searchType" placeholder="按台号" :readonly="navType!=0">
                        <el-option :value="0" label="按台号"></el-option>
                        <el-option :value="1" label="按结账单"></el-option>
                        <el-option :value="2" label="按开单号"></el-option>
                        <el-option :value="3" label="按结账额"></el-option>
                        <el-option :value="4" label="按消费额"></el-option>
                        <el-option :value="5" label="已开票"></el-option>
                        <el-option :value="6" label="未开票"></el-option>
                        <el-option :value="7" label="已返积分"></el-option>
                        <el-option :value="8" label="未返积分"></el-option>
                        <el-option :value="9" label="按流水号"></el-option>
                    </el-select>
                    <key-board-input class="search"
                        type="text" v-model="deskNumberSearch" placeholder="模糊检索"
                        :readonly="navType!=0 || ( searchType==6 || searchType==7 || searchType==8)"
                        isKeyEnterClose isBlurClose 
                        :keyOptions="{isNumber:true,isKeyDownEnter:true}"
                    ></key-board-input>
                    <!-- <div class="search">
                        <input type="text" placeholder="模糊检索" v-model="deskNumberSearch" :readonly="navType!=0 || (searchType==5 || searchType==6 || searchType==7 || searchType==8)" >
                    </div> -->
                    <div class="bnt-search" @click="search()">搜索</div>
                </div>
                <div class="nav-box" >
                    <div class="nav-li" :class="{selected:navType==0}" @click="navType=0">全部账单</div>
                    <div class="nav-li" :class="{selected:navType==1}" @click="navType=1">外卖订单<span class="tip-num">{{this.takeOutOrderList?.length}}</span></div>
                    <div class="nav-li" :class="{selected:navType==2}" @click="navType=2">异常订单<span class="tip-num">{{this.abnormalOrderList?.length}}</span></div>
                </div>
            </div>
            <div class="filter-type" v-if="navType==0">
                <el-select v-model="Equipment_Name" placeholder="所有站点">
                    <el-option value="" label="所有站点"></el-option>
                    <el-option
                        v-for="item in posEquipmentList"
                        :key="item"
                        :label="item.Equipment_Name"
                        :value="item.Equipment_Name"
                    />
                </el-select>
                <el-select v-model="selectChannelId" placeholder="所有渠道">
                    <el-option value="" label="所有渠道"></el-option>
                    <el-option v-for="item in orderChannel" :key="item"
                        :label="item.OrderChannelKind_Name"
                        :value="item.OrderChannelKind_AutoID">
                    </el-option>
                </el-select>
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
        </div>
        <div class="data-box display-flex" v-show="navType==1">
            <div class="left-box">
                <div class="table-box" ref="takeOutTableBox"  v-dom-resize @resize="resize()">
                    <div class="table-thead">
                        <div class="tr">
                            <div class="column">订单渠道
                                <el-select class="filter" v-model="channelName" placeholder="全部">
                                    <el-option value="" label="全部"></el-option>
                                    <el-option value="美团" label="美团"></el-option>
                                    <el-option value="饿了么" label="饿了么"></el-option>
                                </el-select>
                            </div>
                            <div class="column">订单金额</div>
                            <div class="column">POS单号</div>
                            <div class="column">状态</div>
                        </div>
                    </div>
                    <div class="table-tbody">
                        <div class="li" :class="{selected:item.CanDaoOrder_Autoid==takeOutInfo?.CanDaoOrder_Autoid}" v-for="item in takeOutPageList" :key="item" 
                        @click="getSpecialOrderConsume(item.CanDaoOrder_Autoid,1)">
                            <div class="tr">
                                <div class="column channel">{{item.Channel_Name}}
                                    <span class="type" v-if="item.QuitStatus==1">全单退</span>
                                    <span class="type" v-else-if="item.QuitStatus==2">部分退</span>
                                </div>
                                <div class="column money">￥{{item.Eat_SaleMoney}}</div>
                                <div class="column">{{item.Eat_ID}}</div>
                                <div class="column state">
                                    <div class="cell">
                                        <i class="iconfont icon-jinggao" v-if="item.ProcessStatus==0"></i>
                                        <div class="recorded" v-else>已入账</div>
                                    </div>
                                </div>
                            </div>
                            <div class="content-box">
                                单号：{{item.OrderId}} &nbsp;
                                下单时间：{{item.Eat_Time}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-box">
                    <span>当前第 {{takeOutPageIndex}} 页 &nbsp;&nbsp;&nbsp;&nbsp; 共{{takeOutPageSum}}页</span>
                    <div class="page-button">
                        <page-Turning class="but but-up" type="up" v-model="takeOutPageIndex" :pageCount="takeOutPageSum">上一页</page-Turning>
                        <page-Turning class="but but-down" type="down" v-model="takeOutPageIndex" :pageCount="takeOutPageSum">下一页</page-Turning>
                    </div>
                </div>
            </div>
            <div class="details-box" v-if="takeOutInfo">
                <div class="panel-box" style="min-height:289px">
                    <div class="title">订单详情</div>
                    <div class="content-box">
                        <div class="li-flex">
                            <div class="td">
                                <div class="lable-text">订单渠道:</div>
                                <div class="input-from">{{takeOutInfo.Channel_Name}}</div>
                            </div>
                            <div class="td">
                                <div class="lable-text">平台单号:</div>
                                <div class="input-from">{{takeOutInfo.OrderId}}</div>
                            </div>
                        </div>
                        <div class="li-flex">
                            <div class="td">
                                <div class="lable-text">下单时间:</div>
                                <div class="input-from">{{takeOutInfo.Eat_Time}}</div>
                            </div>
                            <div class="td">
                                <div class="lable-text">申请开票:</div>
                                <div class="input-from">{{takeOutInfo.IsInvoice==1?"是":"否"}}</div>
                            </div>
                        </div>
                        <div class="li-flex">
                            <div class="td">
                                <div class="lable-text">POS单号:</div>
                                <div class="input-from">{{takeOutInfo.Eat_ID}}</div>
                            </div>
                            <div class="td">
                                <div class="lable-text">客户支付:</div>
                                <div class="input-from money">￥{{takeOutInfo.Eat_PayMoney}}</div>
                            </div>
                        </div>
                        <div class="li-flex">
                            <div class="td">
                                <div class="lable-text">商家实收:</div>
                                <div class="input-from money">￥{{takeOutInfo.Eat_FactMoney}}</div>
                            </div>
                            <div class="td">
                                <div class="lable-text">骑手信息:</div>
                                <div class="input-from">{{takeOutInfo.OrderDriversName}}<span v-if="takeOutInfo.OrderDriversPhone">({{takeOutInfo.OrderDriversPhone}})</span></div>
                            </div>
                        </div>
                        <div class="li-flex">
                            <div class="td">
                                <div class="lable-text">送餐信息:</div>
                                <div class="input-from">{{takeOutInfo.UserName}}<span v-if="takeOutInfo.UserPhone">({{takeOutInfo.UserPhone}})</span><br/>{{takeOutInfo.Address}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="table-box">
                        <el-table class="el-table--scrollable-y" :data="takeOutInfo.SpecialOrderConsumeList"  style="width: 100%;">
                            <el-table-column v-if="takeOutInfo?.ProcessStatus==0" label="修改" width="50">
                                <template #default="scope">
                                    <i class="iconfont icon-edit" @click="clickEdit(scope.row,scope.$index)"></i>
                                </template>
                            </el-table-column>
                            <el-table-column prop="Food_Code" label="菜品编号"></el-table-column>
                            <el-table-column prop="Food_Name" label="菜品名称"></el-table-column>
                            <el-table-column prop="Food_Number" label="数量">
                                <template #default="scope">
                                    {{scope.row.Food_Number}}
                                    <template v-if="scope.row.Food_Size">/{{scope.row.Food_Size}}</template>
                                </template>
                            </el-table-column>
                            <el-table-column prop="Eat_SaleMoney" label="金额"></el-table-column>
                        </el-table>
                    </div>
                    <div class="total">合计<span class="money">￥{{takeOutTotal.saleMoney}}</span></div>
                </div>
                <div class="panel-box" style="max-height:30%;min-height:160px">
                    <div class="title">优惠详情</div>
                    <div class="table-box" >
                        <el-table class="el-table--scrollable-y" :data="takeOutInfo.SpecialOrderDiscountList"  style="width: 100%;">
                            <el-table-column prop="DiscountName" label="优惠项目"></el-table-column>
                            <el-table-column prop="Mer_DiscountMoney" label="商家承担优惠">
                                <template #default="scope">
                                    -￥{{scope.row.Mer_DiscountMoney}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="Third_DiscountMoney" label="平台承担优惠">
                                <template #default="scope">
                                    -￥{{scope.row.Third_DiscountMoney}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="Total_DiscountMoney" label="顾客享受优惠">
                                <template #default="scope">
                                    -￥{{scope.row.Total_DiscountMoney}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="total">优惠合计<span class="money">￥{{takeOutTotal.discountMoney}}</span></div>
                </div>
                <div class="remarks-box" v-if="takeOutInfo.ProcessStatus==0">
                    <div class="remarks-title"><i class="iconfont icon-jinggao"></i>订单未记账原因</div>
                    <div class="remarks">
                        {{takeOutInfo.ProcessMsg}}
                    </div>
                </div>
                <div class="foot-box fill-box">
                    <div class="money-box">
                        <!-- <div class="td">
                            应收金额:<span class="money">￥{{takeOutInfo.Eat_SaleMoney}}</span>
                        </div> -->
                        <div class="td">
                            实收金额:<span class="money">￥{{takeOutInfo.Eat_FactMoney}}</span>
                        </div>
                    </div>
                    <div class="fill-box"></div>
                    <div class="bnt-box">
                        <template v-if="currentTakeOut?.ProcessStatus==0">
                            <div class="bnt" v-if="currentTakeOut?.fromType=='bestechorder'" @click="selfTakeoutInPos(takeOutInfo.CanDaoOrder_Autoid)">重新同步订单</div>
                            <div class="bnt" v-else @click="takeoutInPos(takeOutInfo.CanDaoOrder_Autoid)">手动重新入账</div>
                        </template>
                        <template v-else>
                            <div class="bnt" v-if="currentTakeOut?.fromType=='bestechorder'" @click="cancelOrderCandao(takeOutInfo.CanDaoOrder_Autoid)">取消订单</div>
                            <div class="bnt" v-else @click="print()">重印结账单</div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-box display-flex" v-show="navType==2">
            <div class="left-box">
                <div class="table-box" ref="abnormalTableBox"  v-dom-resize @resize="resize()">
                    <div class="table-thead">
                        <div class="tr">
                            <div class="column">订单渠道</div>
                            <div class="column">订单金额</div>
                            <div class="column">操作站点</div>
                            <div class="column">状态</div>
                        </div>
                    </div>
                    <div class="table-tbody">
                        <div class="li" :class="{selected:item.Eat_AutoID==abnormalInfo?.Eat_AutoID}" v-for="item in abnormalPageList" :key="item"
                            @click="getSpecialOrderConsume(item.Eat_AutoID,2)">
                            <div class="tr">
                                <div class="column channel">{{item.Channel_Name}}</div>
                                <div class="column money">￥{{item.Eat_SaleMoney}}</div>
                                <div class="column">{{item.Eat_StationName}}</div>
                                <div class="column state">
                                    <div class="cell">
                                        <i class="iconfont icon-jinggao" v-if="item.ProcessStatus==0"></i>
                                        <div class="recorded" v-else>已入账</div>
                                    </div>
                                </div>
                            </div>
                            <div class="content-box">
                                下单时间：{{item.Eat_Time}}
                                下单人:{{item.Eat_Czy}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-box">
                    <span>当前第 {{abnormalPageIndex}} 页 &nbsp;&nbsp;&nbsp;&nbsp; 共{{abnormalPageSum}}页</span>
                    <div class="page-button">
                        <page-Turning class="but but-up" type="up" v-model="abnormalPageIndex" :pageCount="abnormalPageSum">上一页</page-Turning>
                        <page-Turning class="but but-down" type="down" v-model="abnormalPageIndex" :pageCount="abnormalPageSum">下一页</page-Turning>
                    </div>
                </div>
            </div>
            <div class="details-box" v-if="abnormalInfo">
                <div class="panel-box" style="min-height:220px">
                    <div class="title">订单详情</div>
                    <div class="content-box">
                        <div class="li-flex">
                            <div class="td">
                                <div class="lable-text">订单渠道:</div>
                                <div class="input-from">{{getAbnormalInfo.Channel_Name}}</div>
                            </div>
                            <div class="td">
                                <div class="lable-text">操作站点:</div>
                                <div class="input-from">{{getAbnormalInfo.Eat_StationName}}</div>
                            </div>
                        </div>
                        <div class="li-flex">
                            <div class="td">
                                <div class="lable-text">下单时间:</div>
                                <div class="input-from">{{getAbnormalInfo.Eat_Time}}</div>
                            </div>
                            <div class="td">
                                <div class="lable-text">操作人:</div>
                                <div class="input-from">{{getAbnormalInfo.Eat_Czy}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="table-box">
                        <el-table class="el-table--scrollable-y" :data="getAbnormalInfo.SpecialOrderConsumeList"  style="width: 100%;">
                            <el-table-column prop="Food_Code" label="菜品编号"></el-table-column>
                            <el-table-column prop="Food_Name" label="菜品名称"></el-table-column>
                            <el-table-column prop="Food_Number" label="数量">
                                <template #default="scope">
                                    {{scope.row.Food_Number}}
                                    <template v-if="scope.row.Food_Size">/{{scope.row.Food_Size}}</template>
                                </template>
                            </el-table-column>
                            <el-table-column prop="Eat_SaleMoney" label="金额"></el-table-column>
                        </el-table>
                    </div>
                    <div class="total">合计<span class="money">￥{{abnormalTotal.saleMoney}}</span></div>
                </div>
                <div class="panel-box" style="min-height:105px">
                    <div class="title">付款明细</div>
                    <div class="payment-box" >
                        <div class="li" v-for="item in getAbnormalInfo.SpecialOrderPaymentList" :key="item">
                            <div class="name">{{item.PaymentName}}</div>
                            <div class="money">￥{{item.Payment_FactMoney}}</div>
                        </div>
                    </div>
                    <div class="total">合计<span class="money">￥{{abnormalTotal.factMoney}}</span></div>
                </div>
                <div class="remarks-box">
                    <div class="remarks-title"><i class="iconfont icon-jinggao"></i>订单未记账原因</div>
                    <div class="remarks">{{getAbnormalInfo.ProcessMsg}}</div>
                </div>
                <div class="fill-box"></div>
                <div class="bnt-box">
                    <div class="bnt" @click="reCheckout()">去重新结账</div>
                </div>
            </div>
        </div>
        <div class="data-box" v-show="navType==0">
            <div class="table-box" ref="tableBox" v-table-el-height="'tableEl'">
                <el-table class="el-table--scrollable-y" ref="tableEl" :data="billPageList"  style="width: 100%;height:100%;" border  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" 
                     show-summary :summary-method="getSummaries" 
                     @sort-change="sortChange" 
                     @row-dblclick="dblclick"
                     @header-dragend="headerDragend"
                >
                    <el-table-column fixed prop="biao" label="标记" :width="tempTablewidth[0].width">
                        <template #default="scope">
                            <span class="tag tag-give" v-if="scope.row.IS_Gift">赠</span>
                            <span class="tag tag-retreat" v-if="scope.row.IS_Refund">退</span>
                            <span class="tag tag-discount" v-if="scope.row.IS_ZK">折</span>
                            <span class="tag tag-meeting" v-if="scope.row.IS_Member">会</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed sortable="custom" prop="Order_Type" label="类型" :width="tempTablewidth[1].width">
                        <template #default="scope">
                            <span class="lefts">{{orderType(scope.row.Order_Type)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed sortable="custom" prop="Eat_DeskName" label="台号" :min-width="tempTablewidth[2].width">
                        <template #default="scope">
                            <el-tooltip  effect="dark" :content="scope.row.Eat_DeskName" placement="top">
                                <div class="lefts">{{ scope.row.Eat_DeskName }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column fixed sortable="custom" prop="Eat_CheckIndex" label="结账单号" :width="tempTablewidth[3].width">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_CheckIndex}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable="custom" prop="Eat_SaleMoney" label="消费总额" :width="tempTablewidth[4].width" data-format="number">
                        <template #default="scope">
                            <span class="rigths"  v-if="scope.row.Eat_SaleMoney!=0">{{scope.row.Eat_SaleMoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable="custom" prop="ZK_Money" label="折扣额" :width="tempTablewidth[5].width" data-format="number">
                        <template #default="scope">
                            <span class="rigths" v-if="scope.row.ZK_Money!=0">{{scope.row.ZK_Money}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable="custom" prop="Eat_CheckMoney" label="结账金额" :min-width="tempTablewidth[6].width" data-format="number">
                         <template #default="scope">
                            <span class="rigths" v-if="scope.row.Eat_CheckMoney!=0">{{scope.row.Eat_CheckMoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable="custom" prop="Eat_FactMoney" label="实收金额" :min-width="tempTablewidth[7].width" data-format="number">
                         <template #default="scope">
                            <span class="rigths" v-if="scope.row.Eat_FactMoney!=0">{{scope.row.Eat_FactMoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable="custom" prop="Eat_State" label="状态" :width="tempTablewidth[8].width">
                        <template #default="scope">
                            <span v-if="scope.row.Eat_State==2">已结账</span>
                            <span v-if="scope.row.Eat_State==1">未结账</span>
                        </template>
                    </el-table-column> 
                    <el-table-column prop="Order_PaymentDetailList" label="付款情况" :min-width="tempTablewidth[9].width" show-overflow-tooltip>
                        <template #default="scope">
                            <el-tooltip  effect="dark" :content="paymentInfo(scope.row.Order_PaymentDetailList)" placement="top">
                                <div class="lefts">{{paymentInfo(scope.row.Order_PaymentDetailList)}}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CHANNEL_NAME" label="订单渠道" :width="tempTablewidth[10].width">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.CHANNEL_NAME}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="EAT_PHASENAME" label="时段" :width="tempTablewidth[11].width">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.EAT_PHASENAME}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable="custom" prop="Eat_BTime" label="开台时间" :width="tempTablewidth[12].width" :formatter="formatter">
                        <template #default="scope">
                            <span class="lefts" >{{ new Date(scope.row.Eat_BTime).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable="custom" prop="Eat_CheckTime" label="结账时间" :width="tempTablewidth[13].width" :formatter="formatter">
                        <template #default="scope">
                            <span class="lefts" >{{ new Date(scope.row.Eat_CheckTime).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable="custom" prop="Eat_ID" label="开单号" :width="tempTablewidth[14].width" v-if="!$global.isLableData">
                        <template #default="scope">
                            <el-tooltip  effect="dark" :content="scope.row.Eat_ID" placement="top">
                                <div class="lefts">{{ scope.row.Eat_ID }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                   
                    <el-table-column prop="cao" label="操作人信息" :min-width="tempTablewidth[15].width" align="left">
                        <template #default="scope">
                            <span class="lefts" v-if="scope.row.Eat_Czy">开台: {{scope.row.Eat_Czy}}</span><br/>
                            <span class="lefts" v-if="scope.row.Eat_CheckCzy">结账: {{scope.row.Eat_CheckCzy}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_InvoiceMoney" label="开票金额" :min-width="tempTablewidth[16].width" align="right" data-format="number">
                        <template #default="scope">
                            <div @click="BillInvClick(scope.row)">
                                <span v-if="UpdateBill && UpdateBill?.Is_Show==true" class="iconfont icon-xiugai" style="float:left;padding-left:10px;"></span>
                                {{scope.row.Eat_InvoiceMoney>0?scope.row.Eat_InvoiceMoney:''}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Receive_Point" label="返积分" :min-width="tempTablewidth[17].width" align="right">
                        <template #default="scope">
                            {{scope.row.Receive_Point>0?scope.row.Receive_Point:''}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="Dynamic_No" label="卡号" :min-width="tempTablewidth[18].width" align="left"></el-table-column>
                    <el-table-column prop="Eat_StationName" label="收银站点" :min-width="tempTablewidth[19]?.width" align="left"></el-table-column>
                    <el-table-column fixed="right" label="操作" :width="80" >
                        <template #default="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="page-box">
                <span>当前第 {{tablePageIndex+1}} 页 &nbsp;&nbsp;&nbsp;&nbsp; 共{{tablePageSum}}页</span>
                <div class="page-button">
                     <span class="but but-up" @click="billPage()">上一页</span>
                     <span class="but but-down"  @click="billPage(1)">下一页</span>
                </div>
            </div>
        </div>
        <modal-load :isShow="billlnvShow">
            <update-bill-inv-model :isShow="billlnvShow" :UpdateBillitem="UpdateBillitem" v-on:closeModel="billlnvShow=false,this.$keyavail=false"
            @billInvReturn="billInvReturn"></update-bill-inv-model>
        </modal-load>
        <!-- 修改外卖订单信息 -->
        <modal-load :isShow="isEditCode" isUnload>
            <edit-code-modal :isShow="isEditCode"  @close="isEditCode=false" :data="editData" @confirm="updateTakeOutOrder"/>
        </modal-load>
    </div>
</template>

<script>
import { orderEnum } from '/src/services/enum.js'
import updateBillInvModel from './updateBillInvModel/updateBillInvModel.vue';
import editCodeModal from './editCodeModal/editCodeModal.vue'

/**账单 查询界面 */
export default {
    name:'bill',
    components:{
        updateBillInvModel,
        editCodeModal
    },
    data(){
        return{
            userInfo:{},
            //类型
            navType:0,
            orderChannel:[],//订单渠道,默认是0线下门店固定POS
            selectChannelId:'',//渠道选择
            posEquipmentList:[],//站点数据
            Equipment_Name:'',//站点名称
            channelPageIndex:0,
            channelPageSize:3,
            /**营业时间 */
            businessHours:new Date(),
            /**检索类型 */
            searchType:0,
            /**桌台号检索 */
            deskNumberSearch:'',
            /**表格数据 分页当前页 */
            tablePageIndex:0,
            /**一页多少条 */
            tablePageSize:15,
            sortable:undefined,
            tableData: [],
            /**表头长度 */
            tempTablewidth:[
                {proName:'biao',width:66},
                {proName:'Order_Type',width:66},
                {proName:'Eat_DeskName',width:95},
                {proName:'Eat_CheckIndex',width:155},
                {proName:'Eat_SaleMoney',width:105},
                {proName:'ZK_Money',width:75},
                {proName:'Eat_CheckMoney',width:90},
                {proName:'Eat_State',width:85},
                {proName:'Order_PaymentDetailList',width:160},
                {proName:'CHANNEL_NAME',width:90},
                {proName:'EAT_PHASENAME',width:60},
                {proName:'Eat_BTime',width:140},
                {proName:'Eat_CheckTime',width:140},
                {proName:'Eat_ID',width:155},
                {proName:'cao',width:155},
                {proName:'Eat_InvoiceMoney',width:105},
                {proName:'Receive_Point',width:105},
                {proName:'Dynamic_No',width:105},
                {proName:'Eat_StationName',width:105}
            ],
            //外卖订单数据
            takeOutOrderList:[],
            takeOutInfo:null,
            takeOutPageIndex:1,
            takeOutPageSize:10,
            //外卖订单 渠道 过滤
            channelName:"",
            //异常订单
            abnormalOrderList:[],
            abnormalInfo:null,
            abnormalPageIndex:1,
            abnormalPageSize:10,
            //手工登记发票
            UpdateBill:{},
            UpdateBillitem:{},
            billlnvShow:false,
            //修改外卖订单信息 弹层
            isEditCode:false,
            /**要修改的数据 */
            editData:null
        }
    },
    computed:{
        //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        },
        currentTakeOut(){
            if(this.takeOutInfo?.CanDaoOrder_Autoid){
                return this.takeOutOrderList?.find(it=>it.CanDaoOrder_Autoid==this.takeOutInfo?.CanDaoOrder_Autoid);
            }
            return null;
        },
        /**过滤数据 */
        billFilter(){
            let list=Object.assign([],this.tableData);
            if(list && list.length>0){
                if(this.searchType==0 || this.searchType==1 || this.searchType==2 || this.searchType==3 || this.searchType==4 || this.searchType==9){
                    if(this.deskNumberSearch!=''){
                        let seach=this.deskNumberSearch.toUpperCase();
                        if(this.searchType==0 ){//按台号
                            list=list.filter((item)=> (item.Eat_DeskName||'').toUpperCase().indexOf(seach)>=0);
                        }else if(this.searchType==1 ){//按结账单
                            list=list.filter((item)=> (item.Eat_CheckIndex||'').toUpperCase().indexOf(seach)>=0);
                        }else if(this.searchType==2 ){//按开单号
                            list=list.filter((item)=> (item.Eat_ID||'').toUpperCase().indexOf(seach)>=0);
                        }else if(this.searchType==3 ){//按结账额
                            list=list.filter((item)=> item.Eat_CheckMoney==this.deskNumberSearch);
                        }else if(this.searchType==4 ){//按消费额
                            list=list.filter((item)=> item.Eat_SaleMoney==this.deskNumberSearch);
                        }else if(this.searchType==9 ){//按流水号
                            list=list.filter((item)=> 
                                (item.Order_PaymentDetailList||[]).some(pay=>(pay.Pay_HostTrace||'').toUpperCase().indexOf(seach)>=0)
                            );
                        }
                    }
                }else if(this.searchType==5 ){//已开票
                    list=list.filter((item)=> item.Eat_InvoiceMoney>0);
                    if(this.deskNumberSearch>0){
                        list=list.filter((item)=> item.Eat_InvoiceMoney==this.deskNumberSearch);
                    }
                }else if(this.searchType==6 ){//未开票
                    list=list.filter((item)=> !item.Eat_InvoiceMoney>0);
                }else if(this.searchType==7 ){//已返积分
                    list=list.filter((item)=> item.Receive_Point>0);
                }else if(this.searchType==8 ){//未返积分
                    list=list.filter((item)=> !item.Receive_Point>0);
                }

                
                if(this.selectChannelId!=''){//搜索
                    list=list.filter((item)=>item.CHANNE_PARENTID==this.selectChannelId);
                }
                if(this.Equipment_Name!=""){
                    list=list.filter((item)=>item.Eat_StationName==this.Equipment_Name);
                } 
                //排序
                if(this.sortable){
                    let sorttype=this.sortable.order=='ascending'?'asc':'desc';
                    list.order(this.sortable.prop,sorttype);
                }
                
            }
            return list;
        },
        /**分页数据 */
        billPageList(){
            if(this.billFilter && this.billFilter.length>0){
                 return this.billFilter.slice((this.tablePageIndex*this.tablePageSize),(this.tablePageIndex+1)*this.tablePageSize);
            }
            return [];
        },
        //表格数据 分页总页数 
        tablePageSum(){
            let lenth=1;
            if(this.billFilter && this.billFilter.length>0){
                lenth=Math.ceil(this.billFilter.length/this.tablePageSize)||1;
            }
            return lenth;
        },
        //外卖订单合计金额
        takeOutTotal(){
            let totalSaleMoney=0;
            let totalDiscountMoney=0;
            if(this.takeOutInfo){
               
                this.takeOutInfo?.SpecialOrderConsumeList?.map((it)=>{
                    totalSaleMoney+=it.Eat_SaleMoney;
                })
                this.takeOutInfo?.SpecialOrderDiscountList?.map((it)=>{
                    totalDiscountMoney+=it.Total_DiscountMoney;
                })
            }
            return {
                saleMoney:parseFloat(totalSaleMoney.toFixed(2)),
                discountMoney:parseFloat(totalDiscountMoney.toFixed(2)),
            }
        },
        //异常订单合计金额
        abnormalTotal(){
            let totalSaleMoney=0;
            let totalFactMoney=0;
            if(this.getAbnormalInfo){
               
                this.getAbnormalInfo?.SpecialOrderConsumeList?.map((it)=>{
                    totalSaleMoney+=it.Eat_SaleMoney;
                })
                this.getAbnormalInfo?.SpecialOrderPaymentList?.map((it)=>{
                    totalFactMoney+=it.Payment_FactMoney;
                })
            }
            return {
                saleMoney:parseFloat(totalSaleMoney.toFixed(2)),
                factMoney:parseFloat(totalFactMoney.toFixed(2)),
            }
        },
        /**外卖订单 过滤数据 */
        takeOutFilter(){
            let list=this.takeOutOrderList;
            if(list && list.length>0){
                if(this.channelName!=""){//渠道过滤
                    let seach=this.channelName.trim();
                    list=list.filter((item)=>{
                        return (item.Channel_Name||'').indexOf(seach)>=0;
                    })
                }
            }
            return list;
        },
        /**外卖订单 分页数据 */
        takeOutPageList(){
            if(this.takeOutFilter && this.takeOutFilter.length>0){
                 return this.takeOutFilter.slice(((this.takeOutPageIndex-1)*this.takeOutPageSize),this.takeOutPageIndex*this.takeOutPageSize);
            }
            return [];
        },
        //外卖订单 分页总页数 
        takeOutPageSum(){
            let lenth=1;
            if(this.takeOutFilter && this.takeOutFilter.length>0){
                lenth=Math.ceil(this.takeOutFilter.length/this.takeOutPageSize)||1;
            }
            return lenth;
        },
        /**异常订单 过滤数据 */
        abnormalFilter(){
            let list=this.abnormalOrderList;
            if(list && list.length>0){
                if(this.deskNumberSearch!=''){//搜索
                    let seach=this.deskNumberSearch.toUpperCase();
                    list=list.filter((item)=>{
                        return (item.Eat_DeskName||'').toUpperCase().indexOf(seach)>=0  || (item.Eat_CheckIndex||"").toLowerCase().indexOf(seach)>=0;
                    })
                }
            }
            return list;
        },
        /**异常订单 分页数据 */
        abnormalPageList(){
            if(this.abnormalFilter && this.abnormalFilter.length>0){
                 return this.abnormalFilter.slice(((this.abnormalPageIndex-1)*this.abnormalPageSize),this.abnormalPageIndex*this.abnormalPageSize);
            }
            return [];
        },
        //异常订单 分页总页数 
        abnormalPageSum(){
            let lenth=1;
            if(this.abnormalFilter && this.abnormalFilter.length>0){
                lenth=Math.ceil(this.abnormalFilter.length/this.abnormalPageSize)||1;
            }
            return lenth;
        },
        getAbnormalInfo(){
            if(this.abnormalInfo){
                let SpecialOrderConsumeList=[];
                this.abnormalInfo.Order_ConsumeList?.forEach(it=>{
                    SpecialOrderConsumeList.push({
                        Food_Code:it.EAT_XFCode,
                        Food_Name:it.EAT_XFName,
                        Food_Number:it.Eat_Number,
                        Food_Size:it.Eat_XFSize,
                        Eat_SaleMoney:it.Eat_SalePrice * it.Eat_Number
                    });
                    it.Eat_ComboItemList?.forEach(item=>{
                        SpecialOrderConsumeList.push({
                            Food_Code:item.EAT_XFCode,
                            Food_Name:item.EAT_XFName,
                            Food_Number:item.Eat_Number,
                            Food_Size:item.Eat_XFSize,
                            Eat_SaleMoney:item.Eat_SalePrice * item.Eat_Number
                        });
                    })
                })


                let info={
                    Eat_Czy:this.abnormalInfo.Eat_Czy||(this.abnormalInfo.Order_ConsumeList||[])[0]?.Eat_Czy,
                    Eat_StationName:this.abnormalInfo.Eat_StationName,
                    Eat_Time:this.formatter(null,null,this.abnormalInfo.Eat_BeginTime),
                    ProcessMsg:"",
                    SpecialOrderConsumeList:SpecialOrderConsumeList,
                    SpecialOrderPaymentList:this.abnormalInfo.Order_PaymentDetailList?.map(it=>{
                        return {
                            PaymentName:it.Payment_Name,
                            Payment_FactMoney:it.Payment_Money
                        }
                    })
                };
                this.orderChannel?.map(it=>{
                    if(it.OrderChannelKind_AutoID==this.abnormalInfo.CHANNEL_ID){
                        info.Channel_Name=it.OrderChannelKind_Name;
                    }else{
                        let channel=it.OrderChannelList.find(d=>d.OrderChannel_AutoID==this.abnormalInfo.CHANNEL_ID);
                        if(channel){
                            info.Channel_Name=channel.OrderChannel_Name;
                        }
                    }
                })

                if (this.abnormalInfo.Order_Type == 0){
                     info.ProcessMsg = "订单无法关联到桌台！（中餐）";
                }else if(this.abnormalInfo.Order_Type == 1){
                    info.ProcessMsg = "订单未完成结账！（快餐）";
                }
                return info
            }
            return null;
        }
    },
    /**以及每次从缓存中被重新插入的时候调用 */
    activated(){
        this.$emit("navIndex",1);
        if(this.activated){
            if(!this.$route.params?.isNoRefresh){//清除之前的缓存信息
                this.init();
                this.loadBillList();
            }
        }
        this.activated=true;
    },
    mounted(){
        this.$emit("navIndex",1);
        this.$nextTick(() => {
            this.userInfo= this.$auth.getUserInfo();
            //自定义功能按钮
            let SysProjects = this.$cacheData.SysProjects.get();
            if(SysProjects && SysProjects.length>0){
                SysProjects.forEach(element=>{
                    //其他
                    if(element.Project_Key=='Config'){
                        element.SysProjectGroups.forEach(sys=>{
                            if(sys.Group_Key=='BaseSet_CloudPos'){
                                sys.SysProjectItems.forEach(item=>{
                                    //业务功能
                                    if(item.ProjectItem_Key=='BusinessSet'){
                                        item.SysProjectSubItems.forEach(pro=>{
                                            if(pro.ProjectSubItem_Key=='UpdateBillInv'){
                                                this.UpdateBill=pro
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
            if(this.userInfo && this.userInfo.Rpt_Date){//营业日期
                this.businessHours=this.userInfo.Rpt_Date;
            }
            //渠道
            this.$cacheData.OrderChannel().then((d)=>{
              this.orderChannel = d;
            }).catch((e)=>{
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
            this.$cacheData.PosEquipments().then((d)=>{
                if(d){
                    this.posEquipmentList=d.filter(it=>it.Sys_ID==801)
                }
            }).catch((e)=>{
                this.$message.error('加载站点数据失败：'+e,);
                console.log('加载站点数据失败：'+e);
            })
            this.loadBillList();
            /**拖动表宽度 */
            //resizeTable();
        });
    },
    watch:{
        /*监听搜索字符串的变化 */
        deskNumberSearch(val) {
            if(val!=''){
                this.tablePageIndex=0;
            }
        },
        /*监听 渠道筛选导航  */
        selectChannelId(val) {
            if(val!=''){
                this.tablePageIndex=0;
            }
        },
        channelName(val){
            if(val!=''){
                this.takeOutPageIndex=1;
            }
        },
        navType(){
             this.search();
        },
        takeOutOrderList(){
            if(this.takeOutInfo && this.takeOutOrderList?.findIndex(it=>it.CanDaoOrder_Autoid==this.takeOutInfo?.CanDaoOrder_Autoid)<0){
                this.takeOutInfo=null;
            }
            
        },
        abnormalOrderList(){
            if(this.abnormalInfo && this.abnormalOrderList?.findIndex(it=>it.Eat_AutoID==this.abnormalInfo?.Eat_AutoID)<0){
                this.abnormalInfo=null;
            }
        },
        searchType(){
            this.deskNumberSearch=""
        }
    },
    created() {
        // 当页面刷新时，若本地存储中存的有表头数组信息，就用本地的。当然第一次本地是没有的
        if(this.$route.params?.billType==1){
            if(sessionStorage.getItem("tempwidths")){
                this.tempTablewidth = JSON.parse(sessionStorage.getItem("tempwidths"));
            }
        }else{
            sessionStorage.removeItem("tempwidths")
        }
    },
    methods: {
        disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-oneDay;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        init(){//初始化清空数据
            this.userInfo= this.$auth.getUserInfo();
            if(this.userInfo && this.userInfo.Rpt_Date){//营业日期
                this.businessHours=this.userInfo.Rpt_Date;
            }
            this.navType=0;
            /**检索类型 */
            this.searchType=0;
            this.selectChannelId='';//渠道选择
            this.Equipment_Name='';//站点名称
            /**桌台号检索 */
            this.deskNumberSearch='';
            /**表格数据 分页当前页 */
            this.tablePageIndex=0;
            this.sortable=null;
            this.tableData= [],
            //外卖订单数据
            this.takeOutOrderList=[];
            this.takeOutInfo=null;
            this.takeOutPageIndex=1;
            //外卖订单 渠道 过滤
            this.channelName="";
            //异常订单
            this.abnormalOrderList=[];
            this.abnormalInfo=null;
            this.abnormalPageIndex=1;
            //手工登记发票
            //this.UpdateBill={};
            this.UpdateBillitem={};
            this.billlnvShow=false;
        },
        resize(){
            if(this.navType==1){
                if(this.$refs.takeOutTableBox){
                    let height=this.$refs.takeOutTableBox.clientHeight - 40;
                    this.takeOutPageSize=parseInt(height/52);
                }
            }else if(this.navType==2){
                if(this.$refs.abnormalTableBox){
                    let height=this.$refs.abnormalTableBox.clientHeight - 40;
                    this.abnormalPageSize=parseInt(height/52);
                }
            }
        },
        // 表头拖动事件
        headerDragend(newWidth, oldWidth, column, event){
            //依据column中的信息就能够晓得用户拖动的是哪一列，从而将新的列宽度替换原来的列宽度
            let newTableHeader = this.tempTablewidth.map((item, index) => {
                if(column.property==item.proName) {
                    item.width = newWidth;
                }
                return item;
            });
            // 最初存一份到本地，当刷新的时候，就用本地的这个记录了用户拖动宽度的表头数组数据
            sessionStorage.setItem("tempwidths", JSON.stringify(newTableHeader));
        },
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                let height=this.$refs.tableBox.clientHeight - 90;
                this.tablePageSize=parseInt(height/50);
            }
        },
        search(){
            if(this.navType==1){//外卖订单
                this.loadSpecialOrderInfo(1);
            }else if(this.navType==2){//异常订单
                this.loadSpecialOrderInfo(2);
            }else{
                this.loadBillList();
            }
        },
        /**加载账单数据 */
        loadBillList(){
            this.tablePageIndex=0;
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetBusinessBills",{
                Rpt_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),
                User_ID:this.$auth.getUserID(),
                IsHideMarkedBills:this.$global.isLableData //是否隐藏带标签的订单
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.setTablePageSize();
                    console.log(d)
                    this.tableData=d.ResponseBody||[];
                }else{
                    this.tableData=[];
                    //if(d.ResponseHeader.ResultCode!=3003){
                        this.$message.error(d.ResponseHeader.ResultDesc);
                    //}
                }
            }).catch((e)=>{
                this.tableData=[];
                loading.close();
                this.$message.error("加载账单数据失败："+e.message);
                console.log('加载账单数据失败：',e);
            })
        },
        /**加载 异常订单 OrderKind 订单类型 1外卖订单 2异常订单 */
        loadSpecialOrderInfo(OrderKind){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetSpecialOrderInfo",{
                OrderKind:OrderKind,
                User_ID:this.$auth.getUserID()
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(OrderKind==1){
                        this.takeOutOrderList=d.ResponseBody||[];
                    }else{
                        this.abnormalOrderList=d.ResponseBody||[];
                    }
                }else{
                    if(OrderKind==1){
                        this.takeOutOrderList=[];
                    }else{
                        this.abnormalOrderList=[];
                    }
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('加载'+(OrderKind==1?'外卖订单':'异常订单')+'数据失败：'+e.message);
                console.log('加载'+(OrderKind==1?'外卖订单':'异常订单')+'数据失败：'+e);
            })
        },
        //加载异常订单明细信息
        getSpecialOrderConsume(id,OrderKind){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetSpecialOrderConsume",{
                OrderKind:OrderKind,
                OrderAutoid:id,
                User_ID:this.$auth.getUserID()
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    console.log(d)
                    if(OrderKind==1){
                        this.takeOutInfo=d.ResponseBody||{};
                    }else{
                        this.abnormalInfo=d.ResponseBody||{};
                    }
                }else{
                    if(OrderKind==1){
                        this.takeOutInfo=this.takeOutOrderList[0];
                    }else{
                        this.abnormalInfo=this.abnormalOrderList[0];
                    }
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                
                loading.close();
                this.$message.error('加载'+(OrderKind==1?'外卖订单':'异常订单')+'明细数据失败：'+e.message);
                console.log('加载'+(OrderKind==1?'外卖订单':'异常订单')+'明细数据失败：'+e);
            })
        },
        /**外卖订单重新入单 */
        takeoutInPos(id){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.TakeoutInPos",{
                CanDaoOrder_Autoid:id,
                User_ID:this.$auth.getUserID()
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("重新入账成功");
                    this.loadSpecialOrderInfo(1);
                    this.getSpecialOrderConsume(id,1);
                }else{
                    this.takeOutInfo.ProcessMsg="单据入库失败："+d.ResponseHeader.ResultDesc;
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableData=[];
                loading.close();
                this.$message.error('重新入账失败：'+e.message);
                console.log('重新入账失败：',e);
            })
        },
        /**外卖订单 重新同步订单 */
        selfTakeoutInPos(id){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.OnlineScanQR.SelfTakeoutInPos",{
                CanDaoOrder_Autoid:id,
                User_ID:this.$auth.getUserID()
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("重新同步订单成功");
                    this.loadSpecialOrderInfo(1);
                    this.getSpecialOrderConsume(id,1);
                }else{
                    this.takeOutInfo.ProcessMsg="重新同步订单失败："+d.ResponseHeader.ResultDesc;
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableData=[];
                loading.close();
                this.$message.error('重新同步订单失败：'+e.message);
                console.log('重新同步订单失败：',e);
            })
        },
        /**外卖订单 取消订单 */
        cancelOrderCandao(id){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.OnlineScanQR.CancelOrderCandao",{
                CanDaoOrder_Autoid:id,
                CancelReason:"取消订单",
                User_ID:this.$auth.getUserID()
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("取消订单成功");
                    this.loadSpecialOrderInfo(1);
                    this.getSpecialOrderConsume(id,1);
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
                this.loadSpecialOrderInfo(1);
                this.getSpecialOrderConsume(id,1);
            }).catch((e)=>{
                this.tableData=[];
                loading.close();
                this.$message.error('重新同步订单失败：'+e.message);
                console.log('重新同步订单失败：',e);
            })
        },
        /**付款情况 */
        paymentInfo(list){
            let str=[];
            if(list && list.length>0){                
                list.forEach(item => {
                    str.push(item.Payment_Name+":"+item.Payment_Money);
                });
            }
            return str.join(',');
        },
        /*时间格式 */
        formatter(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /*单据类型  */
        orderType(cellValue){
            return orderEnum[cellValue];
        },
        /**查看账单明细 */
        handleClick(row){
            if(row.Eat_State==1){
                this.$router.push({name: 'diningDetail', params:{Desk_AutoID:row.Eat_DeskID,Eat_AutoID:row.Eat_AutoID,urlType:0}});
            }else{
                this.$router.push({name: 'billDetail', params:{id:row.Eat_AutoID,Rpt_Date:row.Rpt_Date}});
            }
        },
        /**双击 某一行*/
        dblclick(row, column){
            if(column.label!= "操作"){
                this.handleClick(row);
            }
        },
        /**账单数据排序 */
        sortChange(column){
            this.sortable=column;
        },
        /**账单数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        /**渠道类型分页 */
        channerPage(type){
            if(type==1){
                if((this.channelPageIndex+1)*this.channelPageSize<this.orderChannel.length){
                    this.channelPageIndex++;
                }
            }else{
                this.channelPageIndex>0?this.channelPageIndex--:this.channelPageIndex=0;
            }
        },
        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
            if (index === 0) { // 只找第一列放合计
                sums[index] = '合计：'
                return
            }else if (index==2 || index==4 || index==5 || index==6 || index==7 || column.property=="Receive_Point" || column.property=="Eat_InvoiceMoney"){
                const values = this.billFilter.map(item => Number(item[column.property]))
                if(index==2){
                    let num = this.billFilter.length
                    return sums[index] = num+'笔'
                }
                if (!values.every(value => isNaN(value))) {
                sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr)
                    if (!isNaN(value)) {
                        return  Number(Number(prev)+Number(curr)).toFixed(2)
                    } else {
                        return Number(prev).toFixed(2)
                    }
                },0)} else {
                    sums[index] = 'N/A'
                    }
                } else {
                    sums[index] = '--'
                    }
                })
            return sums
        },
        /**重印结账单 */
        print(){
            const loading = this.$loading({
                text: "请求结账单打印数据...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let param={
                UserID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Rpt_Date:userInfo?.Rpt_Date,
                /**业务， 1：触摸屏POS   2：扫码点单   3：外卖 */
                PrintBusiness:3,
                /**打印类型， 打印类型， 
                 * 1	消费清单 2	结账清单 8	客单单 9	结班单 10	日结单 88	加菜单
                 */
                PrintType:2,
                Eat_CheckIndex:this.takeOutInfo.Eat_CheckIndex,//结账单号
                Order_ID:this.takeOutInfo.Eat_AutoID,//订单ID
                Is_CheckedOut:true,
            }
            this.$httpAES.post("Bestech.CloudPos.GetPrintContent",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$webBrowser.posPrint(d.ResponseBody);
                    this.$message.success("已发送结账单打印指令");
                }else{
                    this.$message.error("请求结账单打印数据失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('请求结账单打印数据失败：'+e.message);
                console.log('请求结账单打印数据失败：',e);
            })
        },
        /**修改账单开票金额 */
        BillInvClick(scope){
            if(this.UpdateBill.Is_Show){
                if(this.UpdateBill.Permission==true){
                    this.billlnvShow=true
                    this.UpdateBillitem = scope
                }else{
                    //confirmButtonText: '去验证',
                    this.$confirm('该账户权限不足,不支持'+this.UpdateBill.ProjectSubItem_Name+'功能', '提示', {
                        cancelButtonText: '我知道了',
                        type: 'warning'})
                    .then(() => {
                        this.$keyavail=true
                    }).catch(()=>{});
                }
            }
        },
        billInvReturn(money){
            this.billlnvShow=false
            const loading = this.$loading({
                text: "修改结账单开票金额数据...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let param={
                UserID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Rpt_Date:this.UpdateBillitem?.Rpt_Date,
                Eat_CheckID:this.UpdateBillitem?.Eat_CheckID,//结账单ID
                Invoice_Money:money,//发票金额
            }
            this.$httpAES.post("Bestech.CloudPos.ModifyInvoiceMoney",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("结账单开票金额修改成功");
                    this.loadBillList()
                    this.UpdateBillitem={}
                }else{
                    this.$message.error("请求结账单开票修改失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('请求结账单开票修改失败：'+e.message);
                console.log('请求结账单开票修改失败：',e);
            })
        },
        /**去重新结账 */
        reCheckout(){
            this.$router.push({name: 'fastFoodDetail', params:{data:JSON.stringify(this.abnormalInfo)}}); 
        },
        /**Excel导出 */
        exportExcel(){

            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }

            if(this.$refs.tableEl){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.tableEl,
                    titleName:"账单",
                    list:this.billFilter
                })
            }
        },
        /**修改外卖订单中的 异常订单 */
        clickEdit(row){
            this.isEditCode=true;
            this.editData=row;
            console.log(row)
        },
        /**修改编号 */
        updateTakeOutOrder(newCode){
            if(!newCode){
                this.$message.warning("新菜品编号不能为空!");
                return;
            }
            let param={
                CanDaoProduct_Autoid:this.editData.CanDaoProduct_Autoid,
                Food_Code:newCode
            }
            const loading = this.$loading({
                text: "提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetUpdateTakeOutOrder",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error("修改外卖订单信息失败:"+data.ResponseHeader.ResultDesc);
                }else{
                    this.$message.success({ message: "修改成功"});
                    this.isEditCode=false;
                    this.getSpecialOrderConsume(this.takeOutInfo.CanDaoOrder_Autoid,1);
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "修改外卖订单信息失败", {confirmButtonText: "确定"});
            })
        }
    }
}
</script>

<style lang="scss">
    @import "./bill.scss"
</style>