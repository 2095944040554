<template>
	<div style="width:100%;height:100%">
		<router-view  v-on:routerLoad="routerLoad" v-if="isRouterAlive" />
		<div style="height:100%" v-else v-loading="!isRouterAlive" :fullscreen="true"></div>
		<analog-key-board ref="keyBoard" :isKeyDownEnter="isKeyDownEnter" :isNumber="isNumber"></analog-key-board>
	</div>
</template>
<script>
import { config } from './common'
export default{
	data(){
		return {
			isRouterAlive:true,
			isKeyDownEnter:false,
			isNumber:false
		}
	},
	watch:{
		isRouterAlive(newVal){
			if(!newVal){
				this.$nextTick(()=>{
					this.isRouterAlive=true;
				})
			}
		}
	},
	mounted(){
		this.$nextTick(()=>{
			this.$keyBoard.show=(input,options)=>{
				this.isKeyDownEnter=options?.isKeyDownEnter||false;//是否响应回车
				this.isNumber=options?.isNumber||false;//是否展现位数字键盘
				this.$refs.keyBoard.show(input,options);
				input.focus();
			}

			this.$keyBoard.close=(input)=>{
				this.$refs.keyBoard.close(input);
			}
			
		})
	},
	methods:{
		routerLoad(){
			this.isRouterAlive=false;
		}
	},
	beforeCreate() {		

		if(this.$cacheData.get("fullScreen")===false){
			this.$webBrowser.formMax();
		}

		this.$webBrowser.getDevices().then(data=>{
			if(data){
				this.$global.devices=data;
				if(this.$webBrowser.isMobile.Android){//安卓设备
					this.$global.getSysVersion={
						Sys_ID : data.Sys_ID,
						Version_ID : data.versionCode,
						Version_No : data.versionName
					}
				}
			}
		}) 
		//获取版本信息
		this.$webBrowser.getSysVersion().then(data=>{
			if(data){
				this.$global.getSysVersion=data;
			}
		})
		
		if(process.env.NODE_ENV=== "development"){//开发环境  设置信息固定
			this.$global.devices={uuid:'liulanqi2'}
			config.UUID="fa0f920b-427a-4316-b2df-03b628372b5b";//"cac4ae4f-ff63-478d-af99-1c438e31a72c";//"8453be60-6e4e-4b8c-b684-cbeb4adc2b28"
			config.Access_Key="0226FBCC8A9F4528B351BFEFA122236F";//"A749D01CD7724822A46A409354EF1E75"
			config.Secret_key="56D299FE31A24C53800C3B45E2904ABC";//"6A6F7C8F712C46CCA2D2FAE240F96D9D"
			config.OpenAPIUrl="http://139.196.82.8:8056"
			// this.$global.devices={uuid:'18E71CF62AA474C5FA6EF2AA6E7D1B48'}
			// config.UUID="653266e1-2e97-4b43-a221-063887ccb407";//"cac4ae4f-ff63-478d-af99-1c438e31a72c";//"8453be60-6e4e-4b8c-b684-cbeb4adc2b28"
			// config.Access_Key="0226FBCC8A9F4528B351BFEFA122236F";//"A749D01CD7724822A46A409354EF1E75"
			// config.Secret_key="56D299FE31A24C53800C3B45E2904ABC";//"6A6F7C8F712C46CCA2D2FAE240F96D9D"
			// config.OpenAPIUrl="http://139.196.82.8:8056"
		}
		
		
		/**发布时不 需要 end*/
		this.$webBrowser.getConfig().then(data=>{
			if(data){
				config.Access_Key=data.Access_Key;
				config.Secret_key=data.Secret_key;
				config.OpenAPIUrl=data.OpenAPIUrl;
				config.UUID=data.UUID;
			}
			if(this.$route.name=="auxiliaryScreen"){//副屏显示
				return;
			}
			if(!config.Access_Key || !config.Secret_key || !config.OpenAPIUrl){
				this.$router.push('/register');
			}
		})
		setTimeout(()=>{
			if(this.$route.name!="auxiliaryScreen"){//副屏显示
				this.$webBrowser.openAuxiliaryScreen();//显示副屏
			}
		},100)
		
	}
}
</script>
<style>
@import './assets/style/common.css';
@import './assets/style/glyphicons.css';
html,body{
	width: 100%;
	height: 100%;
}
#app {
	width: 100%;
	height: 100%;
}
</style>
